<template>
  <!--begin::Menu-->
  <div
    class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold py-4 fs-6 w-275px"
    data-kt-menu="true">
    <!--begin::Menu item-->
    <div class="menu-item px-3">
      <div class="menu-content d-flex align-items-center px-3">
        <!--begin::Avatar-->
        <div class="symbol symbol-50px" :class="direction == 'ltr' ? 'me-5' : 'ms-5'">
          <img alt="Logo" src="/media/avatars/blank.png" />
        </div>
        <!--end::Avatar-->

        <!--begin::Username-->
        <div class="d-flex flex-column">
          <div class="fw-bolder d-flex align-items-center fs-5">
            {{ user.name }}
            <!--<span
                class="badge badge-light-success fw-bolder fs-8 px-2 py-1 ms-2"
                >Pro</span
              >-->
          </div>
          <a href="#" class="fw-bold text-muted text-hover-primary fs-7 email">{{
            user.email
          }}</a>
        </div>
        <!--end::Username-->
      </div>
    </div>
    <!--end::Menu item-->

    <!--begin::Menu separator-->
    <div class="separator my-2"></div>
    <!--end::Menu separator-->

    <!--begin::Menu item-->
    <div class="menu-item px-5">
      <router-link :to="{ name: 'account-overview' }" class="menu-link px-5">
        {{ $t('account.my_profile') }}
      </router-link>
    </div>
    <!--end::Menu item-->

    <!--begin::Menu separator-->
    <div class="separator my-2"></div>
    <!--end::Menu separator-->

    <!--begin::Menu item-->
    <div class="menu-item px-5" data-kt-menu-trigger="hover" data-kt-menu-placement="left-start"
      data-kt-menu-flip="center, top">
      <a href="#" class="menu-link px-5">
        <!--<span>Language</span>-->
        <span class="menu-title-lang position-relative">
          {{ $t('account.language') }}
          <span class="fs-8 rounded bg-light px-3 py-2 position-absolute translate-middle-y top-50"
            :class="direction == 'rtl' ? 'start-0' : 'end-0'">
            <!--{{ currentLangugeLocale.name }}-->
            {{ currentLangugeLocale.name }}

            <img class="w-15px h-15px rounded-1" :class="direction == 'ltr' ? 'ms-2' : 'me-2'"
              :src="currentLangugeLocale.flag" alt="metronic" />
          </span>
        </span>
      </a>

      <!--begin::Menu sub-->
      <div class="menu-sub menu-sub-dropdown w-175px py-4">
        <!--begin::Menu item-->
        <div class="menu-item px-3">
          <a href="#" class="menu-link d-flex px-5" @click="selectLanguage('en')">
            <span class="symbol symbol-20px" :class="direction == 'ltr' ? 'me-4' : 'ms-4'">
              <img class="rounded-1" src="/media/flags/united-states.svg" alt="metronic" />
            </span>
            {{ $t('languages.English') }}
          </a>
        </div>
        <!--end::Menu item-->

        <!--begin::Menu item-->
        <div class="menu-item px-3">
          <a href="#" class="menu-link d-flex px-5" @click="selectLanguage('ar')">
            <span class="symbol symbol-20px" :class="direction == 'ltr' ? 'me-4' : 'ms-4'">
              <img class="rounded-1" src="/media/flags/saudi-arabia.svg" alt="metronic" />
            </span>
            {{ $t('languages.Arabic') }}
          </a>
        </div>
        <!--end::Menu item-->
      </div>
      <!--end::Menu sub-->
    </div>
    <!--end::Menu item-->

    <!--begin::Menu item-->
    <div class="menu-item px-5 my-1">
      <router-link :to="{ name: 'account-settings' }" class="menu-link px-5">
        {{ $t('constants.account_settings') }}
      </router-link>
    </div>
    <!--end::Menu item-->

    <!--begin::Menu item-->
    <div class="menu-item px-5">
      <span class="menu-link px-5" @click="logout">{{ $t('account.sign_out') }}</span>
    </div>
    <!--end::Menu item-->
  </div>
  <!--end::Menu-->
</template>

<script>

export default {
  name: "kt-user-menu",
  components: {},
  computed: {
    /*user() {
        return this.$store.getters.user;
      }*/
    currentLangugeLocale() {
      return this.countries[this.$i18n.locale];
    },
    direction() {
      return this.$store.state.direction;
    }
  },
  data() {
    return {
      user: this.$store.state.user,
      countries: {
        en: {
          flag: "/media/flags/united-states.svg",
          name: this.$t('languages.English'),
        },
        ar: {
          flag: "/media/flags/saudi-arabia.svg",
          name: this.$t('languages.Arabic'),
        },
      }
    };
  },
  methods: {
    logout() {
      this.$store.commit("setLogout");
      setTimeout(() => {
        this.$router.go(0);
      }, 500);
    },
    selectLanguage(value) {
      this.$store.commit('setLanguage', value);
      setTimeout(() => {
        window.location.reload();
      });
    },
  },
};
</script>

<style>
.menu-title-lang {
  display: flex;
  align-items: center;
  flex-grow: 1;
}

.email {
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  display: -webkit-inline-box;
  word-break: break-word;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
</style>
