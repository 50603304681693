<template>
  <!-- begin:: Body -->
  <div class="page d-flex flex-row flex-column-fluid dashboard-wrapper">
    <div id="kt_wrapper" class="wrapper d-flex flex-column flex-row-fluid">
      <Header />
      <transition :name="viewAnimation" mode="out-in">
        <div class="main-container mt-6">
          <router-view />
        </div>
      </transition>
      <Footer />
    </div>
  </div>
</template>

<script>
import Header from "@/components/dashboard/header/DashboardHeader.vue";
import Footer from "@/components/dashboard/DashboardFooter.vue";
import { DrawerComponent } from "@/assets/components/index";
export default {
  name: "MainPage",
  computed: {
    viewAnimation() {
      return this.$store.getters.viewAnimation || "none";
    },
  },
  components: {
    Header,
    Footer,
  },
  mounted() {
    //this.$store.commit("setSplashScreen", false);
    DrawerComponent.bootstrap();
    //DrawerComponent.updateAll();
  },
};
</script>

<style lang="scss">
// Dashboard style scss
@import "../../assets/dashboard/sass/plugins";
@import "../../assets/dashboard/sass/style";

.dashboard-wrapper {
  background-color: #f5f8fa !important;
  height: auto !important;
  min-height: 100vh;
}
.main-container {
  padding: 0 30px;
  text-align: start;
}
body {
  height: auto !important;
  min-height: 100vh;
}
</style>
