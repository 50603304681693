<template>
  <div class="card">
    <div class="card-header border-0 pt-6">
      <!--begin::Card title-->
      <div class="card-title">
        <!--begin::Search-->
        <div class="d-flex align-items-center position-relative my-1">
          <span class="svg-icon svg-icon-1 position-absolute" :class="direction == 'rtl' ? 'me-6' : 'ms-6'">
            <svg fill="none" viewBox="0 0 24 24" height="24" width="24" xmlns="http://www.w3.org/2000/svg">
              <rect xmlns="http://www.w3.org/2000/svg" opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2"
                rx="1" transform="rotate(45 17.0365 15.1223)" fill="black"></rect>
              <path xmlns="http://www.w3.org/2000/svg"
                d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                fill="black"></path>
            </svg>
          </span>
          <input type="text" v-model="search" @input="searchItems()"
            class="form-control form-control-solid w-250px table-search-input"
            :placeholder="$t('reservations.search_reservation')" :class="direction == 'rtl' ? 'pe-15' : 'ps-15'" />
        </div>
        <!--end::Search-->
      </div>
      <!--begin::Card title-->
      <!--begin::Card toolbar-->
      <div class="card-toolbar">
        <!--begin::Toolbar-->
        <!--<div
              v-if="checkedCustomers.length === 0"
              class="d-flex justify-content-end"
              data-kt-customer-table-toolbar="base"
            >
             
              <button
                type="button"
                class="btn btn-light-primary me-3"
                data-bs-toggle="modal"
                data-bs-target="#kt_customers_export_modal"
              >
                <span class="svg-icon svg-icon-2">
                  <inline-svg src="/media/icons/duotune/arrows/arr078.svg" />
                </span>
                Export
              </button>
             
              <button
                type="button"
                class="btn btn-primary"
                data-bs-toggle="modal"
                data-bs-target="#kt_modal_add_customer"
              >
                <span class="svg-icon svg-icon-2">
                  <inline-svg src="/media/icons/duotone/Navigation/Plus.svg" />
                </span>
                Add Customer
              </button>
              
            </div>-->
        <!--end::Toolbar-->
        <!--begin::Group actions-->
        <div v-if="checkedCustomers.length > 0" class="d-flex justify-content-end align-items-center"
          data-kt-customer-table-toolbar="selected">
          <div class="fw-bolder me-5">
            <span class="me-2">{{ checkedCustomers.length }}</span>Selected
          </div>
          <button type="button" class="btn btn-danger" @click="deleteFewCustomers()">
            Delete Selected
          </button>
        </div>
        <!--end::Group actions-->
        <!--begin::Group actions-->
        <div class="d-flex justify-content-end align-items-center d-none" data-kt-customer-table-toolbar="selected">
          <div class="fw-bolder me-5">
            <span class="me-2" data-kt-customer-table-select="selected_count"></span>Selected
          </div>
          <button type="button" class="btn btn-danger" data-kt-customer-table-select="delete_selected">
            Delete Selected
          </button>
        </div>
        <!--end::Group actions-->
      </div>
      <!--end::Card toolbar-->
    </div>
    <div class="card-body pt-0">
      <Datatable :table-header="tableHeader" :enable-items-per-page-dropdown="true" :url="url" :searchTermProp="search"
        ref="datatable">
        <template v-slot:cell-checkbox="{ row: item }">
          <div class="form-check form-check-sm form-check-custom form-check-solid">
            <input class="form-check-input" type="checkbox" :value="item.id" v-model="checkedCustomers" />
          </div>
        </template>
        <template v-slot:cell-reservation_no="{ row: item }">
          <span># {{ item.reservation_no }}</span>
        </template>
        <template v-slot:cell-car_title="{ row: item }">
          {{ item.car_title }}
        </template>
        <template v-slot:cell-price="{ row: item }">
          {{ formatMoney(item.price, currency) }}
        </template>
        <template v-slot:cell-duration="{ row: item }">
          {{ item.duration | dayFilter($t('constants.day'), $t('constants.days')) }}
        </template>
        <template v-slot:cell-total_price="{ row: item }">
          {{ formatMoney(item.total_price, currency) }}
        </template>

        <template v-slot:cell-status="{ row: item }">
          <span class="badge" :class="orderStatus(item.status)">
            {{ orderStatusName(item.status) }}
          </span>
        </template>
        <template v-slot:cell-payment_status="{ row: item }">
          <span class="badge" :class="paymentStatusColor(item.payment_status)">
            {{ orderPaymentStatusName(item.payment_status) }}
          </span>
        </template>
        <template v-slot:cell-actions="{ row: item }">
          <div>
            <router-link :to="{ name: 'rental-cars-reservation-view', params: { id: item.id } }"
              class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1">
              <span class="svg-icon svg-icon-3">
                <inline-svg src="/media/icons/duotune/general/gen019.svg" />
              </span>
            </router-link>
            <a href="#" class="
                  btn btn-icon btn-bg-light btn-active-color-primary btn-sm
                  me-1
                " data-bs-toggle="modal" data-bs-target="#modal_update_reservation_date"
                @click="setId(item.id)">
              <span class="svg-icon svg-icon-3">
                <inline-svg src="/media/icons/duotune/art/art005.svg" />
              </span>
            </a>
          </div>

        </template>
      </Datatable>
    </div>
    <UpdateOrderDate type="car" :id="selectedId" @success="loadData()"></UpdateOrderDate>
  </div>

  <!--<ExportCustomerModal></ExportCustomerModal>
        <AddCustomerModal></AddCustomerModal>-->
</template>
  
<script>
import Datatable from "@/components/kt-datatable/Datatable.vue";
import { MenuComponent } from "@/assets/components";
import UpdateOrderDate from "../modals/UpdateOrderDate.vue";
import helpers from "@/helpers";

export default {
  name: "RentalCarsReservations",
  components: {
    Datatable,
    UpdateOrderDate
  },
  filters: {
    dayFilter(value, singularTranslation, pluralTranslation) {
      if (value == 1) {
        return `${value} ${singularTranslation}`;
      } else if (value > 1) {
        return `${value} ${pluralTranslation}`;
      }
    },
  },
  data() {
    return {
      currency: this.$store.state.user.currency,
      url: "reservations/rental-cars",
      MenuComponent: MenuComponent,
      checkedCustomers: [],
      tableHeader: [
        {
          key: "checkbox",
        },
        {
          name: this.$t('reservations.reservation_no'),
          key: "reservation_no",
          sortable: true,
        },
        {
          name: this.$t('cars.reservations.car_title'),
          key: "car_title",
          sortable: true,
        },
        {
          name: this.$t('cars.reservations.price'),
          key: "price",
          sortable: true
        },
        {
          name: this.$t('cars.reservations.duration'),
          key: "duration",
          sortable: true
        },
        {
          name: this.$t('cars.reservations.total_price'),
          key: "total_price",
          sortable: true
        },
        {
          name: this.$t('reservations.status'),
          key: "status",
          sortable: true,
        },
        {
          name: this.$t('reservations.payment_status'),
          key: "payment_status",
          sortable: true,
        },
        {
          name: this.$t('constants.actions'),
          key: "actions"
        },
      ],
      initCustomers: [],
      search: "",
      selectedId: ""
    };
  },
  computed: {
    direction() {
      return this.$store.state.direction;
    }
  },
  methods: {
    loadData(){
      this.$refs.datatable.loadItems();
    },
    setId(id){
      this.selectedId = id
    },
    formatMoney(amount, currency) {
      return helpers.formatMoney(amount, currency);
    },
    orderPaymentStatusName(status) {
      if (status == 0) {
        return this.$t('orders.not_paid');
      } else if (status == 1) {
        return this.$t('orders.paid');
      } else if (status == 2) {
        return this.$t('orders.paid_in_office')
      }
    },
    orderStatusName(status) {
      if (status == 0) {
        return this.$t('orders.pending');
      } else if (
        status == 1
      ) {
        return this.$t('orders.accepted');
      } else if (
        status == 2
      ) {
        return this.$t('orders.canceled');
      }
    },

    orderStatus(status) {
      if (status == 0) {
        return "bg-warning text-dark";
      } else if (
        status == 2 ||
        status == 3
      ) {
        return "bg-danger";
      } else if (
        status == 1
      ) {
        return "bg-success";
      }
    },

    paymentStatusColor(status) {
      if (status == 0) {
        return 'bg-warning text-dark';
      } else if (
        status == 1
      ) {
        return 'bg-success';
      } else if (
        status == 2
      ) {
        return 'bg-success';
      }
    },
    deleteFewCustomers() {
      this.checkedCustomers.forEach((item) => {
        this.deleteCustomer(item);
      });
      this.checkedCustomers.length = 0;
    },

    deleteCustomer(id) {
      for (let i = 0; i < this.customers.length; i++) {
        if (this.customers[i].id === id) {
          this.customers.splice(i, 1);
        }
      }
    },

    searchItems() {
      this.customers.splice(0, this.customers.length, this.initCustomers);
      if (this.search !== "") {
        let results = [];
        for (let j = 0; j < this.customers.length; j++) {
          if (this.searchingFunc(this.customers[j], this.search)) {
            results.push(this.customers[j]);
          }
        }
        this.customers.splice(0, this.customers.length, results);
      }
    },

    searchingFunc(obj, value) {
      for (let key in obj) {
        if (!Number.isInteger(obj[key]) && !(typeof obj[key] === "object")) {
          if (obj[key].indexOf(value) != -1) {
            return true;
          }
        }
      }
      return false;
    },
  },
  mounted() {
    this.MenuComponent.reinitialization();
    this.initCustomers.splice(0, this.customers.length, this.customers);
  },
  /*moi:{
          
          
    
          //setCurrentPageBreadcrumbs("Customers Listing", ["Apps", "Customers"]);
      }*/
};
</script>
  
<style>
.table-search-input {
  height: 40px !important;
}
</style>
  