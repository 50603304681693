import Vue from "vue";
import Vuex from "vuex";
import axiosIns from "@/axios.js";
import Cookies from "js-cookie";
import NotificationsRequest from "@/services/requests";
Vue.use(Vuex);
const serverDomain = process.env.NODE_ENV == 'production' ? 'https://api.dgotours.com' : process.env.NODE_ENV == 'development' ? 'http://api.local' : '';

export default new Vuex.Store({
  state: {
    layout: {
      navPos: 'left', //top, bottom, left, right, false
      toolbar: 'top', //top, bottom, false
      footer: true, //above, below, false
      boxed: false, //true, false
      roundedCorners: false, //true, false
      viewAnimation: 'fade-top' // fade-left, fade-right, fade-top, fade-top-in-out, fade-bottom, fade-bottom-in-out, fade, false
    },
    userDetails: {},
    splashScreen: true,
    user: {}, //JSON.parse(localStorage.getItem('CurrentUser')) || {},
    logged: localStorage.getItem("logged") || false,
    direction: Cookies.get('language') ? (Cookies.get('language') == 'en' ? 'ltr' : 'rtl') : 'rtl',
    currentLanguage: 'en',
    language: Cookies.get('language') || 'ar',
    defaultImage: serverDomain + '/uploads/image_placeholder.png',
    notifications: []
  },
  getters: {
    isLogged(state) {
      return state.logged;
    },
    splashScreen(state) {
      return state.splashScreen;
    },
    userType(state) {
      return state.user.type;
    },
    accessToDashPanel(state) {
      if (
        state.user.type == 2 ||
        state.user.type == 3 ||
        state.user.type == 4 ||
        state.user.type == 6
      ) {
        return true;
      } else {
        return false;
      }
    },
    user(state) {
      return state.user;
    },
    viewAnimation(state) {
      return state.layout.viewAnimation
    },
    isActive(state) {
      return state.user.active;
    },
    emailIsVerified(state) {
      return state.user.email_verified;
    },
    notifications(state){
      return state.notifications;
    }
  },
  mutations: {
    setLogin(state, payload) {
      state.logged = true;
      localStorage.setItem("logged", true);
      state.user = payload;
      //state.splashScreen = false;
    },
    setSplashScreen(state, payload) {
      state.splashScreen = payload;
    },
    setLogout(state, payload) {
      state.layout.navPos = null
      state.layout.toolbar = null
      state.logged = false
      localStorage.clear();
      if (process.env.NODE_ENV == "production") {
        //Cookies.remove('access_token', { domain: '.def.techno2030.com' }); // production
        Cookies.remove('access_token', { domain: '.dgotours.com' }); // production
      } else if (process.env.NODE_ENV == "development") {
        Cookies.remove('access_token');
      }
    },
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    setDirection(state, payload) {
      state.direction = payload;
    },
    setLanguage(state, payload) {
      //state.language = payload;
      Cookies.set('language', payload);
    },
    formatPrice(state, payload) {
      //const price = 9.99;
      const currency = "USD";
      const formattedPrice = payload.toLocaleString("en-US", {
        style: "currency",
        currency: currency,
      });
      return formattedPrice;
      //console.log(formattedPrice); // $9.99
    },
    setUserDetails(state, payload) {
      state.userDetails = payload;
    },
    setNotifications(state, payload) {
      state.notifications = payload;
    }
  },
  actions: {
    getCurrentUser(context) {
      return new Promise((resolve, reject) => {
        axiosIns.get("/users/current-user").then((response) => {
          context.commit("setLogin", response.data);
          resolve(response);
        });
      });
    },
    getNotifications(context) {
      return new Promise((resolve, reject) => {
      NotificationsRequest.getAllNotifications()
        .then((response) => {
          context.commit('setNotifications', response.data.data);
          resolve(response);
        })
      })  
    }

  },
  modules: {},
});
