<template>
    <div class="card-body pt-0" v-if="!$store.state.user.email_verified || !$store.state.user.active || !$store.state.user.type == 4">
        <div class="d-flex flex-column" style="gap: 20px">
            <div class="notice d-flex bg-light-warning rounded border-warning border border-dashed p-6"
                v-if="!$store.state.user.email_verified">
                <span class="svg-icon svg-icon-2tx svg-icon-warning me-4">
                    <inline-svg src="/media/icons/duotune/general/gen044.svg" />
                </span>
                <!--begin::Wrapper-->
                <div class="d-flex flex-stack flex-grow-1">
                    <!--begin::Content-->
                    <div class="fw-bold">
                        <h4 class="text-gray-800 fw-bolder">
                            {{ $t("warnings.email_verification_title") }}
                        </h4>

                        <div class="fs-6 text-gray-600">
                            <div>
                                {{ $t("warnings.email_verification") }}
                            </div>
                            <div class="mt-3">
                                <a class="fw-bolder" href="#" @click="resendEmailVerification()">{{
                                    $t("constants.resend_verification_link")
                                }}</a>
                            </div>

                        </div>
                    </div>
                    <!--end::Content-->
                </div>
                <!--end::Wrapper-->
            </div>
            <div class="notice d-flex bg-light-warning rounded border-warning border border-dashed p-6"
                v-if="!$store.state.user.active">
                <span class="svg-icon svg-icon-2tx svg-icon-warning me-4">
                    <inline-svg src="/media/icons/duotune/general/gen044.svg" />
                </span>
                <!--begin::Wrapper-->
                <div class="d-flex flex-stack flex-grow-1">
                    <!--begin::Content-->
                    <div class="fw-bold">
                        <h4 class="text-gray-800 fw-bolder">
                            {{ $t("warnings.account_activation_title") }}
                        </h4>
                        <div class="fs-6 text-gray-600">
                            {{ $t("warnings.account_activation") }}
                        </div>
                    </div>
                    <!--end::Content-->
                </div>
                <!--end::Wrapper-->
            </div>
            <div class="notice d-flex bg-light-warning rounded border-warning border border-dashed p-6"
                v-if="!$store.state.user.profile_completion && $store.state.user.type == 4">
                <span class="svg-icon svg-icon-2tx svg-icon-warning me-4">
                    <inline-svg src="/media/icons/duotune/general/gen044.svg" />
                </span>
                <!--begin::Wrapper-->
                <div class="d-flex flex-stack flex-grow-1">
                    <!--begin::Content-->
                    <div class="fw-bold">
                        <h4 class="text-gray-800 fw-bolder">
                            {{ $t("warnings.profile_completion_title") }}
                        </h4>

                        <div class="fs-6 text-gray-600">
                            <div>
                                {{ $t("warnings.profile_completion") }}
                            </div>

                            <div class="mt-3">
                                <router-link class="fw-bolder" :to="{ name: 'account-settings' }">{{
                                    $t("constants.account_settings")
                                }}</router-link>
                            </div>
                        </div>
                    </div>
                    <!--end::Content-->
                </div>
                <!--end::Wrapper-->
            </div>
        </div>
    </div>
</template>

<script>
import axiosIns from "@/axios.js";
export default {
    name: 'AlertsComponent',
    data() {
        return {
            user_id: this.$store.state.user.id
        }
    },
    methods: {
        resendEmailVerification() {
            this.$swal.showLoading();
            axiosIns.post('resend-verification-email', { user_id: this.user_id })
                .then((response) => {
                    this.$swal.fire({
                        icon: 'success',
                        title: response.data.message,
                        text: response.data.info,
                        confirmButtonColor: '#009EF7',
                    });
                })
        }
    }
}
</script>