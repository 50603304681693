<template>
  <div>
    <!--begin::Sign-in Method-->

    <SignInMethod />

    <!--end::Sign-in Method-->
    <!--begin::Basic info-->

    <UserFormInfo />

    <!--end::Basic info-->



    <!--begin::Connected Accounts-->
    <div class="card mb-5 mb-xl-10" v-if="connected_accounts">
      <!--begin::Card header-->
      <div class="card-header border-0 cursor-pointer" role="button" data-bs-toggle="collapse"
        data-bs-target="#kt_account_connected_accounts" aria-expanded="true"
        aria-controls="kt_account_connected_accounts">
        <div class="card-title m-0">
          <h3 class="fw-bolder m-0">Connected Accounts</h3>
        </div>
      </div>
      <!--end::Card header-->

      <!--begin::Content-->
      <div id="kt_account_connected_accounts" class="collapse show">
        <!--begin::Card body-->
        <div class="card-body border-top p-9 text-start">
          <div class="notice d-flex bg-light-primary rounded border-primary border border-dashed mb-9 p-6">
            <span class="svg-icon svg-icon-2tx svg-icon-primary me-4">
              <inline-svg src="media/icons/duotune/art/art006.svg" />
            </span>

            <!--begin::Wrapper-->
            <div class="d-flex flex-stack flex-grow-1">
              <!--begin::Content-->
              <div class="fw-bold">
                <div class="fs-6 text-gray-600">
                  Two-factor authentication adds an extra layer of security to
                  your account. To log in, in you'll need to provide a 4 digit
                  amazing code. <a href="#" class="fw-bolder">Learn More</a>
                </div>
              </div>
              <!--end::Content-->
            </div>
            <!--end::Wrapper-->
          </div>

          <!--begin::Items-->
          <div class="py-2">
            <!--begin::Item-->
            <div class="d-flex flex-stack">
              <div class="d-flex">
                <img src="media/svg/brand-logos/google-icon.svg" class="w-30px me-6" alt="" />

                <div class="d-flex flex-column">
                  <a href="#" class="fs-5 text-dark text-hover-primary fw-bolder">Google</a>
                  <div class="fs-6 fw-bold text-gray-400">
                    Plan properly your workflow
                  </div>
                </div>
              </div>
              <div class="d-flex justify-content-end">
                <div class="form-check form-check-solid form-switch">
                  <input class="form-check-input w-45px h-30px" type="checkbox" id="googleswitch" checked />
                  <label class="form-check-label" for="googleswitch"></label>
                </div>
              </div>
            </div>
            <!--end::Item-->

            <div class="separator separator-dashed my-5"></div>

            <!--begin::Item-->
            <div class="d-flex flex-stack">
              <div class="d-flex">
                <img src="media/svg/brand-logos/github.svg" class="w-30px me-6" alt="" />

                <div class="d-flex flex-column">
                  <a href="#" class="fs-5 text-dark text-hover-primary fw-bolder">Github</a>
                  <div class="fs-6 fw-bold text-gray-400">
                    Keep eye on on your Repositories
                  </div>
                </div>
              </div>
              <div class="d-flex justify-content-end">
                <div class="form-check form-check-solid form-switch">
                  <input class="form-check-input w-45px h-30px" type="checkbox" id="githubswitch" checked />
                  <label class="form-check-label" for="githubswitch"></label>
                </div>
              </div>
            </div>
            <!--end::Item-->

            <div class="separator separator-dashed my-5"></div>

            <!--begin::Item-->
            <div class="d-flex flex-stack">
              <div class="d-flex">
                <img src="media/svg/brand-logos/slack-icon.svg" class="w-30px me-6" alt="" />

                <div class="d-flex flex-column">
                  <a href="#" class="fs-5 text-dark text-hover-primary fw-bolder">Slack</a>
                  <div class="fs-6 fw-bold text-gray-400">
                    Integrate Projects Discussions
                  </div>
                </div>
              </div>
              <div class="d-flex justify-content-end">
                <div class="form-check form-check-solid form-switch">
                  <input class="form-check-input w-45px h-30px" type="checkbox" id="slackswitch" />
                  <label class="form-check-label" for="slackswitch"></label>
                </div>
              </div>
            </div>
            <!--end::Item-->
          </div>
          <!--end::Items-->
        </div>
        <!--end::Card body-->

        <!--begin::Card footer-->
        <div class="card-footer d-flex justify-content-end py-6 px-9">
          <button class="btn btn-white btn-active-light-primary me-2">
            Discard
          </button>
          <button ref="submitButton2" class="btn btn-primary" @click="saveChanges2()">
            <span class="indicator-label"> Save Changes </span>
            <span class="indicator-progress">
              Please wait...
              <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          </button>
        </div>
        <!--end::Card footer-->
      </div>
      <!--end::Content-->
    </div>
    <!--end::Connected Accounts-->

    <!--begin::Notifications-->
    <div class="card mb-5 mb-xl-10" v-if="email_preferences">
      <!--begin::Card header-->
      <div class="card-header border-0 cursor-pointer" role="button" data-bs-toggle="collapse"
        data-bs-target="#kt_account_email_preferences" aria-expanded="true" aria-controls="kt_account_email_preferences">
        <div class="card-title m-0">
          <h3 class="fw-bolder m-0">Email Preferences</h3>
        </div>
      </div>
      <!--begin::Card header-->

      <!--begin::Content-->
      <div id="kt_account_email_preferences" class="collapse show">
        <!--begin::Form-->
        <form class="form" @submit.prevent="saveChanges3()">
          <!--begin::Card body-->
          <div class="card-body border-top px-9 py-9">
            <!--begin::Option-->
            <label class="form-check form-check-custom form-check-solid align-items-start">
              <!--begin::Input-->
              <input class="form-check-input me-3" type="checkbox" name="email-preferences1" value="1" />
              <!--end::Input-->

              <!--begin::Label-->
              <span class="form-check-label d-flex flex-column align-items-start">
                <span class="fw-bolder fs-5 mb-0">Successful Payments</span>
                <span class="text-muted fs-6">Receive a notification for every successful payment.</span>
              </span>
              <!--end::Label-->
            </label>
            <!--end::Option-->
            <!--begin::Option-->
            <div class="separator separator-dashed my-6"></div>
            <!--end::Option-->

            <!--begin::Option-->
            <label class="form-check form-check-custom form-check-solid align-items-start">
              <!--begin::Input-->
              <input class="form-check-input me-3" type="checkbox" name="email-preferences1" checked value="1" />
              <!--end::Input-->

              <!--begin::Label-->
              <span class="form-check-label d-flex flex-column align-items-start">
                <span class="fw-bolder fs-5 mb-0">Payouts</span>
                <span class="text-muted fs-6">Receive a notification for every initiated payout.</span>
              </span>
              <!--end::Label-->
            </label>
            <!--end::Option-->
            <!--begin::Option-->
            <div class="separator separator-dashed my-6"></div>
            <!--end::Option-->

            <!--begin::Option-->
            <label class="form-check form-check-custom form-check-solid align-items-start">
              <!--begin::Input-->
              <input class="form-check-input me-3" type="checkbox" name="email-preferences1" value="1" />
              <!--end::Input-->

              <!--begin::Label-->
              <span class="form-check-label d-flex flex-column align-items-start">
                <span class="fw-bolder fs-5 mb-0">Fee Collection</span>
                <span class="text-muted fs-6">Receive a notification each time you collect a fee from
                  sales</span>
              </span>
              <!--end::Label-->
            </label>
            <!--end::Option-->
            <!--begin::Option-->
            <div class="separator separator-dashed my-6"></div>
            <!--end::Option-->

            <!--begin::Option-->
            <label class="form-check form-check-custom form-check-solid align-items-start">
              <!--begin::Input-->
              <input class="form-check-input me-3" type="checkbox" name="email-preferences1" checked value="1" />
              <!--end::Input-->

              <!--begin::Label-->
              <span class="form-check-label d-flex flex-column align-items-start">
                <span class="fw-bolder fs-5 mb-0">Customer Payment Dispute</span>
                <span class="text-muted fs-6">Receive a notification if a payment is disputed by a customer
                  and for dispute purposes.</span>
              </span>
              <!--end::Label-->
            </label>
            <!--end::Option-->
            <!--begin::Option-->
            <div class="separator separator-dashed my-6"></div>
            <!--end::Option-->

            <!--begin::Option-->
            <label class="form-check form-check-custom form-check-solid align-items-start">
              <!--begin::Input-->
              <input class="form-check-input me-3" type="checkbox" name="email-preferences1" value="1" />
              <!--end::Input-->

              <!--begin::Label-->
              <span class="form-check-label d-flex flex-column align-items-start">
                <span class="fw-bolder fs-5 mb-0">Refund Alerts</span>
                <span class="text-muted fs-6">Receive a notification if a payment is stated as risk by the
                  Finance Department.</span>
              </span>
              <!--end::Label-->
            </label>
            <!--end::Option-->
            <!--begin::Option-->
            <div class="separator separator-dashed my-6"></div>
            <!--end::Option-->

            <!--begin::Option-->
            <label class="form-check form-check-custom form-check-solid align-items-start">
              <!--begin::Input-->
              <input class="form-check-input me-3" type="checkbox" name="email-preferences1" checked value="1" />
              <!--end::Input-->

              <!--begin::Label-->
              <span class="form-check-label d-flex flex-column align-items-start">
                <span class="fw-bolder fs-5 mb-0">Invoice Payments</span>
                <span class="text-muted fs-6">Receive a notification if a customer sends an incorrect
                  amount to pay their invoice.</span>
              </span>
              <!--end::Label-->
            </label>
            <!--end::Option-->
            <!--begin::Option-->
            <div class="separator separator-dashed my-6"></div>
            <!--end::Option-->

            <!--begin::Option-->
            <label class="form-check form-check-custom form-check-solid align-items-start">
              <!--begin::Input-->
              <input class="form-check-input me-3" type="checkbox" name="email-preferences1" value="1" />
              <!--end::Input-->

              <!--begin::Label-->
              <span class="form-check-label d-flex flex-column align-items-start">
                <span class="fw-bolder fs-5 mb-0">Webhook API Endpoints</span>
                <span class="text-muted fs-6">Receive notifications for consistently failing webhook API
                  endpoints.</span>
              </span>
              <!--end::Label-->
            </label>
            <!--end::Option-->
            <!--begin::Option-->
            <div class="separator separator-dashed my-6"></div>
            <!--end::Option-->
          </div>
          <!--end::Card body-->

          <!--begin::Card footer-->
          <div class="card-footer d-flex justify-content-end py-6 px-9">
            <button class="btn btn-white btn-active-light-primary me-2">
              Discard
            </button>
            <button ref="submitButton3" type="submit" class="btn btn-primary px-6">
              <span class="indicator-label"> Save Changes </span>
              <span class="indicator-progress">
                Please wait...
                <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            </button>
          </div>
          <!--end::Card footer-->
        </form>
        <!--end::Form-->
      </div>
      <!--end::Content-->
    </div>
    <!--end::Notifications-->

    <!--begin::Notifications-->
    <div class="card mb-5 mb-xl-10" v-if="notifications">
      <!--begin::Card header-->
      <div class="card-header border-0 cursor-pointer" role="button" data-bs-toggle="collapse"
        data-bs-target="#kt_account_notifications" aria-expanded="true" aria-controls="kt_account_notifications">
        <div class="card-title m-0">
          <h3 class="fw-bolder m-0">Notifications</h3>
        </div>
      </div>
      <!--begin::Card header-->

      <!--begin::Content-->
      <div id="kt_account_notifications" class="collapse show">
        <!--begin::Form-->
        <form class="form" @submit.prevent="saveChanges4()">
          <!--begin::Card body-->
          <div class="card-body border-top px-9 pt-3 pb-4 text-start">
            <!--begin::Table-->
            <div class="table-responsive">
              <table class="table table-row-dashed border-gray-300 align-middle gy-6">
                <tbody class="fs-6 fw-bold">
                  <!--begin::Table row-->
                  <tr>
                    <td class="min-w-250px fs-4 fw-bolder">Notifications</td>
                    <td class="w-125px">
                      <div class="form-check form-check-solid">
                        <input class="form-check-input" type="checkbox" value="" id="kt_settings_notification_email"
                          checked data-kt-check="true" data-kt-check-target="[data-kt-settings-notification=email]" />
                        <label class="form-check-label ps-2" for="kt_settings_notification_email">
                          Email
                        </label>
                      </div>
                    </td>
                    <td class="w-125px">
                      <div class="form-check form-check-solid">
                        <input class="form-check-input" type="checkbox" value="" id="kt_settings_notification_phone"
                          checked data-kt-check="true" data-kt-check-target="[data-kt-settings-notification=phone]" />
                        <label class="form-check-label ps-2" for="kt_settings_notification_phone">
                          Phone
                        </label>
                      </div>
                    </td>
                  </tr>
                  <!--begin::Table row-->

                  <!--begin::Table row-->
                  <tr>
                    <td>Billing Updates</td>
                    <td>
                      <div class="form-check form-check-solid">
                        <input class="form-check-input" type="checkbox" value="1" id="billing1" checked
                          data-kt-settings-notification="email" />
                        <label class="form-check-label ps-2" for="billing1"></label>
                      </div>
                    </td>
                    <td>
                      <div class="form-check form-check-solid">
                        <input class="form-check-input" type="checkbox" value="" id="billing2" checked
                          data-kt-settings-notification="phone" />
                        <label class="form-check-label ps-2" for="billing2"></label>
                      </div>
                    </td>
                  </tr>
                  <!--begin::Table row-->

                  <!--begin::Table row-->
                  <tr>
                    <td>New Team Members</td>
                    <td>
                      <div class="form-check form-check-solid">
                        <input class="form-check-input" type="checkbox" value="" id="team1" checked
                          data-kt-settings-notification="email" />
                        <label class="form-check-label ps-2" for="team1"></label>
                      </div>
                    </td>
                    <td>
                      <div class="form-check form-check-solid">
                        <input class="form-check-input" type="checkbox" value="" id="team2"
                          data-kt-settings-notification="phone" />
                        <label class="form-check-label ps-2" for="team2"></label>
                      </div>
                    </td>
                  </tr>
                  <!--begin::Table row-->

                  <!--begin::Table row-->
                  <tr>
                    <td>Completed Projects</td>
                    <td>
                      <div class="form-check form-check-solid">
                        <input class="form-check-input" type="checkbox" value="" id="project1"
                          data-kt-settings-notification="email" />
                        <label class="form-check-label ps-2" for="project1"></label>
                      </div>
                    </td>
                    <td>
                      <div class="form-check form-check-solid">
                        <input class="form-check-input" type="checkbox" value="" id="project2" checked
                          data-kt-settings-notification="phone" />
                        <label class="form-check-label ps-2" for="project2"></label>
                      </div>
                    </td>
                  </tr>
                  <!--begin::Table row-->

                  <!--begin::Table row-->
                  <tr>
                    <td class="border-bottom-0">Newsletters</td>
                    <td class="border-bottom-0">
                      <div class="form-check form-check-solid">
                        <input class="form-check-input" type="checkbox" value="" id="newsletter1"
                          data-kt-settings-notification="email" />
                        <label class="form-check-label ps-2" for="newsletter1"></label>
                      </div>
                    </td>
                    <td class="border-bottom-0">
                      <div class="form-check form-check-solid">
                        <input class="form-check-input" type="checkbox" value="" id="newsletter2"
                          data-kt-settings-notification="phone" />
                        <label class="form-check-label ps-2" for="newsletter2"></label>
                      </div>
                    </td>
                  </tr>
                  <!--begin::Table row-->
                </tbody>
              </table>
            </div>
            <!--end::Table-->
          </div>
          <!--end::Card body-->

          <!--begin::Card footer-->
          <div class="card-footer d-flex justify-content-end py-6 px-9">
            <button class="btn btn-white btn-active-light-primary me-2">
              Discard
            </button>
            <button ref="submitButton4" type="submit" class="btn btn-primary px-6">
              <span class="indicator-label"> Save Changes </span>
              <span class="indicator-progress">
                Please wait...
                <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            </button>
          </div>
          <!--end::Card footer-->
        </form>
        <!--end::Form-->
      </div>
      <!--end::Content-->
    </div>
    <!--end::Notifications-->

    <!--begin::Deactivate Account-->
    <div class="card mb-5 mb-xl-10" v-if="deactivate_account">
      <!--begin::Card header-->
      <div class="card-header border-0 cursor-pointer" role="button" data-bs-toggle="collapse"
        data-bs-target="#kt_account_deactivate" aria-expanded="true" aria-controls="kt_account_deactivate">
        <div class="card-title m-0">
          <h3 class="fw-boldest m-0">Deactivate Account</h3>
        </div>
      </div>
      <!--end::Card header-->

      <!--begin::Content-->
      <div id="kt_account_deactivate" class="collapse show">
        <!--begin::Form-->
        <form id="kt_account_deactivate_form" class="form" @submit.prevent="deactivateAccount()">
          <!--begin::Card body-->
          <div class="card-body border-top p-9 text-start">
            <div class="notice d-flex bg-light-warning rounded border-warning border border-dashed mb-9 p-6">
              <span class="svg-icon svg-icon-2tx svg-icon-warning me-4">
                <inline-svg src="media/icons/duotune/general/gen044.svg" />
              </span>
              <!--begin::Wrapper-->
              <div class="d-flex flex-stack flex-grow-1">
                <!--begin::Content-->
                <div class="fw-bold">
                  <h4 class="text-gray-800 fw-bolder">
                    You Are Deactivating Your Account
                  </h4>

                  <div class="fs-6 text-gray-600">
                    For extra security, this requires you to confirm your email
                    or phone number when you reset yousignr password. <br /><a class="fw-bolder" href="#">Learn more</a>
                  </div>
                </div>
                <!--end::Content-->
              </div>
              <!--end::Wrapper-->
            </div>

            <!--begin::Form input row-->
            <div class="form-check form-check-solid fv-row">
              <input name="deactivate" class="form-check-input" type="checkbox" value="" id="deactivate" />
              <label class="form-check-label fw-bold ps-2 fs-6" for="deactivate">Confirm Account Deactivation</label>
            </div>
            <!--end::Form input row-->
          </div>
          <!--end::Card body-->

          <!--begin::Card footer-->
          <div class="card-footer d-flex justify-content-end py-6 px-9">
            <button id="kt_account_deactivate_account_submit" ref="submitButton5" type="submit"
              class="btn btn-danger fw-bold">
              <span class="indicator-label"> Deactivate Account </span>
              <span class="indicator-progress">
                Please wait...
                <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            </button>
          </div>
          <!--end::Card footer-->
        </form>
        <!--end::Form-->
      </div>
      <!--end::Content-->
    </div>
    <!--end::Deactivate Account-->
  </div>
</template>

<script>

import axiosIns from "@/axios.js";
import SignInMethod from "@/components/account/SignInMethod.vue";
import UserFormInfo from "@/components/account/UserFormInfo.vue";
export default {
  name: "account-settings",
  components: {
    SignInMethod,
    UserFormInfo
  },
  data() {
    return {
      nodeEnv: process.env.NODE_ENV,
      
     
      profileDetails: {
        avatar: "media/avatars/150-2.jpg",
        name: "Max",
        surname: "Smith",
        company: "Keenthemes",
        contactPhone: "044 3276 454 935",
        companySite: "keenthemes.com",
        country: "MY",
        language: "msa",
        timezone: "Kuala Lumpur",
        currency: "USD",
        communications: {
          email: false,
          phone: false,
        },
        allowMarketing: false,
      },
      notifications: false,
      connected_accounts: false,
      email_preferences: false,
      deactivate_account: false,
      
    };
  },
  methods: {
    saveChanges1() {
      if (this.submitButton1) {
        // Activate indicator
        this.submitButton1.setAttribute("data-kt-indicator", "on");

        setTimeout(() => {
          this.submitButton1.removeAttribute("data-kt-indicator");
        }, 2000);
      }
    },

    saveChanges2() {
      if (this.submitButton2) {
        // Activate indicator
        this.submitButton2.setAttribute("data-kt-indicator", "on");

        setTimeout(() => {
          this.submitButton2.removeAttribute("data-kt-indicator");
        }, 2000);
      }
    },

    saveChanges3() {
      if (this.submitButton3) {
        // Activate indicator
        this.submitButton3.setAttribute("data-kt-indicator", "on");

        setTimeout(() => {
          this.submitButton3.removeAttribute("data-kt-indicator");
        }, 2000);
      }
    },

    saveChanges4() {
      if (this.submitButton4) {
        // Activate indicator
        this.submitButton4.setAttribute("data-kt-indicator", "on");

        setTimeout(() => {
          this.submitButton4.removeAttribute("data-kt-indicator");
        }, 2000);
      }
    },

    deactivateAccount() {
      if (this.submitButton5) {
        // Activate indicator
        this.submitButton5.setAttribute("data-kt-indicator", "on");

        setTimeout(() => {
          this.submitButton5.removeAttribute("data-kt-indicator");

          this.$swal
            .fire({
              text: "Email is successfully changed!",
              icon: "success",
              confirmButtonText: "Ok",
              buttonsStyling: false,
              customClass: {
                confirmButton: "btn btn-light-primary",
              },
            })
            .then(() => {
              this.emailFormDisplay = false;
            });
        }, 2000);
      }
    },
  },

  mounted() {
    
  },
};
</script>
