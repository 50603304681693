<template>
  <div
    class="modal fade"
    id="modal_update_order_status"
    ref="updateOrderStatus"
    tabindex="-1"
    aria-hidden="true"
  >
    <!--begin::Modal dialog-->
    <div class="modal-dialog modal-dialog-centered mw-650px">
      <!--begin::Modal content-->
      <div class="modal-content">
        <!--begin::Modal header-->
        <div class="modal-header" id="modal_update_order_status_header">
          <!--begin::Modal title-->
          <h2 class="fw-bolder">
            {{ $t("orders.update_order_status") }}
          </h2>
          <!--end::Modal title-->

          <!--begin::Close-->
          <div
            id="modal_update_order_status_close"
            data-bs-dismiss="modal"
            class="btn btn-icon btn-sm btn-active-icon-primary"
            ref="close"
          >
            <span class="svg-icon svg-icon-1">
              <inline-svg src="/media/icons/duotune/arrows/arr061.svg" />
            </span>
          </div>
          <!--end::Close-->
        </div>
        <!--end::Modal header-->
        <!--begin::Form-->
        <el-form :model="form" :rules="rules" ref="formRef">
          <!--begin::Modal body-->

          <div class="modal-body py-10 px-lg-17">
            <div class="row">
              <div class="form-group">
                <label class="fs-6 mb-2">{{ $t("orders.status") }}</label>
                <el-form-item prop="status">
                  <vSelect
                    v-model="form.status"
                    :placeholder="$t('constants.select_status')"
                    :options="statuses"
                    :reduce="(status) => status.value"
                    label="name"
                    :disabled="disabled"
                    :dir="direction"
                  ></vSelect>
                </el-form-item>
              </div>
            </div>
          </div>
          <div class="modal-footer flex-center">
            <div class="d-flex" style="gap: 10px">
              <button
                type="button"
                class="btn btn-primary"
                :disabled="disabled"
                @click="updateStatus()"
              >
                {{$t('constants.save')}}
              </button>
              <button
                type="button"
                class="btn btn-secondary"
                @click="$refs.close.click()"
              >
                {{$t('constants.discard')}}
              </button>
            </div>
          </div>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import axiosIns from "@/axios.js";
import vSelect from "vue-select";

export default {
  props: ["order_id", "current_status"],
  components: {
    vSelect,
  },
  computed: {
    direction(){
      return this.$store.state.direction;
    }
  },
  data() {
    return {
      form: {
        status: "",
      },
      rules: {
        status: [
          {
            required: true,
            message: "this field is required",
            trigger: "blur",
          },
        ],
      },
      statuses: [
        {
          name: this.$t('orders.accept_order'),
          value: 1,
        },
        {
          name: this.$t('orders.reject_order'),
          value: 2,
        },
        /*{
          name: "Canceled",
          value: 3,
        },*/
      ],
      disabled: false,
    };
  },
  methods: {
    updateStatus() {
      this.$refs["formRef"].validate((valid) => {
        if (valid) {
          const formData = new FormData();
          for (const key in this.form) {
            if (this.form[key]) {
              formData.append(key, this.form[key]);
            }
          }
          formData.append("id", this.order_id);
          this.disabled = true;
          this.$swal({
            title: this.$t('constants.are_you_sure'),
            text: this.$t('constants.update_status_desc'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: this.$t('constants.cancel'),
            confirmButtonText: this.$t('constants.update_btn_confirmation')
          }).then((result) => {
            if (result.isConfirmed) {
              axiosIns
                .post(`orders/update-status`, formData)
                .then((response) => {
                  this.disabled = false;
                  this.$notify({
                    title: "مرحبا",
                    type: "success",
                    message: response.data.message,
                    showClose: true,
                    position: "top-left",
                  });
                  this.$refs.close.click();
                  this.$emit("success");
                })
                .catch((error) => {
                  this.disabled = false;
                });
            } else if (result.isDismissed) {
              this.disabled = false;
            }
          });
        }
      });
    },
  },
};
</script>
