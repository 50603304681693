<template>
  <div class="card">
    <div class="card-header border-0 pt-6">
      <!--begin::Card title-->
      <div class="card-title">
        <!--begin::Search-->
        <div class="d-flex align-items-center position-relative my-1">
          <span class="svg-icon svg-icon-1 position-absolute" :class="direction == 'rtl' ? 'me-6' : 'ms-6'">
            <svg fill="none" viewBox="0 0 24 24" height="24" width="24" xmlns="http://www.w3.org/2000/svg">
            <rect xmlns="http://www.w3.org/2000/svg" opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1" transform="rotate(45 17.0365 15.1223)" fill="black"></rect>
            <path xmlns="http://www.w3.org/2000/svg" d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z" fill="black"></path>
            </svg>
          </span>
          <input
            type="text"
            v-model="search"
            class="form-control form-control-solid w-250px table-search-input"
            :placeholder="$t('tours.tours_search')" :class="direction == 'rtl' ? 'pe-15' : 'ps-15'"
          />
        </div>
        <!--end::Search-->
      </div>
      <!--begin::Card title-->
      <!--begin::Card toolbar-->
      <div class="card-toolbar">
        <!--begin::Toolbar-->
        <!--<div
          v-if="checkedCustomers.length === 0"
          class="d-flex justify-content-end"
          data-kt-customer-table-toolbar="base"
        >
         
          <button
            type="button"
            class="btn btn-light-primary me-3"
            data-bs-toggle="modal"
            data-bs-target="#kt_customers_export_modal"
          >
            <span class="svg-icon svg-icon-2">
              <inline-svg src="/media/icons/duotune/arrows/arr078.svg" />
            </span>
            Export
          </button>
         
          <button
            type="button"
            class="btn btn-primary"
            data-bs-toggle="modal"
            data-bs-target="#kt_modal_add_customer"
          >
            <span class="svg-icon svg-icon-2">
              <inline-svg src="/media/icons/duotone/Navigation/Plus.svg" />
            </span>
            Add Customer
          </button>
          
        </div>-->
        <!--end::Toolbar-->
        <!--begin::Group actions-->
        <div
          v-if="checkedCustomers.length > 0"
          class="d-flex justify-content-end align-items-center"
          data-kt-customer-table-toolbar="selected"
        >
          <div class="fw-bolder me-5">
            <span class="me-2">{{ checkedCustomers.length }}</span
            >Selected
          </div>
          <button
            type="button"
            class="btn btn-danger"
            @click="deleteFewCustomers()"
          >
            Delete Selected
          </button>
        </div>
        <!--end::Group actions-->
        <!--begin::Group actions-->
        <div
          class="d-flex justify-content-end align-items-center d-none"
          data-kt-customer-table-toolbar="selected"
        >
          <div class="fw-bolder me-5">
            <span
              class="me-2"
              data-kt-customer-table-select="selected_count"
            ></span
            >Selected
          </div>
          <button
            type="button"
            class="btn btn-danger"
            data-kt-customer-table-select="delete_selected"
          >
            Delete Selected
          </button>
        </div>
        <!--end::Group actions-->
      </div>
      <!--end::Card toolbar-->
    </div>
    <div class="card-body pt-0">
      <Datatable
        :table-header="tableHeader"
        :enable-items-per-page-dropdown="true"
        :url="url"
        :searchTermProp="search"
        ref="datatable"
      >
        <template v-slot:cell-checkbox="{ row: tour }">
          <div
            class="form-check form-check-sm form-check-custom form-check-solid"
          >
            <input
              class="form-check-input"
              type="checkbox"
              :value="tour.id"
              v-model="checkedCustomers"
            />
          </div>
        </template>
        <template v-slot:cell-name="{ row: tour }">
          {{ tour.name }}
        </template>
        <template v-slot:cell-image="{ row: tour }">
          <img :src="tour.image ? tour.image : $store.state.defaultImage" alt="" class="w-100px h-100px">
        </template>
        <template v-slot:cell-company="{ row: tour }">
          {{ tour.company ? tour.company.company_name : ""}}
        </template>
        <template v-slot:cell-driver="{ row: tour }">
          {{ tour.driver ? tour.driver.name : "-"}}
        </template>
        <template v-slot:cell-celebrity="{ row: tour }">
          {{ tour.celebrity ? tour.celebrity.name : "-"}}
        </template>
        <template v-slot:cell-country="{ row: tour }">
          {{ tour.country }}
        </template>
        <template v-slot:cell-city="{ row: tour }">
          {{ tour.city }}
        </template>
        <template v-slot:cell-duration="{ row: tour }">
          {{ tour.duration }}
        </template>
        <template v-slot:cell-price="{ row: tour }">
          {{ formatMoney(tour.price, currency) }}
        </template>
        <template v-slot:cell-enabled="{ row: tour }">
          <div class="form-check form-switch">
            <input class="form-check-input" type="checkbox" role="switch" id="change-status" :checked="tour.enabled == 1" @change="changeTripStatus(tour.id)">
          </div>
        </template>
        <template v-slot:cell-actions="{ row: tour }">
          <div>
            <div
              class="
                btn btn-icon btn-active-light-primary
                w-30px
                h-30px
                w-md-40px
                h-md-40px
              "
              id="kt_activities_toggle"
              >
              <router-link :to="{name: 'edit-tour', params: {id: tour.id}}">
                <span class="svg-icon svg-icon-1">
                  <inline-svg src="/media/icons/duotune/general/gen055.svg" />
                </span>
              </router-link>
              
            </div>
            <div
              class="
                btn btn-icon btn-active-light-primary
                w-30px
                h-30px
                w-md-40px
                h-md-40px
              "
              id="kt_activities_toggle"
              data-bs-toggle="modal"
              data-bs-target="#modal_delete_item"
              @click="deleteTour(tour.id)"
            >
              <span class="svg-icon svg-icon-1">
                <inline-svg src="/media/icons/duotune/general/gen027.svg" />
              </span>
            </div>
          </div>
        </template>
       <!-- <template v-slot:cell-actions="{ row: tour }">
            <a
              href="#"
              class="btn btn-sm btn-light btn-active-light-primary"
              data-kt-menu-trigger="click"
              data-kt-menu-placement="bottom-end"
              data-kt-menu-flip="top-end"
              >Actions
              <span class="svg-icon svg-icon-5 m-0">
                <inline-svg src="/media/icons/duotune/arrows/arr072.svg" />
              </span>
            </a>
            begin::Menu
            <div
              class="
                menu
                menu-sub
                menu-sub-dropdown
                menu-column
                menu-rounded
                menu-gray-600
                menu-state-bg-light-primary
                fw-bold
                fs-7
                w-125px
                py-4
              "
              data-kt-menu="true"
            >
              begin::Menu item
              <div class="menu-item px-3">
                <router-link
                  to="/apps/customers/customer-details"
                  class="menu-link px-3"
                  >View</router-link
                >
              </div>
              end::Menu item
              begin::Menu item
              <div class="menu-item px-3">
                <a @click="deleteCustomer(tour.id)" class="menu-link px-3"
                  >Delete</a
                >
              </div>
              end::Menu item
            </div>
            end::Menu
          </template>-->
      </Datatable>
    </div>
  </div>

  <!--<ExportCustomerModal></ExportCustomerModal>
    <AddCustomerModal></AddCustomerModal>-->
</template>
  
  <script>
//import { defineComponent, ref, onMounted } from "vue";
import Datatable from "@/components/kt-datatable/Datatable.vue";
//import ExportCustomerModal from "@/components/modals/forms/ExportCustomerModal.vue";
//import AddCustomerModal from "@/components/modals/forms/AddCustomerModal.vue";
import { MenuComponent } from "@/assets/components";
//import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import axiosIns from "@/axios.js"

export default {
  name: "tours-table",
  components: {
    Datatable,
  },
  data() {
    return {
      url: "tours",
      MenuComponent: MenuComponent,
      currency: this.$store.state.user.currency,
      checkedCustomers: [],
      tableHeader: [
        {
          name: this.$t('tours.name'),
          key: "name",
          sortable: true,
        },
        {
          name: this.$t('tours.image'),
          key: "image",
          sortable: true,
        },
        {
          name: this.$t('tours.company_id'),
          key: "company",
          sortable: true,
        },
        {
          name: "Driver",
          key: "driver",
          sortable: true,
        },
        {
          name: this.$t('tours.country_id'),
          key: "country",
        },
        {
          name: this.$t('tours.city_id'),
          key: "city",
        },
        {
          name: this.$t('tours.duration'),
          key: "duration",
        },
        {
          name: this.$t('tours.price'),
          key: "price",
        },
        {
          name: this.$t('constants.isEnable'),
          key: "enabled"
        },
        {
          name: this.$t('constants.actions'),
          key: "actions"
        }
      ],

      initCustomers: [],
      search: "",
    };
  },
  computed: {
    direction(){
      return this.$store.state.direction;
    }
  },
  methods: {
    deleteTour(id){
      this.$swal(
      {
        title: this.$t('constants.are_you_sure'),
        text: this.$t('constants.delete_desc'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: this.$t('constants.cancel'),
        confirmButtonText: this.$t('constants.delete_btn_confirmation')
      }).then((result) => {
        if (result.isConfirmed) {
          
          axiosIns.delete(`tours/${id}`)
          .then((response) => {
            this.$swal({
              title: 'Deleted!',
              text: response.data.message,
              icon: 'success'
            })
            this.$refs.datatable.loadItems();
          })
        }
      })
      
    },
    deleteFewCustomers() {
      this.checkedCustomers.forEach((item) => {
        this.deleteCustomer(item);
      });
      this.checkedCustomers.length = 0;
    },

    deleteCustomer(id) {
      for (let i = 0; i < this.customers.length; i++) {
        if (this.customers[i].id === id) {
          this.customers.splice(i, 1);
        }
      }
    },
    formatMoney(amount, currency) {
      // Format the amount using Intl.NumberFormat
      const formattedAmount = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: currency,
      }).format(amount);

      return formattedAmount;
    },
    searchItems() {
      this.customers.splice(0, this.customers.length, this.initCustomers);
      if (this.search !== "") {
        let results = [];
        for (let j = 0; j < this.customers.length; j++) {
          if (this.searchingFunc(this.customers[j], this.search)) {
            results.push(this.customers[j]);
          }
        }
        this.customers.splice(0, this.customers.length, results);
      }
    },

    searchingFunc(obj, value) {
      for (let key in obj) {
        if (!Number.isInteger(obj[key]) && !(typeof obj[key] === "object")) {
          if (obj[key].indexOf(value) != -1) {
            return true;
          }
        }
      }
      return false;
    },
    changeTripStatus(id){
      const loadingDialog = this.$swal.fire({
        allowOutsideClick: false,
        didOpen: () => {
          this.$swal.showLoading();
        }
      });

      //this.$swal.showLoading();
      axiosIns.post(`tours/change-status/${id}`)
      .then((response) => {
        loadingDialog.close();
        this.$notify({
          title: "Success",
          type: "success",
          message: response.data.message,
          showClose: true,
          position: "top-left",
        });
        
      })
    }
  },
  mounted() {
    this.MenuComponent.reinitialization();
    this.initCustomers.splice(0, this.customers.length, this.customers);
  },
};
</script>
 
<style>
.table-search-input{
  height: 40px !important;
}
.table-search-input::placeholder{
  font-weight: 600;
}
</style>