import Vue from "vue";

// axios
import axios from "axios";
import Cookies from "js-cookie";
import store from "./store";

axios.defaults.baseURL =
  process.env.NODE_ENV == "development"
    ? "http://api.local/api/"
    : "https://api.dgotours.com/api/";
axios.defaults.headers.common["Authorization"] = Cookies.get(
  "access_token"
)
  ? Cookies.get("access_token")
  : "";

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  // baseURL: 'https://some-domain.com/api/v1',
  // timeout: 1000,
  // headers: {'X-Custom-Header': 'foobar'}
});

Vue.prototype.$http = axiosIns;
Vue.prototype.$http.interceptors.request.use((config) => {
  // Check if the request method is GET
  if (config.method === 'get' || config.method === 'post' || config.method === 'put') {
    // Add global params to the request URL
    config.params = {
      lang: store.state.language
    };
  }
  return config;
});
export default axiosIns;
