<template>
    <div>
      <CarForm :submitType="'edit'" />
    </div>
  </template>
  
  <script>
  import CarForm from "./form.vue";
  export default {
    name: "EditCar",
    components: {
        CarForm,
    },
  };
  </script>