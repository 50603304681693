<template>
    <div class="card mb-5 mb-xl-10">
        <!--begin::Card header-->
        <div class="card-header border-0 cursor-pointer" role="button" data-bs-toggle="collapse"
            data-bs-target="#kt_account_signin_method">
            <div class="card-title m-0">
                <h3 class="fw-boldest m-0">{{ $t('account.signin_method') }}</h3>
            </div>
        </div>
        <!--end::Card header-->

        <!--begin::Content-->
        <div id="kt_account_signin_method" class="collapse show">
            <!--begin::Card body-->
            <div class="card-body border-top p-9">
                <!--begin::Email Address-->
                <div class="d-flex flex-wrap align-items-center mb-8 justify-content-between">
                    <div id="kt_signin_email" :class="{ 'd-none': emailFormDisplay }">
                        <div class="fs-4 fw-boldest mb-1">{{ $t('account.email_address') }}</div>
                        <div class="fs-6 fw-bold text-gray-600" v-if="email">
                            {{ email }}
                        </div>
                        <div class="d-flex align-items-center mb-2" v-else>
                            <VueSkeletonLoader type="rect" :width="130" :height="20" animation="fade" rounded />
                        </div>
                    </div>

                    <div id="kt_signin_email_edit" :class="{ 'd-none': !emailFormDisplay }" class="flex-row-fluid">
                        <!--begin::Form-->
                        <el-form :model="email_form" :rules="email_rules" ref="email_form">
                            <div class="row mb-6">
                                <div class="col-lg-6 mb-4 mb-lg-0">
                                    <div class="fv-row mb-0">
                                        <label for="emailaddress" class="form-label fs-6 fw-bolder mb-3">{{
                                            $t('account.enter_new_email') }}</label>

                                        <el-form-item prop="email">
                                            <el-input v-model="email_form.email" :placeholder="$t('users.email')"
                                                type="email"></el-input>
                                        </el-form-item>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="fv-row mb-0">
                                        <label for="confirmemailpassword"
                                            class="form-label fs-6 fw-bolder mb-3">{{ $t('account.password') }}</label>
                                        <el-form-item prop="password">
                                            <el-input v-model="email_form.password" :placeholder="$t('users.password')"
                                                type="password"></el-input>
                                        </el-form-item>
                                    </div>
                                </div>
                            </div>
                            <div class="d-flex">
                                <button id="kt_signin_submit" type="button" ref="updateEmailButton"
                                    class="btn btn-primary px-6" :class="direction == 'ltr' ? 'me-2' : 'ms-2'"
                                    @click="updateEmail()">
                                    <span class="indicator-label">{{ $t('account.update_email') }}</span>
                                    <span class="indicator-progress">
                                        {{ $t('account.please_wait') }}
                                        <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                    </span>
                                </button>
                                <button id="kt_signin_cancel" type="button"
                                    class="btn btn-color-gray-400 btn-active-light-primary px-6"
                                    @click="emailFormDisplay = !emailFormDisplay">
                                    {{ $t('constants.cancel') }}
                                </button>
                            </div>
                        </el-form>
                        <!--end::Form-->
                    </div>
                    <div id="kt_signin_email_button" :class="{ 'd-none': emailFormDisplay }">
                        <button class="btn btn-light fw-boldest px-6" @click="emailFormDisplay = !emailFormDisplay">
                            {{ $t('account.change_email') }}
                        </button>
                    </div>
                </div>
                <!--end::Email Address-->

                <!--begin::Password-->
                <div class="d-flex flex-wrap align-items-center mb-8 justify-content-between">
                    <div id="kt_signin_password" :class="{ 'd-none': passwordFormDisplay }">
                        <div class="fs-4 fw-boldest mb-1">{{ $t('account.password') }}</div>
                        <div class="fs-6 fw-bold text-gray-600">************</div>
                    </div>
                    <div id="kt_signin_password_edit" class="flex-row-fluid" :class="{ 'd-none': !passwordFormDisplay }">
                        <div class="fs-6 fw-bold text-gray-600 mb-4">
                            {{ $t('account.password_validation_message') }}
                        </div>

                        <!--begin::Form-->
                        <el-form :model="password_form" :rules="password_rules" ref="password_form">
                            <div class="row mb-6">
                                <div class="col-lg-4">
                                    <div class="fv-row mb-0">
                                        <label for="currentpassword" class="form-label fs-6 fw-bolder mb-3">{{
                                            $t('account.current_password') }}</label>
                                        <el-form-item prop="current_password">
                                            <el-input v-model="password_form.current_password"
                                                :placeholder="$t('account.current_password')" type="password"></el-input>
                                        </el-form-item>

                                    </div>
                                </div>
                                <div class="col-lg-4">
                                    <div class="fv-row mb-0">
                                        <label for="newpassword" class="form-label fs-6 fw-bolder mb-3">{{
                                            $t('account.new_password') }}</label>
                                        <el-form-item prop="password">
                                            <el-input v-model="password_form.password" :placeholder="$t('users.password')"
                                                type="password"></el-input>
                                        </el-form-item>
                                    </div>
                                </div>
                                <div class="col-lg-4">
                                    <div class="fv-row mb-0">
                                        <label for="confirmpassword" class="form-label fs-6 fw-bolder mb-3">{{
                                            $t('account.confirm_new_password') }}</label>
                                        <el-form-item prop="password_confirmation">
                                            <el-input v-model="password_form.password_confirmation"
                                                :placeholder="$t('users.password_confirmation')" type="password"></el-input>
                                        </el-form-item>
                                    </div>
                                </div>
                            </div>
                            <div class="d-flex">
                                <button id="kt_password_submit" type="button" ref="updatePasswordButton"
                                    class="btn btn-primary px-6" :class="direction == 'ltr' ? 'me-2' : 'ms-2'"
                                    @click="updatePassword()">
                                    <span class="indicator-label">{{ $t('account.update_password') }}</span>
                                    <span class="indicator-progress">
                                        {{ $t('account.please_wait') }}
                                        <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                    </span>
                                </button>
                                <button id="kt_password_cancel" type="button"
                                    @click="passwordFormDisplay = !passwordFormDisplay"
                                    class="btn btn-color-gray-400 btn-active-light-primary px-6">
                                    {{ $t('constants.cancel') }}
                                </button>
                            </div>
                        </el-form>
                        <!--end::Form-->
                    </div>
                    <div id="kt_signin_password_button" :class="{ 'd-none': passwordFormDisplay }">
                        <button @click="passwordFormDisplay = !passwordFormDisplay" class="btn btn-light fw-boldest">
                            {{ $t('account.reset_password') }}
                        </button>
                    </div>
                </div>
                <!--end::Password-->
            </div>
            <!--end::Card body-->
        </div>
        <!--end::Content-->
    </div>
</template>


<script>
import axiosIns from "@/axios.js";
import VueSkeletonLoader from 'skeleton-loader-vue';

export default {
    name: "sign-in-method",
    components: {
        VueSkeletonLoader
    },
    data() {
        return {
            emailFormDisplay: false,
            passwordFormDisplay: false,
            email_form: {
                email: "",
                password: ""
            },
            password_form: {
                current_password: "",
                password: "",
                password_confirmation: ""
            },
            email_rules: {
                email: [
                    {
                        required: true,
                        message: this.$t('constants.required'),
                        trigger: "blur",
                    },
                ],
                password: [
                    {
                        required: true,
                        message: this.$t('constants.required'),
                        trigger: "blur",
                    },
                ],
            },
            password_rules: {
                current_password: [
                    {
                        required: true,
                        message: this.$t('constants.required'),
                        trigger: "blur",
                    },
                ],
                password: [
                    { required: true, message: 'Password is required' },
                    { min: 8, message: 'Password must be at least 8 characters long' },
                    { max: 16, message: 'Password cannot be longer than 16 characters' }
                ],
                password_confirmation: [
                    { required: true, message: 'Password confirmation is required' },
                    { validator: this.validatePasswordConfirmation, trigger: 'blur' }
                ]
            }
        }
    },
    computed: {
        email() {
            return this.$store.state.user.email;
        },
        direction() {
            return this.$store.state.user.direction;
        }
    },
    methods: {
        validatePasswordConfirmation(rule, value, callback) {
            if (value !== this.password_form.password) {
                callback(new Error('Passwords do not match'));
            } else {
                callback();
            }
        },
        updateEmail() {
            this.$refs["email_form"].validate((valid) => {
                if (valid) {
                    const formData = new FormData();
                    for (const key in this.email_form) {
                        if (this.email_form[key]) {
                            formData.append(key, this.email_form[key]);
                        }
                    }
                    this.$swal.showLoading();
                    axiosIns
                        .post(`update-email`, formData)
                        .then((response) => {
                            this.email_form.email = "";
                            this.email_form.password = "";
                            this.$swal.close();
                            this.emailFormDisplay = false;
                            this.$notify({
                                title: "مرحبا",
                                type: "success",
                                message: response.data.message,
                                showClose: true,
                                position: "top-left",
                            });
                        }).catch((error) => {
                            this.email_form.email = "";
                            this.email_form.password = "";
                            this.$swal.close();
                            this.$swal.fire({
                                icon: 'error',
                                title: 'Oops...',
                                text: error.response.data.error

                            })
                        })
                }
            })
        },

        updatePassword() {
            this.$refs["password_form"].validate((valid) => {
                if (valid) {
                    const formData = new FormData();
                    for (const key in this.password_form) {
                        if (this.password_form[key]) {
                            formData.append(key, this.password_form[key]);
                        }
                    }
                    this.$swal.showLoading();
                    axiosIns
                        .post(`reset-password`, formData)
                        .then((response) => {
                            this.password_form.current_password = "";
                            this.password_form.password = "";
                            this.password_form.password_confirmation = "";
                            this.$swal.close();
                            this.passwordFormDisplay = false;
                            this.$notify({
                                title: "مرحبا",
                                type: "success",
                                message: response.data.message,
                                showClose: true,
                                position: "top-left",
                            });
                        }).catch((error) => {
                            this.password_form.current_password = "";
                            this.password_form.password = "";
                            this.password_form.password_confirmation = "";
                            this.$swal.close();
                            this.$swal.fire({
                                icon: 'error',
                                title: 'Oops...',
                                text: error.response.data.error

                            })
                        })
                }
            })
        },
        editPrepend() {
            const element = document.querySelectorAll('.el-input-group__append');
            const element2 = document.querySelectorAll('.el-input-group--append .el-input__inner');

            if (this.direction == "rtl") {
                element.forEach((item) => {
                    item.style.borderRight = '0';
                    item.style.borderLeft = '1px solid #DCDFE6';
                    item.style.borderRadius = '0px';
                    item.style.borderTopLeftRadius = '4px';
                    item.style.borderBottomLeftRadius = '4px';
                });

                element2.forEach((item) => {
                    item.style.borderRadius = '0px';
                    item.style.borderTopRightRadius = '4px';
                    item.style.borderBottomRightRadius = '4px';
                });

            }
        },
    },

    mounted() {
        
    }
}
</script>