<template>
    <div style="font-size: 20px;">
        {{title}}
    </div>
</template>

<script>
export default {
    name: 'NotFound',
    data(){
        return {
            title: '404 Not Found',
        }
    }
}
</script>