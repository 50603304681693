<template>
    <div class="modal fade" id="modal_update_reservation_date" ref="updateReservationDate" tabindex="-1" aria-hidden="true">
        <!--begin::Modal dialog-->
        <div class="modal-dialog modal-dialog-centered mw-650px">
            <!--begin::Modal content-->
            <div class="modal-content">
                <!--begin::Modal header-->
                <div class="modal-header" id="modal_update_order_status_header">
                    <!--begin::Modal title-->
                    <h2 class="fw-bolder">
                        {{ $t("constants.update_reservation_date") }}
                    </h2>
                    <!--end::Modal title-->

                    <!--begin::Close-->
                    <div id="modal_update_reservation_date_close" data-bs-dismiss="modal"
                        class="btn btn-icon btn-sm btn-active-icon-primary" ref="close">
                        <span class="svg-icon svg-icon-1">
                            <inline-svg src="/media/icons/duotune/arrows/arr061.svg" />
                        </span>
                    </div>
                    <!--end::Close-->
                </div>
                <!--end::Modal header-->
                <el-form :model="form" :rules="rules" ref="formRef">
                    <!--begin::Modal body-->

                    <div class="modal-body py-10 px-lg-17">
                        <div class="row">
                            <div class="form-group col col-md-8">
                                <label class="fs-6 mb-2">{{ $t("orders.reservation_date") }}</label>
                                <el-form-item prop="date">
                                    <date-picker v-model="form.date" :range="RangePickerMode" value-type="format" class="w-100"
                                        :disabled-date="pickerOptions.disabledDate"></date-picker>
                                </el-form-item>
                            </div>
                            <div class="form-group col col-md-8" v-if="type == 'driver'">
                                <label class="fs-6 mb-2">{{ $t("orders.city_commuting") }}</label>
                                <el-form-item prop="date">
                                    <date-picker v-model="form.date" value-type="format" class="w-100"
                                        :disabled-date="pickerOptions.disabledDate"></date-picker>
                                </el-form-item>
                            </div>
                        </div>
                    </div>
                    <!--begin::Modal footer-->
                    <div class="modal-footer flex-center">
                        <!--begin::Button-->
                        <button type="reset" id="kt_modal_add_customer_cancel" class="btn btn-light me-3">
                            {{$t('constants.discard')}}
                        </button>
                        <!--end::Button-->

                        <!--begin::Button-->
                        <button :data-kt-indicator="loading ? 'on' : null" class="btn btn-lg btn-primary" type="button" 
                            @click="submit()">
                            <span v-if="!loading" class="indicator-label">
                                {{$t('constants.submit')}}
                                <span class="svg-icon svg-icon-3 ms-2 me-0">
                                    <inline-svg src="icons/duotune/arrows/arr064.svg" />
                                </span>
                            </span>
                            <span v-if="loading" class="indicator-progress">
                                {{$t('account.please_wait')}}
                                <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                        </button>
                        <!--end::Button-->
                    </div>
                    <!--end::Modal footer-->
                </el-form>
            </div>
        </div>
    </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import axiosIns from "@/axios";

export default {
    components: {
        DatePicker
    },
    props: {
        type: {
            type: String,
            required: true
        },
        id: {
            type: String,
            required: true
        },
        driver_service_category: {
            type: String
        }
    },
    data() {
        return {
            form: {
                date: ""
            },
            rules: {
                date: [
                    {
                        required: true,
                        message: this.$t('constants.required'),
                        trigger: "blur",
                    }
                ],
            },
            loading: false,
            pickerOptions: {
                disabledDate(time) {
                    return time <= new Date(Date.now() - 8640000);
                },
            },
        }
    },
    computed: {
        url(){
            if(this.type == 'driver'){
                return 'reservations/drivers/update-date';
            }else if(this.type == 'car'){
                return 'reservations/rental-cars/update-date';
            }else if(this.type == 'tour'){
                return 'reservations/tours/update-date';
            }else{
                return '';
            }
        },
        RangePickerMode(){
            if(this.type == 'driver'){
                if(this.driver_service_category == 1){
                    return true;
                }else{
                    return false;
                }
            }else if(this.type == 'tour'){
                return false;
            }else if(this.type == 'car'){
                return true
            }else{
                return false
            }
        }
    },
    methods: {
        submit(){
            this.$refs["formRef"].validate((valid) => {
                if(valid){
                    const formData = new FormData();
                    formData.append("id", this.id);
                    formData.append('reservation_date', this.form.date);
                    this.loading = true;

                    axiosIns
                    .post(this.url, formData)
                    .then((response) => {
                       this.loading = false;
                       this.$notify({
                        title: "مرحبا",
                        type: "success",
                        message: response.data.message,
                        showClose: true,
                        position: "top-left",
                    });
                    this.$refs.close.click();
                    this.$emit("success"); 
                    })
                    .catch((error) => {
                        this.loading = false;
                        this.$swal.fire({
                            icon: 'error',
                            title: this.$t('constants.oops'),
                            text: error.response.data.message,
                        })
                    })
                }
            })
        }
    }
}
</script>