<template>
    <!--begin::Wrapper-->
    <div class="w-lg-500px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto">
        <!--begin::Form-->
        <el-form class="form w-100" id="kt_login_signin_form" ref="form" :model="form" :rules="rules">
            <!--begin::Heading-->
            <div class="text-center mb-10">
                <div class="mb-10">
                    <img src="@/assets/images/logo-dashboard.png" style="width: 160px;height: 130px" />
                </div>

                <!--begin::Title-->
                <h1 class="text-dark mb-3">{{ $t('constants.setup_new_password') }}</h1>
                <!--end::Title-->
            </div>
            <!--begin::Heading-->
            <div class="alert alert-danger d-flex flex-column align-items-center flex-sm-row w-100 p-5 mb-10"
                v-if="auth_error">
                <span class="svg-icon svg-icon-2hx svg-icon-danger" :class="direction == 'ltr' ? 'me-3' : 'ms-3'">
                    <inline-svg src="/media/icons/duotune/general/gen044.svg" />
                </span>
                <!--begin::Content-->
                <div class="d-flex flex-column pe-0"
                    :class="direction == 'ltr' ? 'text-start pe-sm-10' : 'text-end ps-sm-10'">
                    <h5 class="mb-0 text-danger">{{ auth_error_message }}</h5>
                    <!--<span>{{ auth_error_message }}</span>-->
                </div>
                <!--end::Content-->
                <!--begin::Close-->
                <button type="button" class="position-absolute position-sm-relative m-2 m-sm-0 top-0 end-0 btn btn-icon"
                    :class="direction == 'ltr' ? 'ms-sm-auto' : 'me-sm-auto'" @click="auth_error = false">
                    <span class="svg-icon svg-icon-2x svg-icon-danger">
                        <inline-svg src="/media/icons/duotune/arrows/arr061.svg" />
                    </span>
                </button>
                <!--end::Close-->
            </div>
            <!--begin::Input group-->
            <div class="fv-row mb-10">
                <!--begin::Label-->
                <label class="form-label fs-6 fw-bolder text-dark d-block"
                    :class="direction == 'ltr' ? 'text-start' : 'text-end'">{{ $t('users.email') }}</label>
                <!--end::Label-->

                <!--begin::Input-->
                <el-form-item prop="email">
                    <el-input type="text" name="email" autocomplete="off" v-model="form.email" />
                </el-form-item>

                <!--end::Input-->
                <div class="fv-plugins-message-container">
                    <div class="fv-help-block">
                        <!--<ErrorMessage name="email" />-->
                    </div>
                </div>
            </div>
            <!--end::Input group-->

            <!--begin::Input group-->
            <div class="fv-row mb-10">
                <!--begin::Label-->
                <label class="form-label fs-6 fw-bolder text-dark d-block"
                    :class="direction == 'ltr' ? 'text-start' : 'text-end'">{{ $t('account.new_password') }}</label>
                <!--end::Label-->

                <!--begin::Input-->
                <el-form-item prop="password">
                    <el-input type="password" name="email" autocomplete="off" v-model="form.password" />
                </el-form-item>

                <!--end::Input-->
                <div class="fv-plugins-message-container">
                    <div class="fv-help-block">
                        <!--<ErrorMessage name="email" />-->
                    </div>
                </div>
            </div>
            <!--end::Input group-->

            <!--begin::Input group-->
            <div class="fv-row mb-10">
                <!--begin::Label-->
                <label class="form-label fs-6 fw-bolder text-dark d-block"
                    :class="direction == 'ltr' ? 'text-start' : 'text-end'">{{ $t('account.confirm_new_password') }}</label>
                <!--end::Label-->

                <!--begin::Input-->
                <el-form-item prop="password_confirmation">
                    <el-input type="password" name="email" autocomplete="off" v-model="form.password_confirmation" />
                </el-form-item>

                <!--end::Input-->
                <div class="fv-plugins-message-container">
                    <div class="fv-help-block">
                        <!--<ErrorMessage name="email" />-->
                    </div>
                </div>
            </div>
            <!--end::Input group-->

            <!--begin::Actions-->
            <div class="text-center">
                <!--begin::Submit button-->
                <button type="button" ref="submitButton" id="kt_sign_in_submit" class="btn btn-lg btn-primary w-100 mb-5"
                    @click="resetPassword" :data-kt-indicator="loading ? 'on' : ''" :disabled="loading ? true : false">
                    <span class="indicator-label">{{ $t('account.reset_password') }}</span>

                    <span class="indicator-progress">
                        {{ $t('account.please_wait') }}
                        <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                    </span>
                </button>
                <!--end::Submit button-->
            </div>
            <div class="d-flex align-items-center gap-2">
                <!--begin::Link-->
                <div>
                    <span class="text-gray-400 fw-bold fs-5">{{ $t('constants.already_member') }}</span>
                </div>
                <div>
                    <router-link :to="{ name: 'login' }" class="link-primary fs-6 fw-bolder">
                        {{ $t('constants.signInBtn') }}
                    </router-link>
                </div>

                <!--end::Link-->
            </div>
            <!--end::Actions-->
        </el-form>
        <!--end::Form-->
    </div>
    <!--end::Wrapper-->
</template>
    
<script>
import axiosIns from "@/axios";
import Cookies from "js-cookie";

export default {
    name: "LoginPage",
    data() {
        return {
            nodeEnv: process.env.NODE_ENV,
            loading: false,
            auth_error_message: "",
            auth_error: false,
            form: {
                email: "",
                password: "",
                password_confirmation: ""
            },
            rules: {
                email: [
                    {
                        required: true,
                        message: this.$t('constants.required'),
                        trigger: "blur",
                    },
                ],
                password: [
                    { required: true, message: this.$t('constants.password_required') },
                    { min: 8, message: this.$t('constants.password_confirmation_min') },
                    { max: 16, message: this.$t('constants.password_confirmation_max') }
                ],
                password_confirmation: [
                    { required: true, message: this.$t('constants.password_confirmation_required') },
                    { validator: this.validatePasswordConfirmation, trigger: 'blur' }
                ]
            },
        };
    },
    computed: {
        direction() {
            return this.$store.state.direction;
        }
    },
    methods: {
        validatePasswordConfirmation(rule, value, callback) {
            if (value !== this.form.password) {
                callback(new Error(this.$t('message.passwordDoNotMatch')));
            } else {
                callback();
            }
        },
        resetPassword() {
            var self = this;
            var bodyFormData = new FormData();
            bodyFormData.append("email", self.form.email);
            bodyFormData.append("password", self.form.password);
            bodyFormData.append("password_confirmation", self.form.password_confirmation);
            bodyFormData.append("token", this.$route.params.token);
            this.$refs["form"].validate((valid) => {
                if (valid) {
                    this.loading = true;
                    axiosIns
                        .post("users/password/reset", bodyFormData)
                        .then((response) => {
                            this.loading = false;
                            this.$swal.fire({
                                icon: 'success',
                                title: response.data.message,
                                text: response.data.info,
                                confirmButtonColor: '#009EF7',
                                allowOutsideClick: false,
                            }).then((result) => {
                                if (result.isConfirmed) {
                                    this.form.email = "";
                                    this.$router.push('/login');
                                }
                            })

                        })
                        .catch((error) => {
                            this.loading = false;
                            this.auth_error = true;
                            this.auth_error_message = error.response.data.message;
                        });
                }
            });
        },
    },
};
</script>
  
<style>
body {
    justify-content: center;
}
</style>