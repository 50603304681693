<template>
  <div id="app">
    <div class="page-loader d-flex flex-column align-items-center justify-content-center" v-if="splashScreen">
      <span class="spinner-border text-primary" role="status"></span>
      <span class="text-muted fs-6 fw-semibold mt-5">Loading...</span>
    </div>
    <router-view />
  </div>
</template>
<script>
export default {
  computed:{
    splashScreen() {
      return this.$store.getters.splashScreen;
    }
  },
  data(){
    return{

    }
  },

  mounted(){
    /*const urlParams = new URLSearchParams(window.location.search);
    if(urlParams.has('lang') && (urlParams.get('lang') == 'ar' || 'en')){
      this.$i18n.locale = urlParams.get('lang'); 
      if(urlParams.get('lang') == 'ar'){
        document.documentElement.setAttribute('dir', 'rtl');
        document.body.style.textAlign = "right";
        this.$store.commit("setDirection",'rtl');
        this.$store.commit("setCurrentLanguage", urlParams.get('lang'))
      }
    }   */
  },

  beforeCreate(){
    if(this.$store.state.language == "en"){
      
      document.documentElement.setAttribute('dir', 'ltr');
      document.body.style.textAlign = "left";
      this.$i18n.locale = "en";
    }else{
      document.documentElement.setAttribute('dir', 'rtl');
      document.body.style.textAlign = "right";
      this.$i18n.locale = "ar";
    }
  }
  
}
</script>
<style lang="scss">
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.drawer-overlay{
  z-index: 1 !important;
}
</style>
