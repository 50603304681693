import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Element from 'element-ui'
import VueI18n from 'vue-i18n'
import locale from 'element-ui/lib/locale/lang/en'
import InlineSvg from 'vue-inline-svg';
import VueSweetalert2 from 'vue-sweetalert2';
//import i18n_messages from './i18n.json'; 
import * as VueGoogleMaps from 'vue2-google-maps';
import CKEditor from 'ckeditor4-vue';

import 'sweetalert2/dist/sweetalert2.min.css';
import "./core/plugins/keenthemes";
import "bootstrap/dist/js/bootstrap.js"
import 'element-ui/lib/theme-chalk/index.css';
import i18n from './i18n'; // Import the i18n instance

import VueTelInput from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';


Vue.use( CKEditor );
Vue.use(Element,{locale});
Vue.config.productionTip = false;
Vue.component('inline-svg', InlineSvg);
Vue.use(VueSweetalert2);
Vue.use(VueI18n);
//const i18n = new VueI18n({ locale: 'en', messages:i18n_messages })

Vue.use(VueGoogleMaps, {
	load: {
		//key: 'AIzaSyApRs7PjwXUFjU-rfWx8zaBX3s1wxJVSmc',
		key: 'AIzaSyAlsBniBcKc07oYGxR8BamXwyKBBwpjuow',
		libraries: 'places', // This is required if you use the Autocomplete plugin
		// OR: libraries: 'places,drawing'
		// OR: libraries: 'places,drawing,visualization'
		// (as you require)
	}
})

Vue.use(VueTelInput); // Define default global options here (optional)

new Vue({
  i18n,
  router,
  store,
  render: (h) => h(App)
}).$mount("#app");
