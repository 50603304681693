<template>
    <div class="card mb-5 mb-xl-10">
        <!--begin::Card header-->
        <div class="card-header border-0 cursor-pointer" role="button" data-bs-toggle="collapse"
            data-bs-target="#kt_account_profile_details" aria-expanded="true" aria-controls="kt_account_profile_details">
            <!--begin::Card title-->
            <div class="card-title m-0">
                <h3 class="fw-bolder m-0">{{ $t('account.profile_details') }}</h3>
            </div>
            <!--end::Card title-->
        </div>
        <!--begin::Card header-->

        <!--begin::Content-->
        <div id="kt_account_profile_details" class="collapse show">
            <!--begin::Form-->
            <el-form :model="form" :rules="rules" ref="form" v-if="showForm">
                <!--begin::Card body-->
                <div class="card-body border-top p-9">
                    <div class="row">
                        <div class="form-group col-md-4 col-sm-6 col-xs-12">
                            <label class="col-form-label required fw-bold fs-6">{{
                                $t("users.name")
                            }}</label>
                            <el-form-item prop="name">
                                <el-input v-model="form.name" :placeholder="$t('users.name')"></el-input>
                            </el-form-item>
                        </div>
                        <div class="form-group col-md-4 col-sm-6 col-xs-12" v-if="form.type == 2">
                            <label class="col-form-label required fw-bold fs-6">{{
                                $t("users.company_name")
                            }}</label>
                            <el-form-item prop="company_name">
                                <el-input v-model="form.company_name" :placeholder="$t('users.company_name')"
                                    type="text"></el-input>
                            </el-form-item>
                        </div>
                        <div class="form-group col-md-4 col-sm-6 col-xs-12">
                            <label class="col-form-label required fw-bold fs-6">{{
                                $t("users.address")
                            }}</label>
                            <el-form-item>
                                <el-input type="text" v-model="form.address" :placeholder="$t('users.address')"></el-input>
                            </el-form-item>
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col-md-4 col-sm-6 col-xs-12">
                            <label class="col-form-label required fw-bold fs-6">{{
                                $t("users.mobile")
                            }}</label>
                            <el-form-item prop="mobile">
                                <!--<el-input v-model="form.mobile" :placeholder="$t('users.mobile')" type="number"></el-input>-->
                                <vue-tel-input v-model="form.mobile"
                                     :inputOptions="inputOptions" :autoFormat="false"
                                    @country-changed="countryChanged"
                                    :dropdownOptions="dropdownOptions"></vue-tel-input>
                            </el-form-item>
                        </div>
                        <div class="form-group col-md-4 col-sm-6 col-xs-12">
                            <label class="col-form-label required fw-bold fs-6">{{
                                $t("tours.country_id")
                            }}</label>
                            <el-form-item prop="country_id">
                                <vSelect v-model="form.country_id" :placeholder="$t('constants.select_country')"
                                    :options="countries" :reduce="(country) => country.id" :label="direction == 'rtl' ? 'name_ar' : 'name_en'"
                                    @input="getCities" :dir="direction == 'rtl' ? 'rtl' : 'ltr'"></vSelect>
                            </el-form-item>
                        </div>

                        <div class="form-group col-md-4 col-sm-6 col-xs-12">
                            <label class="col-form-label required fw-bold fs-6">{{
                                $t("tours.city_id")
                            }}</label>
                            <el-form-item prop="city_id">
                                <vSelect v-model="form.city_id" :placeholder="$t('constants.select_city')" :options="cities"
                                    :reduce="(city) => city.id" :label="direction == 'rtl' ? 'name_ar' : 'name_en'" :dir="direction == 'rtl' ? 'rtl' : 'ltr'">
                                </vSelect>
                            </el-form-item>
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col-md-4 col-sm-6 col-xs-12">
                            <label class="col-form-label required fw-bold fs-6">{{
                                $t("users.currency")
                            }}</label>
                            <el-form-item prop="currency_id">
                                <vSelect v-model="form.currency_id" :placeholder="$t('constants.select_currency')"
                                    :options="currencies" :reduce="(currency) => currency.id" label="code"
                                    :dir="direction == 'rtl' ? 'rtl' : 'ltr'"></vSelect>
                            </el-form-item>
                        </div>
                        <div class="form-group col-md-4 col-sm-6 col-xs-12" v-if="form.type == 4">
                            <label class="col-form-label required fw-bold fs-6">{{
                                $t("users.payment_method")
                            }}</label>
                            <el-form-item prop="payment_method">
                                <vSelect v-model="form.payment_method" :placeholder="$t('constants.select_payment_method')"
                                    :options="payment_methods" :reduce="(method) => method.value" label="name"
                                    :dir="direction == 'rtl' ? 'rtl' : 'ltr'"></vSelect>
                            </el-form-item>
                        </div>
                        <div class="form-group col-md-4 col-sm-6 col-xs-12" v-if="form.type == 2">
                            <label class="col-form-label required fw-bold fs-6">{{
                                $t("users.license_number")
                            }}</label>
                            <el-form-item prop="license_number">
                                <el-input v-model="form.license_number" :placeholder="$t('users.license_number')"
                                    type="number"></el-input>
                            </el-form-item>
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col-md-4 col-sm-6 col-xs-12">
                            <label class="col-form-label required fw-bold fs-6">{{
                                $t("users.why_us")
                            }}</label>
                            <el-form-item>
                                <el-input type="textarea" :rows="5" v-model="form.why_us"
                                    :placeholder="$t('users.why_us')"></el-input>
                            </el-form-item>
                        </div>
                        <div class="form-group col-md-4 col-sm-6 col-xs-12">
                            <label class="col-form-label required fw-bold fs-6">{{
                                aboutTitle(form.type)
                            }}</label>
                            <el-form-item>
                                <el-input type="textarea" :rows="5" v-model="form.about_company"
                                    :placeholder="$t('users.about_company')"></el-input>
                            </el-form-item>
                        </div>
                        <div class="form-group col-md-4 col-sm-6 col-xs-12">
                            <label class="col-form-label required fw-bold fs-6">{{
                                $t("users.features_and_services")
                            }}</label>
                            <el-form-item>
                                <el-input type="textarea" :rows="5" v-model="form.features_and_services"
                                    :placeholder="$t('users.features_and_services')"></el-input>
                            </el-form-item>
                        </div>
                    </div>
                    <div class="row" v-if="form.type != 4">
                        <div class="form-group col-md-6 col-sm-6 col-xs-12">
                            <label class="col-form-label required fw-bold fs-6">{{
                                $t("users.coordinates")
                            }}</label>
                            <div class="d-flex align-items-center" style="gap: 1rem">
                                <el-form-item prop="coordinates" style="flex: 1">
                                    <el-input :placeholder="$t('users.coordinates')" v-model="form.coordinates">
                                        <div slot="append" id="location-modal" data-bs-toggle="modal"
                                            data-bs-target="#modal_location" class="location_btn"
                                            @click="openLocationModal()">
                                            <i class="el-icon-map-location"></i>
                                        </div>
                                    </el-input>
                                </el-form-item>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col-md-4 col-sm-6 col-xs-12">
                            <label class="col-form-label required fw-bold fs-6">{{ $t("users.image") }}</label>
                            <el-form-item prop="image">
                                <vue-dropzone ref="image" id="dropzone" :options="dropzoneImageOptions" @vdropzone-success="(file, response) => ImageAdded(file, response, 'image')
                                    " @vdropzone-removed-file="(file, error, xhr) =>
        removeImageFile(file, error, xhr, 'image')
        "></vue-dropzone>
                            </el-form-item>
                        </div>
                    </div>

                    <div id="driver_information" v-if="form.type == 4">
                        <div class="mb-5">
                            <h5>{{ $t("users.driver_info") }}</h5>
                        </div>
                        <div class="row">
                            <div class="form-group col-md-4 col-sm-6 col-xs-12">
                                <label class="required fs-6 mb-2">{{ $t("users.vehicle_category") }}</label>
                                <el-form-item prop="vehicle_id">
                                    <vSelect v-model="form.vehicle_id" :placeholder="$t('constants.select_vehicle_category')"
                                        :options="vehicles" :reduce="(vehicle) => vehicle.id" label="name"
                                        :dir="direction == 'rtl' ? 'rtl' : 'ltr'"></vSelect>
                                </el-form-item>
                            </div>
                            <div class="form-group col-md-4 col-sm-6 col-xs-12">
                                <label class="required fs-6 mb-2">{{ $t("users.make_id") }}</label>
                                <el-form-item prop="car_id">
                                    <vSelect v-model="form.make_id" :placeholder="$t('constants.select_car_make')"
                                        :options="car_makes" :reduce="(car) => car.id" label="name" @input="getCarModels"
                                        :dir="direction == 'rtl' ? 'rtl' : 'ltr'"></vSelect>
                                </el-form-item>
                            </div>
                            <div class="form-group col-md-4 col-sm-6 col-xs-12">
                                <label class="required fs-6 mb-2">{{ $t("users.model_id") }}</label>
                                <el-form-item prop="model_id">
                                    <vSelect v-model="form.model_id" :placeholder="$t('constants.select_car_model')"
                                        :options="car_models" :reduce="(model) => model.id" label="name"
                                        :dir="direction == 'rtl' ? 'rtl' : 'ltr'"></vSelect>
                                </el-form-item>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-md-4 col-sm-6 col-xs-12">
                                <label class="required fs-6 mb-2">{{ $t("users.car_year") }}</label>
                                <el-form-item prop="car_year">
                                    <date-picker v-model="form.car_year" type="year" class="w-100" value-type="YYYY"
                                        :placeholder="$t('constants.select_car_year')"></date-picker>
                                </el-form-item>
                            </div>
                            <div class="form-group col-md-4 col-sm-6 col-xs-12">
                                <label class="required fs-6 mb-2">{{ $t("users.color_id") }}</label>
                                <el-form-item prop="color_id">
                                    <vSelect v-model="form.color_id" :placeholder="$t('constants.select_car_color')"
                                        :options="colors" :reduce="(color) => color.id" :label="direction == 'rtl' ? 'name_ar' : 'name_en'"
                                        :dir="direction == 'rtl' ? 'rtl' : 'ltr'"></vSelect>
                                </el-form-item>
                            </div>
                            <div class="form-group col-md-4 col-sm-6 col-xs-12">
                                <label class="required fs-6 mb-2">{{
                                    $t("users.interior_color_id")
                                }}</label>
                                <el-form-item prop="interior_color_id">
                                    <vSelect v-model="form.interior_color_id"
                                        :placeholder="$t('constants.select_car_color_interior')" :options="colors"
                                        :reduce="(color) => color.id" :label="direction == 'rtl' ? 'name_ar' : 'name_en'"
                                        :dir="direction"></vSelect>
                                </el-form-item>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-md-4 col-sm-6 col-xs-12">
                                <label class="required fs-6 mb-2">{{
                                    $t("users.available_hours")
                                }}</label>
                                <el-form-item prop="available_hours">
                                    <el-input :placeholder="$t('users.available_hours')" v-model="form.available_hours"
                                        type="number">
                                        <template slot="prepend" v-if="direction == 'ltr'">{{ $t("users.hour") }}</template>
                                        <template slot="append" v-else>{{ $t("users.hour") }}</template>
                                    </el-input>
                                </el-form-item>
                            </div>
                            <div class="form-group col-md-4 col-sm-6 col-xs-12">
                                <label class="required fs-6 mb-2">{{
                                    $t("users.available_distance")
                                }}</label>
                                <el-form-item prop="available_distance">
                                    <el-input :placeholder="$t('users.available_distance')"
                                        v-model="form.available_distance" type="number">
                                        <template slot="prepend" v-if="direction == 'ltr'">{{ $t("users.KM") }}</template>
                                        <template slot="append" v-else>{{ $t("users.KM") }}</template>
                                    </el-input>
                                </el-form-item>
                            </div>
                            <div class="form-group col-md-4 col-sm-6 col-xs-12">
                                <label class="required fs-6 mb-2">{{
                                    $t("users.daily_price")
                                }}</label>
                                <el-form-item prop="daily_price">
                                    <el-input v-model="form.daily_price" :placeholder="$t('users.daily_price')"
                                        type="number"></el-input>
                                </el-form-item>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-md-4 col-sm-6 col-xs-12">
                                <label class="required fs-6 mb-2">{{
                                    $t("users.airport_pickup_price")
                                }}</label>
                                <el-form-item prop="airport_pickup_price">
                                    <el-input v-model="form.airport_pickup_price"
                                        :placeholder="$t('users.airport_pickup_price')" type="number"></el-input>
                                </el-form-item>
                            </div>

                            <div class="form-group col-md-4 col-sm-6 col-xs-12">
                                <label class="required fs-6 mb-2">{{
                                    $t("users.airport_drop_off_price")
                                }}</label>
                                <el-form-item prop="airport_drop_off_price">
                                    <el-input v-model="form.airport_drop_off_price"
                                        :placeholder="$t('users.airport_drop_off_price')" type="number"></el-input>
                                </el-form-item>
                            </div>
                            <div class="form-group col-md-4 col-sm-6 col-xs-12">
                                <label class="required fs-6 mb-2">{{ $t("users.brief") }}</label>
                                <el-form-item prop="brief">
                                    <el-input v-model="form.brief" :placeholder="$t('users.brief')"></el-input>
                                </el-form-item>
                            </div>
                        </div>
                    </div>

                    <div id="driver_docs" v-if="form.type == 4">
                        <div class="mb-5">
                            <h5>{{ $t("users.driver_docs") }}</h5>
                        </div>

                        <div class="row">
                            <div class="form-group col-md-4 col-sm-6 col-xs-12">
                                <label class="required fs-6 mb-2">{{ $t("users.img_id") }}</label>
                                <el-form-item prop="img_id">
                                    <vue-dropzone ref="img_id" id="dropzone" :options="dropzoneImageOptions"
                                        @vdropzone-success="(file, response) => ImageAdded(file, response, 'img_id')
                                            " @vdropzone-removed-file="(file, error, xhr) =>
        removeImageFile(file, error, xhr, 'img_id')
        "></vue-dropzone>
                                </el-form-item>
                            </div>
                            <div class="form-group col-md-4 col-sm-6 col-xs-12">
                                <label class="required fs-6 mb-2">{{
                                    $t("users.img_license")
                                }}</label>
                                <el-form-item prop="img_license">
                                    <vue-dropzone ref="img_license" id="dropzone" :options="dropzoneImageOptions"
                                        @vdropzone-success="(file, response) => ImageAdded(file, response, 'img_license')
                                            " @vdropzone-removed-file="(file, error, xhr) =>
        removeImageFile(file, error, xhr, 'img_license')
        "></vue-dropzone>
                                </el-form-item>
                            </div>
                            <div class="form-group col-md-4 col-sm-6 col-xs-12">
                                <label class="required fs-6 mb-2">{{
                                    $t("users.img_residence")
                                }}</label>
                                <el-form-item prop="img_residence">
                                    <vue-dropzone ref="img_residence" id="dropzone" :options="dropzoneImageOptions"
                                        @vdropzone-success="(file, response) =>
                                            ImageAdded(file, response, 'img_residence')
                                            " @vdropzone-removed-file="(file, error, xhr) =>
        removeImageFile(file, error, xhr, 'img_residence')
        "></vue-dropzone>
                                </el-form-item>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-md-4 col-sm-6 col-xs-12">
                                <label class="required fs-6 mb-2">{{
                                    $t("users.img_insurance")
                                }}</label>
                                <el-form-item prop="img_insurance">
                                    <vue-dropzone ref="img_insurance" id="dropzone" :options="dropzoneImageOptions"
                                        @vdropzone-success="(file, response) =>
                                            ImageAdded(file, response, 'img_insurance')
                                            " @vdropzone-removed-file="(file, error, xhr) =>
        removeImageFile(file, error, xhr, 'img_insurance')
        "></vue-dropzone>
                                </el-form-item>
                            </div>
                            <div class="form-group col-md-4 col-sm-6 col-xs-12">
                                <label class="required fs-6 mb-2">{{
                                    $t("users.img_vehicle_license")
                                }}</label>
                                <el-form-item prop="img_vehicle_license">
                                    <vue-dropzone ref="img_vehicle_license" id="dropzone" :options="dropzoneImageOptions"
                                        @vdropzone-success="(file, response) =>
                                            ImageAdded(file, response, 'img_vehicle_license')
                                            " @vdropzone-removed-file="(file, error, xhr) =>
        removeImageFile(file, error, xhr, 'img_vehicle_license')
        "></vue-dropzone>
                                </el-form-item>
                            </div>
                            <div class="form-group col-md-4 col-sm-6 col-xs-12">
                                <label class="required fs-6 mb-2">{{
                                    $t("users.img_work_permit")
                                }}</label>
                                <el-form-item prop="img_work_permit">
                                    <vue-dropzone ref="img_work_permit" id="dropzone" :options="dropzoneImageOptions"
                                        @vdropzone-success="(file, response) =>
                                            ImageAdded(file, response, 'img_work_permit')
                                            " @vdropzone-removed-file="(file, error, xhr) =>
        removeImageFile(file, error, xhr, 'img_work_permit')
        "></vue-dropzone>
                                </el-form-item>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-md-4 col-sm-6 col-xs-12">
                                <label class="required fs-6 mb-2">{{ $t("users.img_car") }}</label>
                                <el-form-item prop="img_car">
                                    <vue-dropzone ref="img_car" id="dropzone" :options="dropzoneImageOptions"
                                        @vdropzone-success="(file, response) => ImageAdded(file, response, 'img_car')
                                            " @vdropzone-removed-file="(file, error, xhr) =>
        removeImageFile(file, error, xhr, 'img_car')
        "></vue-dropzone>
                                </el-form-item>
                            </div>
                            <div class="form-group col-md-4 col-sm-6 col-xs-12">
                                <label class="required fs-6 mb-2">{{
                                    $t("users.img_front")
                                }}</label>
                                <el-form-item prop="img_front">
                                    <vue-dropzone ref="img_front" id="dropzone" :options="dropzoneImageOptions"
                                        @vdropzone-success="(file, response) => ImageAdded(file, response, 'img_front')
                                            " @vdropzone-removed-file="(file, error, xhr) =>
        removeImageFile(file, error, xhr, 'img_front')
        "></vue-dropzone>
                                </el-form-item>
                            </div>
                            <div class="form-group col-md-4 col-sm-6 col-xs-12">
                                <label class="required fs-6 mb-2">{{ $t("users.img_back") }}</label>
                                <el-form-item prop="img_back">
                                    <vue-dropzone ref="img_back" id="dropzone" :options="dropzoneImageOptions"
                                        @vdropzone-success="(file, response) => ImageAdded(file, response, 'img_back')
                                            " @vdropzone-removed-file="(file, error, xhr) =>
        removeImageFile(file, error, xhr, 'img_back')
        "></vue-dropzone>
                                </el-form-item>
                            </div>
                        </div>
                    </div>

                </div>
                <!--end::Card body-->

                <!--begin::Actions-->
                <div class="card-footer d-flex justify-content-end py-6 px-9">
                    <!--<button type="reset" class="btn btn-white btn-active-light-primary"
                        :class="direction == 'ltr' ? 'me-2' : 'ms-2'">
                        {{ $t('constants.discard') }}
                    </button>-->

                    <button type="button" id="kt_account_profile_details_submit" ref="submitButton1" class="btn btn-primary"
                        @click="editProfil()">
                        <span class="indicator-label">{{ $t('account.save_changes') }}</span>
                        <span class="indicator-progress">
                            {{ $t('account.please_wait') }}
                            <span class="spinner-border spinner-border-sm align-middle"
                                :class="direction == 'ltr' ? 'ms-2' : 'me-2'"></span>
                        </span>
                    </button>
                </div>
                <!--end::Actions-->
            </el-form>
            <!--end::Form-->
            <!-- coordinate modal -->
            <Location @confirmAddress="getAddress" :coordinates="coordinates" />
            <!-- end coordinates modal-->
        </div>
        <!--end::Content-->
    </div>
</template>

<script>
import axiosIns from "@/axios.js";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import Cookies from "js-cookie";
import Location from "@/views/apps/modals/Location.vue";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import parsePhoneNumber from 'libphonenumber-js'

//import Location from "@/modals/Location.vue";
export default {
    name: "profile-details",
    components: {
        vSelect,
        vueDropzone: vue2Dropzone,
        Location,
        DatePicker
    },
    data() {
        return {
            nodeEnv: process.env.NODE_ENV,
            form: {
                mobile: '',
            },
            current_phone_country: '',
            showForm : false,
            countries: [],
            cities: [],
            currencies: [],
            phoneObject: {},
            formatted_number: "",
            countryChangedCount: 0,
            inputOptions: {
                //placeholder: this.$t('constants.enter_phone_number')
            },
            dropdownOptions: {
                showDialCodeInSelection: true,
                showFlags: true,
                showSearchBox: true
            },
            payment_methods: [
                {
                    name: this.$t('users.online_payment_method'),
                    value: 1
                },
                {
                    name: this.$t('users.office_payment_method'),
                    value: 2
                }
            ],
            car_makes: [],
            car_models: [],
            colors: [],
            coordinates: {
                lat: 21.48436249892535,
                lng: 39.1924169425293,
            },
            vehicles: [],
            rules: {
                name: [
                    {
                        required: true,
                        message: this.$t('constants.required'),
                        trigger: "blur",
                    },
                ],
                company_name: [
                    {
                        required: true,
                        message: this.$t('constants.required'),
                        trigger: "blur",
                    },
                ],
                country_id: [
                    {
                        required: true,
                        message: this.$t('constants.required'),
                        trigger: "blur",
                    },
                ],
                city_id: [
                    {
                        required: true,
                        message: this.$t('constants.required'),
                        trigger: "blur",
                    },
                ],
                currency_id: [
                    {
                        required: true,
                        message: this.$t('constants.required'),
                        trigger: "blur",
                    },
                ],
                payment_method: [
                    {
                        required: true,
                        message: this.$t('constants.required'),
                        trigger: "blur",
                    },
                ],
                make_id: [
                    {
                        required: true,
                        message: this.$t('constants.required'),
                        trigger: "blur",
                    },
                ],
                model_id: [
                    {
                        required: true,
                        message: this.$t('constants.required'),
                        trigger: "blur",
                    },
                ],
                car_year: [
                    {
                        required: true,
                        message: this.$t('constants.required'),
                        trigger: "blur",
                    },
                ],
                airport_pickup_price: [
                    {
                        required: true,
                        message: this.$t('constants.required'),
                        trigger: "blur",
                    },
                ],
                airport_drop_off_price: [
                    {
                        required: true,
                        message: this.$t('constants.required'),
                        trigger: "blur",
                    },
                ],
                daily_price: [
                    {
                        required: true,
                        message: this.$t('constants.required'),
                        trigger: "blur",
                    },
                ],
                mobile: [
                    { required: true, message: this.$t('constants.required'), trigger: 'blur' },
                    {
                        validator: (rule, value, callback) => {
                            if (this.validatePhone()) {
                                callback();
                            } else {
                                callback(new Error(this.$t('constants.invalid_phone_number')));
                            }
                        },
                        trigger: 'blur',
                    },
                ],
                coordinates: [
                    { required: false, trigger: 'blur'},
                    {
                        validator: (rule, value, callback) => {
                            const coordinatesRegex = /^-?\d+(\.\d+)?,\s*-?\d+(\.\d+)?$/;
                            if (!coordinatesRegex.test(value)) {
                                callback(new Error(this.$t('constants.invalid_coordinates')));
                            } else {
                                callback();
                            }
                        },
                        trigger: 'blur',
                    },
                ]
            },
            dropzoneImageOptions: {
                url: process.env.NODE_ENV == "production" ? "https://api.dgotours.com/api/users/images/upload" :
                    process.env.NODE_ENV == "development" ? "http://api.local/api/users/images/upload" : "",
                thumbnailWidth: 150,
               // maxFilesize: 0.2,
                maxFiles: 1,
                autoProcessQueue: true,
                headers: {
                    Authorization: Cookies.get("access_token"),
                },
                //autoQueue: false,
                //chunking: true,
                addRemoveLinks: true,
                timeout: 600000
            },
            deleteFiles: true,
        }
    },
    computed: {
        direction() {
            return this.$store.state.direction;
        }
    },
    watch: {
        location_type(val) {
            if (val == 'pick_up' && this.pick_up_location_coordinates !== "") {
                const pickUpArray = this.pick_up_location_coordinates.split(',').map(parseFloat);
                const pickUpLocation = {
                    lat: pickUpArray[0],
                    lng: pickUpArray[1]
                };
                this.coordinates = pickUpLocation;
            } else if (val == 'drop_off' && this.drop_off_location_coordinates !== "") {
                const dropOffArray = this.drop_off_location_coordinates.split(',').map(parseFloat);
                const dropOffLocation = {
                    lat: dropOffArray[0],
                    lng: dropOffArray[1]
                };
                this.coordinates = dropOffLocation;
            }
        },
        "form.type": function(val){
            if(val == 4){
                setTimeout(() => {
                    this.editPrepend();
                })
                
            }
        }
    },
    methods: {
        validatePhone(){
            const phoneNumber = parsePhoneNumber(this.form.mobile, this.current_phone_country);
            if(phoneNumber.isValid()){
                this.formatted_number = phoneNumber.number;
                return true;
            }else {
                return false
            }
        },
        aboutTitle(type) {
            switch (type) {
                case 2:
                    return this.$t("users.about_company");
                    break;
                case 3:
                    return this.$t("users.about_celebrity");
                    break;
                case 4:
                    return this.$t("users.about_driver");
                    break;
                case 6:
                    return this.$t("users.about_guide");
                    break;
                default:
                    break;
            }
        },
        openLocationModal() {
            const coordinates_data = this.form.coordinates.split(',').map(parseFloat);
            const coords = {
                lat: coordinates_data[0],
                lng: coordinates_data[1]
            };
            this.coordinates = coords;
        },

        validatePercentage(rule, value, callback) {
            if (value && isNaN(value)) {
                callback(new Error('Percentage must be a number'));
            } else if (value && (value < 0 || value > 100)) {
                callback(new Error('Percentage must be between 0 and 100'));
            } else {
                callback();
            }
        },
        ImageAdded(file, response, field) {
            this.form[field] = response.path;
        },
        removeImageFile(file, error, xhr, field) {
            if (this.deleteFiles) {
                this.removeUploadedImage(this.form[field]).then(
                    () => (this.form[field] = "")
                );
            }
        },
        removeUploadedImage(path) {
            return new Promise((resolve, reject) => {
                axiosIns
                    .post(`users/images/delete`, {
                        path: path,
                    })
                    .then((response) => {
                        resolve(response);
                    });
            });
        },
        getAllCountries() {
            axiosIns.get("countries").then((response) => {
                this.countries = response.data.countries;
            });
        },
        getCities() {
            this.form.city_id = "";
            return new Promise((resolve, reject) => {
                axiosIns.get("cities/" + this.form.country_id).then((response) => {
                    this.cities = response.data.cities;
                    resolve(response);
                });
            })
        },

        getCurrencies() {
            axiosIns.get("currencies").then((response) => {
                this.currencies = response.data.currencies;
            });
        },
        getVehicles(){
            axiosIns.get('filters_data/vehicles')
            .then((response) => {
                this.vehicles = response.data.vehicles;
            })
        },
        getUserDetails() {
            return new Promise((resolve, reject) => {
                axiosIns.get(`users/${this.$store.state.user.id}/edit`)
                    .then((response) => {
                        this.form = response.data.data;

                        this.getCities().then((res) => {
                            this.form.city_id = response.data.city_id;
                        });

                        const mockFile = { size: 123, type: "image/png,jpg,jpeg" };
                        this.showForm = true;
                        
                        if (response.data.data.image != '') {
                            setTimeout(() => {
                                this.$refs.image.manuallyAddFile(
                                    mockFile,
                                    response.data.data.image
                                );
                                let url = new URL(response.data.data.image);
                                this.form.image = url.pathname.substring(1);
                            },500)
                            
                        }
                        
                       /* if (response.data.data.lat && response.data.data.lng) {
                            this.coord.lat = response.data.data.lat;
                            this.coord.lng = response.data.data.lng;
                        }*/

                        resolve(response);
                    })
            })
        },
        removeImage() {
            this.profileDetails.avatar = "/media/avatars/blank.png";
        },
        getAddress(event) {
            //console.log(event);
            this.form.coordinates = event.coordinates;
        },
        editProfil() {
            // console.log(this.$refs.phone_number);
           // return;
            this.$refs["form"].validate((valid) => {
                if (valid) {

                    const formData = new FormData();
                    for (const key in this.form) {
                        if (this.form[key]) {
                            formData.append(key, this.form[key]);
                        }
                    }

                    formData.set('mobile', this.formatted_number);

                    this.$swal(
                        {
                            showConfirmButton: false,
                            allowOutsideClick: false, // disable clicking outside the modal
                            backdrop: true, // enable backdrop to prevent closing on outside click
                            didOpen: () => {
                                this.$swal.showLoading();
                            },
                        }
                    );
                    axiosIns
                        .post(`users/${this.$store.state.user.id}?_method=put`, formData)
                        .then((response) => {
                            this.$swal.close();
                            this.$notify({
                                title: "مرحبا",
                                type: "success",
                                message: response.data,
                                showClose: true,
                                position: "top-left",
                            });
                        }).catch((error) => {
                            this.$swal.close();
                            this.$swal.fire({
                                icon: 'error',
                                title: 'Oops...',
                                text: 'Something went wrong!',
                            })
                        });
                } else {
                    let animationFrameId;

                    const animateScroll = () => {
                        const errorElements = document.querySelectorAll('.el-form-item__error');
                        if (errorElements.length > 0) {
                            const firstErrorParent = errorElements[0].closest('.row');
                            if (firstErrorParent) {
                                firstErrorParent.scrollIntoView({ behavior: 'smooth' });
                            }
                        } else {
                            animationFrameId = requestAnimationFrame(animateScroll);
                        }
                    };

                    animationFrameId = requestAnimationFrame(animateScroll);
                }
            })
        },
        getCarMakes() {
            return new Promise((resolve, reject) => {
                axiosIns.get('get-car-makes')
                    .then((response) => {
                        this.car_makes = response.data.makes;

                        resolve(response);
                    })
            })
        },
        getCarModels() {
            this.form.model_id = ""
            return new Promise((resolve, reject) => {
                axiosIns.get(`get-car-models/${this.form.make_id}`)
                    .then((response) => {
                        this.car_models = response.data.models;
                        resolve(response);
                    })
            })
        },
        getColors() {
            axiosIns.get("colors").then((response) => {
                this.colors = response.data.colors;
            });
        },

        editPrepend() {
            const element = document.querySelectorAll('.el-input-group__append');
            const element2 = document.querySelectorAll('.el-input-group--append .el-input__inner');

            if (this.direction == "rtl") {
                element.forEach((item) => {
                    item.style.borderRight = '0';
                    item.style.borderLeft = '1px solid #DCDFE6';
                    item.style.borderRadius = '0px';
                    item.style.borderTopLeftRadius = '4px';
                    item.style.borderBottomLeftRadius = '4px';
                });

                element2.forEach((item) => {
                    item.style.borderRadius = '0px';
                    item.style.borderTopRightRadius = '4px';
                    item.style.borderBottomRightRadius = '4px';
                });
            }
        },

        /*validatePhone(event) {
            this.phoneObject = event;
            console.log(event);
            const phoneNumber = parsePhoneNumber(this.form.mobile, event.country.iso2);
            console.log(phoneNumber.isValid());
            this.formatted_number = event.number;
        },*/

        countryChanged(event){
            //console.log(this.countryChangedCount)
            this.countryChangedCount++;
            if(this.countryChangedCount > 1){
                this.form.mobile = '';
            }
            //console.log(this.countryChangedCount)
            this.current_phone_country = event.iso2;
        }

    },

    mounted() {
        this.getAllCountries();
        this.getCurrencies();
        this.getCarMakes();
        this.getColors();
        this.getVehicles();
        this.editPrepend();
        this.getUserDetails().then((response) => {

            if (this.form.type == 4) {
                this.getCarModels().then((res) => {
                    this.form.model_id = response.data.model_id;
                });

                const mockFile = { size: 123, type: "image/png,jpg,jpeg" };
                if (response.data.data.img_id) {
                    this.$refs.img_id.manuallyAddFile(mockFile, this.form.img_id);
                    let url = new URL(this.form.img_id);
                    this.form.img_id = url.pathname.substring(1);
                }
                if (response.data.data.img_license) {
                    this.$refs.img_license.manuallyAddFile(
                        mockFile,
                        response.data.data.img_license
                    );
                    let url = new URL(response.data.data.img_license);
                    this.form.img_license = url.pathname.substring(1);
                }
                if (response.data.data.img_residence) {
                    this.$refs.img_residence.manuallyAddFile(
                        mockFile,
                        response.data.data.img_residence
                    );
                    let url = new URL(response.data.data.img_residence);
                    this.form.img_residence = url.pathname.substring(1);
                }
                if (response.data.data.img_insurance) {
                    this.$refs.img_insurance.manuallyAddFile(
                        mockFile,
                        response.data.data.img_insurance
                    );
                    let url = new URL(response.data.data.img_insurance);
                    this.form.img_insurance = url.pathname.substring(1);
                }
                if (response.data.data.img_vehicle_license) {
                    this.$refs.img_vehicle_license.manuallyAddFile(
                        mockFile,
                        response.data.data.img_vehicle_license
                    );
                    let url = new URL(response.data.data.img_vehicle_license);
                    this.form.img_vehicle_license = url.pathname.substring(1);
                }
                if (response.data.data.img_work_permit) {
                    this.$refs.img_work_permit.manuallyAddFile(
                        mockFile,
                        response.data.data.img_work_permit
                    );
                    let url = new URL(response.data.data.img_work_permit);
                    this.form.img_work_permit = url.pathname.substring(1);
                }
                if (response.data.data.img_car) {
                    this.$refs.img_car.manuallyAddFile(
                        mockFile,
                        response.data.data.img_car
                    );
                    let url = new URL(response.data.data.img_car);
                    this.form.img_car = url.pathname.substring(1);
                }
                if (response.data.data.img_front) {
                    this.$refs.img_front.manuallyAddFile(
                        mockFile,
                        response.data.data.img_front
                    );
                    let url = new URL(response.data.data.img_front);
                    this.form.img_front = url.pathname.substring(1);
                }
                if (response.data.data.img_back) {
                    this.$refs.img_back.manuallyAddFile(
                        mockFile,
                        response.data.data.img_back
                    );
                    let url = new URL(response.data.data.img_back);
                    this.form.img_back = url.pathname.substring(1);
                }
            }
        });
    },
    beforeDestroy() {
        this.deleteFiles = false;
    }
}
</script>