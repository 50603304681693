<template>
  <div class="card card-xxl-stretch mb-5 mb-xl-10">
    <!--begin::Header-->
    <div class="card-header align-items-center border-0 mt-4">
      <h3 class="card-title align-items-start flex-column">
        <span class="fw-bolder mb-2 text-dark">{{ $t("orders.details") }}</span>
      </h3>
      <div class="card-toolbar gap-5">
        <button class="btn btn-sm btn-primary" data-bs-toggle="modal" data-bs-target="#modal_update_order_status"
          v-if="order.status == 0">
          {{ $t("orders.update_order_status") }}
        </button>
        <button class="btn btn-sm btn-primary" @click="confirmOfficePayment()"
          v-if="order.payment_status == 0 && order.payment_method == 2 && order.status == 1">
          {{ $t("orders.confirm_office_payment") }}
        </button>
        <!--<button class="btn btn-sm btn-primary" @click="responding_to_the_cancellation_request()"
          v-if="order.status == 8">
          {{ $t("orders.responding_to_the_cancellation_request") }}
        </button>-->
      </div>
    </div>
    <div class="card-body py-3" v-if="OrderLength > 0">
      <div class="d-flex flex-column flex-xl-row gap-7 gap-lg-10">
        <div class="card card-flush py-4 flex-row-fluid">
          <div class="card-header">
            <div class="card-title">
              <h2>{{ `${$t("orders.details")} #${order.order_no}` }}</h2>
            </div>
          </div>
          <div class="card-body pt-0">
            <div class="table-responsive">
              <table class="table align-middle table-row-bordered mb-0 fs-6 gy-5 min-w-300px">
                <tbody class="fw-semibold text-gray-600">
                  <tr>
                    <td class="text-muted">
                      {{ $t('orders.service_type') }}
                    </td>
                    <td class="fw-bold text-end">
                      {{ direction == 'ltr' ? order.category.name_en : order.category.name_ar }}
                    </td>
                  </tr>
                  <tr>
                    <td class="text-muted">{{ $t('orders.order_status') }}</td>
                    <td class="fw-bold text-end">
                      <span class="badge" :class="orderStatus(order.status)">{{
                        orderStatusName(order.status)
                      }}</span>
                    </td>
                  </tr>
                  <tr>
                    <td class="text-muted">{{ $t('orders.payment_status') }}</td>
                    <td class="fw-bold text-end">
                      <span class="badge" :class="paymentStatusColor(order.payment_status)">{{
                        orderPaymentStatusName(order.payment_status)
                      }}</span>
                    </td>
                  </tr>
                  <tr v-if="order.category.id != 1">
                    <td class="text-muted">{{ $t('orders.reservation_date') }}</td>
                    <td class="fw-bold text-end">
                      {{ order.reservation_date }}
                    </td>
                  </tr>
                  <tr v-if="order.category.id == 1">
                    <td class="text-muted">{{ $t('orders.arriving_datetime') }}</td>
                    <td class="fw-bold text-end">
                      {{ order.arriving_datetime }}
                    </td>
                  </tr>
                  <tr v-if="order.category.id == 1">
                    <td class="text-muted">{{ $t('orders.leaving_datetime') }}</td>
                    <td class="fw-bold text-end">
                      {{ order.leaving_datetime }}
                    </td>
                  </tr>
                  <tr>
                    <td class="text-muted">{{ $t('orders.days') }}</td>
                    <td class="fw-bold text-end">
                      {{ order.days }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div class="card card-flush py-4 flex-row-fluid">
          <div class="card-header">
            <div class="card-title">
              <h2>{{ $t("orders.user_details") }}</h2>
            </div>
          </div>
          <div class="card-body pt-0">
            <div class="table-responsive">
              <table class="table align-middle table-row-bordered mb-0 fs-6 gy-5 min-w-300px">
                <tbody class="fw-semibold text-gray-600">
                  <tr>
                    <td class="text-muted">{{ $t('users.name') }}</td>
                    <td class="fw-bold text-end">
                      {{ order.user_name }}
                    </td>
                  </tr>
                  <tr>
                    <td class="text-muted">{{ $t('users.email') }}</td>
                    <td class="fw-bold text-end">
                      {{ order.user_email }}
                    </td>
                  </tr>
                  <tr>
                    <td class="text-muted">{{ $t('users.country_id') }}</td>
                    <td class="fw-bold text-end">
                      {{ order.data_object.about.country }}
                    </td>
                  </tr>
                  <tr v-if="order.user_phone != null">
                    <td class="text-muted">{{ $t('users.mobile') }}</td>
                    <td class="fw-bold text-end">
                      {{ order.user_phone }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div class="card card-flush py-4 flex-row-fluid">
          <div class="card-header">
            <div class="card-title">
              <h2>{{ $t("orders.services") }}</h2>
            </div>
          </div>
          <div class="card-body pt-0">
            <div class="table-responsive">
              <table class="table align-middle table-row-bordered mb-0 fs-6 gy-5 min-w-300px">
                <tbody class="fw-semibold text-gray-600">
                  <tr v-if="order.airport_meet == 1">
                    <td class="text-muted">
                      {{ $t('orders.airport_pickup') }}
                      <div v-if="order.airport !== undefined">
                        <ul>
                          <li class="fw-bold">
                            {{ $t('airports_pickup.airport_name') }} : {{ order.airport.name }}
                          </li>
                          <li class="fw-bold">
                            {{ $t('airports_pickup.airport_city') }} : {{ order.airport.municipality }}
                          </li>
                          <li class="fw-bold">
                            {{ $t('users.airport_pickup_price') }} : {{ formatMoney(order.airport.pivot.price, currency)
                            }}
                          </li>
                        </ul>
                      </div>
                    </td>
                  </tr>
                  <tr v-if="order.airport_drop_off == 1">
                    <td class="text-muted">
                      {{ $t('orders.airport_drop_off') }}
                      <div>
                        <ul>
                          <li class="fw-bold">City To: {{ order.city_to }}</li>
                          <li class="fw-bold">
                            Meeting Date & Time: {{ order.meeting_date }}
                          </li>
                          <li class="fw-bold">
                            Meeting Place: <span class="location-info-text" data-bs-toggle="modal"
                              data-bs-target="#modal_meeting_place">{{ $t('constants.location_on_the_map') }}</span>
                            <!--{{ order.meeting_place }}-->
                          </li>
                        </ul>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="text-muted" v-if="order.city_commuting == 1">
                      {{ $t('orders.city_commuting') }}
                      <div>
                        <ul>
                          <li class="fw-bold">
                            City :
                            {{
                              `${order.city_commuting_data.city_from.name_en} -
                                                        ${order.city_commuting_data.city_to.name_en}`
                            }}
                          </li>
                          <li class="fw-bold">
                            Price:
                            {{ formatMoney(order.city_commuting_data.price, currency) }}
                          </li>
                          <li class="fw-bold">
                            Date & Time: {{ order.city_commuting_date }}
                          </li>
                        </ul>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="card card-flush py-4 flex-row-fluid overflow-hidden">
        <div class="card-header">
          <div class="card-title">
            <h2>{{ $t('orders.order_summary') }}</h2>
          </div>
        </div>
        <div class="card-body pt-0">
          <div class="table-responsive">
            <table class="table align-middle table-row-bordered mb-0 fs-6 gy-5 min-w-300px">
              <tbody class="fw-semibold text-gray-600">
                <tr>
                  <td class="text-muted">{{ $t('orders.days') }}</td>
                  <td class="fw-bold text-end">
                    {{ order.days }}
                  </td>
                </tr>
                <tr>
                  <td class="text-muted">
                    {{ direction == 'ltr' ? `${order.category.name_en} (x ${order.days})` : `${order.category.name_ar} (x
                                        ${order.days})` }}
                  </td>
                  <td class="fw-bold text-end">
                    {{
                      order.category.id == 1
                      ? formatMoney(order.item_cost, currency)
                      : order.category.id == 2
                        ? formatMoney(order.airport == undefined ?
                          order.data_object.airport_pickup.amount : order.airport.pivot.price, currency)
                        : order.category.id == 3
                          ? formatMoney(order.data_object.airport_drop_off.amount, currency)
                          : order.category.id == 4
                            ? formatMoney(order.city_commuting_data.price, currency)
                            : ""
                    }}
                  </td>
                </tr>
                <tr v-if="order.airport_meet == 1 && (order.category.id == 1 || order.category.id == 4)">
                  <td class="text-muted">{{ $t('orders.airport_pickup') }}</td>
                  <td class="fw-bold text-end">
                    <!--{{
                      order.category.id != 1
                        ? formatPrice(order.data_object.airport_pickup.amount)
                        : formatPrice(0)
                    }}-->
                    {{ order.airport !== undefined ? formatMoney(order.airport.pivot.price, currency) : order.category.id
                      != 1 ? formatMoney(order.data_object.airport_pickup.amount, 0) : formatMoney(0, currency) }}
                  </td>
                </tr>
                <tr v-if="order.airport_drop_off == 1">
                  <td class="text-muted">{{ $t('orders.airport_drop_off') }}</td>
                  <td class="fw-bold text-end">
                    {{ formatMoney(order.data_object.airport_drop_off_price.amount, currency) }}
                  </td>
                </tr>
                <tr v-if="order.city_commuting == 1">
                  <td class="text-muted">{{ $t('orders.city_commuting') }}</td>
                  <td class="fw-bold text-end">
                    <span v-if="order.additional_price == null">{{
                      formatMoney(order.city_commuting_data.price, currency)
                    }}</span>
                    <span v-else><s>{{
                      formatMoney(order.city_commuting_data.price, currency)
                    }}</s></span>
                  </td>
                </tr>
                <tr v-if="order.additional_price != null">
                  <td class="text-muted">{{ $t('orders.additional_price') }}</td>
                  <td class="fw-bold text-end">
                    {{ formatMoney(order.additional_price, currency) }}
                  </td>
                </tr>
                <tr>
                  <td class="fs-2">{{ $t('orders.grand_total') }}</td>
                  <td class="fw-bold text-end fs-2">
                    {{ formatMoney(order.total_cost, currency) }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <UpdateOrderStatus @success="getOrder()" :order_id="order.id" :current_status="order.status"></UpdateOrderStatus>
    <MeetingPlace v-if="OrderLength > 0 && order.meeting_place" :coordinates="mapStartLocation" />
  </div>
</template>

<script>
import axiosIns from "@/axios.js";
import UpdateOrderStatus from "../modals/UpdateOrderStatus.vue";
import MeetingPlace from "../modals/MeetingPlace.vue";

export default {
  name: "OrderView",
  components: {
    UpdateOrderStatus,
    MeetingPlace
  },
  data() {
    return {
      order: {},
      meeting_place: false,
      mapStartLocation: { lat: 0, lng: 0 },
      currency: this.$store.state.user.currency
    };
  },
  computed: {
    OrderLength() {
      return Object.keys(this.order).length;
    },
  },
  methods: {
    formatMoney(amount, currency) {
      // Format the amount using Intl.NumberFormat
      const formattedAmount = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: currency,
      }).format(amount);

      return formattedAmount;
    },
    openMeetingPlaceModal() {
      this.mapStartLocation.lat = this.order.meeting_place[0];
      this.mapStartLocation.lng = this.order.meeting_place[1];
      this.meeting_place = true;
    },
    orderPaymentStatusName(status) {
      if (status == 0) {
        return this.$t('orders.not_paid');
      } else if (status == 1) {
        return this.$t('orders.paid');
      } else if (status == 2) {
        return this.$t('orders.paid_in_office')
      }
    },
    orderStatusName(status) {
      if (status == 0) {
        return this.$t('orders.pending');
      } else if (
        status == 1
      ) {
        return this.$t('orders.accepted');
      } else if (
        status == 2
      ) {
        return this.$t('orders.rejected');
      } else if (status == 3) {
        return this.$t('orders.canceled');
      } else if (status == 5) {
        return this.$t('orders.started');
      } else if (status == 7) {
        return this.$t('orders.completed');
      }
      else if (status == 8) {
        return this.$t('orders.pending_cancellation');
      }
    },

    orderStatus(status) {
      if (status == 0) {
        return "bg-warning text-dark";
      } else if (
        status == 2 ||
        status == 3
      ) {
        return "bg-danger";
      } else if (
        status == 1
      ) {
        return "bg-success";
      } else {
        return "bg-light text-dark";
      }
    },

    paymentStatusColor(status) {
      if (status == 0) {
        return 'bg-warning text-dark';
      } else if (
        status == 1
      ) {
        return 'bg-success';
      } else if (
        status == 2
      ) {
        return 'bg-success';
      }
    },
    formatPrice(price) {
      //const price = 9.99;
      const currency = "USD";
      const formattedPrice = price.toLocaleString("en-US", {
        style: "currency",
        currency: currency,
      });
      return formattedPrice;
      //console.log(formattedPrice); // $9.99
    },
    getOrder() {
      axiosIns.get(`orders/${this.$route.params.id}`).then((response) => {
        this.order = response.data[0];
        this.mapStartLocation.lat = this.order.meeting_place[0];
        this.mapStartLocation.lng = this.order.meeting_place[1];

      });
    },
    confirmOfficePayment() {
      let order_id = this.$route.params.id
      this.$swal(
        {
          title: this.$t('constants.are_you_sure'),
          text: this.$t('constants.confirm_payment_desc'),
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          cancelButtonText: this.$t('constants.cancel'),
          confirmButtonText: this.$t('constants.payment_btn_confirmation')
        }).then((result) => {
          if (result.isConfirmed) {
            axiosIns.post(`payments/confirm-office-payment/${order_id}`, {
              order_type: 'driver order'
            })
              .then((response) => {
                this.getOrder();
              })
          }
        })

    },
    responding_to_the_cancellation_request() {
      console.log('responding_to_the_cancellation_request');
      this.$swal(
        {
          title: this.$t('orders.responding_to_the_cancellation_request'),
          text: this.$t('orders.do_you_accept_the_cancellation_request'),
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          cancelButtonText: this.$t('constants.reject'),
          confirmButtonText: this.$t('constants.accept')
        }).then((result) => {
          if (result.isConfirmed) {
            /*axiosIns.post(`payments/confirm-office-payment/${order_id}`, {
              order_type: 'driver order'
            })
              .then((response) => {
                this.getOrder();
              })*/
          }else{

          }
        })
    },
  },

  mounted() {
    this.getOrder();
  },
};
</script>

<style>
.important-info-text,
.location-info-text {
  color: #1273c4;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
}

.location-info-text:hover {
  text-decoration: underline;
}
</style>
