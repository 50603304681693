const helpers = {
    formatMoney(amount, currency) {
    // Format the amount using Intl.NumberFormat
        const formattedAmount = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: currency,
        }).format(amount);

        return formattedAmount;
    },
}

export default helpers;