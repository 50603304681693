<template>
  <!--begin::Header-->
  <div
    id="kt_header"
    class="header align-items-stretch flex-column"
    data-kt-sticky-name="header"
    data-kt-sticky-offset="{default: '200px', lg: '300px'}"
  >
    <!--begin::Container-->
    <div
      id="header-top"
      class="d-flex align-items-center bg-white py-2 py-sm-3"
    >
      <!--begin::Aside mobile toggle-->
      <!--<div
        class="d-flex topbar align-items-center d-lg-none ms-n2 me-3"
        title="Show aside menu"
      >
        <div
          class="btn btn-icon btn-active-light-primary"
          id="kt_aside_mobile_toggle"
        >
          <span class="svg-icon svg-icon-2x mt-1">
            <inline-svg src="/media/icons/duotune/abstract/abs015.svg" />
          </span>
        </div>
      </div>-->
      <!--end::Aside mobile toggle-->

      <!--begin::Mobile logo-->
      <div class="d-flex header-logo flex-grow-1 flex-lg-grow-0">
        <router-link to="/">
          <img
            alt="Logo"
            src="@/assets/images/logo-dashboard.png"
            class="logo-default"
            style="width: 70px"
          />
          <!--<img
            alt="Logo"
            src="/media/logos/logo-5.png"
            class="logo-sticky h-25px"
          />-->
          </router-link>
      </div>
      <!--end::Mobile logo-->

      <!--begin::Wrapper-->
      <div
        class="d-flex align-items-stretch justify-content-end flex-lg-grow-1"
      >
        <!--begin::Topbar-->
        <div class="d-flex align-items-stretch flex-shrink-0">
          <KTTopbar></KTTopbar>
        </div>
        <!--end::Topbar-->
      </div>
      <!--end::Wrapper-->
    </div>

    <!--begin::Navbar-->
    <div
      class="d-flex align-items-stretch mt-0 mt-lg-10 bg-white"
      id="kt_header_menu_nav"
    >
      <KTMenu></KTMenu>
    </div>
    <!--end::Navbar-->
    <!--end::Container-->
  </div>
  <!--end::Header-->
</template>

<script>
import KTTopbar from "@/components/dashboard/header/TopBar.vue";
import KTMenu from "@/components/dashboard/header/DashboardMenu.vue";

export default {
  components: {
    KTTopbar,
    KTMenu,
  },
};
</script>

<style lang="scss">
.svg-icon svg [fill]:not(.permanent):not(g) {
  fill: #a1a5b7 !important;
}
#kt_header .svg-icon.svg-icon-2 svg{
  width: 2rem !important;
  height: 2rem !important;
}
#kt_header {
  height: auto !important;
}
#kt_header_menu_nav {
  padding: 0px 30px;
}
#header-top {
  padding-left: 30px;
  padding-right: 30px;
}
</style>
