// auth user
//let user = localStorage.getItem('userData');
import store from "@/store";
import i18n from "@/i18n";

const DocMenuConfig = [
  {
    key: 'dashboard',
    heading: i18n.t('menu.dashboard'),
    route: "/",
    svgIcon: "media/icons/duotune/art/art002.svg",
    fontIcon: "bi-app-indicator",
    allow: true,
    locked: false,
  },
  {
    key: 'orders',
    heading: i18n.t('menu.orders'),
    route: "/dashboard-panel/orders",
    svgIcon: "media/icons/duotune/general/gen019.svg",
    fontIcon: "bi-layers", 
    allow: true,
    locked: !store.getters.isActive || !store.getters.emailIsVerified ? true : false
  },
  {
    key: 'users',
    heading: i18n.t('menu.users'),
    route: "/dashboard-panel/users",
    svgIcon: "media/icons/duotune/general/gen019.svg",
    fontIcon: "bi-layers",
    allow: store.getters.userType == 2,
    locked: !store.getters.isActive || !store.getters.emailIsVerified
  },
  {
    key: 'tours',
    heading: i18n.t('menu.tours'),
    //route: "/dashboard-panel/tours",
    svgIcon: "media/icons/duotune/general/gen019.svg",
    fontIcon: "bi-layers",
    allow: true,
    locked: false,
    children: [
      {
        key: 'tours',
        heading: i18n.t('menu.tours_list'),
        route_name: "tours",
        locked: !store.getters.isActive || !store.getters.emailIsVerified,
      },
      {
        key: 'reservations',
        heading: i18n.t('menu.reservations'),
        route_name: "reservations",
        locked: !store.getters.isActive || !store.getters.emailIsVerified,
      },
      /*{
        heading: "tours offers",
        route_name: "offers",
        locked: !store.getters.isActive || !store.getters.emailIsVerified,
      },*/
    ],
  },
  {
    key: 'services',
    heading: i18n.t('menu.services'),
    //route: "/dashboard-panel/users",
    svgIcon: "media/icons/duotune/general/gen019.svg",
    fontIcon: "bi-layers",
    allow: store.getters.userType == 4,
    locked: false,
    children: [
      {
        key: 'cities commuting',
        heading: i18n.t('menu.cities_commuting'),
        route_name: "cities-commuting",
        locked: false,
      },
      {
        key: 'airports pickup',
        heading: i18n.t('menu.airports_pickup'),
        route_name: "airports-pickup",
        locked: false,
      },
    ],
  },
  {
    key: 'account',
    heading: i18n.t('menu.account'),
    //route: "/dashboard-panel/users",
    svgIcon: "media/icons/duotune/general/gen019.svg",
    fontIcon: "bi-layers",
    allow: true,
    locked: false,
    children: [
      {
        key: 'overview',
        heading: i18n.t('menu.overview'),
        route_name: "account-overview",
        locked: false,
      },
      {
        key: 'settings',
        heading: i18n.t('menu.settings'),
        route_name: "account-settings",
        locked: false,
      },
    ],
  },
  {
    key: 'rental cars',
    heading: i18n.t('menu.rental_cars'),
    //route: "/dashboard-panel/cars",
    svgIcon: "/media/icons/duotune/general/gen019.svg",
    fontIcon: "bi-layers",
    allow: store.getters.userType == 2,
    locked: !store.getters.isActive || !store.getters.emailIsVerified,
    children: [
      {
        key: "cars",
        heading: i18n.t('menu.cars'),
        route_name: "cars",
        locked: !store.getters.isActive || !store.getters.emailIsVerified,
      },
      {
        key: 'reservations',
        heading: i18n.t('menu.reservations'),
        route_name: "rental-cars-reservations",
        locked: !store.getters.isActive || !store.getters.emailIsVerified,
      },
    ],
  },
  
];

export default DocMenuConfig;
