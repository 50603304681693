<template>
  <div class="modal fade" id="modal_meeting_place" ref="locationRef" tabindex="-1" aria-hidden="true">
    <!--begin::Modal dialog-->
    <div class="modal-dialog modal-lg modal-dialog-centered mw-650px">
      <!--begin::Modal content-->
      <div class="modal-content">
        <!--begin::Modal header-->
        <div class="modal-header" id="modal_meeting_place_header">
          <!--begin::Modal title-->
          <h2 class="fw-bolder">{{ $t('constants.meeting_place') }}</h2>
          <!--end::Modal title-->

          <!--begin::Close-->
          <div id="modal_add_reservation_close" data-bs-dismiss="modal"
            class="btn btn-icon btn-sm btn-active-icon-primary" ref="close">
            <span class="svg-icon svg-icon-1">
              <inline-svg src="/media/icons/duotune/arrows/arr061.svg" />
            </span>
          </div>
          <!--end::Close-->
        </div>
        <!--end::Modal header-->
        <div class="modal-body py-10 px-lg-17">
          <div class="row">
            <div>
              <h5 class="pb-3">{{$t('constants.address')}}</h5>
              <p>{{ address }}</p>
            </div>
          </div>
          <div>
            <div class="col">
              <h5 class="pb-3">{{ $t('constants.location_on_the_map') }}</h5>
            </div>
            <div class="col">
              <GmapMap :center="coordinates" :zoom="7" map-type-id="terrain" style="width: 100%; height: 350px"
                ref="mapRef">
                <GmapMarker ref="myMarker" :position="coordinates" />
              </GmapMap>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
    
<script>
export default {
  name: 'LocationModal',
  props: {
    coordinates: Object
  },
  data() {
    return {
      coord: {
        lat: 21.48436249892535,
        lng: 39.1924169425293,
      },
      address: "",
    };
  },
  methods: {
    geocodeCoordinates() {
      const geocoder = new google.maps.Geocoder();
      const latlng = { lat: this.coordinates.lat, lng: this.coordinates.lng };

      geocoder.geocode({ location: latlng }, (results, status) => {
        if (status === 'OK') {
          if (results[0]) {
            this.address = results[0].formatted_address;
          } else {
            this.address = 'No results found';
          }
        } else {
          this.address = 'Geocoder failed due to: ' + status;
        }
      });
    },
  },

  mounted() {
    this.geocodeCoordinates();
    /*const modal = this.$refs.locationRef;
    modal.addEventListener("hidden.bs.modal", () => {
        this.address = "";
    });*/
  }
};
</script>

<style></style>
  