<template>
  <div class="card card-xxl-stretch mb-5 mb-xl-10">
    <!--begin::Header-->
    <div class="card-header align-items-center border-0 mt-4">
      <h3 class="card-title align-items-start flex-column">
        <span class="fw-bolder mb-2 text-dark">{{$t('users.title')}}</span>
      </h3>

      <div
        class="card-toolbar"
        data-bs-toggle="tooltip"
        data-bs-placement="top"
        data-bs-trigger="hover"
        title="Click to add a user"
      >
        <router-link :to="{ name: 'add-user' }" class="btn btn-sm btn-primary">
          <span class="svg-icon svg-icon-3">
            <inline-svg src="/media/icons/duotune/arrows/arr075.svg" />
          </span>
          {{$t('users.new')}}
        </router-link>
      </div>
    </div>
    <div class="card-body py-3">
      <UsersTable />
    </div>
  </div>
</template>

<script>
import UsersTable from "./UsersTable.vue";
export default {
  name: "UsersIndex",
  components: {
    UsersTable,
  },
  data() {
    return {};
  },
};
</script>
