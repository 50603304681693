<template>
  <!--begin::Menu-->
  <div class="menu menu-sub menu-sub-dropdown menu-column w-350px w-lg-375px" data-kt-menu="true">
    <!--begin::Heading-->
    <div class="d-flex flex-column bgi-no-repeat rounded-top" style="background-image: url('/media/misc/pattern-1.jpg')">
      <!--begin::Title-->
      <h3 class="text-white fw-bold px-9 mt-10 mb-6">
        {{ $t('constants.notifications') }} <!--<span class="fs-8 opacity-75 ps-3">24 reports</span>-->
      </h3>
      <!--end::Title-->

      <!--begin::Tabs-->
      <!--<ul class="nav nav-line-tabs nav-line-tabs-2x nav-stretch fw-bold px-9">
        <li class="nav-item">
          <a
            class="nav-link text-white opacity-75 opacity-state-100 pb-4"
            data-bs-toggle="tab"
            href="#kt_topbar_notifications_1"
            >Alerts</a
          >
        </li>

        <li class="nav-item">
          <a
            class="nav-link text-white opacity-75 opacity-state-100 pb-4 active"
            data-bs-toggle="tab"
            href="#kt_topbar_notifications_2"
            >Updates</a
          >
        </li>

        <li class="nav-item">
          <a
            class="nav-link text-white opacity-75 opacity-state-100 pb-4"
            data-bs-toggle="tab"
            href="#kt_topbar_notifications_3"
            >Logs</a
          >
        </li>
      </ul>-->
      <!--end::Tabs-->
    </div>
    <!--end::Heading-->
    <div class="" id="kt_topbar_notifications_1" role="tabpanel">
      <!--begin::Items-->
      <div class="scroll-y mh-325px my-5">
        <div v-if="notifications.length == 0">
          <p class="fs-7 text-gray-800 fw-bolder mb-0">
            {{ $t('constants.no_notifications') }}
          </p>
        </div>
        <template>
          <!--begin::Item-->
          <router-link v-for="(item, index) in notifications" :key="index"
            :to="generateRoute(item)">
            <div class="d-flex flex-stack py-4 px-4 bg-hover-light-primary" 
            @click="markNotificationAsRead(item.id, item.read_at)">
              <!--begin::Section-->
              <div class="d-flex align-items-center">
                <!--begin::Symbol-->
                <div class="symbol symbol-35px" :class="direction == 'rtl' ? 'ms-4' : 'me-4'">
                  <span :class="`bg-light-${selectState(item.type)}`" class="symbol-label">
                    <span :class="`svg-icon-${selectState(item.type)}`" class="svg-icon svg-icon-2">
                      <inline-svg :src="selectIcon(item.type)" />
                    </span>
                  </span>
                </div>
                <!--end::Symbol-->
                <!--begin::Title-->
                <div class="mb-0 text-align-start" :class="direction == 'rtl' ? 'ms-2' : 'me-2'">
                  <div class="d-flex align-items-center">
                    <p class="fs-7 text-gray-800 text-hover-primary fw-bolder mb-0">{{ item.title }}
                      <span v-if="item.reservation_no != null">{{ ` #`+item.reservation_no }}</span>
                    </p>
                  </div>
                  <div class="text-gray-400 fs-8">
                    {{ item.description }}
                  </div>
                  <div class="text-gray-600 fs-9 mt-2">
                    {{ item.formatted_date_full }}
                  </div>
                </div>
                <!--end::Title-->
              </div>
              <!--end::Section-->
              <!--begin::Label-->
              <div class="d-flex align-items-center gap-3">
                <span class="badge badge-light fs-9">{{ item.formatted_date }}</span>
                <span class="circle" :class="item.read_at == null ? 'unread' : 'read'"></span>
              </div>
              <!--end::Label-->
            </div>
          </router-link>

          <!--end::Item-->
        </template>
      </div>
      <!--end::Items-->

      <!--begin::View more-->
      <div class="py-3 text-center border-top">
        <router-link :to="{ name: 'Notifications' }" class="btn btn-color-gray-600 btn-active-color-primary">
          {{ $t('constants.view_all') }}
          <span class="svg-icon svg-icon-5">
            <inline-svg :src="`/media/icons/duotune/arrows/${direction == 'rtl' ? 'arr063' : 'arr064'}.svg`" />
          </span>
        </router-link>
      </div>
      <!--end::View more-->
    </div>

  </div>
  <!--end::Menu-->
</template>

<script>
import NotificationRequest from "@/services/requests/index";
export default {
  name: "notifications-menu",
  components: {},
  props: {
    notifications: {
      type: Array,
      required: true
    }
  },
  computed: {
    direction() {
      return this.$store.state.direction;
    }
  },
  data() {
    return {
   
    };
  },
  methods: {
    selectIcon(type) {
      var icon = '';
      switch (type) {
        case 'driver_request':
          icon = '/media/icons/duotune/general/gen013.svg'
          break;
        case 'driver_request_cancelled':
          icon = '/media/icons/duotune/general/gen040.svg'
          break;
        case 'account_activation':
          icon = '/media/icons/duotune/coding/cod001.svg'
          break;
        case 'account_deactivation':
          icon = '/media/icons/duotune/coding/cod001.svg'
          break;    
        default:
          break;
      }

      return icon;
    },
    selectState(type) {
      var state = '';
      switch (type) {
        case 'driver_request':
          state = 'warning'
          break;
        case 'driver_request_cancelled':
          state = 'danger'
          break;
        case 'account_activation':
          state = 'primary'
          break;
        case 'account_deactivation':
          state = 'primary'
          break;  
        default:
          break;
      }

      return state;
    },
    markNotificationAsRead(id, read_at) {
      if (read_at == null) {
        NotificationRequest.markNotificationAsRead(id)
          .then(() => {
            this.$store.dispatch('getNotifications');
          })
      } else {
        return false;
      }
    },
    generateRoute(item) {
      if (item.type == 'driver_request' || item.type == 'driver_request_cancelled') {
        return {
          name: 'order-details',
          params: { id: item.reservation_id }
        };
      } else if(item.type == 'account_activation' || item.type == 'account_deactivation'){
        return {
          name: 'account-overview'
        };
      }
  },
  }
};
</script>

<style>
.text-align-start {
  text-align: start;
}

.circle {
  display: block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

.circle.unread {
  background-color: #009ef7;
}

.circle.read {
  background-color: #EFF2F5;
}
</style>
