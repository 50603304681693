<template>
  <div class="car_form">
    <div class="card card-xxl-stretch mb-5 mb-xl-10">
      <!--begin::Header-->
      <el-form ref="form" :model="form" :rules="rules" label-width="120px">
        <div class="card-header align-items-center border-0 mt-4">
          <h3 class="card-title align-items-start flex-column">
            <span class="fw-bolder mb-2 text-dark">{{ $t("cars.new") }}</span>
          </h3>
          <div class="card-toolbar">
            <div class="d-flex" style="gap: 10px">
              <button type="button" class="btn btn-primary" @click="onSubmit">
                {{ $t('constants.save') }}
              </button>
              <button type="button" class="btn btn-secondary" @click="back()">{{ $t('constants.cancel') }}</button>
            </div>
          </div>
        </div>
        <div class="card-body py-3">
          <div class="row">
            <div class="form-group col-md-4 col-sm-6 col-xs-12">
              <label class="required fs-6 mb-2">{{ $t("cars.make") }}</label>
              <vSelect v-model="form.make_id" :placeholder="$t('constants.select_car_make')" :options="car_makes"
                :reduce="(make) => make.id" label="name" @input="getCarModels" :dir="direction">
              </vSelect>
            </div>
            <div class="form-group col-md-4 col-sm-6 col-xs-12">
              <label class="required fs-6 mb-2">{{ $t("cars.model") }}</label>
              <vSelect v-model="form.model_id" :placeholder="$t('constants.select_car_model')" :options="car_models"
                :reduce="(model) => model.id" label="name" :dir="direction == 'rtl' ? 'rtl' : 'ltr'"></vSelect>
            </div>
            <div class="form-group col-md-4 col-sm-6 col-xs-12">
              <label class="fs-6 mb-2">{{ $t("cars.car_model_generation") }}</label>
              <el-form-item prop="car_model_generation">
                <el-input v-model="form.car_model_generation" :placeholder="$t('cars.car_model_generation')"></el-input>
              </el-form-item>
            </div>
            <div class="form-group col-md-4 col-sm-6 col-xs-12">
              <label class="required fs-6 mb-2">{{ $t("cars.year") }}</label>
              <el-form-item prop="year">
                <date-picker v-model="form.year" type="year" value-type="format" class="w-100"
                  :placeholder="$t('constants.select_car_year')"></date-picker>
              </el-form-item>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-md-4 col-sm-6 col-xs-12">
              <label class="required fs-6 mb-2">{{
                $t("cars.car_group_id")
              }}</label>
              <el-form-item prop="car_group_id">
                <vSelect v-model="form.car_group_id" :placeholder="$t('constants.select_car_group')" :options="car_groups"
                  :reduce="(group) => group.id" :label="direction == 'ltr' ? 'name_en' : 'name_ar'" multiple :dir="direction">
                </vSelect>
              </el-form-item>
            </div>
            <div class="form-group col-md-4 col-sm-6 col-xs-12">
              <label class="required fs-6 mb-2">{{
                $t("cars.transmission_type")
              }}</label>
              <el-form-item prop="transmission_type">
                <vSelect v-model="form.transmission_type" :placeholder="$t('constants.select_transmission_type')"
                  :options="transmission_types" :reduce="(transmission) => transmission.id" label="value"
                  :dir="direction"></vSelect>
              </el-form-item>
            </div>
            <div class="form-group col-md-4 col-sm-6 col-xs-12">
              <label class="required fs-6 mb-2">{{
                $t("cars.engine_type")
              }}</label>
              <el-form-item prop="engine_type">
                <vSelect v-model="form.engine_type" :placeholder="$t('constants.select_engine_type')"
                  :options="engine_types" :reduce="(type) => type.id" label="value"
                  :dir="direction"></vSelect>
              </el-form-item>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-md-4 col-sm-6 col-xs-12">
              <label class="required fs-6 mb-2">{{
                $t("cars.country_id")
              }}</label>
              <el-form-item prop="country_id">
                <vSelect v-model="form.country_id" :placeholder="$t('constants.select_country')" :options="countries"
                  :reduce="(country) => country.id" :label="direction == 'ltr' ? 'name_en' : 'name_ar'" @input="getCities()"
                  :dir="direction"></vSelect>
              </el-form-item>
            </div>
            <div class="form-group col-md-4 col-sm-6 col-xs-12">
              <label class="required fs-6 mb-2">{{ $t("cars.city_id") }}</label>
              <el-form-item prop="city_id">
                <vSelect v-model="form.city_id" :placeholder="$t('constants.select_city')" :options="cities"
                  :reduce="(city) => city.id" :label="direction == 'ltr' ? 'name_en' : 'name_ar'" :dir="direction"></vSelect>
              </el-form-item>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-md-4 col-sm-6 col-xs-12">
              <label class="required fs-6 mb-2">{{
                $t("cars.daily_price")
              }}</label>
              <el-form-item prop="daily_price">
                <el-input v-model="form.daily_price" :placeholder="$t('cars.daily_price')" type="number"></el-input>
              </el-form-item>
            </div>
            <div class="form-group col-md-4 col-sm-6 col-xs-12">
              <label class="required fs-6 mb-2">{{ $t("cars.doors") }}</label>
              <el-form-item prop="doors">
                <el-input v-model="form.doors" :placeholder="$t('cars.doors')" type="number"></el-input>
              </el-form-item>
            </div>
          </div>
          <div class="row mb-5">
            <div class="form-group col-md-6 col-sm-6 col-xs-12">
              <label class="required fs-6 mb-2">{{
                $t("cars.pick_up_location")
              }}</label>
              <div class="d-flex align-items-center" style="gap: 1rem">
                <el-form-item prop="pick_up_location" class="mb-0" style="flex: 1">

                  <el-input :placeholder="$t('cars.pick_up_location')" v-model="form.pick_up_location">
                    <div slot="append" id="location-modal" data-bs-toggle="modal" data-bs-target="#modal_location"
                      @click="location_type = 'pick_up'" class="location_btn">
                      <i class="el-icon-map-location"></i>
                    </div>
                  </el-input>
                </el-form-item>
              </div>
            </div>
            <div class="form-group col-md-6 col-sm-6 col-xs-12">
              <label class="required fs-6 mb-2">{{
                $t("cars.drop_off_location")
              }}</label>
              <div class="d-flex align-items-center" style="gap: 1rem">
                <el-form-item prop="drop_off_location" class="mb-0" style="flex: 1">
                  <!--<el-input
                    v-model="form.drop_off_location"
                    :placeholder="$t('cars.drop_off_location')"
                  ></el-input>-->
                  <el-input :placeholder="$t('cars.drop_off_location')" v-model="form.drop_off_location">
                    <div slot="append" id="location-modal" data-bs-toggle="modal" data-bs-target="#modal_location"
                      @click="location_type = 'drop_off'" class="location_btn">
                      <i class="el-icon-map-location"></i>
                    </div>
                  </el-input>
                </el-form-item>
                <!--<el-button
                  type="primary"
                  icon="el-icon-map-location"
                  round
                  id="location-modal"
                  data-bs-toggle="modal"
                  data-bs-target="#modal_location"
                  @click="location_type = 'drop_off'"
                  >{{ $t("constants.select_from_map") }}</el-button
                >-->
              </div>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-md-4 col-sm-6 col-xs-12">
              <el-form-item>
                <el-checkbox v-model="other_drop_off_location">
                  {{ $t("cars.other_drop_off_location") }}
                </el-checkbox>
              </el-form-item>
            </div>
            <div class="border py-3" style="margin: 0.75rem" v-if="other_drop_off_location">
              <div class="row" v-for="(location, index) in form.other_drop_off_locations" :key="index">
                <div class="form-group col-md-3 col-sm-6 col-xs-12">
                  <label class="required fs-6 mb-2">{{
                    $t("cars.country_id")
                  }}</label>
                  <el-form-item :prop="'other_drop_off_locations.' + index + '.country_id'" :rules="{
                    required: true,
                    message: $t('constants.required'),
                    trigger: 'blur',
                  }">
                    <vSelect v-model="location.country_id" :placeholder="$t('constants.select_country')"
                      :options="countries" :reduce="(country) => country.id" label="name_en" @input="getCities(index)"
                      :dir="direction"></vSelect>
                  </el-form-item>
                </div>
                <div class="form-group col-md-3 col-sm-6 col-xs-12">
                  <label class="required fs-6 mb-2">{{
                    $t("cars.city_id")
                  }}</label>
                  <el-form-item :prop="'other_drop_off_locations.' + index + '.city_id'" :rules="{
                    required: true,
                    message: $t('constants.required'),
                    trigger: 'blur',
                  }">
                    <vSelect v-model="location.city_id" :placeholder="$t('constants.select_city')"
                      :options="location.cities" :reduce="(city) => city.id" :label="direction == 'ltr' ? 'name_en' : 'name_ar'"
                      :dir="direction"></vSelect>
                  </el-form-item>
                </div>
                <div class="form-group col-md-3 col-sm-6 col-xs-12">
                  <label class="required fs-6 mb-2">{{
                    $t("cars.drop_off_location")
                  }}</label>
                  <div class="d-flex align-items-center" style="gap: 1rem">
                    <el-form-item class="mb-0" style="flex: 1"
                      :prop="'other_drop_off_locations.' + index + '.drop_off_location'" :rules="{
                        required: true,
                        message: $t('constants.required'),
                        trigger: 'blur',
                      }">
                      <el-input :placeholder="$t('cars.drop_off_location')" v-model="location.drop_off_location">
                        <div slot="append" data-bs-toggle="modal" data-bs-target="#modal_location"
                          @click="editLocation(index, location.coordinates)" class="location_btn">
                          <i class="el-icon-map-location"></i>
                        </div>
                      </el-input>
                    </el-form-item>
                  </div>
                </div>
                <div class="form-group col-md-2 mt-2 mt-md-0">
                  <label class="required fs-6 mb-2">{{
                    $t("constants.actions")
                  }}</label>
                  <br>
                  <button type="button" class="btn btn-sm btn-danger" @click="deleteDropOffLocation(index)">
                    <span class="svg-icon svg-icon-3">
                      <inline-svg src="/media/icons/duotune/arrows/arr075.svg" />
                    </span>
                    {{ $t("constants.delete") }}
                  </button>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-4 mt-3 mt-md-0">
                  <button type="button" class="btn btn-sm btn-light-primary" @click="addNewLocation()">
                    <span class="svg-icon svg-icon-3">
                      <inline-svg src="/media/icons/duotune/arrows/arr075.svg" />
                    </span>
                    {{ $t("constants.new") }}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-md-4 col-sm-6 col-xs-12">
              <label class="required fs-6 mb-2">{{
                $t("cars.fuel_policy_id")
              }}</label>
              <el-form-item prop="fuel_policy_id">
                <vSelect v-model="form.fuel_policy_id" :placeholder="$t('constants.select_fuel_policy')"
                  :options="fuel_policies" :reduce="(policy) => policy.id" :label="direction == 'ltr' ? 'name_en' : 'name_ar'"
                  :dir="direction"></vSelect>
              </el-form-item>
            </div>
            <div class="form-group col-md-4 col-sm-6 col-xs-12">
              <label class="fs-6 mb-2">{{ $t("cars.similar_car") }}</label>
              <el-form-item>
                <el-switch v-model="form.similar_car"></el-switch>
              </el-form-item>
            </div>
            <div class="form-group col-md-4 col-sm-6 col-xs-12">
              <label class="fs-6 mb-2">{{ $t("cars.pay_on_arrival") }}</label>
              <el-form-item>
                <el-switch v-model="form.pay_on_arrival"></el-switch>
              </el-form-item>
            </div>
          </div>
          <div class="row">
            <label class="required fs-6 mb-2">{{ $t("cars.insurance") }} ({{
              $t("constants.choose_one")
            }})</label>
            <div>
              <el-form-item prop="insurance">
                <el-radio-group v-model="form.insurance_option_id">
                  <el-radio-button :label="1">
                    {{ $t("cars.full_protection") }}
                  </el-radio-button>
                  <el-radio-button :label="2">
                    {{ $t("cars.partial_protection") }}
                  </el-radio-button>
                  <el-radio-button :label="3">
                    {{ $t("cars.no_protection") }}
                  </el-radio-button>
                </el-radio-group>
              </el-form-item>
            </div>
            <div class="border py-3" style="margin: 0.75rem" v-if="form.insurance_option_id == 1 || form.insurance_option_id == 2
              ">
              <div class="row">
                <div class="form-group col-md-4 col-sm-6 col-xs-12">
                  <label class="required fs-6 mb-2">{{
                    $t("cars.guarantee_amount")
                  }}</label>
                  <el-form-item prop="insurance_infos.guarantee_amount">
                    <el-input v-model="form.insurance_infos.guarantee_amount" :placeholder="$t('cars.guarantee_amount')"
                      type="number"></el-input>
                  </el-form-item>
                </div>
              </div>
              <div class="row mb-5">
                <label class="fs-6 mb-2">{{ $t("cars.includes") }}</label>
                <div class="row align-items-center mb-5" v-for="(item, index) in form.insurance_infos.includes"
                  :key="index">
                  <div class="col-md-5 mt-2 mt-md-0">
                    <el-form-item :prop="'insurance_infos.includes.' + index + '.name_en'" :rules="{
                      required: true,
                      message: $t('constants.required'),
                      trigger: 'blur',
                    }" class="mb-0">
                      <el-input :placeholder="$t('cars.include_name_ar')" v-model="item.name_en">
                      </el-input>
                    </el-form-item>
                  </div>
                  <div class="col-md-5 mt-2 mt-md-0">
                    <el-form-item :prop="'insurance_infos.includes.' + index + '.name_ar'" :rules="{
                      required: true,
                      message: $t('constants.required'),
                      trigger: 'blur',
                    }" class="mb-0">
                      <el-input :placeholder="$t('cars.include_name_en')" v-model="item.name_ar">
                      </el-input>
                    </el-form-item>
                  </div>
                  <div class="col-md-2 mt-2 mt-md-0">
                    <button type="button" class="btn btn-sm btn-danger" @click="deleteInclude(index)">
                      <span class="svg-icon svg-icon-3">
                        <inline-svg src="/media/icons/duotune/arrows/arr075.svg" />
                      </span>
                      {{ $t("constants.delete") }}
                    </button>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-4 mt-3 mt-md-0">
                    <button type="button" class="btn btn-sm btn-light-primary" @click="addNewInclude()">
                      <span class="svg-icon svg-icon-3">
                        <inline-svg src="/media/icons/duotune/arrows/arr075.svg" />
                      </span>
                      {{ $t("constants.new") }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-md-4 col-sm-6 col-xs-12">
              <label class="required fs-6 mb-2">{{ $t("cars.image") }}</label>
              <el-form-item prop="image">
                <vue-dropzone ref="image" id="dropzone" :options="dropzoneImageOptions" @vdropzone-success="ImageAdded"
                  @vdropzone-removed-file="removeImageFile"></vue-dropzone>
              </el-form-item>
            </div>
          </div>
          <div class="row mt-5">
            <div class="form-group col text-center">
              <div class="d-flex justify-content-center" style="gap: 10px">
                <button type="button" class="btn btn-primary" @click="onSubmit">
                  Save
                </button>
                <button type="button" class="btn btn-secondary" @click="back()">{{ $t('constants.cancel') }}</button>
              </div>
            </div>
          </div>
        </div>
      </el-form>
    </div>
    <Location @confirmAddress="getAddress" :coordinates="coordinates" />
  </div>
</template>

<script>
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import axiosIns from "@/axios.js";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import Cookies from "js-cookie";
import Location from "../modals/Location.vue";
import cloneDeep from 'lodash/cloneDeep';

export default {
  name: "CarForm",
  props: ["submitType"],
  components: {
    vueDropzone: vue2Dropzone,
    vSelect,
    DatePicker,
    Location,
  },
  data() {
    return {
      coordinates: {
        lat: 1.2333333,
        lng: 20.256666
      },
      locationModal: false,
      location_type: "",
      nodeEnv: process.env.NODE_ENV,
      deleteFiles: true,
      other_drop_off_location: false,
      modalLocationIndex: null,
      pick_up_location_coordinates: "",
      drop_off_location_coordinates: "",
      car_makes: [],
      car_models: [],
      form: {
        make_id: "",
        model_id: "",
        car_model_generation: "",
        year: "",
        transmission_type: "",
        engine_type: "",
        country_id: "",
        city_id: "",
        hotooffers: false,
        vehicle_id: "",
        image: "",
        daily_price: "",
        doors: "",
        pick_up_location: "",
        drop_off_location: "",
        fuel_policy_id: "",
        similar_car: false,
        car_group_id: "",
        insurance_option_id: "",
        pay_on_arrival: false,
        insurance_infos: {
          guarantee_amount: "",
          includes: [
            {
              name_en: "",
              name_ar: "",
            },
          ],
        },
        other_drop_off_locations: [
          {
            country_id: "",
            city_id: "",
            drop_off_location: "",
            coordinates: "",
            cities: []
          },
        ],
      },
      fuel_policies: [],
      transmission_types: [
        { id: 1, value: this.$t('cars.manual') },
        { id: 2, value: this.$t('cars.automatic') },
      ],
      engine_types: [
        { id: 1, value: this.$t('cars.gasoline') },
        { id: 2, value: this.$t('cars.diesel') },
        { id: 3, value: this.$t('cars.electric') },
        { id: 4, value: this.$t('cars.hybrid') }
      ],
      countries: [],
      cities: [],
      car_groups: [],
      rules: {
        make_id: [
          {
            required: true,
            message: this.$t('constants.required'),
            trigger: "blur",
          },
        ],
        model_id: [
          {
            required: true,
            message: this.$t('constants.required'),
            trigger: "blur",
          },
        ],
        year: [
          {
            required: true,
            message: this.$t('constants.required'),
            trigger: "blur",
          },
        ],
        transmission_type: [
          {
            required: true,
            message: this.$t('constants.required'),
            trigger: "blur",
          },
        ],
        engine_type: [
          {
            required: true,
            message: this.$t('constants.required'),
            trigger: "blur",
          },
        ],
        country_id: [
          {
            required: true,
            message: this.$t('constants.required'),
            trigger: "blur",
          },
        ],
        city_id: [
          {
            required: true,
            message: this.$t('constants.required'),
            trigger: "blur",
          },
        ],
        car_group_id: [
          {
            required: true,
            message: this.$t('constants.required'),
            trigger: "blur",
          },
        ],
        daily_price: [
          {
            required: true,
            message: this.$t('constants.required'),
            trigger: "blur",
          },
        ],
        doors: [
          {
            required: true,
            message: this.$t('constants.required'),
            trigger: "blur",
          },
        ],
        fuel_policy_id: [
          {
            required: true,
            message: this.$t('constants.required'),
            trigger: "blur",
          },
        ],
        pick_up_location: [
          {
            required: true,
            message: this.$t('constants.required'),
            trigger: "blur",
          },
        ],
        drop_off_location: [
          {
            required: true,
            message: this.$t('constants.required'),
            trigger: "blur",
          },
        ],
        insurance_option_id: [
          {
            required: true,
            message: this.$t('constants.required'),
            trigger: "blur",
          },
        ],
        insurance_infos: {
          guarantee_amount: [
            {
              required: true,
              message: this.$t('constants.required'),
              trigger: "blur",
            },
          ],
        },
      },
      dropzoneImageOptions: {
        url:
          process.env.NODE_ENV == "production"
            ? "https://api.dgotours.com/api/rental-cars/images/upload"
            : process.env.NODE_ENV == "development" ? "http://localhost:8000/api/rental-cars/images/upload" : '',
        thumbnailWidth: 150,
        //maxFilesize: 0.5,
        headers: { Authorization: Cookies.get("access_token") },
        maxFiles: 1,
        autoProcessQueue: false,
        //autoQueue: false,
        //chunking: true,
        addRemoveLinks: true,
        timeout: 600000
      },

      selected_removed_image: ""
    };
  },
  watch: {
    location_type(val) {
      if (val == 'pick_up' && this.pick_up_location_coordinates !== "") {
        const pickUpArray = this.pick_up_location_coordinates.split(',').map(parseFloat);
        const pickUpLocation = {
          lat: pickUpArray[0],
          lng: pickUpArray[1]
        };
        this.coordinates = pickUpLocation;
      } else if (val == 'drop_off' && this.drop_off_location_coordinates !== "") {
        const dropOffArray = this.drop_off_location_coordinates.split(',').map(parseFloat);
        const dropOffLocation = {
          lat: dropOffArray[0],
          lng: dropOffArray[1]
        };
        this.coordinates = dropOffLocation;
      }
    },
    other_drop_off_location(val) {
      if (val == true) {
        setTimeout(() => {
          this.editPrepend();
        })

      }
    }
  },
  computed: {
    direction() {
      return this.$store.state.direction;
    }
  },
  methods: {
    editLocation(index, coord) {
      this.location_type = 'other_drop_off';
      this.modalLocationIndex = index;
      if (coord !== undefined) {
        const dropOffArray = coord.split(',').map(parseFloat);
        const dropOffLocation = {
          lat: dropOffArray[0],
          lng: dropOffArray[1]
        };
        this.coordinates = dropOffLocation;
      }

    },
    addNewInclude() {
      this.form.insurance_infos.includes.push({
        name_en: "",
        name_ar: "",
      });
    },
    deleteInclude(index) {
      if (this.form.insurance_infos.includes.length > 1) {
        this.form.insurance_infos.includes.splice(index, 1);
      }
    },
    addNewLocation() {
      this.form.other_drop_off_locations.push({
        country_id: "",
        city_id: "",
        drop_off_location: "",
        cities: []
      });

    },
    deleteDropOffLocation(index) {
      if (this.form.other_drop_off_locations.length > 1) {
        this.form.other_drop_off_locations.splice(index, 1);
      }
    },
    getAddress(event) {
      if (this.location_type == "pick_up") {
        this.form.pick_up_location = event.formatted_address;
        this.pick_up_location_coordinates = event.coordinates;
      } else if (this.location_type == "drop_off") {
        this.form.drop_off_location = event.formatted_address;
        this.drop_off_location_coordinates = event.coordinates;
      } else if (this.location_type == "other_drop_off") {
        this.form.other_drop_off_locations[this.modalLocationIndex].drop_off_location = event.formatted_address;
        this.form.other_drop_off_locations[this.modalLocationIndex].coordinates = event.coordinates;
      }
    },
    ImageAdded(file, response) {
      this.form.image = response.path;
    },
    removeImageFile(file) {
      if (this.deleteFiles) {
        this.selected_removed_image = this.form.image;
        /*this.removeUploadedImage(this.form.image).then(
          () => (this.form.image = "")
        );*/
      }
    },
    removeUploadedImage(path) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`rental-cars/images/delete`, {
            path: path,
          })
          .then((response) => {
            resolve(response);
          });
      });
    },
    getAllCountries() {
      axiosIns.get("countries").then((response) => {
        this.countries = response.data.countries;
      });
    },
    getCities(index, id) {

      let country_id = null;
      if (index !== undefined && index !== null) {
        country_id = this.form?.other_drop_off_locations[index].country_id;
      } else if ((index == undefined && index == null) && (id != null && id !== undefined)) {
        country_id = id;
      } else {
        country_id = this.form?.country_id;
      }

      return new Promise((resolve, reject) => {

        axiosIns.get("cities/" + country_id).then((response) => {
          //console.log(index)
          //this.cities = response.data.cities;

          if (index !== undefined && index !== null) {
            this.form.other_drop_off_locations[index].cities = response.data.cities;
          } else {
            this.cities = response.data.cities;
          }
          resolve(response);
        });
      });
    },
    getVehiclesTypes() {
      axiosIns.get("vehicles-types").then((response) => {
        this.vehicles_types = response.data.types;
      });
    },
    getFuelPolicies() {
      axiosIns.get("fuel-policies?lang=en").then((response) => {
        this.fuel_policies = response.data.fuel_policies;
      });
    },
    getCarGroups() {
      axiosIns.get("car-groups").then((response) => {
        this.car_groups = response.data.car_groups;
      });
    },
    back() {
      this.$router.go(-1);
    },
    editPrepend() {
      const element = document.querySelectorAll('.el-input-group__append');
      const element2 = document.querySelectorAll('.el-input-group--append .el-input__inner');

      if (this.direction == "rtl") {
        element.forEach((item) => {
          item.style.borderRight = '0';
          item.style.borderLeft = '1px solid #DCDFE6';
          item.style.borderRadius = '0px';
          item.style.borderTopLeftRadius = '4px';
          item.style.borderBottomLeftRadius = '4px';
        });

        element2.forEach((item) => {
          item.style.borderRadius = '0px';
          item.style.borderTopRightRadius = '4px';
          item.style.borderBottomRightRadius = '4px';
        });

      }
    },
    onSubmit() {
      const url = this.submitType == "add" ? 'cars' : `cars/${this.$route.params.id}?_method=put`;
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.$swal(
            {
              showConfirmButton: false,
              allowOutsideClick: false, // disable clicking outside the modal
              backdrop: true, // enable backdrop to prevent closing on outside click
              didOpen: () => {
                this.$swal.showLoading();
              },
            }
          );


          //this.$swal.showLoading();
          const uploadPromises = [];

          if (this.submitType == "add") {
            if (this.$refs.image.dropzone.files.length > 0) {
              uploadPromises.push(new Promise((resolve, reject) => {
                this.$refs.image.processQueue();
                this.$refs.image.dropzone.on('queuecomplete', () => {
                  resolve();
                });
              }));
            }

          } else if (this.submitType == "edit") {
            if (this.$refs.image.getAcceptedFiles().length > 0) {
              uploadPromises.push(new Promise((resolve, reject) => {
                this.$refs.image.processQueue();
                this.$refs.image.dropzone.on('queuecomplete', () => {
                  resolve();
                });
              }));
            }

            if (this.selected_removed_image !== '') {

              uploadPromises.push(this.removeUploadedImage(this.selected_removed_image).then(() => {
                this.form.image = ""
              }));

            }
          }

          Promise.all(uploadPromises).then(() => {
            const formData = new FormData();
            for (const key in this.form) {
              if (this.form[key]) {
                formData.append(key, this.form[key]);
              }
            }
            if (formData.has("pick_up_location")) {
              formData.set('pick_up_location', this.pick_up_location_coordinates);
            }
            if (formData.has("drop_off_location")) {
              formData.set('drop_off_location', this.drop_off_location_coordinates);
            }

            if (formData.has("similar_car")) {
              formData.delete("similar_car");
            }
            if (this.form.similar_car == false) {
              formData.append("similar_car", 0);
            } else if (this.form.similar_car == true) {
              formData.append("similar_car", 1);
            }

            if (formData.has("pay_on_arrival")) {
              formData.delete("pay_on_arrival");
            }
            if (this.form.pay_on_arrival == false) {
              formData.append("pay_on_arrival", 0);
            } else if (this.form.pay_on_arrival == true) {
              formData.append("pay_on_arrival", 1);
            }

            formData.delete("insurance_infos");

            formData.append(
              "insurance_infos",
              JSON.stringify(this.form.insurance_infos)
            );
            if (this.form.insurance_option_id == 3) {
              formData.delete("insurance_infos");
            }

            formData.delete('other_drop_off_locations');
            //formData.append('other_drop_off_locations', JSON.stringify(this.form.other_drop_off_locations));
            if (this.other_drop_off_location) {
              // Append the other_drop_off_locations data to the FormData object
              this.form.other_drop_off_locations.forEach((location, index) => {
                formData.append(`other_drop_off_locations[${index}][country_id]`, location.country_id);
                formData.append(`other_drop_off_locations[${index}][city_id]`, location.city_id);
                formData.append(`other_drop_off_locations[${index}][drop_off_location]`, location.coordinates);
              });
            }

            axiosIns
              .post(url, formData)
              .then((response) => {
                this.$swal.close();
                this.$notify({
                  title: "مرحبا",
                  type: "success",
                  message: response.data,
                  showClose: true,
                  position: "top-left",
                });

                setTimeout(() => {
                  this.$router.push({ name: "cars" });
                }, 500);
              }).catch((error) => {
                this.$swal.close();
                this.$swal.fire({
                  icon: 'error',
                  title: 'Oops...',
                  text: 'Something went wrong!',
                })
              });
          })

        } else {

          let animationFrameId;

          const animateScroll = () => {
            const errorElements = document.querySelectorAll('.el-form-item__error');
            if (errorElements.length > 0) {
              const firstErrorParent = errorElements[0].closest('.row');
              if (firstErrorParent) {
                firstErrorParent.scrollIntoView({ behavior: 'smooth' });
              }
            } else {
              animationFrameId = requestAnimationFrame(animateScroll);
            }
          };

          animationFrameId = requestAnimationFrame(animateScroll);

        }
      });
    },
    getCarMakes() {
      return new Promise((resolve, reject) => {
        axiosIns.get('get-car-makes')
          .then((response) => {
            this.car_makes = response.data.makes;

            resolve(response);
          })
      })
    },
    getCarModels(id) {
      this.form.model_id = ""
      return new Promise((resolve, reject) => {
        axiosIns.get(`get-car-models/${id !== null || id !== undefined ? id : this.form.make_id}`)
          .then((response) => {
            this.car_models = response.data.models;
            resolve(response);
          })
      })
    },
    geocodeCoordinates(coord) {
      return new Promise((resolve, reject) => {
        const geocoder = new google.maps.Geocoder();
        geocoder.geocode({ location: coord }, (results, status) => {
          if (status === 'OK') {
            if (results[0]) {
              const address = results[0].formatted_address;
              resolve(address);
            } else {
              reject('No results found');
            }
          } else {
            reject('Geocoder failed due to: ' + status);
          }
        });
      });
    },
    getDropOffLocationsCities(country_id) {
      return new Promise((resolve, reject) => {
        axiosIns.get("cities/" + country_id).then((response) => {
          resolve(response);
        });
      });
    },
    getCar() {
      return new Promise((resolve, reject) => {
        axiosIns.get(`cars/${this.$route.params.id}/edit`).then((response) => {

          if (response.data.data.other_drop_off_locations != undefined && response.data.data.other_drop_off_locations.length > 0) {
            this.other_drop_off_location = true;
          }

          const fetchedDataClone = cloneDeep(response.data.data);
          Promise.all([

            this.getCarModels(response.data.data.make_id),
            this.getCities(null, response.data.data.country_id).then(() => {
              if (fetchedDataClone.other_drop_off_locations !== undefined) {
                //console.log(fetchedDataClone.other_drop_off_locations);
                fetchedDataClone.other_drop_off_locations.forEach(element => {
                  this.getDropOffLocationsCities(element.country_id).then((response) => {
                    element.cities = response.data.cities;
                    const dropOffLocationArray = element.coordinates.split(',').map(parseFloat);
                    const dropOffLocation = {
                      lat: dropOffLocationArray[0],
                      lng: dropOffLocationArray[1]
                    };
                    this.geocodeCoordinates(dropOffLocation)
                      .then(address => {
                        element.drop_off_location = address;
                      })
                  })

                });



              }
            })
          ]).then((res) => {

            for (const key in fetchedDataClone) {

              if (this.form[key] !== fetchedDataClone[key]) {
                this.$set(this.form, key, fetchedDataClone[key]);
              }
            }
            if (fetchedDataClone.similar_car == 0) {
              this.form.similar_car = false;
            } else {
              this.form.similar_car = true;
            }

            if (fetchedDataClone.pay_on_arrival == 0) {
              this.form.pay_on_arrival = false;
            } else {
              this.form.pay_on_arrival = true;
            }

            this.pick_up_location_coordinates = response.data.data.pick_up_location;
            this.drop_off_location_coordinates = response.data.data.drop_off_location;
            const pickUpArray = response.data.data.pick_up_location.split(',').map(parseFloat);
            const dropOffArray = response.data.data.drop_off_location.split(',').map(parseFloat);

            const pickUpLocation = {
              lat: pickUpArray[0],
              lng: pickUpArray[1]
            };

            const dropOffLocation = {
              lat: dropOffArray[0],
              lng: dropOffArray[1]
            };

            this.geocodeCoordinates(pickUpLocation)
              .then(address => {
                this.form.pick_up_location = address;
              })
              .catch(error => {
                console.error('Error:', error);
              })

            this.geocodeCoordinates(dropOffLocation)
              .then(address => {
                this.form.drop_off_location = address;
              })
              .catch(error => {
                console.error('Error:', error);
              })


            const mockFile = { size: 123, type: "image/png,jpg,jpeg" };
            if (response.data.data.image) {
              this.$refs.image.manuallyAddFile(
                mockFile,
                response.data.data.image
              );
              let url = new URL(response.data.data.image);
              this.form.image = url.pathname.substring(1);
            }
            resolve(response);
          })

        });
      });
    },
  },
  mounted() {
    Promise.all([
      this.getCarMakes(),
      this.getCarGroups(),
      this.getAllCountries(),
      //this.getVehiclesTypes();
      this.getFuelPolicies(),
      this.editPrepend()
    ]).then(() => {
      if (this.submitType == "edit") {
        this.getCar();
      }
    });
  },
  beforeDestroy() {
    this.deleteFiles = false;
  },
};
</script>

<style lang="scss">
/*.car_form i {
  color: white;
}*/

.el-icon-map-location {
  font-size: 18px;
}

.location_btn {
  display: flex;
  align-items: center;
  height: 39px;
  padding: 0 20px;
  cursor: pointer;
}
</style>

<style scoped>
.el-input-group__append {
  background-color: #009EF7 !important;
  padding: 0 !important;
}
</style>