<template>
  <div class="dataTables_wrapper dt-bootstrap4 no-footer">
    <div class="vgt-loading text-center" v-if="loading">
      <span class="vgt-loading__content">Loading ...</span>
    </div>
    <div class="inner-wrap" :class="loading ? 'is-loading' : ''">
      <div class="table-responsive">
        <table
          class="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer text-start"
          id="kt_customers_table"
          role="grid"
        >
          <!--begin::Table head-->
          <thead>
            <!--begin::Table row-->
            <tr
              class="text-start text-gray-400 fw-bolder fs-7 text-uppercase gs-0"
              role="row"
            >
              <template>
                <th
                  v-for="(cell, i) in tableHeader"
                  :key="i"
                  @click="
                    sort(
                      cell.sortingField ? cell.sortingField : cell.key,
                      cell.sortable
                    )
                  "
                  :class="[
                    cell.name && 'min-w-125px',
                    cell.sortable && 'sorting',
                    
                    currentSort ===
                      `${cell.sortingField ? cell.sortingField : cell.key}2` &&
                      'sorting_desc',
                    currentSort ===
                      `${cell.sortingField ? cell.sortingField : cell.key}1` &&
                      'sorting_asc',
                  ]"
                  tabindex="0"
                  rowspan="1"
                  colspan="1"
                  style="cursor: pointer"
                >
                  {{ cell.name }}
                </th>
              </template>
            </tr>
            <!--end::Table row-->
          </thead>
          <!--end::Table head-->
          <!--begin::Table body-->
          <tbody class="fw-bold text-gray-600" v-if="data_items.length > 0">
            <template>
              <tr class="odd" v-for="(item, i) in data_items" :key="i">
                <template>
                  <td
                    v-for="(cell, i) in tableHeader"
                    :key="i"
                  >
                    <slot :name="`cell-${cell.key}`" :row="item">
                      {{ item[prop] }}
                    </slot>
                  </td>
                </template>
                <!--end::Item=-->
              </tr>
            </template>
          </tbody>
          <tbody v-else>
            <tr>
              <td
                :colspan="tableHeader.length"
                style="color: #909399; text-align: center !important"
              >
                {{ $t('constants.no_data_available') }}
              </td>
            </tr>
          </tbody>
          <!--end::Table body-->
        </table>
      </div>
    </div>

    <div class="row">
      <div
        class="col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start"
      >
        <!--<div
          v-if="enableItemsPerPageDropdown"
          class="dataTables_length"
          id="kt_customers_table_length"
        >
          <label
            ><select
              name="kt_customers_table_length"
              class="form-select form-select-sm form-select-solid"
              v-model="pagination.rowsPerPage"
            >
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select></label
          >
        </div>-->
      </div>
      <div
        class="col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end"
      >
        <el-pagination
          background
          layout="prev, pager, next"
          @current-change="setCurrent"
          :hide-on-single-page="false"
          :page-count="pages"
          :page-size="parseInt(pagination.rowsPerPage)"
          :total="pagination.total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
//import { computed, defineComponent, ref, onMounted, watch } from "vue";
import arraySort from "array-sort";
import axiosIns from "@/axios.js";
/*interface IPagination {
    page: number;
    total: number;
    rowsPerPage: number;
  }*/

export default {
  name: "DataTable",
  props: {
    tableHeader: { type: Array, required: true },
    //tableData: { type: Array, required: true },
    url: String,
    enableItemsPerPageDropdown: Boolean,
    rowsPerPage: Number,
    searchTermProp: String,
  },
  components: {},
  watch: {
    data_items(value) {
      //this.pagination.total = value.length;
    },
    searchTermProp(val) {
      this.searchTerm = val;
      this.loadItems();
    },
  },
  /*watch(data.value, () => {
        pagination.value.total = data.value.length;
    }),*/
  computed: {
    getItems() {
      const clone = JSON.parse(JSON.stringify(this.data_items));
      const startFrom =
        this.pagination.page * this.pagination.rowsPerPage -
        this.pagination.rowsPerPage;
      return clone.splice(startFrom, this.pagination.rowsPerPage);
    },
    pages() {
      return Math.ceil(this.pagination.total / this.pagination.rowsPerPage);
    },
  },
  data() {
    return {
      data_items: [],
      currentSort: "",
      click: 1,
      searchTerm: "",
      //current_url:
      pagination: {
        page: 1,
        total: 0,
        rowsPerPage: 10,
      },
      next_page_url: null,
      loading: false,
    };
  },
  methods: {
    loadItems() {
      this.loading = true;
      axiosIns
        .get(
          `${this.url}?page=${this.pagination.page}&searchTerm=${this.searchTerm}`,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((response) => {
          this.data_items = response.data.data;
          this.pagination.rowsPerPage = response.data.meta.per_page; //this.rowsPerPage ? this.rowsPerPage : 10;
          this.pagination.total = response.data.meta.total; //this.data_items.length;
          this.loading = false;
        })
        .catch(() => (this.loading = false));
    },
    setCurrent(val) {
      this.pagination.page = val;
      //console.log(val);
      this.loadItems();
      this.$store.commit("scrollToTop");
    },
    sort(columnName, sortable) {
      if (!sortable) {
        return;
      }

      if (this.click === 2) {
        this.click = 1;
        arraySort(this.data_items, columnName, { reverse: false });
      } else {
        this.click++;
        arraySort(this.data_items, columnName, { reverse: true });
      }
      this.currentSort = columnName + this.click;
    },
  },
  mounted() {
    this.loadItems();
    /*this.pagination.rowsPerPage = this.rowsPerPage ? this.rowsPerPage : 10;
      this.pagination.total = this.data_items.length;*/
  },
};
</script>

<style lang="scss">
table.dataTable {
  clear: both;
  margin-top: 6px !important;
  margin-bottom: 6px !important;
  max-width: none !important;
  border-collapse: separate !important;
  border-spacing: 0;
}

table.dataTable > thead {
  th.sorting {
    position: relative;
  }
  .sorting:after {
    position: absolute;
  }
}

.el-pagination.is-background .btn-next,
.el-pagination.is-background .btn-prev,
.el-pagination.is-background .el-pager li {
  background: none;
  border-radius: 0.475rem;
  font-weight: 500;
  font-size: 1.075rem;
  font-family: Poppins, Helvetica, sans-serif;
}

.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #009ef7;
}
.dataTables_wrapper {
  position: relative;
}
.inner-wrap.is-loading {
  opacity: 0.5;
  pointer-events: none;
}
.dataTables_wrapper table {
  font-size: 15px !important;
}
.dataTables_wrapper table thead tr {
  font-size: 14px !important;
}
.vgt-loading {
  position: absolute;
  width: 100%;
  z-index: 10;
  margin-top: 117px;
  &__content {
    background-color: lighten($color: #409eff, $amount: 25%);
    color: #409eff;
    padding: 7px 30px;
    border-radius: 3px;
  }
}
</style>
