import Vue from "vue";
import VueRouter from "vue-router";
//import HomeView from "../views/HomeView.vue";
import store from "../store";
import Cookies from "js-cookie";
// Dashboard

import Main from "../views/apps/Main.vue";
import Home from "../views/apps/Home.vue";
import OrdersView from "../views/apps/orders/index.vue";
import Tours from "../views/apps/tours/index.vue";
import Reservations from "../views/apps/reservations/index.vue";
import TourReservationView from "../views/apps/reservations/view.vue";
//import Offers from "../views/apps/offers/index.vue";
import AddTour from "../views/apps/tours/add-tour.vue";
import EditTour from "../views/apps/tours/edit-tour.vue";
//import OrderForm from "../views/apps/orders/form.vue";
import ViewOrder from "../views/apps/orders/view.vue";
import Users from "../views/apps/users/index.vue";
import AddUser from "../views/apps/users/add-user.vue";
import EditUser from "../views/apps/users/edit-user.vue";
import CitiesCommuting from "../views/apps/cities-commuting/index.vue";
import AirportsPickup from "../views/apps/airports-pickup/index.vue";
import Account from "../views/apps/account/Account.vue";
import AccountOverview from "../views/apps/account/Overview.vue";
import AccountSettings from "../views/apps/account/Settings.vue";
import Login from "../views/pages/Login.vue";
import AddCar from "../views/apps/cars/add-car.vue";
import EditCar from "../views/apps/cars/edit-car.vue";
import RentalCarsReservations from "../views/apps/cars/reservations";
import RentalCarReservationView from "../views/apps/cars/reservation-view.vue";
import ForgotPassword from "../views/pages/ForgotPassword.vue";
import ResetPassword from "../views/pages/ResetPassword.vue";
import Notifications  from "../views/apps/notifications/index.vue";
//import CarForm from "../views/apps/cars/form.vue";
import NotFound from "../views/NotFound.vue";
import Cars from "../views/apps/cars/index.vue";
Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "login",
    component: Login,
    beforeEnter: (to, from, next) => {
      if(auth.loggedIn() && auth.accessToDashPanel() && Cookies.get("access_token")){
        next({name: "DashboardPanel"});
      }else if(Cookies.get("access_token") && !auth.accessToDashPanel()){
        store.dispatch('getCurrentUser').then(() => {
          if(auth.accessToDashPanel()){
            next({name: "DashboardPanel"});
            store.state.splashScreen = false;
          }else{
            next({name: "login"});
            store.state.splashScreen = false;
          } 
        })
      }else{
        next();
        store.state.splashScreen = false;
      }
    }
  },
  {
    path: "/forgot-password",
    name: "forgot-password",
    component: ForgotPassword,
    beforeEnter: (to, from, next) => {
      store.state.splashScreen = false;
      next();
    } 
  },
  {
    path: "/reset-password/:token",
    name: "reset-password",
    component: ResetPassword,
    beforeEnter: (to, from, next) => {
      store.state.splashScreen = false;
      next();
    } 
  },
  {
    path: "/",
    component: Main,
    children: [
      {
        path: "",
        name: "DashboardPanel",
        component: Home,
      },
      {
        path: "/dashboard-panel/notifications",
        name: "Notifications",
        component: Notifications
      },
      {
        path: "/dashboard-panel/orders",
        name: "orders-view",
        component: OrdersView,
        beforeEnter: (to, from, next) => {
          if( auth.emailIsVerified() && auth.isActive() && auth.userType() == 4){
            next();
          }else{
            next({ name: "DashboardPanel" });
          }
        }
      },
      /*{
        path: "/dashboard-panel/orders/form",
        name: "order-form",
        component: OrderForm,
        beforeEnter: (to, from, next) => {
          if( auth.emailIsVerified() && auth.isActive() ){
            next();
          }else{
            next({ name: "DashboardPanel" });
          }
        }
      },*/
      {
        path: "/dashboard-panel/orders/:id/view",
        name: "order-details",
        component: ViewOrder,
        beforeEnter: (to, from, next) => {
          if( auth.emailIsVerified() && auth.isActive() && auth.userType() == 4){
            next();
          }else{
            next({ name: "DashboardPanel" });
          }
        }
      },
      {
        path: "/dashboard-panel/tours",
        name: "tours",
        component: Tours,
        beforeEnter: (to, from, next) => {
          if( auth.emailIsVerified() && auth.isActive() ){
            next();
          }else{
            next({ name: "DashboardPanel" });
          }
        }
      },
      {
        path: "/dashboard-panel/tours/reservations",
        name: "reservations",
        component: Reservations,
        beforeEnter: (to, from, next) => {
          if( auth.emailIsVerified() && auth.isActive() ){
            next();
          }else{
            next({ name: "DashboardPanel" });
          }
        }
      },
      {
        path: "/dashboard-panel/tours/reservations/:id/view",
        name: "tour-reservation-details",
        component: TourReservationView,
        beforeEnter: (to, from, next) => {
          if( auth.emailIsVerified() && auth.isActive() ){
            next();
          }else{
            next({ name: "DashboardPanel" });
          }
        }
      },
      /*{
        path: "/dashboard-panel/tours/offers",
        name: "offers",
        component: Offers,
        beforeEnter: (to, from, next) => {
          if( auth.emailIsVerified() && auth.isActive() ){
            next();
          }else{
            next({ name: "DashboardPanel" });
          }
        }
      },*/
      {
        path: "/dashboard-panel/tours/add",
        name: "add-tour",
        component: AddTour,
        beforeEnter: (to, from, next) => {
          if( auth.emailIsVerified() && auth.isActive() ){
            next();
          }else{
            next({ name: "DashboardPanel" });
          }
        }
      },
      {
        path: "/dashboard-panel/tours/:id/edit",
        name: "edit-tour",
        component: EditTour,
        beforeEnter: (to, from, next) => {
          if( auth.emailIsVerified() && auth.isActive() ){
            next();
          }else{
            next({ name: "DashboardPanel" });
          }
        }
      },
      {
        path: "/dashboard-panel/users",
        name: "users",
        component: Users,
        beforeEnter: (to, from, next) => {
          if (
            auth.loggedIn() &&
            auth.accessToDashPanel() &&
            auth.userType() == 2 &&
            auth.emailIsVerified() &&
            auth.isActive() 
          ) {
            next();
          }else if( auth.loggedIn() &&
          auth.accessToDashPanel() &&
          auth.userType() == 2 &&
          (!auth.emailIsVerified() ||
          !auth.isActive()) ){
            next({ name: "DashboardPanel" });
          } 
          else if (
            auth.loggedIn() &&
            auth.accessToDashPanel() &&
            auth.userType() != 2
          ) {
            next({ name: "DashboardPanel" });
          }
        },
      },
      {
        path: "/dashboard-panel/users/add",
        name: "add-user",
        component: AddUser,
        beforeEnter: (to, from, next) => {
          if (
            auth.loggedIn() &&
            auth.accessToDashPanel() &&
            auth.userType() == 2 &&
            auth.emailIsVerified() &&
            auth.isActive() 
          ) {
            next();
          } else if (
            auth.loggedIn() &&
            auth.accessToDashPanel() &&
            auth.userType() != 2
          ) {
            next({ name: "DashboardPanel" });
          }
        },
      },
      {
        path: "/dashboard-panel/users/:id/edit",
        name: "edit-user",
        component: EditUser,
        beforeEnter: (to, from, next) => {
          if (
            auth.loggedIn() &&
            auth.accessToDashPanel() &&
            auth.userType() == 2 &&
            auth.emailIsVerified() &&
            auth.isActive() 
          ) {
            next();
          } else if (
            auth.loggedIn() &&
            auth.accessToDashPanel() &&
            auth.userType() != 2
          ) {
            next({ name: "DashboardPanel" });
          }
        },
      },
      {
        path: "/dashboard-panel/services/cities-commuting",
        name: "cities-commuting",
        component: CitiesCommuting,
        beforeEnter: (to, from, next) => {
          if(auth.loggedIn() && auth.accessToDashPanel() && auth.emailIsVerified() && auth.isActive() && auth.userType() == 4){
            next();
          }else{
            next({ name: "DashboardPanel" });
          }
        }
      },
      {
        path: "/dashboard-panel/services/airports-pickup",
        name: "airports-pickup",
        component: AirportsPickup,
        beforeEnter: (to, from, next) => {
          if(auth.loggedIn() && auth.accessToDashPanel() && auth.emailIsVerified() && auth.isActive() && auth.userType() == 4){
            next();
          }else{
            next({ name: "DashboardPanel" });
          }
        }
      },
      {
        path: "/dashboard-panel/account",
        name: "account",
        component: Account,
        children: [
          {
            path: "overview",
            name: "account-overview",
            component: AccountOverview,
          },
          {
            path: "settings",
            name: "account-settings",
            component: AccountSettings,
          },
        ],
      },
      {
        path: "/dashboard-panel/cars",
        name: "cars",
        component: Cars,
        beforeEnter: (to, from, next) => {
          if (
            auth.loggedIn() &&
            auth.accessToDashPanel() &&
            auth.userType() == 2 &&
            auth.emailIsVerified() &&
            auth.isActive() 
          ) {
            next();
          } else if( auth.loggedIn() &&
          auth.accessToDashPanel() &&
          auth.userType() == 2 &&
          (!auth.emailIsVerified() ||
          !auth.isActive()) ){
            next({ name: "DashboardPanel" });
          }
          else if (
            auth.loggedIn() &&
            auth.accessToDashPanel() &&
            auth.userType() != 2
          ) {
            next({ name: "DashboardPanel" });
          }
        }
      },
      {
        path: "/dashboard-panel/cars/add",
        name: "add-car",
        component: AddCar,
        beforeEnter: (to, from, next) => {
          if (
            auth.loggedIn() &&
            auth.accessToDashPanel() &&
            auth.userType() == 2 &&
            auth.emailIsVerified() &&
            auth.isActive() 
          ) {
            next();
          } else if (
            auth.loggedIn() &&
            auth.accessToDashPanel() &&
            auth.userType() != 2
          ) {
            next({ name: "DashboardPanel" });
          }
        }
      },
      {
        path: "/dashboard-panel/cars/:id/edit",
        name: "edit-car",
        component: EditCar,
        beforeEnter: (to, from, next) => {
          if (
            auth.loggedIn() &&
            auth.accessToDashPanel() &&
            auth.userType() == 2 &&
            auth.emailIsVerified() &&
            auth.isActive() 
          ) {
            next();
          } else if (
            auth.loggedIn() &&
            auth.accessToDashPanel() &&
            auth.userType() != 2
          ) {
            next({ name: "DashboardPanel" });
          }
        }
      },
      {
        path: "/dashboard-panel/cars/reservations",
        name: "rental-cars-reservations",
        component: RentalCarsReservations,
        beforeEnter: (to, from, next) => {
          if (
            auth.loggedIn() &&
            auth.accessToDashPanel() &&
            auth.userType() == 2 &&
            auth.emailIsVerified() &&
            auth.isActive() 
          ) {
            next();
          } else if (
            auth.loggedIn() &&
            auth.accessToDashPanel() &&
            auth.userType() != 2
          ) {
            next({ name: "DashboardPanel" });
          }
        }
      },
      {
        path: "/dashboard-panel/cars/reservations/:id/view",
        name: "rental-cars-reservation-view",
        component: RentalCarReservationView,
        beforeEnter: (to, from, next) => {
          if( auth.emailIsVerified() && auth.isActive() ){
            next();
          }else{
            next({ name: "DashboardPanel" });
          }
        }
      },
    ],
    /*beforeEnter: (to, from, next) => {
      if (
        auth.loggedIn() &&
        !auth.accessToDashPanel() &&
        Cookies.get("access_token")
      ) {
        store.dispatch("getCurrentUser").then(() => {
          next();
          store.state.splashScreen = false;
        });
      } else if (
        auth.loggedIn() &&
        auth.accessToDashPanel() &&
        Cookies.get("access_token")
      ) {
        next();
      } else {
        next({ name: "login" });
      }
    },*/
    beforeEnter: (to, from, next) => {
      if(Cookies.get("access_token") && auth.loggedIn() && auth.accessToDashPanel()){
        next();
      }else if(Cookies.get("access_token") && !auth.accessToDashPanel()){
        store.dispatch('getCurrentUser').then(() => {
          if(auth.accessToDashPanel()){
            next();
            store.state.splashScreen = false;
          }else{
            next({name: "login"});
          }
          
        })
      }else{
        next({name: "login"});
      }
    }
  },
  { 
    path: '*',
    name: 'not-found',
    component: NotFound,
    beforeEnter: (to, from, next) => {
      store.state.splashScreen = false;
      next();
    } 
  },
];
//insert here login logic
const auth = {
  loggedIn() {
    return store.getters.isLogged;
  },
  userType() {
    return store.getters.userType;
  },
  accessToDashPanel() {
    return store.getters.accessToDashPanel;
  },
  logout() {
    store.commit("setLogout");
  },
  isActive(){
    return store.getters.isActive;
  },
  emailIsVerified(){
    return store.getters.emailIsVerified;
  }
};
const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
