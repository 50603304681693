<template>
  <div>
    <UserForm :submitType="'add'" />
  </div>
</template>

<script>
import UserForm from "./form.vue";
export default {
  components: {
    UserForm,
  },
};
</script>
