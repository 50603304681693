<template>
    <div class="statistics-wrapper">
        <div class="financial-statistics">
            <!--<div class="card card-xl-stretch">
                <div class="card-body">
                    <div class="d-flex flex-stack flex-grow-1">
                        <div class="d-flex flex-column me-2">
                            <span class="text-dark text-hover-primary fw-bold fs-3">{{ $t('statistics.total_earnings') }}</span>
                            <span class="text-muted fw-semibold mt-1">Your Weekly Sales Chart</span>
                        </div>
                        <div class="symbol symbol-50px">
                            <span class="symbol-label fs-5 fw-bold bg-light-success text-success">{{formatMoney(statistics.total_earnings, currency)}}</span>
                        </div>
                    </div>
                </div>
            </div>-->
            <div class="card bg-light-success hoverable card-xl-stretch">
                <div class="card-body">
                    <div class="card-title fw-bold text-success fs-5 mb-3 d-block">{{ $t('statistics.total_earnings') }}</div>
                    <div class="text-dark fs-1 fw-bold" v-if="statistics.total_earnings >= 0">
                        {{formatMoney(statistics.total_earnings, currency)}}
                        <!--<Counter :target="statistics.total_earnings"></Counter>-->
                    </div>
                    <div class="fw-semibold text-gray-400">

                    </div>
                </div>
            </div>
            <div class="card bg-light-primary hoverable card-xl-stretch">
                <div class="card-body">
                    <div class="card-title fw-bold text-primary fs-5 mb-3 d-block">{{ $t('statistics.total_commission_amount') }}</div>
                    <div class="text-dark fs-1 fw-bold me-2" v-if="statistics.total_commission_amount >= 0">
                        {{ formatMoney(statistics.total_commission_amount, currency) }}
                    </div>
                </div>
            </div>
            <div class="card bg-light-warning hoverable card-xl-stretch">
                <div class="card-body">
                    <div class="card-title fw-bold text-warning fs-5 mb-3 d-block">{{$t('statistics.total_tours_earnings')}}</div>
                    <div class="text-dark fs-1 fw-bold me-2" v-if="statistics.total_tours_earnings >= 0">
                        {{ formatMoney(statistics.total_tours_earnings, currency) }}
                    </div>
                </div>
            </div>
            <div class="card bg-light-info hoverable card-xl-stretch" v-if="$store.state.user.type == 2">
                <div class="card-body">
                    <div class="card-title fw-bold text-info fs-5 mb-3 d-block">{{$t('statistics.total_rental_cars_earnings')}}</div>
                    <div class="text-dark fs-1 fw-bold me-2" v-if="statistics.total_rental_cars_earnings >= 0">
                        {{ formatMoney(statistics.total_rental_cars_earnings, currency) }}
                    </div>
                </div>
            </div>
            <div class="card bg-light-info hoverable card-xl-stretch" v-if="$store.state.user.type == 4">
                <div class="card-body">
                    <div class="card-title fw-bold text-info fs-5 mb-3 d-block">{{ $t('statistics.total_orders_earnings') }}</div>
                    <div class="text-dark fs-1 fw-bold me-2" v-if="statistics.total_orders_earnings >= 0">
                        {{ formatMoney(statistics.total_orders_earnings,currency) }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axiosIns from "@/axios.js";
import helpers from "@/helpers";
//import Counter from "./Counter.vue";
export default {

    name: 'StatisticsComponent',
    components: {
        //Counter
    },
    data() {
        return {
            statistics: {},
            currency: this.$store.state.user.currency
        }
    },
    methods: {
        formatMoney(amount, currency){
            return helpers.formatMoney(amount, currency);
        },
        getStatistics() {
            axiosIns.get('/statistics')
                .then((response) => {
                    this.statistics = response.data;
                })
        }
    },

    mounted() {
        this.getStatistics()
    }
}
</script>

<style lang="scss">
.other-statistics {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;

    .card {
        border: 1px solid #F1F1F2;
    }
}
.financial-statistics {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;

    .card {
        border: 1px solid #F1F1F2;
    }
    @media screen and (max-width: 1024px) {
        grid-template-columns: repeat(3, 1fr);
    }
    @media screen and (max-width: 768px) {
        grid-template-columns: repeat(2, 1fr);
    }
    @media screen and (max-width: 576px) {
        grid-template-columns: repeat(1, 1fr);
    }
}
.statistics-wrapper {
    padding: 2.25rem;
    display: flex;
    flex-direction: column;
    gap: 20px;
}
</style>