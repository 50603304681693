import axiosIns from "@/axios.js";

const NotificationsRequest = {
    getAllNotifications() {
        return new Promise((resolve, reject) => {
            axiosIns.get('notifications')
                .then(res => resolve(res))
                .catch(err => reject(err))
        });
    },
    markNotificationAsRead(notification_id) {
        return new Promise((resolve, reject) => {
            axiosIns.post('notifications/mark-as-read',
                { notification_id: notification_id }
            )
                .then(res => resolve(res))
                .catch(err => reject(err))
        });
    },
    markAllNotificationAsRead() {
        return new Promise((resolve, reject) => {
            axiosIns.post('notifications/mark-all-as-read',)
                .then(res => resolve(res))
                .catch(err => reject(err))
        });
    },
    seenState() {
        return new Promise((resolve, reject) => {
            axiosIns.post('notifications/seen-state')
                .then(res => resolve(res))
                .catch(err => reject(err))
        });
    }
}

export default NotificationsRequest;