<template>
  <div class="card card-xxl-stretch mb-5 mb-xl-10">
    <!--begin::Header-->
    <div class="card-header align-items-center border-0">
      <h3 class="card-title align-items-start flex-column m-0">
        <span class="fw-bolder text-dark"
          >{{$t('constants.welcome')}} {{ $store.state.user.name }}</span
        >
      </h3>
    </div>
    <!-- Alerts component-->
    <Alerts />
    <!-- Statistics component -->
    <Statistics v-if="$store.state.user.email_verified && $store.state.user.active"/>

    <!-- Charts -->
    <!--<BarChart></BarChart>-->
  </div>
</template>
<script>
import axiosIns from "@/axios.js";
import Alerts from "@/components/dashboard/Alerts.vue";
import Statistics from "@/components/dashboard/Statistics.vue";
//import BarChart from "@/components/dashboard/charts/BarChart.vue";
export default {
  name: "HomePage",
  components: {
    Alerts,
    Statistics,
    //BarChart
  },
  data(){
    return{
      user_id: this.$store.state.user.id
    }
  },
  methods: {
    resendEmailVerification(){
      this.$swal.showLoading();
      axiosIns.post('resend-verification-email', {user_id : this.user_id})
      .then((response) => {
        console.log(response.data);
        this.$swal.fire({
        icon: 'success',
        title: response.data.message,
        text: response.data.info,
        confirmButtonColor: '#009EF7',
      });
      })
    }
  }
};
</script>
<style lang="scss"></style>
