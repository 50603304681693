<template>
  <!--begin::Menu wrapper-->
  <div class="header-menu align-items-stretch" data-kt-drawer="true" data-kt-drawer-name="header-menu"
    data-kt-drawer-activate="{default: true, lg: false}" data-kt-drawer-overlay="true"
    data-kt-drawer-width="{default:'200px', '300px': '250px'}" data-kt-drawer-direction="start"
    data-kt-drawer-toggle="#kt_header_menu_mobile_toggle">
    <!--begin::Menu-->
    <div
      class="menu menu-lg-rounded menu-column menu-lg-row menu-state-bg menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-400 fw-bold my-5 my-lg-0 align-items-stretch"
      id="#kt_header_menu" data-kt-menu="true">
      <template v-for="(item, index) in MainMenuConfig">
        <template v-if="!item.children">
          <div class="menu-item menu-lg-down-accordion me-lg-1" :key="index"
          :class="{ 'active': isParentActive(item) }"
            v-if="allowMenuItem(item.key)">
            <router-link :to="item.route" custom v-slot="{ href, navigate, isExactActive }">
              <a :href="href" class="menu-link py-3" @click="navigate"
                :class="[isExactActive && 'router-link-exact-active']">
                <span class="menu-icon" :class="direction == 'rtl' ? 'ms-2' : 'me-2'" v-if="lockedMenuItem(item.key)">
                  <span class="svg-icon svg-icon-2">
                    <inline-svg src="/media/icons/duotune/general/gen047.svg" />
                  </span>
                </span>
                <span class="menu-title">{{ item.heading }}</span>
              </a>
            </router-link>
          </div>
        </template>
        <div data-kt-menu-trigger="click" data-kt-menu-placement="bottom-start"
          class="menu-item menu-lg-down-accordion me-lg-1" v-if="item.children && allowMenuItem(item.key)" :key="index">
          <div class="btn btn-active-light-primary menu-link">
            <span class="menu-title">{{ item.heading }}</span>
            <!--<span class="menu-arrow d-lg-none"></span>-->
            <span class="svg-icon svg-icon-4 m-0" style="margin-inline-start: 5px !important">
              <inline-svg src="/media/icons/duotune/arrows/arr072.svg"/>
             
            </span>
          </div>
          <div class="menu-sub menu-sub-lg-down-accordion menu-sub-lg-dropdown menu-rounded-0 py-lg-4 w-lg-225px">
            <div class="menu-item" v-for="(child, i) in item.children" :key="i">
              <router-link class="menu-link py-3" :to="{ name: child.route_name }" exact>
                <span class="menu-icon" :class="direction == 'rtl' ? 'ms-2' : 'me-2'">

                  <span v-if="lockedMenuItem(item.key)" class="svg-icon svg-icon-2">
                    <inline-svg src="/media/icons/duotune/general/gen047.svg" />
                  </span>
                  <i v-else class="bi bi-box fs-3"></i>
                </span>
                <span class="menu-title">{{ child.heading }}</span>
              </router-link>
            </div>
          </div>
        </div>
      </template>

      <!--end::Menu-->
    </div>
  </div>
  <!--end::Menu wrapper-->
</template>

<script>

import { MenuComponent } from "@/assets/components";
import MainMenuConfig from "@/core/plugins/config/MainMenuConfig";

export default {
  name: "KTMenu",
  components: {},
  computed: {
    direction() {
      return this.$store.state.direction;
    }
  },
  data() {
    return {
      MainMenuConfig: MainMenuConfig,
      headerMenuIcons: "font",
    };
  },
  methods: {
    isParentActive(parentItem) {
      // Check if any child item is active
      if (parentItem.children) {
        return parentItem.children.some(child => this.$route.name === child.route_name);
      }
      return false;
    },
    hasActiveChildren(match) {
      return this.$route.path.indexOf(match) !== -1;
    },

    allowMenuItem(item) {
      if ((item == "users" || item == "rental cars") && this.$store.state.user.type == 2) {
        return true;
      } else if (item == "orders" && (this.$store.state.user.type == 4)) {
        return true;
      }
      else if (
        item == "dashboard" ||
        item == "tours" ||
        item == "account"
      ) {
        return true;
      } else if (item == "services" && this.$store.state.user.type == 4) {
        return true;
      } else {
        return false;
      }
    },
    lockedMenuItem(item) {
      if (item == 'dashboard' || item == "account") {
        return false;
      }
      else if (!this.$store.getters.isActive || !this.$store.getters.emailIsVerified) {
        return true;
      } else {
        return false;
      }
    }
  },
  mounted() {
    MenuComponent.reinitialization();
  },
};
</script>

<style lang="scss" scoped>
.header-fixed:not([data-kt-sticky-header="on"]) .menu-item>a>.menu-link.active {
  transition: color 0.2s ease, background-color 0.2s ease;
  background-color: rgba(255, 255, 255, 0.1);
  color: #fff;
}

body[data-kt-drawer-header-menu="on"] .menu-item>a>.menu-link.active {
  color: #5e6278;
}

.header-fixed:not([data-kt-sticky-header="on"]) .menu-item>a>.menu-link {
  color: #fff;
}

.drawer-on .menu-item>a>.menu-link {
  //color: #5e6278 !important;
}

.show>.btn.btn-active-light-primary .menu-title {
  color: #009ef7 !important;
}

.menu-sub a.menu-link {
  color: #5e6278;
}

.menu-link {
  padding: 0.65rem 1rem !important;
}

.menu-link,
.menu-link .menu-title {
  color: #959eaf !important;
}

.menu-link .menu-title {
  margin-bottom: 0 !important;
  background-color: transparent !important;
  border-bottom: 0 !important;
  font-weight: 500 !important;
  padding: 0 !important;
  font-size: 14px;
  text-transform: capitalize;
}

.menu-link:hover:not(.disabled):not(.active) {
  background-color: rgba(245, 248, 250, 0.8) !important;
  color: #009ef7 !important;
}

.menu-link:hover:not(.disabled):not(.active) .menu-title {
  color: #009ef7 !important;
}

.router-link-active,
.router-link-exact-active {
  background-color: rgba(245, 248, 250, 0.8) !important;
  color: #009ef7 !important;
  border-radius: 0.475rem;
}

.router-link-active .menu-title,
.router-link-exact-active .menu-title {
  color: #009ef7 !important;
}
.menu{
  height: 70px;
}
#kt_header_menu .menu-icon .svg-icon svg {
  width: 1.75rem !important;
  height: 1.75rem !important;
}
</style>
