<template>
  <div class="card card-xxl-stretch mb-5 mb-xl-10">
    <!--begin::Header-->
    <el-form ref="form" :model="form" :rules="rules" label-width="120px">
      <div class="card-header align-items-center border-0 mt-4">
        <h3 class="card-title align-items-start flex-column">
          <span class="fw-bolder mb-2 text-dark">{{ $t("tours.new") }}</span>
        </h3>
        <div class="card-toolbar">
          <div class="d-flex" style="gap: 10px">
            <button type="button" class="btn btn-primary" @click="onSubmit">
              {{ $t('constants.save') }}
            </button>
            <button type="button" class="btn btn-secondary" @click="back()">{{ $t('constants.cancel') }}</button>
          </div>
        </div>
      </div>
      <div class="card-body py-3">
        <div class="row">
          <div class="form-group col-md-4 col-sm-6 col-xs-12">
            <label class="required fs-6 mb-2">{{ $t("tours.tour_type") }}</label>
            <el-form-item prop="tour_type">
              <vSelect v-model="form.tour_type" :placeholder="$t('constants.select_tour_type')" :options="filteredTypes"
                :reduce="(type) => type.value" label="name" :dir="direction"></vSelect>
            </el-form-item>
          </div>
          <div class="form-group col-md-4 col-sm-6 col-xs-12">
            <label class="required fs-6 mb-2">{{ $t("tours.name_en") }}</label>
            <el-form-item prop="name_en">
              <el-input v-model="form.name_en" :placeholder="$t('tours.name_en')"></el-input>
            </el-form-item>
          </div>
          <div class="form-group col-md-4 col-sm-6 col-xs-12">
            <label class="required fs-6 mb-2">{{ $t("tours.name_ar") }}</label>
            <el-form-item prop="name_ar">
              <el-input v-model="form.name_ar" :placeholder="$t('tours.name_ar')"></el-input>
            </el-form-item>
          </div>
        </div>
        <div class="row" v-if="form.tour_type == 5 || form.tour_type == 6 || form.tour_type == 1">
          <div class="form-group col-md-8 col-sm-12 col-xs-12">
            <label class="required fs-6 mb-2">{{
              $t("tours.event_duration")
            }}</label>
            <div class="row flex-column flex-sm-row">
              <div class="form-group col mb-0">
                <el-form-item prop="duration_hours">
                  <el-input type="number" v-model="form.duration_hours" min="0">
                    <template slot="append">{{ $t("tours.hours") }}</template>
                  </el-input>
                </el-form-item>
              </div>
              <div class="form-group col mb-0">
                <el-form-item prop="duration_minutes">
                  <el-input type="number" v-model="form.duration_minutes" min="0" max="59">
                    <template slot="append">{{ $t("tours.minutes") }}</template>
                  </el-input>
                </el-form-item>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="form-group col-md-4 col-sm-6 col-xs-12">
            <label class="required fs-6 mb-2">{{
              $t("tours.country_id")
            }}</label>
            <el-form-item prop="country_id">
              <vSelect v-model="form.country_id" :placeholder="$t('constants.select_country')" :options="countries"
                :reduce="(country) => country.id" :label="direction == 'rtl' ? 'name_ar' : 'name_en'" @input="getCities"
                :dir="direction"></vSelect>
            </el-form-item>
          </div>
          <div class="form-group col-md-4 col-sm-6 col-xs-12">
            <label class="required fs-6 mb-2">{{ $t("tours.city_id") }}</label>
            <el-form-item prop="city_id">
              <vSelect v-model="form.city_id" :placeholder="$t('constants.select_city')" :options="cities" :reduce="(city) => city.id"
                :label="direction == 'rtl' ? 'name_ar' : 'name_en'" :dir="direction"></vSelect>
            </el-form-item>
          </div>

          <!--<div class="form-group col-md-4 col-sm-6 col-xs-12">
            <label class="required fs-6 mb-2">{{
              $t("tours.category_id")
            }}</label>
            <el-form-item prop="category_id
              <vSelect
              v-model="form.category_id"
              placeholder="Select category"  
              :options="categories"
              :reduce="category => category.id"
              label="name_en"></vSelect>
            </el-form-item>
          </div>-->
        </div>

        <div class="row">
          <div class="form-group col-md-4 col-sm-6 col-xs-12" v-if="form.tour_type == 1 || form.type == 3">
            <label class="required fs-6 mb-2">{{ $t("tours.date") }}</label>
            <el-form-item prop="date">
              <date-picker v-model="form.date" range value-type="format" class="w-100"
                :disabled-date="pickerOptions.disabledDate"></date-picker>
            </el-form-item>
          </div>
          <div class="form-group col-md-4 col-sm-6 col-xs-12" v-if="form.type == 3">
            <label class="required fs-6 mb-2">{{
              $t("tours.reservation_end_date")
            }}</label>
            <el-form-item prop="reservation_end_date">
              <date-picker v-model="form.reservation_end_date" value-type="format" class="w-100"
                :disabled-date="pickerOptions.disabledDate"></date-picker>
            </el-form-item>
          </div>
          <div class="form-group col-md-4 col-sm-6 col-xs-12" v-if="form.type !== 3">
            <label class="required fs-6 mb-2">{{ $t("tours.price_per_person") }}</label>
            <el-form-item prop="price">
              <el-input v-model="form.price" :placeholder="$t('tours.price_per_person')" type="number"></el-input>
            </el-form-item>
          </div>
          <div class="form-group col-md-4 col-sm-6 col-xs-12">
            <label class="required fs-6 mb-2">{{
              $t("tours.passengers")
            }}</label>
            <el-form-item prop="passengers">
              <el-input v-model="form.passengers" :placeholder="$t('tours.passengers')" type="number"></el-input>
            </el-form-item>
          </div>
          <div class="form-group col-md-4 col-sm-6 col-xs-12">
            <label class="fs-6 mb-2">{{ $t("tours.pay_on_arrival") }}</label>
            <el-form-item>
              <el-switch v-model="form.pay_on_arrival"></el-switch>
            </el-form-item>
          </div>
        </div>
        <div class="row" v-if="user.type == 3">
          <div class="form-group col-md-4 col-sm-6 col-xs-12">
            <label class="required fs-6 mb-2">{{
              $t("tours.single_room")
            }}</label>
            <el-form-item prop="single_room_price">
              <el-input v-model="form.single_room_price" :placeholder="$t('tours.single_room')" type="number"></el-input>
            </el-form-item>
          </div>
          <div class="form-group col-md-4 col-sm-6 col-xs-12">
            <label class="required fs-6 mb-2">{{
              $t("tours.double_room")
            }}</label>
            <el-form-item prop="double_room_price">
              <el-input v-model="form.double_room_price" :placeholder="$t('tours.double_room')" type="number"></el-input>
            </el-form-item>
          </div>
        </div>
        <div class="row" v-if="form.tour_type == 2">
          <label class="fs-6 mb-2 fw-bold">{{ $t("tours.offers") }}</label>
          <div class="row mb-5">
            <label class="fs-6 mb-2 fw-bold">{{ $t("tours.adults") }}</label>
            <div class="row align-items-center">
              <div class="col-md-5 mt-2 mt-md-0">
                <label class="fs-6 mb-2">{{ $t("tours.num_of_persons") }}</label>
              </div>
              <div class="col-md-5 mt-2 mt-md-0">
                <label class="fs-6 mb-2">{{ $t("tours.discount_percentage") }}</label>
              </div>
            </div>
            <div class="row align-items-start" v-for="(item, index) in form.adults_offers" :key="index">
              <div class="col-md-5 mt-2 mt-md-0">
                <el-form-item :prop="'adults_offers.' + index + '.person_count'" :rules="getValidationRules(index)">
                  <el-input :placeholder="$t('tours.num_of_persons')" v-model="item.person_count" type="number">
                  </el-input>
                </el-form-item>
              </div>
              <div class="col-md-5 mt-2 mt-md-0">
                <el-form-item :prop="'adults_offers.' + index + '.discount_percentage'"
                  :rules="getValidationRules(index)">
                  <el-input :placeholder="$t('tours.discount_percentage')" v-model="item.discount_percentage"
                    type="number">
                  </el-input>
                </el-form-item>
              </div>
              <div class="col-md-2 mt-2 mt-md-0">
                <button type="button" class="btn btn-sm btn-danger" @click="deleteActivity('adults_offers', index)">
                  <span class="svg-icon svg-icon-3">
                    <inline-svg src="/media/icons/duotune/arrows/arr075.svg" />
                  </span>
                  {{ $t("constants.delete") }}
                </button>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-4 mt-3 mt-md-0">
                <button type="button" class="btn btn-sm btn-light-primary" @click="addNew('adults_offers')">
                  <span class="svg-icon svg-icon-3">
                    <inline-svg src="/media/icons/duotune/arrows/arr075.svg" />
                  </span>
                  {{ $t("constants.new") }}
                </button>
              </div>
            </div>
          </div>
          <div class="row mb-5">
            <label class="fs-6 mb-2 fw-bold">{{ $t("tours.childs") }}</label>
            <div class="row align-items-center">
              <div class="col-md-5 mt-2 mt-md-0">
                <label class="fs-6 mb-2">{{ $t("tours.discount_percentage") }}</label>
                <el-form-item prop="discount_percentage">
                  <el-input :placeholder="$t('tours.discount_percentage')" v-model="form.discount_percentage"
                    type="number">
                  </el-input>
                </el-form-item>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <!--<div class="form-group col-md-4 col-sm-6 col-xs-12">
            <label class="required fs-6 mb-2">{{ $t("tours.image") }}</label>
            <el-form-item prop="image">
              <vue-dropzone ref="image" id="dropzone" :options="dropzoneImageOptions" @vdropzone-success="ImageAdded"
                ></vue-dropzone>
            </el-form-item>
          </div>-->
          <!--<div class="form-group col-md-4 col-sm-6 col-xs-12">
            <label class="fs-6 mb-2">{{ $t("tours.public") }}</label>
            <el-form-item>
              <el-switch v-model="form.public"> </el-switch>
            </el-form-item>
          </div>-->
        </div>
        <div class="row">
          <div class="form-group col-6">
            <label class="fs-6 mb-2">{{ $t("tours.description_en") }}</label>
            <el-form-item>
              <!--<ckeditor v-model="form.description_en" :config="editorConfigDescEn"></ckeditor>-->
              <el-input type="textarea" :rows="5" placeholder="Please input" v-model="form.description_en"></el-input>
            </el-form-item>
          </div>
          <div class="form-group col-6">
            <label class="fs-6 mb-2">{{ $t("tours.description_ar") }}</label>
            <el-form-item>
              <!--<ckeditor v-model="form.description_ar" :config="editorConfigDescAr"></ckeditor>-->
              <el-input type="textarea" :rows="5" placeholder="Please input" v-model="form.description_ar"></el-input>
            </el-form-item>
          </div>
        </div>
        <div class="row">
          <div class="form-group col-6">
            <label class="fs-6 mb-2">{{
              $t("tours.travel_schedule_en")
            }}</label>
            <el-form-item>
              <el-input type="textarea" :rows="2" placeholder="Please input" v-model="form.travel_schedule_en">
              </el-input>
            </el-form-item>
          </div>
          <div class="form-group col-6">
            <label class="fs-6 mb-2">{{
              $t("tours.travel_schedule_ar")
            }}</label>
            <el-form-item>
              <el-input type="textarea" :rows="2" placeholder="Please input" v-model="form.travel_schedule_ar">
              </el-input>
            </el-form-item>
          </div>
        </div>

        <div class="row">
          <div class="form-group col-6">
            <label class="required fs-6 mb-2">{{
              $t("tours.general_terms_en")
            }}</label>
            <el-form-item>
              <el-input type="textarea" :rows="3" :placeholder="$t('tours.general_terms_en')"
                v-model="form.general_terms_en">
              </el-input>
            </el-form-item>
          </div>
          <div class="form-group col-6">
            <label class="fs-6 mb-2">{{ $t("tours.general_terms_ar") }}</label>
            <el-form-item>
              <el-input type="textarea" :rows="3" :placeholder="$t('tours.general_terms_ar')"
                v-model="form.general_terms_ar">
              </el-input>
            </el-form-item>
          </div>
        </div>
        <hr />
        <div class="row mb-5">
          <label class="fs-6 mb-2">{{ $t("tours.activities") }}</label>
          <div class="row align-items-center mb-5" v-for="(item, index) in form.activities" :key="index">

            <div class="col-md-5 mt-2 mt-md-0">
              <el-form-item :prop="'activities.' + index + '.name_en'" :rules="{
                required: true,
                message: $t('constants.required'),
                trigger: 'blur',
              }">
                <el-input :placeholder="$t('tours.activity_name_en')" v-model="item.name_en">
                </el-input>
              </el-form-item>
            </div>
            <div class="col-md-5 mt-2 mt-md-0">
              <el-form-item :prop="'activities.' + index + '.name_ar'" :rules="{
                required: true,
                message: $t('constants.required'),
                trigger: 'blur',
              }">
                <el-input :placeholder="$t('tours.activity_name_ar')" v-model="item.name_ar">
                </el-input>
              </el-form-item>
            </div>
            <div class="col-md-2 mt-2 mt-md-0">
              <button type="button" class="btn btn-sm btn-danger" @click="deleteActivity('activities', index)">
                <span class="svg-icon svg-icon-3">
                  <inline-svg src="/media/icons/duotune/arrows/arr075.svg" />
                </span>
                {{ $t("constants.delete") }}
              </button>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-4 mt-3 mt-md-0">
              <button type="button" class="btn btn-sm btn-light-primary" @click="addNew('activities')">
                <span class="svg-icon svg-icon-3">
                  <inline-svg src="/media/icons/duotune/arrows/arr075.svg" />
                </span>
                {{ $t("constants.new") }}
              </button>
            </div>
          </div>
        </div>

        <hr />
        <div class="row mb-5">
          <label class="fs-6 mb-2">{{ $t("tours.highlights") }}</label>
          <div class="row align-items-center mb-5" v-for="(item, index) in form.highlights" :key="index">
            <div class="col-md-5 mt-2 mt-md-0">
              <el-form-item :prop="'highlights.' + index + '.name_en'" :rules="{
                required: true,
                message: $t('constants.required'),
                trigger: 'blur',
              }">
                <el-input :placeholder="$t('tours.highlight_name_en')" v-model="item.name_en">
                </el-input>
              </el-form-item>
            </div>
            <div class="col-md-5 mt-2 mt-md-0">
              <el-form-item :prop="'highlights.' + index + '.name_ar'" :rules="{
                required: true,
                message: $t('constants.required'),
                trigger: 'blur',
              }">
                <el-input :placeholder="$t('tours.highlight_name_ar')" v-model="item.name_ar">
                </el-input>
              </el-form-item>
            </div>
            <div class="col-md-2 mt-2 mt-md-0">
              <button type="button" class="btn btn-sm btn-danger" @click="deleteActivity('highlights', index)">
                <span class="svg-icon svg-icon-3">
                  <inline-svg src="/media/icons/duotune/arrows/arr075.svg" />
                </span>
                {{ $t("constants.delete") }}
              </button>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-4 mt-3 mt-md-0">
              <button type="button" class="btn btn-sm btn-light-primary" @click="addNew('highlights')">
                <span class="svg-icon svg-icon-3">
                  <inline-svg src="/media/icons/duotune/arrows/arr075.svg" />
                </span>
                {{ $t("constants.new") }}
              </button>
            </div>
          </div>
        </div>

        <hr />
        <div class="row mb-5">
          <label class="fs-6 mb-2">{{ $t("tours.includes") }}</label>
          <div class="row align-items-center mb-5" v-for="(item, index) in form.includes" :key="index">
            <div class="col-md-5 mt-2 mt-md-0">
              <el-form-item :prop="'includes.' + index + '.name_en'" :rules="{
                required: true,
                message: $t('constants.required'),
                trigger: 'blur',
              }">
                <el-input :placeholder="$t('tours.include_name_en')" v-model="item.name_en">
                </el-input>
              </el-form-item>
            </div>
            <div class="col-md-5 mt-2 mt-md-0">
              <el-form-item :prop="'includes.' + index + '.name_ar'" :rules="{
                required: true,
                message: $t('constants.required'),
                trigger: 'blur',
              }">
                <el-input :placeholder="$t('tours.include_name_ar')" v-model="item.name_ar">
                </el-input>
              </el-form-item>
            </div>
            <div class="col-md-2 mt-2 mt-md-0">
              <button type="button" class="btn btn-sm btn-danger" @click="deleteActivity('includes', index)">
                <span class="svg-icon svg-icon-3">
                  <inline-svg src="/media/icons/duotune/arrows/arr075.svg" />
                </span>
                {{ $t("constants.delete") }}
              </button>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-4 mt-3 mt-md-0">
              <button type="button" class="btn btn-sm btn-light-primary" @click="addNew('includes')">
                <span class="svg-icon svg-icon-3">
                  <inline-svg src="/media/icons/duotune/arrows/arr075.svg" />
                </span>
                {{ $t("constants.new") }}
              </button>
            </div>
          </div>
        </div>

        <hr />
        <div class="row mb-5">
          <label class="fs-6 mb-2">{{ $t("tours.excludes") }}</label>
          <div class="row align-items-center mb-5" v-for="(item, index) in form.excludes" :key="index">
            <div class="col-md-5 mt-2 mt-md-0">
              <el-form-item :prop="'excludes.' + index + '.name_en'" :rules="{
                required: true,
                message: $t('constants.required'),
                trigger: 'blur',
              }">
                <el-input :placeholder="$t('tours.exclude_name_en')" v-model="item.name_en">
                </el-input>
              </el-form-item>
            </div>
            <div class="col-md-5 mt-2 mt-md-0">
              <el-form-item :prop="'excludes.' + index + '.name_ar'" :rules="{
                required: true,
                message: $t('constants.required'),
                trigger: 'blur',
              }">
                <el-input :placeholder="$t('tours.exclude_name_ar')" v-model="item.name_ar">
                </el-input>
              </el-form-item>
            </div>
            <div class="col-md-2 mt-2 mt-md-0">
              <button type="button" class="btn btn-sm btn-danger" @click="deleteActivity('excludes', index)">
                <span class="svg-icon svg-icon-3">
                  <inline-svg src="/media/icons/duotune/arrows/arr075.svg" />
                </span>
                {{ $t("constants.delete") }}
              </button>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-4 mt-3 mt-md-0">
              <button type="button" class="btn btn-sm btn-light-primary" @click="addNew('excludes')">
                <span class="svg-icon svg-icon-3">
                  <inline-svg src="/media/icons/duotune/arrows/arr075.svg" />
                </span>
                {{ $t("constants.new") }}
              </button>
            </div>
          </div>
        </div>
        <hr />
        <div class="row">
          <div class="form-group col-md-4 col-sm-6 col-xs-12">
            <label class="required fs-6 mb-2">{{ $t("tours.image") }}</label>
            <el-form-item prop="image">
              <vue-dropzone ref="image" id="dropzone" :options="dropzoneImageOptions" @vdropzone-success="ImageAdded"
                ></vue-dropzone>
            </el-form-item>
          </div>
        </div>
        <div class="row">
          <div class="form-group col">
            <label class="fs-6 mb-2">{{ $t("tours.images") }}</label>
            <el-form-item>
              <vue-dropzone ref="images" id="dropzone" :options="dropzoneImagesOptions" @vdropzone-success="ImagesAdded"
                ></vue-dropzone>
            </el-form-item>
          </div>
        </div>
        <div class="row">
          <div class="form-group col">
            <label class="fs-6 mb-2">{{ $t("tours.slider_images") }}</label>
            <el-form-item>
              <vue-dropzone ref="slider_images" id="dropzone" :options="dropzoneSliderImagesOptions"
                @vdropzone-success="SliderImagesAdded"></vue-dropzone>
            </el-form-item>
          </div>
        </div>
        <hr />
        <div class="row align-items-center">
          <label class="fs-6 mb-2">{{ $t("tours.GPS") }}</label>

          <div class="form-group col-6">
            <label class="fs-6 mb-2">{{ $t("tours.coordinates") }}</label>
            <el-form-item>
              <el-input v-model="form.coordinates" prefix-icon="el-icon-location" disabled>
              </el-input>
            </el-form-item>
          </div>
          <div class="form-group col-6">
            <button type="button" class="btn btn-sm btn-light-primary" @click="getCurrentLocation()">
              <span class="svg-icon svg-icon-3">
                <inline-svg src="/media/icons/duotune/arrows/arr075.svg" />
              </span>
              {{ $t("tours.currentLocation") }}
            </button>
          </div>
        </div>
        <div class="row">
          <label class="fs-6 mb-2">{{ $t("tours.address") }}</label>
          <p>{{ formatted_address }}</p>
        </div>

        <div class="row">
          <el-form-item>
            <GmapMap :center="coord" :zoom="7" map-type-id="terrain" style="width: 100%; height: 450px" ref="mapRef">
              <GmapMarker ref="myMarker" :position="coord" :draggable="true" @dragend="updateCoordinates" />
            </GmapMap>
          </el-form-item>
        </div>

        <div class="row mt-5">
          <div class="form-group col text-center">
            <div class="d-flex justify-content-center" style="gap: 10px">
              <button type="button" class="btn btn-primary" @click="onSubmit">
                {{ $t('constants.save') }}
              </button>
              <button type="button" class="btn btn-secondary" @click="back()">{{ $t('constants.cancel') }}</button>
            </div>
          </div>
        </div>
      </div>
    </el-form>
  </div>
</template>

<script>
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
//import axios from "axios";
import axiosIns from "@/axios.js";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import Cookies from "js-cookie";
export default {
  name: "add-tour",
  components: {
    vueDropzone: vue2Dropzone,
    DatePicker,
    vSelect,
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    direction() {
      return this.$store.state.direction;
    },
    filteredTypes() {
      var fileterd_types = [];
      if (this.$store.state.user.type == 3) {
        fileterd_types = this.types.filter(({ value }) => {
          return value == 3 || value == 4;
        })
        return fileterd_types;
      } else if (this.$store.state.user.type == 4) {
        fileterd_types = this.types.filter(({ value }) => {
          return value == 6;
        })
        return fileterd_types;
      }
      else {
        fileterd_types = this.types.filter(({ value }) => {
          return value != 3 && value != 4;
        });
        return fileterd_types;
      }
      /*else if(this.$store.state.user.type == 2){
        fileterd_types = this.types.filter(({value}) => {
          return value == 1 || value == 2;
        })
        //return fileterd_types;
      }else if(this.$store.state.user.type == 4){
        fileterd_types = this.types.filter(({value}) => {
          return value == 2;
        })
        //return fileterd_types;
      }*/
    },
  },
  data() {
    var validateHours = (rule, value, callback) => {
      const regex = /^\d*\.?\d+$/;
      if (!regex.test(value)) {
        //this.error = 'Invalid hours format. Please enter a valid hours in hh format.';
        return callback(new Error("Please input a valid hour"));
      } else {
        //this.error = '';
        callback();
      }
    };
    var validateMinutes = (rule, value, callback) => {
      const regex = /^[0-5]?\d$/;
      if (!regex.test(value)) {
        //this.error = 'Invalid minutes format. Please enter a valid minutes in mm format.';
        return callback(new Error("Please input a number between 0 and 59"));
      } else {
        callback();
        //this.error = '';
      }
    };
    return {
      nodeEnv: process.env.NODE_ENV,
      formatted_address: "",
      form: {
        name_ar: "",
        name_en: "",
        company_id: "",
        driver_id: "",
        celebrity_id: "",
        guide_id: "",
        country_id: "",
        city_id: "",
        date: "",
        reservation_end_date: "",
        price: "",
        passengers: "",
        tour_type: "",
        type: this.$store.state.user.type,
        description_en: "",
        description_ar: "",
        travel_schedule_en: "",
        travel_schedule_ar: "",
        general_terms_ar: "",
        general_terms_en: "",
        coordinates: "",
        image: "",
        single_room_price: "",
        double_room_price: "",
        duration_hours: "",
        duration_minutes: "",
        pay_on_arrival: false,
        discount_percentage: "",
        activities: [
          {
            name_ar: "",
            name_en: ""
          },
        ],
        highlights: [
          {
            name_ar: "",
            name_en: ""
          },
        ],
        includes: [
          {
            name_ar: "",
            name_en: ""
          },
        ],
        excludes: [
          {
            name_ar: "",
            name_en: ""
          },
        ],
        adults_offers: [
          {
            person_count: "",
            discount_percentage: ""
          }
        ]
      },
      coord: {
        lat: 21.48436249892535,
        lng: 39.1924169425293,
      },
      images: [],
      slider_images: [],
      countries: [],
      cities: [],
      categories: [],
      drivers: [],
      guides: [],
      types: [
        {
          name: this.$t("tours.group_tour"),
          value: 1,
        },
        {
          name: this.$t("tours.private_tour"),
          value: 2,
        },
        {
          name: this.$t("tours.family_tour"),
          value: 3,
        },
        {
          name: this.$t("tours.youth_tour"),
          value: 4,
        },
        {
          name: this.$t("tours.guided_tour"),
          value: 5,
        },
        {
          name: this.$t("tours.daily_trip_tour"),
          value: 6,
        },
      ],
      pickerOptions: {
        disabledDate(time) {
          return time <= new Date(Date.now() - 8640000);
        },
      },
      dropzoneImageOptions: {
        url: process.env.NODE_ENV == "production" ? "https://api.dgotours.com/api/tours/images/upload" : process.env.NODE_ENV == "development" ? "http://localhost:8000/api/tours/images/upload" : "",
        thumbnailWidth: 150,
        //maxFilesize: 0.5,
        headers: { Authorization: Cookies.get("access_token") },
        maxFiles: 1,
        autoProcessQueue: false,
        //autoQueue: false,
        //chunking: true,
        addRemoveLinks: true,
        timeout: 600000
      },
      dropzoneImagesOptions: {
        url: process.env.NODE_ENV == "production" ? "https://api.dgotours.com/api/tours/images/upload" : process.env.NODE_ENV == "development" ? "http://localhost:8000/api/tours/images/upload" : "",
        thumbnailWidth: 150,
        headers: { Authorization: Cookies.get("access_token") },
        autoProcessQueue: false,
        //maxFilesize: 0.5,
        //maxFiles: 1,
        addRemoveLinks: true,
        timeout: 600000
      },
      dropzoneSliderImagesOptions: {
        url: process.env.NODE_ENV == "production" ? "https://api.dgotours.com/api/tours/images/upload" : process.env.NODE_ENV == "development" ? "http://localhost:8000/api/tours/images/upload" : "",
        thumbnailWidth: 150,
        headers: { Authorization: Cookies.get("access_token") },
        autoProcessQueue: false,
        //maxFilesize: 0.5,
        //maxFiles: 1,
        addRemoveLinks: true,
        timeout: 600000
      },
      editorConfigDescAr: {
        //language: "ar",
        //autoParagraph: false
      },
      editorConfigDescEn: {
        //language: "ar"
        //autoParagraph: false
      },
      rules: {
        duration_hours: [{ validator: validateHours, trigger: "blur" }],
        duration_minutes: [{ validator: validateMinutes, trigger: "blur" }],
        tour_type: [
          {
            required: true,
            message: this.$t('constants.required'),
            trigger: "blur",
          },
        ],
        name_en: [
          {
            required: true,
            message: this.$t('constants.required'),
            trigger: "blur",
          },
        ],
        name_ar: [
          {
            required: true,
            message: this.$t('constants.required'),
            trigger: "blur",
          },
        ],
        country_id: [
          {
            required: true,
            message: this.$t('constants.required'),
            trigger: "blur",
          },
        ],
        city_id: [
          {
            required: true,
            message: this.$t('constants.required'),
            trigger: "blur",
          },
        ],
        date: [
          {
            required: true,
            message: this.$t('constants.required'),
            trigger: "blur",
          },
        ],
        reservation_end_date: [
          {
            required: true,
            message: this.$t('constants.required'),
            trigger: "blur",
          },
        ],
        price: [
          {
            required: true,
            message: this.$t('constants.required'),
            trigger: "blur",
          },
        ],
        double_room_price: [
          {
            required: this.$store.state.user.type == '3' ? true : false,
            message: this.$t('constants.required'),
            trigger: "blur",
          },
        ],
        single_room_price: [
          {
            required: this.$store.state.user.type == '3' ? true : false,
            message: this.$t('constants.required'),
            trigger: "blur",
          },
        ],
      },
      deleteFiles: true,
    };
  },
  watch: {
    "form.tour_type": function (val) {
      if (val == 5 || val == 6 || val == 1) {
        setTimeout(() => {
          this.editPrepend();
        })

      }
    }
  },
  methods: {
    getValidationRules(index) {
      return [
        {
          required: this.isFieldFilled(index),
          message: this.$t('constants.required'),
          trigger: 'blur'
        }
      ];
    },
    isFieldFilled(index) {
      const item = this.form.adults_offers[index];
      return item.person_count.trim() !== '' || item.discount_percentage.trim() !== '';
    },
    ImageAdded(file, response) {
      this.form.image = response.path;
    },
    ImagesAdded(file, response) {
      this.images.push({
        name: response.name,
        path: response.path,
      });
    },
    SliderImagesAdded(file, response) {
      this.slider_images.push({
        name: response.name,
        path: response.path,
      });
    },
    updateCoordinates(location) {
      const geocoder = new google.maps.Geocoder();
      const { latLng } = location;
      //const geocoder = new google.maps.Geocoder();
      const latlng = { lat: latLng.lat(), lng: latLng.lng() };

      geocoder.geocode({ location: latlng }, (results, status) => {

        if (status === 'OK') {
          if (results[0]) {
            this.formatted_address = results[0].formatted_address;
          } else {
            this.formatted_address = 'No results found';
          }
        } else {
          this.formatted_address = 'Geocoder failed due to: ' + status;
        }
      });

      this.form.coordinates =
        location.latLng.lat() + ", " + location.latLng.lng();
      this.coord.lat = location.latLng.lat();
      this.coord.lng = location.latLng.lng();
    },
    removeImageFile(file) {
      if (this.deleteFiles) {
        this.removeUploadedImage(this.form.image).then(
          () => (this.form.image = "")
        );
      }
    },
    removeImagesFile(file) {
      if (this.deleteFiles) {
        const selectedFile = this.images.filter((item) => {
          return item.name == file.name;
        });
        this.removeUploadedImage(selectedFile[0].path).then(() => {
          const indexOfObject = this.images.findIndex((object) => {
            return object.name == file.name;
          });
          this.images.splice(indexOfObject, 1);
        });
      }
    },
    removeSliderImageFile(file) {
      if (this.deleteFiles) {
        const selectedFile = this.slider_images.filter((item) => {
          return item.name == file.name;
        });
        this.removeUploadedImage(selectedFile[0].path).then(() => {
          const indexOfObject = this.slider_images.findIndex((object) => {
            return object.name == file.name;
          });
          this.slider_images.splice(indexOfObject, 1);
        });
      }
    },
    removeUploadedImage(path) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`tours/images/delete`, {
            path: path,
          })
          .then((response) => {
            resolve(response);
          });
      });
    },

    onSubmit() {
      this.$refs["form"].validate((valid) => {
        if (valid) {

          this.$swal(
            {
              showConfirmButton: false,
              allowOutsideClick: false, // disable clicking outside the modal
              backdrop: true, // enable backdrop to prevent closing on outside click
              didOpen: () => {
                this.$swal.showLoading();
              },
            }
          );
          // Create an array of Promises for each Dropzone component
          const uploadPromises = [];

          if (this.$refs.image.dropzone.files.length > 0) {
            uploadPromises.push(new Promise((resolve, reject) => {
              this.$refs.image.processQueue();
              this.$refs.image.dropzone.on('queuecomplete', () => {
                resolve();
              });
            }));
          }

          if (this.$refs.images.dropzone.files.length > 0) {
            uploadPromises.push(new Promise((resolve, reject) => {
              this.$refs.images.processQueue();
              this.$refs.images.dropzone.on('queuecomplete', () => {
                resolve();
              });
            }));
          }

          if (this.$refs.slider_images.dropzone.files.length > 0) {
            uploadPromises.push(new Promise((resolve, reject) => {
              this.$refs.slider_images.processQueue();
              this.$refs.slider_images.dropzone.on('queuecomplete', () => {
                resolve();
              });
            }));
          }

          Promise.all(uploadPromises).then(() => {
            const formData = new FormData();
            for (const key in this.form) {
              if (this.form[key]) {
                formData.append(key, this.form[key]);
              }
            }

            if (formData.has("pay_on_arrival")) {
              formData.delete("pay_on_arrival");
            }
            if (this.form.pay_on_arrival == false) {
              formData.append("pay_on_arrival", 0);
            } else if (this.form.pay_on_arrival == true) {
              formData.append("pay_on_arrival", 1);
            }
            if (this.form.adults_offers.length > 0 &&
              this.form.adults_offers[0].discount_percentage != "" &&
              this.form.adults_offers[0].person_count != "") {
              formData.set('adults_offers', JSON.stringify(this.form.adults_offers));
            } else {
              formData.delete('adults_offers')
            }

            formData.delete('activities')
            if (
              this.form.activities.length > 0 &&
              this.form.activities[0].name_ar != "" &&
              this.form.activities[0].name_en != ""
            ) {
              formData.append("activities", JSON.stringify(this.form.activities));
            }
            formData.delete('highlights')

            if (
              this.form.highlights.length > 0 &&
              this.form.highlights[0].name_ar != "" &&
              this.form.highlights[0].name_en != ""
            ) {
              formData.append("highlights", JSON.stringify(this.form.highlights));
            }
            formData.delete('includes');
            if (
              this.form.includes.length > 0 &&
              this.form.includes[0].name_ar != "" &&
              this.form.includes[0].name_en != ""
            ) {
              formData.append("includes", JSON.stringify(this.form.includes));
            }
            formData.delete('excludes');
            if (
              this.form.excludes.length > 0 &&
              this.form.excludes[0].name_ar != "" &&
              this.form.excludes[0].name_en != ""
            ) {
              formData.append("excludes", JSON.stringify(this.form.excludes));
            }
            if (this.images.length > 0) {
              formData.append("images", JSON.stringify(this.images));
            }
            if (this.slider_images.length > 0) {
              formData.append(
                "slider_images",
                JSON.stringify(this.slider_images)
              );
            }

            axiosIns
              .post("tours", formData, {
                headers: { "content-type": "multipart/form-data" },
              })
              .then((response) => {
                this.$swal.close();
                this.$notify({
                  title: "مرحبا",
                  type: "success",
                  message: response.data,
                  showClose: true,
                  position: "top-left",
                });

                setTimeout(() => {
                  this.$router.push({ name: "tours" });
                }, 500);
              })
              .catch((error) => {
                this.$swal.close();
                this.$swal.fire({
                  icon: 'error',
                  title: 'Oops...',
                  text: 'Something went wrong!',
                })
              });
          });
        } else {
          let animationFrameId;

          const animateScroll = () => {
            const errorElements = document.querySelectorAll('.el-form-item__error');
            if (errorElements.length > 0) {
              const firstErrorParent = errorElements[0].closest('.row');
              if (firstErrorParent) {
                firstErrorParent.scrollIntoView({ behavior: 'smooth' });
              }
            } else {
              animationFrameId = requestAnimationFrame(animateScroll);
            }
          };

          animationFrameId = requestAnimationFrame(animateScroll);
        }
      });
    },
    getCurrentLocation() {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          this.form.coordinates =
            position.coords.latitude + ", " + position.coords.longitude;
          this.coord.lat = position.coords.latitude;
          this.coord.lng = position.coords.longitude;
        },
        (error) => {
          console.log(error.message);
        }
      );
    },
    addNew(array_data) {
      const activity = {
        name_ar: "",
        name_en: "",
      };
      const highlight = {
        name_ar: "",
        name_en: "",
      };
      const include = {
        name_ar: "",
        name_en: "",
      };
      const exclude = {
        name_ar: "",
        name_en: "",
      };
      const adult_offer = {
        person_count: "",
        discount_percentage: ""
      };
      const child_offer = {
        child_count: "",
        discount_percentage: ""
      }
      this.form[array_data].push(
        array_data == "activities"
          ? activity
          : array_data == "highlights"
            ? highlight
            : array_data == "includes"
              ? include
              : array_data == "excludes"
                ? exclude
                : array_data == "adults_offers"
                  ? adult_offer
                  : array_data == "childs_offers"
                    ? child_offer
                    : ""
      );
    },
    deleteActivity(array_data, index) {
      if (this.form[array_data].length > 1) {
        this.form[array_data].splice(index, 1);
      }
    },
    getAllCountries() {
      axiosIns.get("countries").then((response) => {
        this.countries = response.data.countries;
      });
    },
    getCities() {
      this.form.city_id = "";
      axiosIns.get("cities/" + this.form.country_id).then((response) => {
        this.cities = response.data.cities;
      });
    },
    getCategories() {
      axiosIns.get("categories").then((response) => {
        this.categories = response.data.categories;
      });
    },
    getDrivers() {
      axiosIns.get("drivers/" + this.form.company_id).then((response) => {
        this.drivers = response.data.drivers;
      });
    },
    getGuides() {
      axiosIns.get("guides/" + this.form.company_id).then((response) => {
        this.guides = response.data.guides;
      });
    },
    back() {
      this.$router.go(-1);
    },
    editPrepend() {
      console.log("editPrepend");
      const element = document.querySelectorAll('.el-input-group__append');
      const element2 = document.querySelectorAll('.el-input-group--append .el-input__inner');

      console.log(element, element2);

      if (this.direction == "rtl") {
        element.forEach((item) => {
          item.style.borderRight = '0';
          item.style.borderLeft = '1px solid #DCDFE6';
          item.style.borderRadius = '0px';
          item.style.borderTopLeftRadius = '4px';
          item.style.borderBottomLeftRadius = '4px';
        });

        element2.forEach((item) => {
          item.style.borderRadius = '0px';
          item.style.borderTopRightRadius = '4px';
          item.style.borderBottomRightRadius = '4px';
        });

      }
    },
  },
  mounted() {
    if (this.$store.state.user.type == 2) {
      this.form.company_id = this.$store.state.user.id;
    } else if (
      this.$store.state.user.type == 4 &&
      this.$store.state.user.parent_id != undefined
    ) {
      this.form.company_id = this.$store.state.user.parent_id;
      this.form.driver_id = this.$store.state.user.id;
    } else if (this.$store.state.user.type == 4) {
      this.form.driver_id = this.$store.state.user.id;
    } else if (this.$store.state.user.type == 3) {
      this.form.celebrity_id = this.$store.state.user.id;
    } else if (this.$store.state.user.type == 6 &&
      this.$store.state.user.parent_id != undefined) {
      this.form.company_id = this.$store.state.user.parent_id;
      this.form.guide_id = this.$store.state.user.id;
    }
    else if (this.$store.state.user.type == 6) {
      this.form.guide_id = this.$store.state.user.id;
    }
    this.getAllCountries();
    this.editPrepend();
    //this.getCategories();
  },
  beforeDestroy() {
    this.deleteFiles = false;
  },
};
</script>

<style>
.row>* {
  /*width: auto !important;*/
}

.el-form-item__content {
  margin-left: 0 !important;
}

.mx-input {
  height: 40px !important;
}

.vs--searchable .vs__dropdown-toggle {
  height: 40px;
}

.el-form-item.is-error .el-input__inner,
.el-form-item.is-error .el-input__inner:focus,
.el-form-item.is-error .el-textarea__inner,
.el-form-item.is-error .el-textarea__inner:focus {
  border-color: #dcdfe6 !important;
}
</style>
