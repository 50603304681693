<template>
  <div
    class="modal fade"
    id="modal_new_cities_commuting"
    ref="addNewCitiesCommuting"
    tabindex="-1"
    aria-hidden="true"
  >
    <!--begin::Modal dialog-->
    <div class="modal-dialog modal-dialog-centered mw-650px">
      <!--begin::Modal content-->
      <div class="modal-content">
        <!--begin::Modal header-->
        <div class="modal-header" id="modal_new_cities_commuting_header">
          <!--begin::Modal title-->
          <h2 class="fw-bolder">
            {{
              mode == "add"
                ? $t("cities_commuting.new")
                : $t("cities_commuting.update")
            }}
          </h2>
          <!--end::Modal title-->

          <!--begin::Close-->
          <div
            id="modal_add_cities_commuting_close"
            data-bs-dismiss="modal"
            class="btn btn-icon btn-sm btn-active-icon-primary"
            ref="close"
          >
            <span class="svg-icon svg-icon-1">
              <inline-svg src="/media/icons/duotune/arrows/arr061.svg" />
            </span>
          </div>
          <!--end::Close-->
        </div>
        <!--end::Modal header-->
        <!--begin::Form-->
        <el-form
          @submit.prevent="submit()"
          :model="form"
          :rules="rules"
          ref="formRef"
        >
          <!--begin::Modal body-->

          <div class="modal-body py-10 px-lg-17">
            <div class="row">
              <div class="form-group col-6">
                <label class="fs-6 mb-2">{{
                  $t("cities_commuting.from_city_id")
                }}</label>
                <el-form-item prop="from_city_id">
                  <vSelect
                    v-model="form.from_city_id"
                    placeholder="Select city"
                    :options="cities"
                    :reduce="(city) => city.id"
                    label="name_en"
                  ></vSelect>
                </el-form-item>
              </div>
              <div class="form-group col-6">
                <label class="fs-6 mb-2">{{
                  $t("cities_commuting.to_city_id")
                }}</label>
                <el-form-item prop="to_city_id">
                  <vSelect
                    v-model="form.to_city_id"
                    placeholder="Select city"
                    :options="cities"
                    :reduce="(city) => city.id"
                    label="name_en"
                  ></vSelect>
                </el-form-item>
              </div>
            </div>
            <div class="row">
              <div class="form-group col-6">
                <label class="fs-6 mb-2">{{
                  $t("cities_commuting.price")
                }}</label>
                <el-form-item prop="price">
                  <el-input
                    type="number"
                    v-model="form.price"
                    :placeholder="$t('cities_commuting.price')"
                  >
                  </el-input>
                </el-form-item>
              </div>
            </div>
          </div>
          <div class="modal-footer flex-center">
            <div class="d-flex" style="gap: 10px">
              <button
                type="button"
                class="btn btn-primary"
                @click="onSubmit"
                :disabled="disabled"
              >
                {{$t('constants.save')}}
              </button>
              <button type="button" class="btn btn-secondary"
            data-bs-dismiss="modal" ref="close">{{$t('constants.cancel')}}</button>
            </div>
          </div>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import axiosIns from "@/axios.js";
export default {
  components: {
    vSelect,
  },
  props: {
    item: Object,
    mode: {
      type: String,
      //default: 'add'
    },
  },
  watch: {
    /*item(val){
      
      this.form.from_city_id = val.from_city_id.id
      this.form.to_city_id = val.to_city_id.id
      this.form.price = val.price
      
    },*/
    mode() {
      //console.log(val);
      //this.form = {}
    },
  },
  data() {
    return {
      form: {
        from_city_id: "",
        to_city_id: "",
        price: "",
      },
      rules: {
        from_city_id: [
          {
            required: true,
            message: "this field is required",
            trigger: "blur",
          },
        ],
        to_city_id: [
          {
            required: true,
            message: "this field is required",
            trigger: "blur",
          },
        ],
        price: [
          {
            required: true,
            message: "this field is required",
            trigger: "blur",
          },
        ],
      },
      cities: [],
      disabled: false,
    };
  },
  methods: {
    getCities(country_id) {
      axiosIns.get("cities/" + country_id).then((response) => {
        this.cities = response.data.cities;
      });
    },
    onSubmit() {
      this.$refs["formRef"].validate((valid) => {
        if (valid) {
          const formData = new FormData();
          for (const key in this.form) {
            if (this.form[key]) {
              formData.append(key, this.form[key]);
            }
          }
          //this.$swal.showLoading();
          this.disabled = true;
          if (this.mode == "add") {
            axiosIns
              .post("cities-commuting", formData)
              .then((response) => {
                //this.$swal.close();
                this.disabled = false;
                this.$notify({
                  title: "مرحبا",
                  type: "success",
                  message: response.data,
                  showClose: true,
                  position: "top-left",
                });

                this.$refs.close.click();
                this.$emit("success");
              })
              .catch((error) => {
                this.disabled = false;
              });
          } else if (this.mode == "edit") {
            axiosIns
              .post(
                "cities-commuting/" + this.item.id + "?_method=put",
                formData
              )
              .then((response) => {
                //this.$swal.close();
                this.disabled = false;
                this.$notify({
                  title: "مرحبا",
                  type: "success",
                  message: response.data,
                  showClose: true,
                  position: "top-left",
                });

                this.$refs.close.click();
                this.$emit("success");
              })
              .catch((error) => {
                this.disabled = false;
              });
          }
        }
      });
    },
  },
  mounted() {
    if (this.$store.state.user.type == 4) {
      this.getCities(this.$store.state.user.country_id);
    }

    const modal = this.$refs.addNewCitiesCommuting;
    modal.addEventListener("shown.bs.modal", () => {
      if (this.mode == "edit") {
        this.form.from_city_id = this.item.from_city_id.id;
        this.form.to_city_id = this.item.to_city_id.id;
        this.form.price = this.item.price;
      }
    });
    modal.addEventListener("hidden.bs.modal", () => {
      this.form.from_city_id = "";
      this.form.to_city_id = "";
      this.form.price = "";
    });
  },
};
</script>
