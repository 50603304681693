<template>
  <!--begin::Toolbar wrapper-->
  <div class="d-flex align-items-stretch flex-shrink-0">
    <!--begin::Notifications-->
    <div class="d-flex align-items-center ms-1 ms-lg-3">
      <!--begin::Menu-->
      <div class="btn btn-icon btn-active-light-primary position-relative w-30px h-30px w-md-40px h-md-40px"
        data-kt-menu-trigger="click" data-kt-menu-attach="parent" data-kt-menu-placement="bottom-end"
        data-kt-menu-flip="bottom" @click="seenState()">
        <span class="notif bg-danger" v-if="unreadNotifications > 0">
          {{ unreadNotifications }}
        </span>
        <span class="svg-icon svg-icon-1 position-relative">
          <inline-svg src="/media/icons/duotune/general/gen007.svg" />
        </span>
      </div>
      <KTNotificationsMenu :notifications="notifications"></KTNotificationsMenu>
      <!--end::Menu-->
    </div>
    <!--end::Notifications-->

    <!--begin::User-->
    <div class="d-flex align-items-center ms-1 ms-lg-3" id="kt_header_user_menu_toggle">
      <!--begin::Menu-->
      <div class="cursor-pointer symbol symbol-30px symbol-md-40px" data-kt-menu-trigger="click"
        data-kt-menu-attach="parent" data-kt-menu-placement="bottom-end" data-kt-menu-flip="bottom">
        <img src="/media/avatars/blank.png" alt="metronic" />
      </div>
      <KTUserMenu></KTUserMenu>
      <!--end::Menu-->
    </div>
    <!--end::User -->

    <!--begin::Heaeder menu toggle-->
    <div class="d-flex align-items-center d-lg-none" :class="direction == 'rtl' ? 'me-2' : 'ms-2'"
      title="Show header menu">
      <div class="btn btn-icon btn-active-light-primary" id="kt_header_menu_mobile_toggle">
        <span class="svg-icon svg-icon-1">
          <inline-svg src="/media/icons/duotune/text/txt001.svg" />
        </span>
      </div>
    </div>
    <!--end::Heaeder menu toggle-->
  </div>
  <!--end::Toolbar wrapper-->
</template>

<script>
import KTNotificationsMenu from "@/components/dashboard/header/partials/NotificationsMenu.vue";
//import KTQuickLinksMenu from "@/components/dashboard/header/partials/QuickLinksMenu.vue";
import KTUserMenu from "@/components/dashboard/header/partials/UserMenu.vue";
import NotificationRequest from "@/services/requests/index"

export default {
  name: "TopBar",
  components: {
    KTNotificationsMenu,
    //KTQuickLinksMenu,
    KTUserMenu,
  },
  data() {
    return {
      
    }
  },
  computed: {

    user() {
      return this.$store.getters.user;
    },
    direction() {
      return this.$store.state.direction;
    },
    notifications() {
      return this.$store.getters.notifications;
    },
    unreadNotifications() {
      const notifications = this.notifications.filter((item) => item.seen_state == 'UNSEEN');
      return notifications.length;
    },
  },

  methods: {
    getNotifications() {
      this.$store.dispatch('getNotifications')
      /* NotificationRequest.getAllNotifications()
         .then((response) => {
           this.notifications = response.data.data;
         })*/
    },
    seenState() {
      if (this.unreadNotifications > 0) {
        NotificationRequest.seenState()
          .then((response) => {
            if (response.data.success) {
              this.getNotifications();
            }
          })
      }
    }
  },
  mounted() {
    this.getNotifications();
  }
};
</script>

<style>
.notif {
  position: absolute;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  top: 0;
  color: white;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.75rem;
}

@media screen and (max-width: 768px) {
  .notif {
    top: -5px;
    right: -4px;
  }
}
</style>
