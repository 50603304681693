// i18n.js
import Vue from 'vue';
import VueI18n from 'vue-i18n';
import messages from './i18n_messages.json'; // Your translation messages file
import Cookies from 'js-cookie';

Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: Cookies.get('language') || 'ar',
  messages,
});

export default i18n;