<template>
    <div class="card card-xxl-stretch mb-5 mb-xl-10">
        <!--begin::Header-->
        <div class="card-header align-items-center border-0 mt-4">
            <h3 class="card-title align-items-start flex-column">
                <span class="fw-bolder mb-2 text-dark">{{ $t("reservations.details") }}</span>
            </h3>

            <div class="card-toolbar" v-if="reservation.payment_status == 0 && reservation.payment_method == 2">
                <button class="btn btn-sm btn-primary" @click="confirmOfficePayment()">
                    {{ $t("orders.confirm_office_payment") }}
                </button>
            </div>
        </div>
        <div class="card-body py-3" v-if="ReservationLength > 0">
            <div class="d-flex flex-column flex-xl-row gap-7 gap-lg-10">
                <div class="card card-flush py-4 flex-row-fluid">
                    <div class="card-header">
                        <div class="card-title">
                            <h2>{{ `${$t("reservations.details")} #${reservation.reservation_no}` }}</h2>
                        </div>
                    </div>
                    <div class="card-body pt-0">
                        <div class="table-responsive">
                            <table class="table align-middle table-row-bordered mb-0 fs-6 gy-5 min-w-300px">
                                <tbody class="fw-semibold text-gray-600">
                                    <tr>
                                        <td class="text-muted">{{ $t('orders.order_status') }}</td>
                                        <td class="fw-bold text-end">
                                            <span class="badge" :class="orderStatus(reservation.status)">{{
                                                orderStatusName(reservation.status)
                                            }}</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="text-muted">{{ $t('orders.payment_status') }}</td>
                                        <td class="fw-bold text-end">
                                            <span class="badge" :class="paymentStatusColor(reservation.payment_status)">{{
                                                orderPaymentStatusName(reservation.payment_status)
                                            }}</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="text-muted">{{ $t('orders.reservation_date') }}</td>
                                        <td class="fw-bold text-end">
                                            {{ reservation.created_at }}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="card card-flush py-4 flex-row-fluid">
                    <div class="card-header">
                        <div class="card-title">
                            <h2>{{ $t("cars.reservations.car_details") }}</h2>
                        </div>
                    </div>
                    <div class="card-body pt-0">
                        <div class="table-responsive">
                            <table class="table align-middle table-row-bordered mb-0 fs-6 gy-5 min-w-300px">
                                <tbody class="fw-semibold text-gray-600">
                                    <tr>
                                        <td class="text-muted">{{ $t('cars.make') }}</td>
                                        <td class="fw-bold text-end">
                                            {{ reservation.car.make }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="text-muted">{{ $t('cars.model') }}</td>
                                        <td class="fw-bold text-end">
                                            {{ reservation.car.model }}
                                        </td>
                                    </tr>
                                    <tr v-if="reservation.car.car_model_generation !== null">
                                        <td class="text-muted">{{ $t('cars.car_model_generation') }}</td>
                                        <td class="fw-bold text-end">
                                            {{ reservation.car.car_model_generation }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="text-muted">{{ $t('cars.year') }}</td>
                                        <td class="fw-bold text-end">
                                            {{ reservation.car.year }}
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="card card-flush py-4 flex-row-fluid">
                    <div class="card-header">
                        <div class="card-title">
                            <h2>{{ $t("reservations.user_details") }}</h2>
                        </div>
                    </div>
                    <div class="card-body pt-0">
                        <div class="table-responsive">
                            <table class="table align-middle table-row-bordered mb-0 fs-6 gy-5 min-w-300px">
                                <tbody class="fw-semibold text-gray-600">
                                    <tr>
                                        <td class="text-muted">{{ $t('users.name') }}</td>
                                        <td class="fw-bold text-end">
                                            {{ reservation.user.name }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="text-muted">{{ $t('users.email') }}</td>
                                        <td class="fw-bold text-end">
                                            {{ reservation.user.email }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="text-muted">{{ $t('users.country_id') }}</td>
                                        <td class="fw-bold text-end">
                                            {{ reservation.user.country }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="text-muted">{{ $t('users.mobile') }}</td>
                                        <td class="fw-bold text-end">
                                            {{ reservation.user.phone }}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card card-flush py-4 flex-row-fluid overflow-hidden">
            <div class="card-header">
                <div class="card-title">
                    <h2>{{ $t('reservations.reservation_summary') }}</h2>
                </div>
            </div>
            <div class="card-body pt-0">
                <div class="table-responsive">
                    <table class="table align-middle table-row-bordered mb-0 fs-6 gy-5 min-w-300px">
                        <tbody class="fw-semibold text-gray-600">
                            <tr>
                                <td class="text-muted">{{ $t('reservations.price') }}</td>
                                <td class="fw-bold text-end">
                                    {{ formatPrice(reservation.price) }}
                                </td>
                            </tr>
                            <tr>
                                <td class="text-muted">{{ $t('cars.reservations.duration') }}</td>
                                <td class="fw-bold text-end">
                                    {{ reservation.duration | dayFilter($t('constants.day'), $t('constants.days')) }}
                                </td>
                            </tr>
                            <tr>
                                <td class="text-muted">{{ $t('cars.reservations.guarantee_amount') }}</td>
                                <td class="fw-bold text-end">
                                    {{ formatPrice(reservation.insurance_info.guarantee_amount) }}
                                </td>
                            </tr>
                            <tr>
                                <td class="fs-2">Grand Total</td>
                                <td class="fw-bold text-end fs-2">
                                    {{ formatPrice(reservation.total_price) }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axiosIns from "@/axios.js";
export default {
    name: "RentalCarsReservationView",
    data() {
        return {
            reservation: {},
        }
    },
    computed: {
        ReservationLength() {
            return Object.keys(this.reservation).length;
        },
    },
    filters: {
        decimalWithPercent(value) {
            const decimalNumber = parseFloat(value);
            return `${decimalNumber}%`;
        },
        dayFilter(value, singularTranslation, pluralTranslation) {
            if (value == 1) {
            return `${value} ${singularTranslation}`;
            } else if (value > 1) {
            return `${value} ${pluralTranslation}`;
            }
        },
    },
    methods: {
        orderPaymentStatusName(status) {
            if (status == 0) {
                return this.$t('orders.not_paid');
            } else if (status == 1) {
                return this.$t('orders.paid');
            } else if (status == 2) {
                return this.$t('orders.paid_in_office')
            }
        },
        orderStatusName(status) {
            if (status == 0) {
                return this.$t('orders.pending');
            } else if (
                status == 1
            ) {
                return this.$t('orders.accepted');
            } else if (
                status == 2
            ) {
                return this.$t('orders.canceled');
            }
        },

        orderStatus(status) {
            if (status == 0) {
                return "bg-warning text-dark";
            } else if (
                status == 2 ||
                status == 3
            ) {
                return "bg-danger";
            } else if (
                status == 1
            ) {
                return "bg-success";
            }
        },

        paymentStatusColor(status) {
            if (status == 0) {
                return 'bg-warning text-dark';
            } else if (
                status == 1
            ) {
                return 'bg-success';
            } else if (
                status == 2
            ) {
                return 'bg-success';
            }
        },
        getReservation() {
            axiosIns.get(`reservations/rental-cars/${this.$route.params.id}`).then((response) => {
                this.reservation = response.data.data;
            });
        },
        formatPrice(price) {
            //const price = 9.99;
            const currency = "USD";
            const formattedPrice = price.toLocaleString("en-US", {
                style: "currency",
                currency: currency,
            });
            return formattedPrice;
            //console.log(formattedPrice); // $9.99
        },
    },

    mounted() {
        this.getReservation();
    }
}
</script>