<template>
  <div class="card card-xxl-stretch mb-5 mb-xl-10">
    <!--begin::Header-->
    <div class="card-header align-items-center border-0 mt-4">
      <h3 class="card-title align-items-start flex-column">
        <span class="fw-bolder mb-2 text-dark">{{
          $t("cities_commuting.title")
        }}</span>
      </h3>
      <div
        class="card-toolbar"
        data-bs-toggle="tooltip"
        data-bs-placement="top"
        data-bs-trigger="hover"
        @click="changeMode()"
      >
        <button
          type="button"
          class="btn btn-sm btn-primary"
          data-bs-toggle="modal"
          data-bs-target="#modal_new_cities_commuting"
        >
          <span class="svg-icon svg-icon-3">
            <inline-svg src="/media/icons/duotune/arrows/arr075.svg" />
          </span>
          {{ $t("constants.new") }}
        </button>
      </div>
    </div>
    <div class="card-body py-3">
      <CitiesCommutingTable ref="city_commuting_table" />
    </div>
  </div>
</template>

<script>
import CitiesCommutingTable from "./CitiesCommutingTable.vue";

export default {
  name:"CitiesCommuting",
  components: {
    CitiesCommutingTable,
    //NewCitiesCommuting
  },
  data() {
    return {
      mode: "add",
    };
  },
  methods: {
    changeMode() {
      this.$refs.city_commuting_table.mode = "add";
    },
  },
};
</script>
