<template>
  <div>
    <!--begin::Navbar-->
    <div class="card mb-5 mb-xl-10">
      <div class="card-body pt-9 pb-0">
        <!--begin::Details-->
        <div class="d-flex flex-wrap flex-sm-nowrap mb-3">
          <!--begin: Pic-->
          <div v-if="user.image" class="mb-4" :class="direction == 'ltr' ? 'me-7': 'ms-7'">
            <div
              class="symbol symbol-100px symbol-lg-160px symbol-fixed position-relative"
            >
              <!--<img src="/media/avatars/150-2.jpg" alt="image" />-->
              <img :src="user.image ? user.image : $store.state.defaultImage" alt="image">
              <div
                class="position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-white h-20px w-20px"
              ></div>
            </div>
            
          </div>
          <div v-else class="mb-4" :class="direction == 'ltr' ? 'me-7': 'ms-7'">
            <VueSkeletonLoader 
              type="rect"
              :width="160"
              :height="160"
              animation="fade"
              rounded
            />
          </div>
          <!--end::Pic-->

          <!--begin::Info-->
          <div class="flex-grow-1">
            <!--begin::Title-->
            <div
              class="d-flex justify-content-between align-items-start flex-wrap mb-2"
            >
              <!--begin::User-->
              <div class="d-flex flex-column">
                <!--begin::Name-->
                <div class="d-flex align-items-center mb-2" v-if="user.name">
                  <a
                    href="#"
                    class="text-gray-800 text-hover-primary fs-2 fw-bolder"
                    :class="direction == 'ltr' ? 'me-1': 'ms-1'"
                    >{{ user.name }}</a
                  >
                  <a href="#" v-if="$store.getters.isActive && $store.getters.emailIsVerified">
                    <span class="svg-icon svg-icon-1 svg-icon-primary">
                      <inline-svg
                        src="/media/icons/duotune/general/gen026.svg"
                      />
                    </span>
                  </a>
                </div>
                <div class="d-flex align-items-center mb-2" v-else>
                  <VueSkeletonLoader 
                    type="rect"
                    :width="130"
                    :height="20"
                    animation="fade"
                    rounded
                  />
                </div>
                <!--end::Name-->

                <!--begin::Info-->
                <div class="d-flex flex-wrap fw-bold fs-6 mb-4" :class="direction == 'ltr' ? 'pe-2' : 'ps-2'" v-if="user.type || user.country || user.email">
                  <a
                    href="#"
                    class="d-flex align-items-center text-gray-400 text-hover-primary mb-2"
                    :class="direction == 'ltr' ? 'me-5': 'ms-5'"
                  >
                    <span class="svg-icon svg-icon-4" :class="direction == 'ltr' ? 'me-1' : 'ms-1'">
                      <inline-svg
                        src="/media/icons/duotune/communication/com006.svg"
                      />
                    </span>
                    {{user.type }}
                  </a>
                  <a
                    href="#"
                    class="d-flex align-items-center text-gray-400 text-hover-primary mb-2"
                    :class="direction == 'ltr' ? 'me-5': 'ms-5'"
                  >
                    <span class="svg-icon svg-icon-4" :class="direction == 'ltr' ? 'me-1' : 'ms-1'">
                      <inline-svg
                        src="/media/icons/duotune/general/gen018.svg"
                      />
                    </span>
                    {{ user.country+', '+user.city }}
                  </a>
                  <a
                    href="#"
                    class="d-flex align-items-center text-gray-400 text-hover-primary mb-2"
                    :class="direction == 'ltr' ? 'me-5': 'ms-5'"
                  >
                    <span class="svg-icon svg-icon-4" :class="direction == 'ltr' ? 'me-1' : 'ms-1'">
                      <inline-svg
                        src="/media/icons/duotune/communication/com011.svg"
                      />
                    </span>
                    {{ user.email }}
                  </a>
                </div>
                <div class="d-flex flex-wrap fw-bold fs-6 mb-4" :class="direction == 'ltr' ? 'pe-2' : 'ps-2'" v-else>
                  <VueSkeletonLoader 
                    type="rect"
                    :width="250"
                    :height="20"
                    animation="fade"
                    rounded
                  />
                </div>
                <!--end::Info-->
              </div>
              <!--end::User-->

              <!--begin::Actions-->
              <!--<div class="d-flex my-4">
                <a
                  href="#"
                  class="btn btn-sm btn-light me-2"
                  id="kt_user_follow_button"
                >
                  <span class="svg-icon svg-icon-3 d-none">
                    <inline-svg src="/media/icons/duotune/arrows/arr012.svg" />
                  </span>
                  Follow
                </a>

                <a
                  href="#"
                  class="btn btn-sm btn-primary me-3"
                  data-bs-toggle="modal"
                  data-bs-target="#kt_modal_offer_a_deal"
                  >Hire Me</a
                >

                <!- -begin::Menu- ->
                <div class="me-0">
                  <button
                    class="btn btn-sm btn-icon btn-bg-light btn-active-color-primary"
                    data-kt-menu-trigger="click"
                    data-kt-menu-placement="bottom-end"
                    data-kt-menu-flip="top-end"
                  >
                    <i class="bi bi-three-dots fs-3"></i>
                  </button>
                  <!- -<Dropdown3></Dropdown3>- ->
                </div>
                <!- -end::Menu- ->
              </div>-->
              <!--end::Actions-->
            </div>
            <!--end::Title-->

            <!--begin::Stats-->
            <div class="d-flex flex-wrap flex-stack">
              <!--begin::Wrapper-->
              <div class="d-flex flex-column flex-grow-1" :class="direction == 'ltr' ? 'pe-8' : 'ps-8'">
                <!--begin::Stats-->
                <div class="d-flex flex-wrap">
                  <!--begin::Stat-->
                  <div
                    class="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 mb-3"
                    :class="direction == 'ltr' ? 'me-6' : 'ms-6'"
                  >
                    <!--begin::Number-->
                    <div class="d-flex align-items-center">
                      <!--<span class="svg-icon svg-icon-success" :class="direction == 'ltr' ? 'me-2': 'ms-2'">
                        <inline-svg
                          src="/media/icons/duotune/arrows/arr066.svg"
                        />
                      </span>-->
                      <div
                        class="fs-2 fw-bolder"
                        data-kt-countup="true"
                        data-kt-countup-value="4500"
                        data-kt-countup-prefix="$"
                      >
                        {{user.tours_count}}
                      </div>
                    </div>
                    <!--end::Number-->

                    <!--begin::Label-->
                    <div class="fw-bold fs-6 text-gray-400">{{ $t('account.tours_count') }}</div>
                    <!--end::Label-->
                  </div>
                  <!--end::Stat-->

                  <!--begin::Stat-->
                  <div
                    class="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 mb-3"
                    :class="direction == 'ltr' ? 'me-6' : 'ms-6'"
                    v-if="$store.state.user.type == 2"
                  >
                    <!--begin::Number-->
                    <div class="d-flex align-items-center">
                      <!--<span class="svg-icon svg-icon-3 svg-icon-danger" :class="direction == 'ltr' ? 'me-2': 'ms-2'">
                        <inline-svg
                          src="/media/icons/duotune/arrows/arr065.svg"
                        />
                      </span>-->
                      <div
                        class="fs-2 fw-bolder"
                        data-kt-countup="true"
                        data-kt-countup-value="75"
                      >
                        {{user.rental_cars_count}}
                      </div>
                    </div>
                    <!--end::Number-->

                    <!--begin::Label-->
                    <div class="fw-bold fs-6 text-gray-400">{{$t('account.rental_cars_count')}}</div>
                    <!--end::Label-->
                  </div>
                  <!--end::Stat-->

                  <!--begin::Stat-->
                  <div
                    class="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 mb-3"
                    :class="direction == 'ltr' ? 'me-6' : 'ms-6'"
                  >
                    <!--begin::Number-->
                    <div class="d-flex align-items-center">
                      
                      <div
                        class="fs-2 fw-bolder"
                        data-kt-countup="true"
                        data-kt-countup-value="60"
                        data-kt-countup-prefix="%"
                      >
                        {{ user.tours_reservations }}
                      </div>
                    </div>
                    <!--end::Number-->

                    <!--begin::Label-->
                    <div class="fw-bold fs-6 text-gray-400">{{ $t('account.tours_reservation_count') }}</div>
                    <!--end::Label-->
                  </div>
                  <!--end::Stat-->

                  <!--begin::Stat-->
                  <div
                    class="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 mb-3"
                    :class="direction == 'ltr' ? 'me-6' : 'ms-6'"
                    v-if="$store.state.user.type == 2"
                  >
                    <!--begin::Number-->
                    <div class="d-flex align-items-center">
                      <div
                        class="fs-2 fw-bolder"
                        data-kt-countup="true"
                        data-kt-countup-value="60"
                        data-kt-countup-prefix="%"
                      >
                        {{ user.rental_cars_reservations }}
                      </div>
                    </div>
                    <!--end::Number-->

                    <!--begin::Label-->
                    <div class="fw-bold fs-6 text-gray-400">{{ $t('account.rental_cars_reservation_count') }}</div>
                    <!--end::Label-->
                  </div>
                  <!--end::Stat-->

                  <!--begin::Stat-->
                  <div
                    class="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 mb-3"
                    :class="direction == 'ltr' ? 'me-6' : 'ms-6'"
                    v-if="$store.state.user.type == 4"
                  >
                    <!--begin::Number-->
                    <div class="d-flex align-items-center">
                      <div
                        class="fs-2 fw-bolder"
                        data-kt-countup="true"
                        data-kt-countup-value="60"
                        data-kt-countup-prefix="%"
                      >
                        {{ user.orders_count }}
                      </div>
                    </div>
                    <!--end::Number-->

                    <!--begin::Label-->
                    <div class="fw-bold fs-6 text-gray-400">{{ $t('account.driver_orders') }}</div>
                    <!--end::Label-->
                  </div>
                  <!--end::Stat-->
                  
                </div>
                <!--end::Stats-->
              </div>
              <!--end::Wrapper-->

              <!--begin::Progress-->
              <div
                class="d-flex align-items-center w-200px w-sm-300px flex-column mt-3"
              >
                <div class="d-flex justify-content-between w-100 mt-auto mb-2">
                  <span class="fw-bold fs-6 text-gray-400"
                    >{{$t('account.profile_completion')}}</span
                  >
                  <span class="fw-bolder fs-6">{{ $store.state.user.profile_completion_percentage+'%' }}</span>
                </div>

                <div class="h-5px mx-3 w-100 bg-light mb-3">
                  <div
                    class="bg-success rounded h-5px"
                    role="progressbar"
                    :style="'width:'+$store.state.user.profile_completion_percentage+'%'"
                    :aria-valuenow="$store.state.user.profile_completion"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
              </div>
              <!--end::Progress-->
              
            </div>
            <!--end::Stats-->
          </div>
          <!--end::Info-->
        </div>
        <!--end::Details-->
        

        <!--begin::Navs-->
        <div class="d-flex overflow-auto h-55px">
          <ul
            class="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap"
            :class="direction == 'rtl' ? 'pe-0' : ''"
          >
            <!--begin::Nav item-->
            <li class="nav-item">
              <router-link
                :to="{ name: 'account-overview' }"
                class="nav-link text-active-primary"
                :class="direction == 'ltr' ? 'me-6' : 'ms-6'"
                active-class="active"
              >
                {{$t('account.overview')}}
              </router-link>
            </li>
            <!--end::Nav item-->
            <!--begin::Nav item-->
            <li class="nav-item">
              <router-link
                :to="{ name: 'account-settings' }"
                class="nav-link text-active-primary me-6"
                :class="direction == 'ltr' ? 'me-6' : 'ms-6'"
                active-class="active"
              >
                {{$t('account.settings')}}
              </router-link>
            </li>
            <!--end::Nav item-->
          </ul>
        </div>
        <!--begin::Navs-->
      </div>
    </div>
    <!--end::Navbar-->
    <router-view></router-view>
  </div>
</template>

<script>
//import Dropdown3 from "@/components/dropdown/Dropdown3.vue";
import axiosIns from "@/axios.js";
import VueSkeletonLoader from 'skeleton-loader-vue';

export default {
  name: "kt-account",
  components: {
    //Dropdown3,
    VueSkeletonLoader
  },
  data(){
    return{
      user: {}  
    }
  },
  computed: {
    direction(){
      return this.$store.state.direction;
    }
  },
  methods: {
    getUserInfo(){
      axiosIns.get(`users/${this.$store.state.user.id}`)
      .then((response) => {
        this.user = response.data.data;
        this.$store.commit('setUserDetails', response.data.data);
      })
    }
  },
  mounted(){
    this.getUserInfo();
  }
};
</script>

<style>
/*.skeleton {
  width: 100px;
  height: 100px;
  background-color: #e0e0e0;
  border-radius: 50%;
  position: relative;
  overflow: hidden;
}

.skeleton::before {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background-color: rgba(255, 255, 255, 0.4);
  transform: translate(-50%, -50%) rotate(45deg);
  animation: skeleton-animation 2s ease-in-out infinite;
}

@keyframes skeleton-animation {
  0% {
    transform: translate(-50%, -50%) rotate(45deg);
  }
  50% {
    transform: translate(-50%, -50%) rotate(225deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(405deg);
  }
}*/
.skeleton {
  background-color: #f2f2f2;
  animation: pulse 1s infinite;
}

@keyframes pulse {
  0% {
    opacity: 0.6;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.6;
  }
}
</style>
