<template>
  <div class="card card-xxl-stretch mb-5 mb-xl-10">
    <!--begin::Header-->
    <el-form ref="form" :model="form" :rules="rules" label-width="120px">
      <div class="card-header align-items-center border-0 mt-4">
        <h3 class="card-title align-items-start flex-column">
          <span class="fw-bolder mb-2 text-dark">{{ $t("tours.update") }}</span>
        </h3>

        <div class="card-toolbar">
          <div class="d-flex" style="gap: 10px">
            <button type="button" class="btn btn-primary" @click="onSubmit">
              {{ $t('constants.save') }}
            </button>
            <button type="button" class="btn btn-secondary" @click="back()">{{ $t('constants.cancel') }}</button>
          </div>
        </div>
      </div>
      <div class="card-body py-3">
        <div class="row">
          <div class="form-group col-md-4 col-sm-6 col-xs-12">
            <label class="required fs-6 mb-2">{{ $t("tours.tour_type") }}</label>
            <el-form-item prop="tour_type">
              <vSelect v-model="form.tour_type" placeholder="Select type" :options="filteredTypes"
                :reduce="(type) => type.value" label="name"></vSelect>
            </el-form-item>
          </div>
          <div class="form-group col-md-4 col-sm-6 col-xs-12">
            <label class="required fs-6 mb-2">{{ $t("tours.name_ar") }}</label>
            <el-form-item prop="name_ar">
              <el-input v-model="form.name_ar" :placeholder="$t('tours.name_ar')"></el-input>
            </el-form-item>
          </div>
          <div class="form-group col-md-4 col-sm-6 col-xs-12">
            <label class="required fs-6 mb-2">{{ $t("tours.name_en") }}</label>
            <el-form-item prop="name_en">
              <el-input v-model="form.name_en" :placeholder="$t('tours.name_en')"></el-input>
            </el-form-item>
          </div>
        </div>
        <div class="row" v-if="form.tour_type == 5 || form.tour_type == 6 || form.tour_type == 1">
          <div class="form-group col-md-8 col-sm-12 col-xs-12">
            <label class="required fs-6 mb-2">{{
              $t("tours.event_duration")
            }}</label>
            <div class="row">
              <div class="form-group col">
                <el-form-item prop="duration_hours">
                  <el-input type="number" v-model="form.duration_hours" min="0">
                    <template slot="append">Hours</template>
                  </el-input>
                </el-form-item>
              </div>
              <div class="form-group col">
                <el-form-item prop="duration_minutes">
                  <el-input placeholder="Please input" type="number" v-model="form.duration_minutes" min="0" max="59">
                    <template slot="append">Minutes</template>
                  </el-input>
                </el-form-item>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="form-group col-md-4 col-sm-6 col-xs-12">
            <label class="required fs-6 mb-2">{{
              $t("tours.country_id")
            }}</label>
            <el-form-item prop="country_id">
              <vSelect v-model="form.country_id" placeholder="Select country" :options="countries"
                :reduce="(country) => country.id" :label="direction == 'rtl' ? 'name_ar' : 'name_en'" @input="getCities"></vSelect>
            </el-form-item>
          </div>

          <div class="form-group col-md-4 col-sm-6 col-xs-12">
            <label class="required fs-6 mb-2">{{ $t("tours.city_id") }}</label>
            <el-form-item prop="city_id">
              <!--<el-select
                v-model="form.city_id"
                :placeholder="$t('tours.city_id')"
                class="w-100"
              >
                <el-option v-for="item in cities" :key="item.id" :label="item.name_en" :value="item.id"></el-option>
                
              </el-select>-->
              <vSelect v-model="form.city_id" placeholder="Select city" :options="cities" :reduce="(city) => city.id"
                label="name_en"></vSelect>
            </el-form-item>
          </div>

          <!--<div class="form-group col-md-4 col-sm-6 col-xs-12">
            <label class="required fs-6 mb-2">{{
              $t("tours.category_id")
            }}</label>
            <el-form-item prop="category_id">
              <vSelect
                v-model="form.category_id"
                placeholder="Select category"
                :options="categories"
                :reduce="(category) => category.id"
                label="name_en"
              ></vSelect>
            </el-form-item>
          </div>-->
        </div>

        <div class="row">
          <div class="form-group col-md-4 col-sm-6 col-xs-12" v-if="form.tour_type == 1 || form.type == 3">
            <label class="required fs-6 mb-2">{{ $t("tours.date") }}</label>
            <el-form-item prop="date">
              <date-picker v-model="form.date" range value-type="format" class="w-100"
                :disabled-date="pickerOptions.disabledDate"></date-picker>
            </el-form-item>
          </div>
          <div class="form-group col-md-4 col-sm-6 col-xs-12" v-if="form.type == 3">
            <label class="required fs-6 mb-2">{{
              $t("tours.reservation_end_date")
            }}</label>
            <el-form-item prop="reservation_end_date">
              <date-picker v-model="form.reservation_end_date" value-type="format" class="w-100"
                :disabled-date="pickerOptions.disabledDate"></date-picker>
            </el-form-item>
          </div>
          <div class="form-group col-md-4 col-sm-6 col-xs-12" v-if="form.type !== 3">
            <label class="required fs-6 mb-2">{{ $t("tours.price_per_person") }}</label>
            <el-form-item prop="price">
              <el-input v-model="form.price" :placeholder="$t('tours.price_per_person')" type="number"></el-input>
            </el-form-item>
          </div>

          <div class="form-group col-md-4 col-sm-6 col-xs-12">
            <label class="required fs-6 mb-2">{{
              $t("tours.passengers")
            }}</label>
            <el-form-item prop="passengers">
              <el-input v-model="form.passengers" :placeholder="$t('tours.passengers')" type="number"></el-input>
            </el-form-item>
          </div>
          <div class="form-group col-md-4 col-sm-6 col-xs-12">
            <label class="fs-6 mb-2">{{ $t("tours.pay_on_arrival") }}</label>
            <el-form-item>
              <el-switch v-model="form.pay_on_arrival"></el-switch>
            </el-form-item>
          </div>
        </div>
        <div class="row" v-if="form.type == 3">
          <div class="form-group col-md-4 col-sm-6 col-xs-12">
            <label class="required fs-6 mb-2">{{
              $t("tours.single_room")
            }}</label>
            <el-form-item prop="single_room_price">
              <el-input v-model="form.single_room_price" :placeholder="$t('tours.single_room')" type="number"></el-input>
            </el-form-item>
          </div>
          <div class="form-group col-md-4 col-sm-6 col-xs-12">
            <label class="required fs-6 mb-2">{{
              $t("tours.double_room")
            }}</label>
            <el-form-item prop="double_room_price">
              <el-input v-model="form.double_room_price" :placeholder="$t('tours.double_room')" type="number"></el-input>
            </el-form-item>
          </div>
        </div>
        <div class="row" v-if="form.tour_type == 2">
          <label class="fs-6 mb-2 fw-bold">{{ $t("tours.offers") }}</label>
          <div class="row mb-5">
            <label class="fs-6 mb-2 fw-bold">{{ $t("tours.adults") }}</label>
            <div class="row align-items-center">
              <div class="col-md-5 mt-2 mt-md-0">
                <label class="fs-6 mb-2">{{ $t("tours.num_of_persons") }}</label>
              </div>
              <div class="col-md-5 mt-2 mt-md-0">
                <label class="fs-6 mb-2">{{ $t("tours.discount_percentage") }}</label>
              </div>
            </div>
            <div class="row align-items-start" v-for="(item, index) in form.adults_offers" :key="index">
              <div class="col-md-5 mt-2 mt-md-0">
                <el-form-item :prop="'adults_offers.' + index + '.person_count'" :rules="getValidationRules(index)">
                  <el-input :placeholder="$t('tours.num_of_persons')" v-model="item.person_count" type="number">
                  </el-input>
                </el-form-item>
              </div>
              <div class="col-md-5 mt-2 mt-md-0">
                <el-form-item :prop="'adults_offers.' + index + '.discount_percentage'"
                  :rules="getValidationRules(index)">
                  <el-input :placeholder="$t('tours.discount_percentage')" v-model="item.discount_percentage"
                    type="number">
                  </el-input>
                </el-form-item>
              </div>
              <div class="col-md-2 mt-2 mt-md-0">
                <button type="button" class="btn btn-sm btn-danger" @click="deleteActivity('adults_offers', index)">
                  <span class="svg-icon svg-icon-3">
                    <inline-svg src="/media/icons/duotune/arrows/arr075.svg" />
                  </span>
                  {{ $t("constants.delete") }}
                </button>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-4 mt-3 mt-md-0">
                <button type="button" class="btn btn-sm btn-light-primary" @click="addNew('adults_offers')">
                  <span class="svg-icon svg-icon-3">
                    <inline-svg src="/media/icons/duotune/arrows/arr075.svg" />
                  </span>
                  {{ $t("constants.new") }}
                </button>
              </div>
            </div>
          </div>
          <div class="row mb-5">
            <label class="fs-6 mb-2 fw-bold">{{ $t("tours.childs") }}</label>
            <div class="row align-items-center">
              <div class="col-md-5 mt-2 mt-md-0">
                <label class="fs-6 mb-2">{{ $t("tours.discount_percentage") }}</label>
                <el-form-item prop="discount_percentage">
                  <el-input :placeholder="$t('tours.discount_percentage')" v-model="form.discount_percentage"
                    type="number">
                  </el-input>
                </el-form-item>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="form-group col-md-4 col-sm-6 col-xs-12">
            <label class="required fs-6 mb-2">{{ $t("tours.image") }}</label>
            <el-form-item prop="image">
              <vue-dropzone ref="image" id="dropzone" :options="dropzoneImageOptions" @vdropzone-success="ImageAdded"
                @vdropzone-removed-file="removeImageFile"></vue-dropzone>
            </el-form-item>
          </div>
          <!--<div class="form-group col-md-4 col-sm-6 col-xs-12">
            <label class="fs-6 mb-2">{{ $t("tours.public") }}</label>
            <el-form-item>
              <el-switch v-model="form.public"> </el-switch>
            </el-form-item>
          </div>-->
        </div>
        <div class="row">
          <div class="form-group col-6">
            <label class="fs-6 mb-2">{{ $t("tours.description_ar") }}</label>
            <el-form-item>
              <!--<ckeditor v-model="form.description_ar" :config="editorConfigDescAr"></ckeditor>-->
              <el-input type="textarea" :rows="5" placeholder="Please input" v-model="form.description_ar">
              </el-input>
            </el-form-item>
          </div>
          <div class="form-group col-6">
            <label class="fs-6 mb-2">{{ $t("tours.description_en") }}</label>
            <el-form-item>
              <!--<ckeditor v-model="form.description_en" :config="editorConfigDescEn"></ckeditor>-->
              <el-input type="textarea" :rows="5" placeholder="Please input" v-model="form.description_en">
              </el-input>
            </el-form-item>
          </div>
        </div>
        <div class="row">
          <div class="form-group col-6">
            <label class="fs-6 mb-2">{{
              $t("tours.travel_schedule_en")
            }}</label>
            <el-form-item>
              <el-input type="textarea" :rows="2" placeholder="Please input" v-model="form.travel_schedule_en">
              </el-input>
            </el-form-item>
          </div>
          <div class="form-group col-6">
            <label class="fs-6 mb-2">{{
              $t("tours.travel_schedule_ar")
            }}</label>
            <el-form-item>
              <el-input type="textarea" :rows="2" placeholder="Please input" v-model="form.travel_schedule_ar">
              </el-input>
            </el-form-item>
          </div>
        </div>

        <div class="row">
          <div class="form-group col-6">
            <label class="fs-6 mb-2">{{ $t("tours.general_terms_ar") }}</label>
            <el-form-item>
              <el-input type="textarea" :rows="3" :placeholder="$t('tours.general_terms_ar')"
                v-model="form.general_terms_ar">
              </el-input>
            </el-form-item>
          </div>
          <div class="form-group col-6">
            <label class="required fs-6 mb-2">{{
              $t("tours.general_terms_en")
            }}</label>
            <el-form-item>
              <el-input type="textarea" :rows="3" :placeholder="$t('tours.general_terms_en')"
                v-model="form.general_terms_en">
              </el-input>
            </el-form-item>
          </div>
        </div>
        <hr />
        <div class="row mb-5">
          <label class="fs-6 mb-2">{{ $t("tours.activities") }}</label>
          <div class="row align-items-center mb-5" v-for="(item, index) in form.activities" :key="index">
            <div class="col-md-5 mt-2 mt-md-0">
              <el-form-item :prop="'activities.' + index + '.name_en'" :rules="{
                required: true,
                message: $t('constants.required'),
                trigger: 'blur',
              }">
                <el-input :placeholder="$t('tours.activity_name_en')" v-model="item.name_en">
                </el-input>
              </el-form-item>
            </div>
            <div class="col-md-5 mt-2 mt-md-0">
              <el-form-item :prop="'activities.' + index + '.name_ar'" :rules="{
                required: true,
                message: $t('constants.required'),
                trigger: 'blur',
              }">
                <el-input :placeholder="$t('tours.activity_name_ar')" v-model="item.name_ar">
                </el-input>
              </el-form-item>
            </div>
            <div class="col-md-2 mt-2 mt-md-0">
              <button type="button" class="btn btn-sm btn-danger" @click="deleteActivity('activities', index)">
                <span class="svg-icon svg-icon-3">
                  <inline-svg src="/media/icons/duotune/arrows/arr075.svg" />
                </span>
                {{ $t("constants.delete") }}
              </button>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-4 mt-3 mt-md-0">
              <button type="button" class="btn btn-sm btn-light-primary" @click="addNew('activities')">
                <span class="svg-icon svg-icon-3">
                  <inline-svg src="/media/icons/duotune/arrows/arr075.svg" />
                </span>
                {{ $t("constants.new") }}
              </button>
            </div>
          </div>
        </div>

        <hr />
        <div class="row mb-5">
          <label class="fs-6 mb-2">{{ $t("tours.highlights") }}</label>
          <div class="row align-items-center mb-5" v-for="(item, index) in form.highlights" :key="index">
            <div class="col-md-5 mt-2 mt-md-0">
              <el-form-item :prop="'highlights.' + index + '.name_en'" :rules="{
                required: true,
                message: $t('constants.required'),
                trigger: 'blur',
              }">
                <el-input :placeholder="$t('tours.highlight_name_en')" v-model="item.name_en">
                </el-input>
              </el-form-item>
            </div>
            <div class="col-md-5 mt-2 mt-md-0">
              <el-form-item :prop="'highlights.' + index + '.name_ar'" :rules="{
                required: true,
                message: $t('constants.required'),
                trigger: 'blur',
              }">
                <el-input :placeholder="$t('tours.highlight_name_ar')" v-model="item.name_ar">
                </el-input>
              </el-form-item>
            </div>
            <div class="col-md-2 mt-2 mt-md-0">
              <button type="button" class="btn btn-sm btn-danger" @click="deleteActivity('highlights', index)">
                <span class="svg-icon svg-icon-3">
                  <inline-svg src="/media/icons/duotune/arrows/arr075.svg" />
                </span>
                {{ $t("constants.delete") }}
              </button>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-4 mt-3 mt-md-0">
              <button type="button" class="btn btn-sm btn-light-primary" @click="addNew('highlights')">
                <span class="svg-icon svg-icon-3">
                  <inline-svg src="/media/icons/duotune/arrows/arr075.svg" />
                </span>
                {{ $t("constants.new") }}
              </button>
            </div>
          </div>
        </div>

        <hr />
        <div class="row mb-5">
          <label class="fs-6 mb-2">{{ $t("tours.includes") }}</label>
          <div class="row align-items-center mb-5" v-for="(item, index) in form.includes" :key="index">
            <div class="col-md-5 mt-2 mt-md-0">
              <el-form-item :prop="'includes.' + index + '.name_en'" :rules="{
                required: true,
                message: $t('constants.required'),
                trigger: 'blur',
              }">
                <el-input :placeholder="$t('tours.include_name_en')" v-model="item.name_en">
                </el-input>
              </el-form-item>
            </div>
            <div class="col-md-5 mt-2 mt-md-0">
              <el-form-item :prop="'includes.' + index + '.name_ar'" :rules="{
                required: true,
                message: $t('constants.required'),
                trigger: 'blur',
              }">
                <el-input :placeholder="$t('tours.include_name_ar')" v-model="item.name_ar">
                </el-input>
              </el-form-item>
            </div>
            <div class="col-md-2 mt-2 mt-md-0">
              <button type="button" class="btn btn-sm btn-danger" @click="deleteActivity('includes', index)">
                <span class="svg-icon svg-icon-3">
                  <inline-svg src="/media/icons/duotune/arrows/arr075.svg" />
                </span>
                {{ $t("constants.delete") }}
              </button>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-4 mt-3 mt-md-0">
              <button type="button" class="btn btn-sm btn-light-primary" @click="addNew('includes')">
                <span class="svg-icon svg-icon-3">
                  <inline-svg src="/media/icons/duotune/arrows/arr075.svg" />
                </span>
                {{ $t("constants.new") }}
              </button>
            </div>
          </div>
        </div>

        <hr />
        <div class="row mb-5">
          <label class="fs-6 mb-2">{{ $t("tours.excludes") }}</label>
          <div class="row align-items-center mb-5" v-for="(item, index) in form.excludes" :key="index">
            <div class="col-md-5 mt-2 mt-md-0">
              <el-form-item :prop="'excludes.' + index + '.name_en'" :rules="{
                required: true,
                message: $t('constants.required'),
                trigger: 'blur',
              }">
                <el-input :placeholder="$t('tours.exclude_name_en')" v-model="item.name_en">
                </el-input>
              </el-form-item>
            </div>
            <div class="col-md-5 mt-2 mt-md-0">
              <el-form-item :prop="'excludes.' + index + '.name_ar'" :rules="{
                required: true,
                message: $t('constants.required'),
                trigger: 'blur',
              }">
                <el-input :placeholder="$t('tours.exclude_name_ar')" v-model="item.name_ar">
                </el-input>
              </el-form-item>
            </div>
            <div class="col-md-2 mt-2 mt-md-0">
              <button type="button" class="btn btn-sm btn-danger" @click="deleteActivity('excludes', index)">
                <span class="svg-icon svg-icon-3">
                  <inline-svg src="/media/icons/duotune/arrows/arr075.svg" />
                </span>
                {{ $t("constants.delete") }}
              </button>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-4 mt-3 mt-md-0">
              <button type="button" class="btn btn-sm btn-light-primary" @click="addNew('excludes')">
                <span class="svg-icon svg-icon-3">
                  <inline-svg src="/media/icons/duotune/arrows/arr075.svg" />
                </span>
                {{ $t("constants.new") }}
              </button>
            </div>
          </div>
        </div>
        <hr />
        <div class="row">
          <div class="form-group col">
            <label class="fs-6 mb-2">{{ $t("tours.images") }}</label>
            <el-form-item>
              <vue-dropzone ref="images" id="dropzone" :options="dropzoneImagesOptions" @vdropzone-success="ImagesAdded"
                @vdropzone-removed-file="removeImagesFile"></vue-dropzone>
            </el-form-item>
          </div>
        </div>
        <div class="row">
          <div class="form-group col">
            <label class="fs-6 mb-2">{{ $t("tours.slider_images") }}</label>
            <el-form-item>
              <vue-dropzone ref="slider_images" id="dropzone" :options="dropzoneSliderImagesOptions"
                @vdropzone-success="SliderImagesAdded" @vdropzone-removed-file="removeSliderImageFile"></vue-dropzone>
            </el-form-item>
          </div>
        </div>
        <hr />
        <div class="row align-items-center">
          <label class="fs-6 mb-2">{{ $t("tours.GPS") }}</label>

          <div class="form-group col-6">
            <label class="fs-6 mb-2">{{ $t("tours.coordinates") }}</label>
            <el-form-item>
              <el-input v-model="form.coordinates" prefix-icon="el-icon-location" disabled>
              </el-input>
            </el-form-item>
          </div>
          <div class="form-group col-6">
            <button type="button" class="btn btn-sm btn-light-primary" @click="getCurrentLocation()">
              <span class="svg-icon svg-icon-3">
                <inline-svg src="/media/icons/duotune/arrows/arr075.svg" />
              </span>
              {{ $t("tours.currentLocation") }}
            </button>
          </div>
        </div>

        <div class="row">
          <el-form-item>
            <GmapMap :center="coord" :zoom="7" map-type-id="terrain" style="width: 100%; height: 450px" ref="mapRef">
              <GmapMarker ref="myMarker" :position="coord" :draggable="true" @dragend="updateCoordinates" />
            </GmapMap>
          </el-form-item>
        </div>

        <div class="row mt-5">
          <div class="form-group col text-center">
            <div class="d-flex justify-content-center" style="gap: 10px">
              <button type="button" class="btn btn-primary" @click="onSubmit">
                {{ $t('constants.save') }}
              </button>
              <button type="button" class="btn btn-secondary" @click="back()">{{ $t('constants.cancel') }}</button>
            </div>
          </div>
        </div>
      </div>
    </el-form>
  </div>
</template>

<style>
.row>* {
  /*width: auto !important;*/
}

.el-form-item__content {
  margin-left: 0 !important;
}

.mx-input {
  height: 40px !important;
}

.vs--searchable .vs__dropdown-toggle {
  height: 40px;
}

.el-form-item.is-error .el-input__inner,
.el-form-item.is-error .el-input__inner:focus,
.el-form-item.is-error .el-textarea__inner,
.el-form-item.is-error .el-textarea__inner:focus {
  border-color: #dcdfe6 !important;
}
</style>

<script>
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
//import axios from "axios";
import axiosIns from "@/axios.js";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import Cookies from "js-cookie";
export default {
  name: "edit-tour",
  components: {
    vueDropzone: vue2Dropzone,
    DatePicker,
    vSelect,
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    filteredTypes() {
      var fileterd_types = [];
      if (this.$store.state.user.type == 3) {
        fileterd_types = this.types.filter(({ value }) => {
          return value == 3 || value == 4;
        })
        return fileterd_types;
      } else if (this.$store.state.user.type == 4) {
        fileterd_types = this.types.filter(({ value }) => {
          return value == 6;
        })
        return fileterd_types;
      }
      else {
        fileterd_types = this.types.filter(({ value }) => {
          return value != 3 && value != 4;
        });
        return fileterd_types;
      }
    },
    direction(){
      return this.$store.state.direction;
    }
  },
  data() {
    return {
      form: {
        name_ar: "",
        name_en: "",
        company_id: "",
        driver_id: "",
        celebrity_id: "",
        country_id: "",
        city_id: "",
        category_id: "",
        date: [],
        reservation_end_date: "",
        price: "",
        passengers: "",
        tour_type: "",
        type: this.$store.state.user.type,
        description_en: "",
        description_ar: "",
        travel_schedule_en: "",
        travel_schedule_ar: "",
        general_terms_ar: "",
        general_terms_en: "",
        coordinates: "",
        image: "",
        single_room_price: "",
        double_room_price: "",
        duration_hours: "",
        duration_minutes: "",
        pay_on_arrival: false,
        discount_percentage: "",
        adults_offers: [
          {
            person_count: "",
            discount_percentage: ""
          }
        ],
        activities: [
          {
            name_ar: "",
            name_en: "",
          },
        ],
        highlights: [
          {
            name_ar: "",
            name_en: "",
          },
        ],
        includes: [
          {
            name_ar: "",
            name_en: "",
          },
        ],
        excludes: [
          {
            name_ar: "",
            name_en: "",
          },
        ],
      },
      coord: {
        lat: 21.48436249892535,
        lng: 39.1924169425293,
      },
      images: [],
      slider_images: [],
      countries: [],
      cities: [],
      categories: [],
      types: [
        {
          name: this.$t("tours.group_tour"),
          value: 1,
        },
        {
          name: this.$t("tours.private_tour"),
          value: 2,
        },
        {
          name: this.$t("tours.family_tour"),
          value: 3,
        },
        {
          name: this.$t("tours.youth_tour"),
          value: 4,
        },
        {
          name: this.$t("tours.guided_tour"),
          value: 5,
        },
        {
          name: this.$t("tours.daily_trip_tour"),
          value: 6,
        },
      ],
      pickerOptions: {
        disabledDate(time) {
          return time <= new Date(Date.now() - 8640000);
        },
      },
      dropzoneImageOptions: {
        url: process.env.NODE_ENV == "production" ? "https://api.dgotours.com/api/tours/images/upload" : process.env.NODE_ENV == "development" ? "http://localhost:8000/api/tours/images/upload" : "",
        thumbnailWidth: 150,
        //maxFilesize: 0.5,
        headers: {
          Authorization: Cookies.get("access_token"),
        },
        maxFiles: 1,
        autoProcessQueue: false,
        //autoQueue: false,
        //chunking: true,
        addRemoveLinks: true,
        timeout: 600000
      },
      dropzoneImagesOptions: {
        url: process.env.NODE_ENV == "production" ? "https://api.dgotours.com/api/tours/images/upload" : process.env.NODE_ENV == "development" ? "http://localhost:8000/api/tours/images/upload" : "",
        thumbnailWidth: 150,
        headers: {
          Authorization: Cookies.get("access_token"),
        },
        autoProcessQueue: false,
        //maxFilesize: 0.5,
        //maxFiles: 1,
        addRemoveLinks: true,
      },
      dropzoneSliderImagesOptions: {
        url: process.env.NODE_ENV == "production" ? "https://api.dgotours.com/api/tours/images/upload" : process.env.NODE_ENV == "development" ? "http://localhost:8000/api/tours/images/upload" : "",
        thumbnailWidth: 150,
        autoProcessQueue: false,
        headers: {
          Authorization: Cookies.get("access_token"),
        },
        //maxFilesize: 0.5,
        //maxFiles: 1,
        addRemoveLinks: true,
      },
      editorConfigDescAr: {
        //language: "ar",
        //autoParagraph: false
      },
      editorConfigDescEn: {
        //language: "ar"
        //autoParagraph: false
      },
      rules: {
        tour_type: [
          {
            required: true,
            message: this.$t('constants.required'),
            trigger: "blur",
          },
        ],
        name_en: [
          {
            required: true,
            message: this.$t('constants.required'),
            trigger: "blur",
          },
        ],
        name_ar: [
          {
            required: true,
            message: this.$t('constants.required'),
            trigger: "blur",
          },
        ],
        country_id: [
          {
            required: true,
            message: this.$t('constants.required'),
            trigger: "blur",
          },
        ],
        city_id: [
          {
            required: true,
            message: this.$t('constants.required'),
            trigger: "blur",
          },
        ],
        /*category_id: [
          {
            required: true,
            message: "this field is required",
            trigger: "blur",
          },
        ],*/
        date: [
          {
            required: true,
            message: this.$t('constants.required'),
            trigger: "blur",
          },
        ],
        price: [
          {
            required: true,
            message: this.$t('constants.required'),
            trigger: "blur",
          },
        ],
        reservation_end_date: [
          {
            required: true,
            message: this.$t('constants.required'),
            trigger: "blur",
          },
        ],
      },
      deleteFiles: true,
      selected_removed_slider_images: [],
      selected_removed_images: [],
      selected_removed_image: ""
    };
  },
  methods: {
    getValidationRules(index) {
      return [
        {
          required: this.isFieldFilled(index),
          message: this.$t('constants.required'),
          trigger: 'blur'
        }
      ];
    },
    isFieldFilled(index) {
      const item = this.form.adults_offers[index];
      return item.person_count.trim() !== '' || item.discount_percentage.trim() !== '';
    },
    getTour() {
      axiosIns.get(`tours/${this.$route.params.id}/edit`).then((response) => {
        this.form.tour_type = response.data.data.tour_type;
        this.form.name_ar = response.data.data.name_ar;
        this.form.name_en = response.data.data.name_en;
        this.form.type = response.data.data.type;
        this.form.duration_hours = response.data.data.duration_hours;
        this.form.duration_minutes = response.data.data.duration_minutes;

        if (response.data.data.company_id) {
          this.form.company_id = response.data.data.company_id;
        }
        if (response.data.data.driver_id) {
          this.form.driver_id = response.data.data.driver_id;
        }
        this.form.country_id = response.data.data.country_id;
        this.getCities().then((res) => {
          this.form.city_id = response.data.data.city_id;
        });

        this.form.category_id = response.data.data.category_id;
        //date: ,
        if (response.data.data.start != null && response.data.data.end != null) {
          this.form.date.push(response.data.data.start);
          this.form.date.push(response.data.data.end);
        } else {
          this.form.date = "";
        }

        if (response.data.data.type == 3) {
          this.form.single_room_price = response.data.data.single_room_price;
          this.form.double_room_price = response.data.data.double_room_price;
          this.form.reservation_end_date =
            response.data.data.reservation_end_date;
        }
        this.form.price = response.data.data.price;
        this.form.passengers = response.data.data.passengers;
        this.form.pay_on_arrival = response.data.data.pay_on_arrival;
        this.form.public = response.data.data.public;
        this.form.description_en = response.data.data.description_en;
        this.form.description_ar = response.data.data.description_ar;
        this.form.travel_schedule_en = response.data.data.travel_schedule_en;
        this.form.travel_schedule_ar = response.data.data.travel_schedule_ar;
        this.form.general_terms_ar = response.data.data.general_terms_ar;
        this.form.general_terms_en = response.data.data.general_terms_en;
        this.form.coordinates =
          response.data.data.lat + ", " + response.data.data.lng;

        if (response.data.data.lat && response.data.data.lng) {
          this.coord.lat = response.data.data.lat;
          this.coord.lng = response.data.data.lng;
        }
        if (response.data.data.image != "") {
          var mockFile = { size: 123, type: "image/png,jpg,jpeg" };
          this.$refs.image.manuallyAddFile(mockFile, response.data.data.image);
          let url = new URL(response.data.data.image);
          this.form.image = url.pathname.substring(1);
        }

        // activities
        if (response.data.data.activities.length > 0) {
          this.form.activities = response.data.data.activities;
        }

        // highlight
        if (response.data.data.highlights.length > 0) {
          this.form.highlights = response.data.data.highlights;
        }

        // includes
        if (response.data.data.includes.length > 0) {
          this.form.includes = response.data.data.includes;
        }

        // excludes
        if (response.data.data.excludes.length > 0) {
          this.form.excludes = response.data.data.excludes;
        }

        // adults offers
        if (response.data.data.adults_offers !== undefined && response.data.data.adults_offers.length > 0) {
          this.form.adults_offers = response.data.data.adults_offers;
        }
        // childs offers
        if (response.data.data.discount_percentage !== undefined) {
          this.form.discount_percentage = response.data.data.discount_percentage[0];
        }

        // images
        if (response.data.data.images) {
          //this.images = response.data.data.
          response.data.data.images.forEach((item) => {
            var mockFile = {
              size: 123,
              name: item.name,
              type: "image/png,jpg,jpeg",
            };
            this.$refs.images.manuallyAddFile(mockFile, item.path);
            let url = new URL(item.path);
            this.images.push({
              name: item.name,
              path: url.pathname.substring(1), // remove first '/' from url using substr
            });
          });
        }

        if (response.data.data.slider_images) {
          response.data.data.slider_images.forEach((item) => {
            var mockFile = {
              size: 123,
              name: item.name,
              type: "image/png,jpg,jpeg",
            };
            this.$refs.slider_images.manuallyAddFile(mockFile, item.path);
            let url = new URL(item.path);
            this.slider_images.push({
              name: item.name,
              path: url.pathname.substring(1), // remove first '/' from url using substr
            });
          });
        }
      });
    },
    getFileCount(file) {
      console.log(file);
    },
    ImageAdded(file, response) {
      this.form.image = response.path;
    },
    ImagesAdded(file, response) {
      this.images.push({
        name: response.name,
        path: response.path,
      });
    },
    SliderImagesAdded(file, response) {
      this.slider_images.push({
        name: response.name,
        path: response.path,
      });
    },
    removeImageFile(file) {
      if (this.deleteFiles) {
        this.selected_removed_image = this.form.image;
        /*this.removeUploadedImage(this.form.image).then(
          () => (this.form.image = "")
        );*/
      }
    },
    removeImagesFile(file) {
      if (this.deleteFiles) {
        const selectedFile = this.images.filter((item) => {
          return item.name == file.name;
        });

        this.selected_removed_images.push(selectedFile[0].path);
        /*this.removeUploadedImage(selectedFile[0].path).then(() => {
          const indexOfObject = this.images.findIndex((object) => {
            return object.name == file.name;
          });
          this.images.splice(indexOfObject, 1);
        });*/
      }
    },
    removeSliderImageFile(file) {
      if (this.deleteFiles) {
        const selectedFile = this.slider_images.filter((item) => {
          return item.name == file.name;
        });
        this.selected_removed_slider_images.push(selectedFile[0].path);

        /*this.removeUploadedImage(selectedFile[0].path).then(() => {
          const indexOfObject = this.slider_images.findIndex((object) => {
            return object.name == file.name;
          });
          this.slider_images.splice(indexOfObject, 1);
        });*/
      }
    },
    removeUploadedImage(path) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`tours/images/delete`, {
            path: path,
          })
          .then((response) => {
            resolve(response);
          });
      });
    },
    updateCoordinates(location) {
      this.form.coordinates =
        location.latLng.lat() + ", " + location.latLng.lng();
      this.coord.lat = location.latLng.lat();
      this.coord.lng = location.latLng.lng();
    },
    onSubmit() {
      //console.log(this.$refs.images.getAcceptedFiles().length);
      //console.log(this.$refs.image.dropzone.files.length,this.$refs.images.dropzone.files.length,this.$refs.slider_images.dropzone.files.length)
      //return;

      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.$swal(
            {
              showConfirmButton: false,
              allowOutsideClick: false, // disable clicking outside the modal
              backdrop: true, // enable backdrop to prevent closing on outside click
              didOpen: () => {
                this.$swal.showLoading();
              },
            }
          );

          const uploadPromises = [];

          if (this.$refs.image.getAcceptedFiles().length > 0) {
            uploadPromises.push(new Promise((resolve, reject) => {
              this.$refs.image.processQueue();
              this.$refs.image.dropzone.on('queuecomplete', () => {
                resolve();
              });
            }));
          }

          if (this.$refs.images.getAcceptedFiles().length > 0) {
            uploadPromises.push(new Promise((resolve, reject) => {
              this.$refs.images.processQueue();
              this.$refs.images.dropzone.on('queuecomplete', () => {
                resolve();
              });
            }));
          }

          if (this.$refs.slider_images.getAcceptedFiles().length > 0) {
            uploadPromises.push(new Promise((resolve, reject) => {
              this.$refs.slider_images.processQueue();
              this.$refs.slider_images.dropzone.on('queuecomplete', () => {
                resolve();
              });
            }));
          }

          // Additional logic for removing uploaded images
          if (this.selected_removed_image !== '') {

            uploadPromises.push(this.removeUploadedImage(this.selected_removed_image).then(() => {
              this.form.image = ""
            }));

          }

          if (this.selected_removed_images.length > 0) {
            this.selected_removed_images.forEach(element => {
              uploadPromises.push(this.removeUploadedImage(element).then(() => {
                const indexOfObject = this.images.findIndex((object) => {
                  return object.path == element;
                });
                this.images.splice(indexOfObject, 1);
              }));
            });
          }

          if (this.selected_removed_slider_images.length > 0) {
            this.selected_removed_slider_images.forEach(element => {
              uploadPromises.push(this.removeUploadedImage(element).then(() => {
                const indexOfObject = this.slider_images.findIndex((object) => {
                  return object.path == element;
                });
                this.slider_images.splice(indexOfObject, 1);
              }));
            });
          }

          Promise.all(uploadPromises).then(() => {
            const formData = new FormData();
            for (const key in this.form) {
              if (this.form[key]) {
                formData.append(key, this.form[key]);
              }
            }
            if (formData.has("pay_on_arrival")) {
              formData.delete("pay_on_arrival");
            }
            if (this.form.pay_on_arrival == false) {
              formData.append("pay_on_arrival", 0);
            } else if (this.form.pay_on_arrival == true) {
              formData.append("pay_on_arrival", 1);
            }

            formData.set('adults_offers', JSON.stringify(this.form.adults_offers));

            formData.delete('activities');
            if (
              this.form.activities.length > 0 &&
              this.form.activities[0].name_ar != "" &&
              this.form.activities[0].name_en != ""
            ) {
              formData.append("activities", JSON.stringify(this.form.activities));
            }

            formData.delete('highlights');
            if (
              this.form.highlights.length > 0 &&
              this.form.highlights[0].name_ar != "" &&
              this.form.highlights[0].name_en != ""
            ) {
              formData.append("highlights", JSON.stringify(this.form.highlights));
            }

            formData.delete('includes');
            if (
              this.form.includes.length > 0 &&
              this.form.includes[0].name_ar != "" &&
              this.form.includes[0].name_en != ""
            ) {
              formData.append("includes", JSON.stringify(this.form.includes));
            }

            formData.delete('excludes');
            if (
              this.form.excludes.length > 0 &&
              this.form.excludes[0].name_ar != "" &&
              this.form.excludes[0].name_en != ""
            ) {
              formData.append("excludes", JSON.stringify(this.form.excludes));
            }

            if (this.images.length > 0) {
              formData.append("images", JSON.stringify(this.images));
            }
            if (this.slider_images.length > 0) {
              formData.append(
                "slider_images",
                JSON.stringify(this.slider_images)
              );
            }

            axiosIns
              .post(`tours/${this.$route.params.id}?_method=put`, formData, {
                headers: { "content-type": "multipart/form-data" },
              })
              .then((response) => {
                this.$swal.close();
                this.$notify({
                  title: "مرحبا",
                  type: "success",
                  message: response.data,
                  showClose: true,
                  position: "top-left",
                });
                setTimeout(() => {
                  this.$router.push({ name: "tours" });
                }, 500);
              })
              .catch((error) => {
                this.$swal.close();
                this.$swal.fire({
                  icon: 'error',
                  title: 'Oops...',
                  text: 'Something went wrong!',
                })
              });
          });

        } else {
          let animationFrameId;

          const animateScroll = () => {
            const errorElements = document.querySelectorAll('.el-form-item__error');
            if (errorElements.length > 0) {
              const firstErrorParent = errorElements[0].closest('.row');
              if (firstErrorParent) {
                firstErrorParent.scrollIntoView({ behavior: 'smooth' });
              }
            } else {
              animationFrameId = requestAnimationFrame(animateScroll);
            }
          };

          animationFrameId = requestAnimationFrame(animateScroll);
        }
      });
    },
    getCurrentLocation() {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          this.form.coordinates =
            position.coords.latitude + ", " + position.coords.longitude;
          this.coord.lat = position.coords.latitude;
          this.coord.lng = position.coords.longitude;
        },
        (error) => {
          console.log(error.message);
        }
      );
    },
    addNew(array_data) {
      const activity = {
        name_ar: "",
        name_en: "",
      };
      const highlight = {
        name_ar: "",
        name_en: "",
      };
      const include = {
        name_ar: "",
        name_en: "",
      };
      const exclude = {
        name_ar: "",
        name_en: "",
      };
      const adult_offer = {
        person_count: "",
        price: ""
      };
      const child_offer = {
        child_count: "",
        price: ""
      }
      this.form[array_data].push(
        array_data == "activities"
          ? activity
          : array_data == "highlights"
            ? highlight
            : array_data == "includes"
              ? include
              : array_data == "excludes"
                ? exclude
                : array_data == "adults_offers"
                  ? adult_offer
                  : array_data == "childs_offers"
                    ? child_offer
                    : ""
      );
    },
    deleteActivity(array_data, index) {
      if (this.form[array_data].length > 1) {
        this.form[array_data].splice(index, 1);
      }
    },
    getAllCountries() {
      axiosIns.get("countries").then((response) => {
        this.countries = response.data.countries;
      });
    },
    getCities() {
      this.form.city_id = "";
      return new Promise((resolve, reject) => {
        axiosIns.get("cities/" + this.form.country_id).then((response) => {
          this.cities = response.data.cities;
          resolve(response);
        });
        //this.cities = response.data.cities;
      });
    },
    getCategories() {
      axiosIns.get("categories").then((response) => {
        this.categories = response.data.categories;
      });
    },
    back() {
      this.$router.go(-1);
    }
  },
  mounted() {
    this.getAllCountries();
    this.getCategories();
    this.getTour();
    this.$store.commit("scrollToTop");
  },
  beforeDestroy() {
    this.deleteFiles = false;
  },
};
</script>
