<template>
  <div>
    <!--begin::details View-->
    <div class="card mb-5 mb-xl-10" id="kt_profile_details_view">
      <!--begin::Card header-->
      <div class="card-header cursor-pointer">
        <!--begin::Card title-->
        <div class="card-title m-0">
          <h3 class="fw-bolder m-0">{{ $t('account.profile_details') }}</h3>
        </div>
        <!--end::Card title-->

        <!--begin::Action-->
        <router-link
          :to="{ name: 'account-settings' }"
          class="btn btn-primary align-self-center"
          >{{ $t('account.edit_profile') }}</router-link
        >
        <!--end::Action-->
      </div>
      <!--begin::Card header-->

      <!--begin::Card body-->
      <div class="card-body p-9">
        <!--begin::Row-->
        <div class="row mb-7">
          <!--begin::Label-->
          <label class="col-lg-4 fw-bold text-muted">{{ $t('account.full_name') }}</label>
          <!--end::Label-->

          <!--begin::Col-->
          <div class="col-lg-8">
            <span class="fw-bolder fs-6 text-dark">{{ user.name }}</span>
          </div>
          <!--end::Col-->
        </div>
        <!--end::Row-->

        <div class="row mb-7">
          <!--begin::Label-->
          <label class="col-lg-4 fw-bold text-muted">
            {{$t('account.email')}}
            <i
              class="fas fa-exclamation-circle fs-7"
              :class="direction == 'ltr' ? 'me-1' : 'me-1'"
              data-bs-toggle="tooltip"
              title="Phone number must be active"
            ></i>
          </label>
          <!--end::Label-->

          <!--begin::Col-->
          <div class="col-lg-8 d-flex align-items-center">
            <span class="fw-bolder fs-6" :class="direction == 'ltr' ? 'me-2' : 'ms-2'">{{ user.email }}</span>

            <span class="badge badge-success" v-if="user.email && $store.getters.emailIsVerified">{{ $t('account.verified') }}</span>
            <span class="badge badge-warning" v-if="user.email && !$store.getters.emailIsVerified">{{ $t('account.not_verified') }}</span>
          </div>
          <!--end::Col-->
        </div>

        <!--begin::Input group-->
        <div class="row mb-7">
          <!--begin::Label-->
          <label class="col-lg-4 fw-bold text-muted">{{$t('account.company')}}</label>
          <!--end::Label-->

          <!--begin::Col-->
          <div class="col-lg-8 fv-row">
            <span class="fw-bold fs-6">{{user.company_name}}</span>
          </div>
          <!--end::Col-->
        </div>
        <!--end::Input group-->

        <!--begin::Input group-->
        <div class="row mb-7">
          <!--begin::Label-->
          <label class="col-lg-4 fw-bold text-muted">
            {{ $t('account.contact_phone') }}
            <i
              class="fas fa-exclamation-circle ms-1 fs-7"
              data-bs-toggle="tooltip"
              title="Phone number must be active"
            ></i>
          </label>
          <!--end::Label-->

          <!--begin::Col-->
          <div class="col-lg-8 d-flex align-items-center">
            <span class="fw-bolder fs-6" :class="direction == 'ltr' ? 'me-2' : 'ms-2'">{{ user.mobile }}</span>

            <!--<span class="badge badge-success">Verified</span> -->
          </div>
          <!--end::Col-->
        </div>
        <!--end::Input group-->

        <!--begin::Input group-->
        <div class="row mb-7">
          <!--begin::Label-->
          <label class="col-lg-4 fw-bold text-muted">{{$t('account.company_site')}}</label>
          <!--end::Label-->

          <!--begin::Col-->
          <div class="col-lg-8">
            <a href="#" class="fw-bold fs-6 text-dark text-hover-primary"
              >dgo.com</a
            >
          </div>
          <!--end::Col-->
        </div>
        <!--end::Input group-->

        <!--begin::Input group-->
        <div class="row mb-7">
          <!--begin::Label-->
          <label class="col-lg-4 fw-bold text-muted">
            {{$t('account.country')}}
            <i
              class="fas fa-exclamation-circle fs-7"
              :class="direction == 'ltr' ? 'ms-1' : 'me-1'"
              data-bs-toggle="tooltip"
              title="Country of origination"
            ></i>
          </label>
          <!--end::Label-->

          <!--begin::Col-->
          <div class="col-lg-8">
            <span class="fw-bolder fs-6 text-dark">{{user.country}}</span>
          </div>
          <!--end::Col-->
        </div>
        <!--end::Input group-->
        <div class="row mb-7">
          <!--begin::Label-->
          <label class="col-lg-4 fw-bold text-muted">
            {{$t('account.city')}}
            <i
              class="fas fa-exclamation-circle fs-7"
              :class="direction == 'ltr' ? 'ms-1' : 'me-1'"
              data-bs-toggle="tooltip"
              title="Country of origination"
            ></i>
          </label>
          <!--end::Label-->

          <!--begin::Col-->
          <div class="col-lg-8">
            <span class="fw-bolder fs-6 text-dark">{{user.city}}</span>
          </div>
          <!--end::Col-->
        </div>
        <!--end::Input group-->

        <!--begin::Input group-->
        <!--<div class="row mb-7">
          <!- -begin::Label- ->
          <label class="col-lg-4 fw-bold text-muted">Communication</label>
          <!- -end::Label- ->

          <!- -begin::Col- ->
          <div class="col-lg-8">
            <span class="fw-bolder fs-6 text-dark">Email, Phone</span>
          </div>
          <!- -end::Col- ->
        </div>-->
        <!--end::Input group-->

        <!--begin::Input group-->
        <!--<div class="row mb-10">
          <!- -begin::Label- ->
          <label class="col-lg-4 fw-bold text-muted">Allow Changes</label>
          <!- -begin::Label- ->

          <!- -begin::Label- ->
          <div class="col-lg-8">
            <span class="fw-bold fs-6">Yes</span>
          </div>
          <!- -begin::Label- ->
        </div>-->
        <!--end::Input group-->

        <!--<div
          class="notice d-flex bg-light-warning rounded border-warning border border-dashed p-6"
        >
          <span class="svg-icon svg-icon-2tx svg-icon-warning me-4">
            <inline-svg src="/media/icons/duotune/general/gen044.svg" />
          </span>
          <!- -begin::Wrapper- ->
          <div class="d-flex flex-stack flex-grow-1">
            <!- -begin::Content- ->
            <div class="fw-bold">
              <h4 class="text-gray-800 fw-bolder">We need your attention!</h4>

              <div class="fs-6 text-gray-600">
                Your payment was declined. To start using tools, please
                <a class="fw-bolder" href="#">Add Payment Method</a>.
              </div>
            </div>
            <!- -end::Content- ->
          </div>
          <!- -end::Wrapper- ->
        </div>-->
      </div>
      <!--end::Card body-->
    </div>
    <!--end::details View-->

    <!--begin::Row-->
    <div class="row gy-10 gx-xl-10">
      <!--begin::Col-->
      <div class="col-xl-6">
        <!--<KTChartWidget1
          widget-classes="card-xxl-stretch mb-5 mb-xl-10"
        ></KTChartWidget1>-->
      </div>
      <!--end::Col-->

      <!--begin::Col-->
      <div class="col-xl-6">
        <!--<KTListWidget1
          widget-classes="card-xxl-stretch mb-5 mb-xl-10'"
        ></KTListWidget1>-->
      </div>
      <!--end::Col-->
    </div>
    <!--end::Row-->

    <!--begin::Row-->
    <div class="row gy-10 gx-xl-10">
      <!--begin::Col-->
      <div class="col-xl-6">
        <!--<KTListWidget5
          widget-classes="card-xxl-stretch mb-5 mb-xl-10"
        ></KTListWidget5>-->
      </div>
      <!--end::Col-->

      <!--begin::Col-->
      <div class="col-xl-6">
        <!--<KTTableWidget5
          widget-classes="card-xxl-stretch mb-5 mb-xl-10"
        ></KTTableWidget5>-->
      </div>
      <!--end::Col-->
    </div>
    <!--end::Row-->
  </div>
</template>

<script>
import axiosIns from "@/axios.js";
  
export default {
  name: "account-overview",
  data() {
    return {
      //user: {}
    };
  },
  computed: {
    direction(){
      return this.$store.state.direction;
    },
    user(){
      return this.$store.state.userDetails;
    }
  },
  methods: {
    /*getUserInfo(){
      axiosIns.get(`users/${this.$store.state.user.id}`)
      .then((response) => {
        this.user = response.data.data;
      })
    }*/
  },
  mounted(){
    //this.getUserInfo();
  }
};
</script>
