<template>
    <div class="card">
      <div class="card-header border-0 pt-6">
        <!--begin::Card title-->
        <div class="card-title">
          <!--begin::Search-->
          <div class="d-flex align-items-center position-relative my-1">
            <span class="svg-icon svg-icon-1 position-absolute" 
            :class="direction == 'ltr' ? 'ms-6': 'me-6'">
              <svg
                fill="none"
                viewBox="0 0 24 24"
                height="24"
                width="24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  xmlns="http://www.w3.org/2000/svg"
                  opacity="0.5"
                  x="17.0365"
                  y="15.1223"
                  width="8.15546"
                  height="2"
                  rx="1"
                  transform="rotate(45 17.0365 15.1223)"
                  fill="black"
                ></rect>
                <path
                  xmlns="http://www.w3.org/2000/svg"
                  d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                  fill="black"
                ></path>
              </svg>
            </span>
            <input
              type="text"
              v-model="search"
              class="form-control form-control-solid w-250px ps-15 table-search-input"
              :class="direction == 'ltr' ? 'ps-15': 'pe-15'"
              :placeholder="$t('airports_pickup.search_airports_pickup')"
            />
          </div>
          <!--end::Search-->
        </div>
      </div>
      <div class="card-body pt-0">
        <Datatable
          :table-header="tableHeader"
          :enable-items-per-page-dropdown="true"
          :url="url"
          :searchTermProp="search"
          ref="datatable"
        >
          <template v-slot:cell-checkbox="{ row: item }">
            <div
              class="form-check form-check-sm form-check-custom form-check-solid"
            >
              <input
                class="form-check-input"
                type="checkbox"
                :value="item.id"
                v-model="checkedUsers"
              />
            </div>
          </template>
          
          <template v-slot:cell-name="{ row: item }">
            {{ item.name }}
          </template>
          <template v-slot:cell-city="{ row: item }">
            {{ item.city }}
          </template>
          <template v-slot:cell-price="{ row: item }">
            {{ item.price }}
          </template>
          
          <template v-slot:cell-created_at="{ row: item }">
            {{ item.created_at }}
          </template>
          <template v-slot:cell-actions="{ row: item }">
            <div>
              <div
                class="btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px"
                id="kt_activities_toggle"
                data-bs-toggle="modal"
                data-bs-target="#modal_new_airport_pickup"
                @click="editProduct(item)"
              >
                <span class="svg-icon svg-icon-1">
                  <inline-svg src="/media/icons/duotune/general/gen055.svg" />
                </span>
              </div>
              <div
                class="btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px"
                id="kt_activities_toggle"
                @click="deleteItem(item.id)"
              >
                <span class="svg-icon svg-icon-1">
                  <inline-svg src="/media/icons/duotune/general/gen027.svg" />
                </span>
              </div>
            </div>
          </template>
        </Datatable>
      </div>
      <NewAirportPickup
        @success="loadItems()"
        :item="selectedItem"
        :mode="mode"
      ></NewAirportPickup>
    </div>
  </template>
  
  <script>
  import Datatable from "@/components/kt-datatable/Datatable.vue";
  import NewAirportPickup from "../modals/NewAirportPickup.vue";
  import axiosIns from "@/axios.js";
  
  export default {
    name: 'AirportsPickupTable',
    components: {
      Datatable,
      NewAirportPickup,
    },
    data() {
      return {
        url: "airports-pickup",
        checkedUsers: [],
        search: "",
        selectedItem: {},
        mode: "",
        tableHeader: [
          {
            key: "checkbox",
          },
          {
            name: this.$t("airports_pickup.airport_name"),
            key: "name",
            sortable: true,
          },
          {
            name: this.$t("airports_pickup.airport_city"),
            key: "city",
            sortable: true,
          },
          
          {
            name: this.$t("airports_pickup.price"),
            key: "price",
            sortable: true,
          },
          {
            name: this.$t('airports_pickup.created_at'),
            key: "created_at",
            sortable: true,
          },
          {
            name: this.$t('constants.actions'),
            key: "actions",
          },
        ],
      };
    },
    computed: {
      direction(){
        return this.$store.state.direction;
      }
    },
    methods: {
      loadItems() {
        this.$refs.datatable.loadItems();
      },
      editProduct(item) {
        this.selectedItem = item;
        this.mode = "edit";
      },
      deleteItem(id) {
        this.$swal({
          title: this.$t('constants.are_you_sure'),
          text: this.$t('constants.delete_desc'),
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          cancelButtonText: this.$t('constants.cancel'),
          confirmButtonText: this.$t('constants.delete_btn_confirmation')
        }).then((result) => {
          if (result.isConfirmed) {
            axiosIns.delete(`airports-pickup/${id}`).then((response) => {
              this.$swal({
                title: "Deleted!",
                text: response.data.message,
                icon: "success",
              });
              this.$refs.datatable.loadItems();
            });
          }
        });
      },
    },
  };
  </script>
  