<template>
  <div class="card card-xxl-stretch mb-5 mb-xl-10">
    <!--begin::Header-->
    <el-form ref="form" :model="form" :rules="rules" label-width="120px">
      <div class="card-header align-items-center border-0 mt-4">
        <h3 class="card-title align-items-start flex-column">
          <span class="fw-bolder mb-2 text-dark">{{
            submitType == "add" ? $t("users.new") : $t("users.update")
          }}</span>
        </h3>
        <div class="card-toolbar">
          <div class="d-flex" style="gap: 10px">
            <button type="button" class="btn btn-primary" @click="onSubmit">
              {{ $t('constants.save') }}
            </button>
            <button type="button" class="btn btn-secondary" @click="back()">{{ $t('constants.cancel') }}</button>
          </div>
        </div>
      </div>
      <div class="card-body py-3">
        <div class="mb-5">
          <h5 class="text-left">{{ $t("users.basic_info") }}</h5>
        </div>
        <div class="row">
          <div class="form-group col-md-4 col-sm-6 col-xs-12">
            <label class="required fs-6 mb-2">{{ $t("users.type") }}</label>
            <el-form-item prop="type">
              <vSelect v-model="form.type" :placeholder="$t('constants.select_user_type')" :options="types"
                :reduce="(type) => type.value" label="option" :disabled="submitType == 'edit' ? true : false"
                :dir="direction == 'rtl' ? 'rtl' : 'ltr'"></vSelect>
            </el-form-item>
          </div>
          <div class="form-group col-md-4 col-sm-6 col-xs-12">
            <label class="required fs-6 mb-2">{{ $t("users.name") }}</label>
            <el-form-item prop="name">
              <el-input v-model="form.name" :placeholder="$t('users.name')"></el-input>
            </el-form-item>
          </div>
          <div class="form-group col-md-4 col-sm-6 col-xs-12">
            <label class="required fs-6 mb-2">{{ $t("users.email") }}</label>
            <el-form-item prop="email">
              <el-input v-model="form.email" :placeholder="$t('users.email')" type="email"></el-input>
            </el-form-item>
          </div>
        </div>
        <div class="row">
          <div class="form-group col-md-4 col-sm-6 col-xs-12">
            <label class="required fs-6 mb-2">{{ $t("users.mobile") }}</label>
            <el-form-item prop="mobile">
              <!--<el-input v-model="form.mobile" :placeholder="$t('users.mobile')" type="number"></el-input>-->
              <vue-tel-input v-model="form.mobile" @validate="validatePhone" invalidMsg="invalid phone number"
                :inputOptions="inputOptions" @country-changed="countryChanged" :dropdownOptions="dropdownOptions"
                :autoFormat="false"></vue-tel-input>
            </el-form-item>
          </div>
          <div class="form-group col-md-4 col-sm-6 col-xs-12">
            <label class="required fs-6 mb-2">{{
              $t("tours.country_id")
            }}</label>
            <el-form-item prop="country_id">
              <vSelect v-model="form.country_id" :placeholder="$t('constants.select_country')" :options="countries"
                :reduce="(country) => country.id" label="name_en" @input="getCities"
                :dir="direction == 'rtl' ? 'rtl' : 'ltr'"></vSelect>
            </el-form-item>
          </div>
          <div class="form-group col-md-4 col-sm-6 col-xs-12">
            <label class="required fs-6 mb-2">{{ $t("tours.city_id") }}</label>
            <el-form-item prop="city_id">
              <vSelect v-model="form.city_id" :placeholder="$t('constants.select_city')" :options="cities"
                :reduce="(city) => city.id" label="name_en" :dir="direction == 'rtl' ? 'rtl' : 'ltr'"></vSelect>
            </el-form-item>
          </div>
        </div>
        <div class="row">
          <div class="form-group col-md-4 col-sm-6 col-xs-12">
            <label class="required fs-6 mb-2">{{ $t("users.address") }}</label>
            <el-form-item prop="address">
              <el-input v-model="form.address" :placeholder="$t('users.address')"></el-input>
            </el-form-item>
          </div>
          <div class="form-group col-md-4 col-sm-6 col-xs-12">
            <label class="required fs-6 mb-2">{{ $t("users.password") }}</label>
            <el-form-item prop="password">
              <el-input v-model="form.password" :placeholder="$t('users.password')" type="password"></el-input>
            </el-form-item>
          </div>
          <div class="form-group col-md-4 col-sm-6 col-xs-12">
            <label class="required fs-6 mb-2">{{
              $t("users.password_confirmation")
            }}</label>
            <el-form-item prop="password_confirmation">
              <el-input v-model="form.password_confirmation" :placeholder="$t('users.password_confirmation')"
                type="password"></el-input>
            </el-form-item>
          </div>
        </div>
        <div class="row">
          <div class="form-group col-md-4 col-sm-6 col-xs-12">
            <label class="col-form-label required fw-bold fs-6">{{
              $t("users.currency")
            }}</label>
            <el-form-item prop="currency_id">
              <vSelect v-model="form.currency_id" :placeholder="$t('constants.select_currency')" :options="currencies"
                :reduce="(currency) => currency.id" label="code" :dir="direction == 'rtl' ? 'rtl' : 'ltr'"></vSelect>
            </el-form-item>
          </div>
          <div class="form-group col-md-4 col-sm-6 col-xs-12" v-if="form.type == 4">
            <label class="col-form-label required fw-bold fs-6">{{
              $t("users.payment_method")
            }}</label>
            <el-form-item prop="payment_method">
              <vSelect v-model="form.payment_method" :placeholder="$t('constants.select_payment_method')"
                :options="payment_methods" :reduce="(method) => method.value" label="name"
                :dir="direction == 'rtl' ? 'rtl' : 'ltr'"></vSelect>
            </el-form-item>
          </div>
        </div>
        <div class="row">
          <div class="form-group col-md-4 col-sm-6 col-xs-12">
            <label class="required fs-6 mb-2">{{ $t("users.image") }}</label>
            <el-form-item prop="image">
              <vue-dropzone ref="image" id="dropzone" :options="dropzoneImageOptions" @vdropzone-success="(file, response) => ImageAdded(file, response, 'image')
                " @vdropzone-removed-file="(file, error, xhr) =>
    removeImageFile(file, error, xhr, 'image')
    "></vue-dropzone>
            </el-form-item>
          </div>
        </div>
      </div>
      <div class="card-body py-3" id="driver_information" v-if="form.type == 4">
        <div class="mb-5">
          <h5>{{ $t("users.driver_info") }}</h5>
        </div>
        <div class="row">
          <div class="form-group col-md-4 col-sm-6 col-xs-12">
            <label class="required fs-6 mb-2">{{ $t("users.make_id") }}</label>
            <el-form-item prop="make_id">
              <vSelect v-model="form.make_id" :placeholder="$t('constants.select_car_make')" :options="car_makes"
                :reduce="(car) => car.id" label="name" @input="getCarModels" :dir="direction == 'rtl' ? 'rtl' : 'ltr'">
              </vSelect>
            </el-form-item>
          </div>
          <div class="form-group col-md-4 col-sm-6 col-xs-12">
            <label class="required fs-6 mb-2">{{ $t("users.model_id") }}</label>
            <el-form-item prop="model_id">
              <vSelect v-model="form.model_id" :placeholder="$t('constants.select_car_model')" :options="car_models"
                :reduce="(model) => model.id" label="name" :dir="direction == 'rtl' ? 'rtl' : 'ltr'"></vSelect>
            </el-form-item>
          </div>
        </div>
        <div class="row">
          <div class="form-group col-md-4 col-sm-6 col-xs-12">
            <label class="required fs-6 mb-2">{{ $t("users.car_year") }}</label>
            <el-form-item prop="car_year">
              <date-picker v-model="form.car_year" type="year" class="w-100" value-type="YYYY"
                :placeholder="$t('constants.select_car_year')"></date-picker>
            </el-form-item>
          </div>
          <div class="form-group col-md-4 col-sm-6 col-xs-12">
            <label class="required fs-6 mb-2">{{ $t("users.color_id") }}</label>
            <el-form-item prop="color_id">
              <vSelect v-model="form.color_id" :placeholder="$t('constants.select_car_color')" :options="colors"
                :reduce="(color) => color.id" label="name_en" :dir="direction == 'rtl' ? 'rtl' : 'ltr'"></vSelect>
            </el-form-item>
          </div>
          <div class="form-group col-md-4 col-sm-6 col-xs-12">
            <label class="required fs-6 mb-2">{{
              $t("users.interior_color_id")
            }}</label>
            <el-form-item prop="interior_color_id">
              <vSelect v-model="form.interior_color_id" :placeholder="$t('constants.select_car_color_interior')"
                :options="colors" :reduce="(color) => color.id" label="name_en" :dir="direction == 'rtl' ? 'rtl' : 'ltr'">
              </vSelect>
            </el-form-item>
          </div>
        </div>
        <div class="row">
          <div class="form-group col-md-4 col-sm-6 col-xs-12">
            <label class="required fs-6 mb-2">{{
              $t("users.available_hours")
            }}</label>
            <el-form-item prop="available_hours">
              <el-input :placeholder="$t('users.available_hours')" v-model="form.available_hours" type="number">
                <template slot="prepend" v-if="direction == 'ltr'">{{ $t("users.hour") }}</template>
                <template slot="append" v-else>{{ $t("users.hour") }}</template>
              </el-input>
            </el-form-item>
          </div>
          <div class="form-group col-md-4 col-sm-6 col-xs-12">
            <label class="required fs-6 mb-2">{{
              $t("users.available_distance")
            }}</label>
            <el-form-item prop="available_distance">
              <el-input :placeholder="$t('users.available_distance')" v-model="form.available_distance" type="number">
                <template slot="prepend" v-if="direction == 'ltr'">{{ $t("users.KM") }}</template>
                <template slot="append" v-else>{{ $t("users.KM") }}</template>
              </el-input>
            </el-form-item>
          </div>
          <div class="form-group col-md-4 col-sm-6 col-xs-12">
            <label class="required fs-6 mb-2">{{
              $t("users.daily_price")
            }}</label>
            <el-form-item prop="daily_price">
              <el-input v-model="form.daily_price" :placeholder="$t('users.daily_price')" type="number"></el-input>
            </el-form-item>
          </div>
        </div>
        <div class="row">
          <div class="form-group col-md-4 col-sm-6 col-xs-12">
            <label class="required fs-6 mb-2">{{
              $t("users.airport_pickup_price")
            }}</label>
            <el-form-item prop="airport_pickup_price">
              <el-input v-model="form.airport_pickup_price" :placeholder="$t('users.airport_pickup_price')"
                type="number"></el-input>
            </el-form-item>
          </div>
          <div class="form-group col-md-4 col-sm-6 col-xs-12">
            <label class="required fs-6 mb-2">{{
              $t("users.airport_drop_off_price")
            }}</label>
            <el-form-item prop="airport_drop_off_price">
              <el-input v-model="form.airport_drop_off_price" :placeholder="$t('users.airport_drop_off_price')"
                type="number"></el-input>
            </el-form-item>
          </div>
          <div class="form-group col-md-4 col-sm-6 col-xs-12">
            <label class="required fs-6 mb-2">{{ $t("users.brief") }}</label>
            <el-form-item prop="brief">
              <el-input v-model="form.brief" :placeholder="$t('users.brief')"></el-input>
            </el-form-item>
          </div>
        </div>
      </div>
      <div class="card-body py-3" id="driver_docs" v-if="form.type == 4">
        <div class="mb-5">
          <h5>{{ $t("users.driver_docs") }}</h5>
        </div>

        <div class="row">
          <div class="form-group col-md-4 col-sm-6 col-xs-12">
            <label class="required fs-6 mb-2">{{ $t("users.img_id") }}</label>
            <el-form-item prop="img_id">
              <vue-dropzone ref="img_id" id="dropzone" :options="dropzoneImageOptions" @vdropzone-success="(file, response) => ImageAdded(file, response, 'img_id')
                " @vdropzone-removed-file="(file, error, xhr) =>
    removeImageFile(file, error, xhr, 'img_id')
    "></vue-dropzone>
            </el-form-item>
          </div>
          <div class="form-group col-md-4 col-sm-6 col-xs-12">
            <label class="required fs-6 mb-2">{{
              $t("users.img_license")
            }}</label>
            <el-form-item prop="img_license">
              <vue-dropzone ref="img_license" id="dropzone" :options="dropzoneImageOptions" @vdropzone-success="(file, response) => ImageAdded(file, response, 'img_license')
                " @vdropzone-removed-file="(file, error, xhr) =>
    removeImageFile(file, error, xhr, 'img_license')
    "></vue-dropzone>
            </el-form-item>
          </div>
          <div class="form-group col-md-4 col-sm-6 col-xs-12">
            <label class="required fs-6 mb-2">{{
              $t("users.img_residence")
            }}</label>
            <el-form-item prop="img_residence">
              <vue-dropzone ref="img_residence" id="dropzone" :options="dropzoneImageOptions" @vdropzone-success="(file, response) =>
                ImageAdded(file, response, 'img_residence')
                " @vdropzone-removed-file="(file, error, xhr) =>
    removeImageFile(file, error, xhr, 'img_residence')
    "></vue-dropzone>
            </el-form-item>
          </div>
        </div>
        <div class="row">
          <div class="form-group col-md-4 col-sm-6 col-xs-12">
            <label class="required fs-6 mb-2">{{
              $t("users.img_insurance")
            }}</label>
            <el-form-item prop="img_insurance">
              <vue-dropzone ref="img_insurance" id="dropzone" :options="dropzoneImageOptions" @vdropzone-success="(file, response) =>
                ImageAdded(file, response, 'img_insurance')
                " @vdropzone-removed-file="(file, error, xhr) =>
    removeImageFile(file, error, xhr, 'img_insurance')
    "></vue-dropzone>
            </el-form-item>
          </div>
          <div class="form-group col-md-4 col-sm-6 col-xs-12">
            <label class="required fs-6 mb-2">{{
              $t("users.img_vehicle_license")
            }}</label>
            <el-form-item prop="img_vehicle_license">
              <vue-dropzone ref="img_vehicle_license" id="dropzone" :options="dropzoneImageOptions" @vdropzone-success="(file, response) =>
                ImageAdded(file, response, 'img_vehicle_license')
                " @vdropzone-removed-file="(file, error, xhr) =>
    removeImageFile(file, error, xhr, 'img_vehicle_license')
    "></vue-dropzone>
            </el-form-item>
          </div>
          <div class="form-group col-md-4 col-sm-6 col-xs-12">
            <label class="required fs-6 mb-2">{{
              $t("users.img_work_permit")
            }}</label>
            <el-form-item prop="img_work_permit">
              <vue-dropzone ref="img_work_permit" id="dropzone" :options="dropzoneImageOptions" @vdropzone-success="(file, response) =>
                ImageAdded(file, response, 'img_work_permit')
                " @vdropzone-removed-file="(file, error, xhr) =>
    removeImageFile(file, error, xhr, 'img_work_permit')
    "></vue-dropzone>
            </el-form-item>
          </div>
        </div>
        <div class="row">
          <div class="form-group col-md-4 col-sm-6 col-xs-12">
            <label class="required fs-6 mb-2">{{ $t("users.img_car") }}</label>
            <el-form-item prop="img_car">
              <vue-dropzone ref="img_car" id="dropzone" :options="dropzoneImageOptions" @vdropzone-success="(file, response) => ImageAdded(file, response, 'img_car')
                " @vdropzone-removed-file="(file, error, xhr) =>
    removeImageFile(file, error, xhr, 'img_car')
    "></vue-dropzone>
            </el-form-item>
          </div>
          <div class="form-group col-md-4 col-sm-6 col-xs-12">
            <label class="required fs-6 mb-2">{{
              $t("users.img_front")
            }}</label>
            <el-form-item prop="img_front">
              <vue-dropzone ref="img_front" id="dropzone" :options="dropzoneImageOptions" @vdropzone-success="(file, response) => ImageAdded(file, response, 'img_front')
                " @vdropzone-removed-file="(file, error, xhr) =>
    removeImageFile(file, error, xhr, 'img_front')
    "></vue-dropzone>
            </el-form-item>
          </div>
          <div class="form-group col-md-4 col-sm-6 col-xs-12">
            <label class="required fs-6 mb-2">{{ $t("users.img_back") }}</label>
            <el-form-item prop="img_back">
              <vue-dropzone ref="img_back" id="dropzone" :options="dropzoneImageOptions" @vdropzone-success="(file, response) => ImageAdded(file, response, 'img_back')
                " @vdropzone-removed-file="(file, error, xhr) =>
    removeImageFile(file, error, xhr, 'img_back')
    "></vue-dropzone>
            </el-form-item>
          </div>
        </div>
      </div>
      <div class="card-body py-3">
        <div class="mb-5">
          <h5>{{ $t("users.GPS") }}</h5>
        </div>
        <div class="row align-items-center">
          <div class="form-group col-6">
            <label class="fs-6 mb-2">{{ $t("tours.coordinates") }}</label>
            <el-form-item>
              <el-input v-model="form.coordinates" prefix-icon="el-icon-location" disabled>
              </el-input>
            </el-form-item>
          </div>
          <div class="form-group col-6">
            <button type="button" class="btn btn-sm btn-light-primary" @click="getCurrentLocation()">
              <span class="svg-icon svg-icon-3">
                <inline-svg src="/media/icons/duotune/arrows/arr075.svg" />
              </span>
              {{ $t("tours.currentLocation") }}
            </button>
          </div>
        </div>
        <div class="row">
          <el-form-item>
            <GmapMap :center="coord" :zoom="7" map-type-id="terrain" style="width: 100%; height: 450px" ref="mapRef">
              <GmapMarker ref="myMarker" :position="coord" :draggable="true" @dragend="updateCoordinates" />
            </GmapMap>
          </el-form-item>
        </div>
        <div class="row mt-5">
          <div class="form-group col text-center">
            <div class="d-flex justify-content-center" style="gap: 10px">
              <button type="button" class="btn btn-primary" @click="onSubmit">
                {{ $t('constants.save') }}
              </button>
              <button type="button" class="btn btn-secondary" @click="back()">{{ $t('constants.cancel') }}</button>
            </div>
          </div>
        </div>
      </div>
    </el-form>
  </div>
</template>

<script>
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import axiosIns from "@/axios.js";
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import Cookies from "js-cookie";

export default {
  name: "UserForm",
  props: ["submitType"],
  components: {
    vSelect,
    vueDropzone: vue2Dropzone,
    DatePicker,
  },
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error(this.$t('constants.required')));
      } else {
        if (this.form.password_confirmation !== "") {
          this.$refs.form.validateField("password_confirmation");
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error(this.$t('constants.required')));
      } else if (value !== this.form.password) {
        callback(new Error("The password confirmation does not match"));
      } else {
        callback();
      }
    };
    return {
      nodeEnv: process.env.NODE_ENV,
      phoneObject: {},
      formatted_number: "",
      countryChangedCount: 0,
      form: {
        type: "",
        name: "",
        email: "",
        country_id: "",
        city_id: "",
        address: "",
        mobile: "",
        password: "",
        password_confirmation: "",
        currency_id: "",
        payment_method: "",
        image: "",
        make_id: "",
        model_id: "",
        car_year: "",
        color_id: "",
        interior_color_id: "",
        available_hours: "",
        available_distance: "",
        airport_pickup_price: "",
        airport_drop_off_price: "",
        daily_price: "",
        brief: "",
        coordinates: "",
        img_id: "",
        img_license: "",
        img_residence: "",
        img_insurance: "",
        img_vehicle_license: "",
        img_work_permit: "",
        img_car: "",
        img_front: "",
        img_back: "",
      },
      field: "",
      types: [
        {
          option: "driver",
          value: 4,
        },
        {
          option: "guide",
          value: 6,
        },
      ],
      coord: {
        lat: 21.48436249892535,
        lng: 39.1924169425293,
      },
      countries: [],
      cities: [],
      car_makes: [],
      car_models: [],
      colors: [],
      currencies: [],
      payment_methods: [
        {
          name: this.$t('users.online_payment_method'),
          value: 1
        },
        {
          name: this.$t('users.office_payment_method'),
          value: 2
        }
      ],
      dropzoneImageOptions: {
        url: process.env.NODE_ENV == "production" ? "https://api.dgotours.com/api/users/images/upload" : process.env.NODE_ENV == "development" ? "http://localhost:8000/api/users/images/upload" : "",
        thumbnailWidth: 150,
        //maxFilesize: 0.5,
        maxFiles: 1,
        autoProcessQueue: true,
        headers: {
          Authorization: Cookies.get("access_token"),
        },
        //autoQueue: false,
        //chunking: true,
        addRemoveLinks: true,
        timeout: 600000
      },
      deleteFiles: true,
      rules: {
        type: [
          {
            required: true,
            message: this.$t('constants.required'),
            trigger: "blur",
          },
        ],
        name: [
          {
            required: true,
            message: this.$t('constants.required'),
            trigger: "blur",
          },
        ],
        email: [
          {
            required: true,
            message: this.$t('constants.required'),
            trigger: "blur",
          },
        ],
        country_id: [
          {
            required: true,
            message: this.$t('constants.required'),
            trigger: "blur",
          },
        ],
        city_id: [
          {
            required: true,
            message: this.$t('constants.required'),
            trigger: "blur",
          },
        ],
        address: [
          {
            required: true,
            message: this.$t('constants.required'),
            trigger: "blur",
          },
        ],
        password: [
          //{ required: true, message: 'this field is required', trigger: 'blur' },
          { validator: validatePass, trigger: "blur" },
        ],
        password_confirmation: [
          //{ required: true, message: 'this field is required', trigger: 'blur' },
          { validator: validatePass2, trigger: "blur" },
        ],
        currency_id: [
          {
            required: true,
            message: this.$t('constants.required'),
            trigger: "blur",
          },
        ],
        payment_method: [
          {
            required: true,
            message: this.$t('constants.required'),
            trigger: "blur",
          },
        ],
        make_id: [
          {
            required: true,
            message: this.$t('constants.required'),
            trigger: "blur",
          },
        ],
        model_id: [
          {
            required: true,
            message: this.$t('constants.required'),
            trigger: "blur",
          },
        ],
        car_year: [
          {
            required: true,
            message: this.$t('constants.required'),
            trigger: "blur",
          },
        ],
        airport_pickup_price: [
          {
            required: true,
            message: this.$t('constants.required'),
            trigger: "blur",
          },
        ],
        airport_drop_off_price: [
          {
            required: true,
            message: this.$t('constants.required'),
            trigger: "blur",
          },
        ],
        daily_price: [
          {
            required: true,
            message: this.$t('constants.required'),
            trigger: "blur",
          },
        ],
        mobile: [
          { required: true, message: this.$t('constants.required'), trigger: 'blur' },
          {
            validator: (rule, value, callback) => {
              if (this.phoneObject.valid) {
                callback();
              } else {
                callback(new Error(this.$t('constants.invalid_phone_number')));
              }
            },
            trigger: 'blur',
          },
        ],
      },
      inputOptions: {
        //placeholder: this.$t('constants.enter_phone_number')
      },
      dropdownOptions: {
        showDialCodeInSelection: true,
        showFlags: true,
        showSearchBox: true
      }
    };
  },
  computed: {
    direction() {
      return this.$store.state.direction;
    }
  },
  watch: {
    'form.type': function (val) {
      setTimeout(() => {
        this.editPrepend()
      }, 1000)

    }
  },
  methods: {
    ImageAdded(file, response, field) {
      this.form[field] = response.path;
    },
    removeImageFile(file, error, xhr, field) {
      if (this.deleteFiles) {
        this.removeUploadedImage(this.form[field]).then(
          () => (this.form[field] = "")
        );
      }
    },
    removeUploadedImage(path) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`users/images/delete`, {
            path: path,
          })
          .then((response) => {
            resolve(response);
          });
      });
    },
    onSubmit() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          const formData = new FormData();
          for (const key in this.form) {
            if (this.form[key]) {
              formData.append(key, this.form[key]);
            }
          }

          formData.set('mobile', this.formatted_number);

          this.$swal(
            {
              showConfirmButton: false,
              allowOutsideClick: false, // disable clicking outside the modal
              backdrop: true, // enable backdrop to prevent closing on outside click
              didOpen: () => {
                this.$swal.showLoading();
              },
            }
          );
          if (this.submitType == "add") {
            axiosIns.post("users", formData).then((response) => {
              this.$swal.close();
              this.$notify({
                title: "مرحبا",
                type: "success",
                message: response.data,
                showClose: true,
                position: "top-left",
              });

              setTimeout(() => {
                this.$router.push({ name: "users" });
              }, 600);
            }).catch((error) => {
              this.$swal.close();
              this.$swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong!',
              })
            });
          } else if (this.submitType == "edit") {
            axiosIns
              .post(`users/${this.$route.params.id}?_method=put`, formData)
              .then((response) => {
                this.$swal.close();
                this.$notify({
                  title: "مرحبا",
                  type: "success",
                  message: response.data,
                  showClose: true,
                  position: "top-left",
                });

                setTimeout(() => {
                  this.$router.push({ name: "users" });
                }, 500);
              }).catch((error) => {
                this.$swal.close();
                this.$swal.fire({
                  icon: 'error',
                  title: 'Oops...',
                  text: 'Something went wrong!',
                })
              });
          }
        } else {
          let animationFrameId;

          const animateScroll = () => {
            const errorElements = document.querySelectorAll('.el-form-item__error');
            if (errorElements.length > 0) {
              const firstErrorParent = errorElements[0].closest('.row');
              if (firstErrorParent) {
                firstErrorParent.scrollIntoView({ behavior: 'smooth' });
              }
            } else {
              animationFrameId = requestAnimationFrame(animateScroll);
            }
          };

          animationFrameId = requestAnimationFrame(animateScroll);
        }
      });
    },
    getAllCountries() {
      axiosIns.get("countries").then((response) => {
        this.countries = response.data.countries;
      });
    },
    getCities() {
      this.form.city_id = "";
      return new Promise((resolve, reject) => {
        axiosIns.get("cities/" + this.form.country_id).then((response) => {
          this.cities = response.data.cities;
          resolve(response);
        });
      });
    },
    getColors() {
      axiosIns.get("colors").then((response) => {
        this.colors = response.data.colors;
      });
    },
    getCurrentLocation() {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          this.form.coordinates =
            position.coords.latitude + ", " + position.coords.longitude;
          this.coord.lat = position.coords.latitude;
          this.coord.lng = position.coords.longitude;
        },
        (error) => {
          console.log(error.message);
        }
      );
    },
    updateCoordinates(location) {
      this.form.coordinates =
        location.latLng.lat() + ", " + location.latLng.lng();
      this.coord.lat = location.latLng.lat();
      this.coord.lng = location.latLng.lng();
    },

    getCarMakes() {
      return new Promise((resolve, reject) => {
        axiosIns.get('get-car-makes')
          .then((response) => {
            this.car_makes = response.data.makes;

            resolve(response);
          })
      })
    },
    getCarModels() {
      this.form.model_id = ""
      return new Promise((resolve, reject) => {
        axiosIns.get(`get-car-models/${this.form.make_id}`)
          .then((response) => {
            this.car_models = response.data.models;
            resolve(response);
          })
      })
    },
    getCurrencies() {
      axiosIns.get("currencies").then((response) => {
        this.currencies = response.data.currencies;
      });
    },
    getUser() {
      return new Promise((resolve, reject) => {
        axiosIns.get(`users/${this.$route.params.id}/edit`).then((response) => {
          this.form = response.data.data;
          this.getCities().then((res) => {
            this.form.city_id = response.data.city_id;
          });

          const mockFile = { size: 123, type: "image/png,jpg,jpeg" };
          if (response.data.data.image) {
            this.$refs.image.manuallyAddFile(
              mockFile,
              response.data.data.image
            );
            let url = new URL(response.data.data.image);
            this.form.image = url.pathname.substring(1);
          }

          if (response.data.data.lat && response.data.data.lng) {
            this.coord.lat = response.data.data.lat;
            this.coord.lng = response.data.data.lng;
          }

          resolve(response);
        });
      });
    },
    back() {
      this.$router.go(-1);
    },
    editPrepend() {
      const element = document.querySelectorAll('.el-input-group__append');
      const element2 = document.querySelectorAll('.el-input-group--append .el-input__inner');

      if (this.direction == "rtl") {
        element.forEach((item) => {
          item.style.borderRight = '0';
          item.style.borderLeft = '1px solid #DCDFE6';
          item.style.borderRadius = '0px';
          item.style.borderTopLeftRadius = '4px';
          item.style.borderBottomLeftRadius = '4px';
        });

        element2.forEach((item) => {
          item.style.borderRadius = '0px';
          item.style.borderTopRightRadius = '4px';
          item.style.borderBottomRightRadius = '4px';
        });

      }
    },

    validatePhone(event) {
      this.phoneObject = event;
      this.formatted_number = event.number;
    },

    countryChanged(event) {
      this.countryChangedCount++;
      if (this.countryChangedCount > 1) {
        this.form.mobile = '';
      }
    }
  },
  mounted() {
    this.getAllCountries();
    this.getCurrencies();
    this.getCarMakes();
    this.getColors();
    //this.editPrepend();
    if (this.submitType == "edit") {
      this.getUser().then((response) => {
        if (this.form.type == 4) {
          this.getCarModels().then((res) => {
            this.form.model_id = response.data.model_id;
          });
          const mockFile = { size: 123, type: "image/png,jpg,jpeg" };
          if (response.data.data.img_id) {
            this.$refs.img_id.manuallyAddFile(mockFile, this.form.img_id);
            let url = new URL(this.form.img_id);
            this.form.img_id = url.pathname.substring(1);
          }
          if (response.data.data.img_license) {
            this.$refs.img_license.manuallyAddFile(
              mockFile,
              response.data.data.img_license
            );
            let url = new URL(response.data.data.img_license);
            this.form.img_license = url.pathname.substring(1);
          }
          if (response.data.data.img_residence) {
            this.$refs.img_residence.manuallyAddFile(
              mockFile,
              response.data.data.img_residence
            );
            let url = new URL(response.data.data.img_residence);
            this.form.img_residence = url.pathname.substring(1);
          }
          if (response.data.data.img_insurance) {
            this.$refs.img_insurance.manuallyAddFile(
              mockFile,
              response.data.data.img_insurance
            );
            let url = new URL(response.data.data.img_insurance);
            this.form.img_insurance = url.pathname.substring(1);
          }
          if (response.data.data.img_vehicle_license) {
            this.$refs.img_vehicle_license.manuallyAddFile(
              mockFile,
              response.data.data.img_vehicle_license
            );
            let url = new URL(response.data.data.img_vehicle_license);
            this.form.img_vehicle_license = url.pathname.substring(1);
          }
          if (response.data.data.img_work_permit) {
            this.$refs.img_work_permit.manuallyAddFile(
              mockFile,
              response.data.data.img_work_permit
            );
            let url = new URL(response.data.data.img_work_permit);
            this.form.img_work_permit = url.pathname.substring(1);
          }
          if (response.data.data.img_car) {
            this.$refs.img_car.manuallyAddFile(
              mockFile,
              response.data.data.img_car
            );
            let url = new URL(response.data.data.img_car);
            this.form.img_car = url.pathname.substring(1);
          }
          if (response.data.data.img_front) {
            this.$refs.img_front.manuallyAddFile(
              mockFile,
              response.data.data.img_front
            );
            let url = new URL(response.data.data.img_front);
            this.form.img_front = url.pathname.substring(1);
          }
          if (response.data.data.img_back) {
            this.$refs.img_back.manuallyAddFile(
              mockFile,
              response.data.data.img_back
            );
            let url = new URL(response.data.data.img_back);
            this.form.img_back = url.pathname.substring(1);
          }
        }
      });
    }
  },
  beforeDestroy() {
    this.deleteFiles = false;
  },
};
</script>

<style>
.el-input-group__append {
  /*border-left: 1px solid #DCDFE6 !important;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;*/
}
</style>
