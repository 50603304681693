<template>
  <div class="card">
    <div class="card-header border-0 pt-6">
      <!--begin::Card title-->
      <div class="card-title">
        <!--begin::Search-->
        <div class="d-flex align-items-center position-relative my-1">
          <span class="svg-icon svg-icon-1 position-absolute" :class="direction == 'rtl' ? 'me-6' : 'ms-6'">
            <svg
              fill="none"
              viewBox="0 0 24 24"
              height="24"
              width="24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                xmlns="http://www.w3.org/2000/svg"
                opacity="0.5"
                x="17.0365"
                y="15.1223"
                width="8.15546"
                height="2"
                rx="1"
                transform="rotate(45 17.0365 15.1223)"
                fill="black"
              ></rect>
              <path
                xmlns="http://www.w3.org/2000/svg"
                d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                fill="black"
              ></path>
            </svg>
          </span>
          <input
            type="text"
            v-model="search"
            class="form-control form-control-solid w-250px table-search-input"
            :class="direction == 'rtl' ? 'pe-15' : 'ps-15'"
            :placeholder="$t('users.search_users')"
          />
        </div>
        <!--end::Search-->
      </div>
    </div>
    <div class="card-body pt-0">
      <Datatable
        :table-header="tableHeader"
        :enable-items-per-page-dropdown="true"
        :url="url"
        :searchTermProp="search"
        ref="datatable"
      >
        <template v-slot:cell-checkbox="{ row: user }">
          <div
            class="form-check form-check-sm form-check-custom form-check-solid"
          >
            <input
              class="form-check-input"
              type="checkbox"
              :value="user.id"
              v-model="checkedUsers"
            />
          </div>
        </template>
        <template v-slot:cell-name="{ row: user }">
          {{ user.name }}
        </template>
        <template v-slot:cell-email="{ row: user }">
          {{ user.email }}
        </template>
        <template v-slot:cell-mobile="{ row: user }">
          {{ user.mobile }}
        </template>
        <template v-slot:cell-type="{ row: user }">
          {{ user.type }}
        </template>
        <!--<template v-slot:cell-enabled="{ row: user }">
          <el-switch v-model="user.enabled"></el-switch>
        </template>-->
        <template v-slot:cell-created_at="{ row: user }">
          {{ user.created_at }}
        </template>
        <template v-slot:cell-actions="{ row: user }">
          <div>
            <div
              class="btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px"
              id="kt_activities_toggle"
              data-bs-toggle="modal"
              data-bs-target="#modal_new_cities_commuting"
            >
              <router-link :to="{ name: 'edit-user', params: { id: user.id } }">
                <span class="svg-icon svg-icon-1">
                  <inline-svg src="/media/icons/duotune/general/gen055.svg" />
                </span>
              </router-link>
            </div>
            <div
              class="btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px"
              id="kt_activities_toggle"
              @click="deleteUser(user.id)"
            >
              <span class="svg-icon svg-icon-1">
                <inline-svg src="/media/icons/duotune/general/gen027.svg" />
              </span>
            </div>
          </div>
        </template>
      </Datatable>
    </div>
  </div>
</template>

<script>
import Datatable from "@/components/kt-datatable/Datatable.vue";
import axiosIns from "@/axios.js"
export default {
  components: {
    Datatable,
  },
  data() {
    return {
      url: "users",
      checkedUsers: [],
      search: "",
      tableHeader: [
        {
          key: "checkbox",
        },
        {
          name: this.$t('users.name'),
          key: "name",
          sortable: true,
        },
        {
          name: this.$t('users.email'),
          key: "email",
          sortable: true,
        },
        {
          name: this.$t('users.mobile'),
          key: "mobile",
          sortable: true,
        },
        {
          name: this.$t('users.type'),
          key: "type",
          sortable: true,
        },
        /*{
          name: "Enabled",
          key: "enabled",
          sortable: true,
        },*/
        {
          name: this.$t('constants.created_at'),
          key: "created_at",
          sortable: true,
        },
        {
          name: this.$t('constants.actions'),
          key: "actions",
        },
      ],
    };
  },
  computed: {
    direction(){
      return this.$store.state.direction;
    }
  },
  methods: {
    deleteUser(id){
      this.$swal(
      {
        title: this.$t('constants.are_you_sure'),
        text: this.$t('constants.delete_desc'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: this.$t('constants.cancel'),
        confirmButtonText: this.$t('constants.delete_btn_confirmation')
      }).then((result) => {
        if (result.isConfirmed) {
          
          axiosIns.delete(`users/${id}`)
          .then((response) => {
            this.$swal({
              title: 'Deleted!',
              text: response.data.message,
              icon: 'success'
            })
            this.$refs.datatable.loadItems();
          })
        }
      })
    }
  },
};
</script>
