<template>
  <!--begin::Wrapper-->
  <div class="w-lg-500px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto">
    <!--begin::Form-->
    <el-form
      class="form w-100"
      id="kt_login_signin_form"
      ref="form"
      :model="form"
      :rules="rules"
    >
      <!--begin::Heading-->
      <div class="text-center mb-10">
        <div class="mb-10">
          <img src="@/assets/images/logo-dashboard.png" style="width: 160px;height: 130px"/>
        </div>
        
        <!--begin::Title-->
        <h1 class="text-dark mb-3">{{ $t('constants.sign_in_to_dgo') }}</h1>
        <!--end::Title-->
      </div>
      <!--begin::Heading-->
      <div
        class="alert alert-danger d-flex flex-column align-items-center flex-sm-row w-100 p-5 mb-10"
        v-if="auth_error"
      >
        <span class="svg-icon svg-icon-2hx svg-icon-danger" :class="direction == 'ltr' ? 'me-3' : 'ms-3'">
          <inline-svg src="/media/icons/duotune/general/gen044.svg" />
        </span>
        <!--begin::Content-->
        <div class="d-flex flex-column pe-0" :class="direction == 'ltr' ? 'text-start pe-sm-10' : 'text-end ps-sm-10'">
          <h5 class="mb-0 text-danger">{{ auth_error_message }}</h5>
          <!--<span
            >{{ auth_error_message }}</span
          >-->
        </div>
        <!--end::Content-->
        <!--begin::Close-->
        <button
          type="button"
          class="position-absolute position-sm-relative m-2 m-sm-0 top-0 end-0 btn btn-icon"
          :class="direction == 'ltr' ? 'ms-sm-auto': 'me-sm-auto'"
          @click="auth_error = false"
        >
          <span class="svg-icon svg-icon-2x svg-icon-danger">
            <inline-svg src="/media/icons/duotune/arrows/arr061.svg" />
          </span>
        </button>
        <!--end::Close-->
      </div>
      <!--begin::Input group-->
      <div class="fv-row mb-10">
        <!--begin::Label-->
        <label class="form-label fs-6 fw-bolder text-dark d-block"
        :class="direction == 'ltr' ? 'text-start' : 'text-end'"
          >{{$t('users.email')}}</label
        >
        <!--end::Label-->

        <!--begin::Input-->
        <el-form-item prop="email">
          <el-input
            type="text"
            name="email"
            autocomplete="off"
            v-model="form.email"
          />
        </el-form-item>

        <!--end::Input-->
        <div class="fv-plugins-message-container">
          <div class="fv-help-block">
            <!--<ErrorMessage name="email" />-->
          </div>
        </div>
      </div>
      <!--end::Input group-->

      <!--begin::Input group-->
      <div class="fv-row mb-10">
        <!--begin::Wrapper-->
        <div class="d-flex flex-stack mb-2">
          <!--begin::Label-->
          <label class="form-label fw-bolder text-dark fs-6 mb-0"
            >{{$t('users.password')}}</label
          >
          <!--end::Label-->

          <!--begin::Link-->
          <router-link :to="{name: 'forgot-password'}" class="link-primary fs-6 fw-bolder">
            {{$t('users.forgot_password')}}
          </router-link>
          <!--end::Link-->
        </div>
        <!--end::Wrapper-->

        <!--begin::Input-->
        <el-form-item prop="password">
          <el-input
            type="password"
            name="password"
            autocomplete="off"
            v-model="form.password"
          />
        </el-form-item>
        <!--end::Input-->
        <div class="fv-plugins-message-container">
          <div class="fv-help-block">
            <!--<ErrorMessage name="password" />-->
          </div>
        </div>
      </div>
      <!--end::Input group-->

      <!--begin::Actions-->
      <div class="text-center">
        <!--begin::Submit button-->
        <button
          type="button"
          ref="submitButton"
          id="kt_sign_in_submit"
          class="btn btn-lg btn-primary w-100 mb-5"
          @click="login"
          :data-kt-indicator="loading ? 'on' : ''"
          :disabled="loading ? true : false"
        >
          <span class="indicator-label">{{$t('constants.signIn')}}</span>

          <span class="indicator-progress">
            {{$t('account.please_wait')}}
            <span
              class="spinner-border spinner-border-sm align-middle ms-2"
            ></span>
          </span>
        </button>
        <!--end::Submit button-->
      </div>
      <!--end::Actions-->
    </el-form>
    <!--end::Form-->
  </div>
  <!--end::Wrapper-->
</template>
  
<script>
import axiosIns from "@/axios";
import Cookies from "js-cookie";

export default {
  name: "LoginPage",
  data() {
    return {
      nodeEnv: process.env.NODE_ENV,
      loading: false,
      auth_error_message: "",
      auth_error: false,
      form: {
        email: "",
        password: "",
      },
      rules: {
        email: [
          {
            required: true,
            message: this.$t('constants.required'),
            trigger: "blur",
          },
        ],
        password: [
          {
            required: true,
            message: this.$t('constants.required'),
            trigger: "blur",
          },
        ],
      },
    };
  },
  computed: {
    direction(){
      return this.$store.state.direction;
    }
  },
  methods: {
    login() {
      var self = this;
      var bodyFormData = new FormData();
      bodyFormData.append("email", self.form.email);
      bodyFormData.append("password", self.form.password);
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.loading = true;
          axiosIns
            .post("users/login", bodyFormData)
            .then((response) => {
              if(response.data.user && response.data.user.access_token){
                if(self.nodeEnv == "production"){
                  //Cookies.set("access_token", response.data.user.access_token, { domain: '.def.techno2030.com' });
                  Cookies.set("access_token", response.data.user.access_token, { domain: '.dgotours.com' });
                }else if(self.nodeEnv == "development"){
                  Cookies.set("access_token", response.data.user.access_token);
                }
                
                
                axiosIns.defaults.headers.common['Authorization'] = response.data.user.access_token; // update authorization
                self.$store.commit("setLogin", response.data.user);
                self.$router.push("/");
              }else{
                this.loading = false;
                this.auth_error = true;
                this.auth_error_message = response.data.status.message;  
              }
              
            })
            .catch((error) => {
              this.loading = false;
            });
        }
      });
    },
  },
};
</script>

<style>
body {
  justify-content: center;
}
</style>