<template>
    <div class="card card-xxl-stretch mb-5 mb-xl-10">
        <div class="card-header card-header-stretch">
            <div class="card-title d-flex align-items-center">
                <h3 class="fw-bold m-0 text-gray-800">
                    {{ $t('notifications.title') }}
                </h3>
            </div>
            <div class="card-toolbar m-0">
                <div class="d-flex align-items-center" v-if="checkIfExistUnreadNotifications">
                    <button class="btn btn-sm btn-light me-2" @click="markAllNotificationAsRead">
                        {{ $t('constants.mark_all_notif_as_read') }}
                    </button>
                </div>
            </div>
        </div>
        <div class="card-body px-0" v-if="notifications.length > 0">
            <router-link v-for="item in notifications" :key="item.id" :to="generateRoute(item)" class="text-gray-800">
                <div class="notification bg-hover-light-primary px-7"
                    @click="markNotificationAsRead(item.id, item.read_at)">
                    <!--begin::Symbol-->
                    <div class="symbol symbol-35px" :class="direction == 'rtl' ? 'ms-4' : 'me-4'">
                        <span :class="`bg-light-${selectState(item.type)}`" class="symbol-label">
                            <span :class="`svg-icon-${selectState(item.type)}`" class="svg-icon svg-icon-2">
                                <inline-svg :src="selectIcon(item.type)" />
                            </span>
                        </span>
                    </div>
                    <!--end::Symbol-->
                    <div class="flex-1 center">
                        <div>
                            <a href="#" class="text-gray-800 fw-bold text-hover-primary fs-6">{{ item.title }}
                                <span v-if="item.reservation_no !== null">{{ ` #` + item.reservation_no }}</span>
                            </a>
                        </div>
                        <div>
                            <p class="text-gray-400 fs-7">{{ item.description }}</p>
                        </div>
                        <div>
                            <p style="color: #7E8299;">{{ item.formatted_date_full }}</p>
                        </div>
                    </div>
                    <div class="right">
                        <div class="d-flex align-items-center gap-2">
                            <span class="badge badge-light fs-8">
                                {{ item.formatted_date }}
                            </span>
                            <span class="circle" :class="item.read_at == null ? 'unread' : 'read'"></span>
                        </div>
                    </div>
                </div>
            </router-link>
        </div>

        <div class="card-body" v-else>
            <h4>{{ $t('constants.no_notifications') }}</h4>
        </div>

        <div class="col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end mb-4" v-if="notifications.length > 0">
            <el-pagination background layout="prev, pager, next" @current-change="setCurrent" :hide-on-single-page="false"
                :page-count="pages" :page-size="parseInt(pagination.rowsPerPage)" :total="pagination.total">
            </el-pagination>
        </div>
    </div>
</template>

<script>
import NotificationRequest from "@/services/requests/index"
export default {
    name: "NotificationsIndex",
    data() {
        return {
            pagination: {
                page: 1,
                total: 0,
                rowsPerPage: 10,
            },
        }
    },
    computed: {
        notifications() {
            return this.$store.getters.notifications;
        },
        pages() {
            return Math.ceil(this.pagination.total / this.pagination.rowsPerPage);
        },
        checkIfExistUnreadNotifications() {
            return this.notifications.some(item => item.read_at === null);
        },
        direction() {
            return this.$store.state.direction;
        }
    },
    methods: {
        generateRoute(item) {
            if (item.type == 'driver_request' || item.type == 'driver_request_cancelled') {
                return {
                    name: 'order-details',
                    params: { id: item.reservation_id }
                };
            } else if (item.type == 'account_activation' || item.type == 'account_deactivation') {
                return {
                    name: 'account-overview'
                };
            }
        },
        getAllNotifications() {
            /*NotificationRequest.getAllNotifications()
                .then((response) => {
                    this.notifications = response.data.data;
                    this.pagination.rowsPerPage = response.data.meta.per_page; //this.rowsPerPage ? this.rowsPerPage : 10;
                    this.pagination.total = response.data.meta.total; //this.data_items.length;
                })*/
            this.$store.dispatch('getNotifications')
                .then((response) => {
                    this.pagination.rowsPerPage = response.data.meta.per_page; //this.rowsPerPage ? this.rowsPerPage : 10;
                    this.pagination.total = response.data.meta.total; //this.data_items.length;
                });
        },
        setCurrent(val) {
            this.pagination.page = val;
            //console.log(val);
            this.loadItems();
            this.$store.commit("scrollToTop");
        },
        markNotificationAsRead(id, read_at) {
            if (read_at == null) {
                NotificationRequest.markNotificationAsRead(id)
                    .then(() => {
                        this.getAllNotifications();
                    })
            } else {
                return false;
            }
        },
        markAllNotificationAsRead() {
            NotificationRequest.markAllNotificationAsRead()
                .then((response) => {
                    this.getAllNotifications();
                    this.$notify({
                        title: "",
                        type: "success",
                        message: response.data.message,
                        showClose: true,
                        position: "top-left",
                    });
                });
        },
        selectIcon(type) {
            var icon = '';
            switch (type) {
                case 'driver_request':
                    icon = '/media/icons/duotune/general/gen013.svg'
                    break;
                case 'driver_request_cancelled':
                    icon = '/media/icons/duotune/general/gen040.svg'
                    break;
                case 'account_activation':
                    icon = '/media/icons/duotune/coding/cod001.svg'
                    break;
                case 'account_deactivation':
                    icon = '/media/icons/duotune/coding/cod001.svg'
                    break;
                default:
                    break;
            }

            return icon;
        },
        selectState(type) {
            var state = '';
            switch (type) {
                case 'driver_request':
                    state = 'warning'
                    break;
                case 'driver_request_cancelled':
                    state = 'danger'
                    break;
                case 'account_activation':
                    state = 'primary'
                    break;
                case 'account_deactivation':
                    state = 'primary'
                    break;
                default:
                    break;
            }

            return state;
        },
    },
    mounted() {
        this.getAllNotifications();
    }
}
</script>

<style scoped>
.notification {
    display: flex;
    justify-content: space-between;
    gap: 16px;
    padding-top: 16px;
    padding-bottom: 16px;
    border-bottom: 1px solid #EFF2F5;
    align-items: center;
}

.notification .center {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.flex-1 {
    flex: 1;
}

.circle {
    display: block;
    width: 12px;
    height: 12px;
    border-radius: 50%;
}

.circle.unread {
    background-color: #009ef7;
}

.circle.read {
    background-color: #EFF2F5;
}
</style>