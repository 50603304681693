var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"dataTables_wrapper dt-bootstrap4 no-footer"},[(_vm.loading)?_c('div',{staticClass:"vgt-loading text-center"},[_c('span',{staticClass:"vgt-loading__content"},[_vm._v("Loading ...")])]):_vm._e(),_c('div',{staticClass:"inner-wrap",class:_vm.loading ? 'is-loading' : ''},[_c('div',{staticClass:"table-responsive"},[_c('table',{staticClass:"table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer text-start",attrs:{"id":"kt_customers_table","role":"grid"}},[_c('thead',[_c('tr',{staticClass:"text-start text-gray-400 fw-bolder fs-7 text-uppercase gs-0",attrs:{"role":"row"}},[_vm._l((_vm.tableHeader),function(cell,i){return _c('th',{key:i,class:[
                  cell.name && 'min-w-125px',
                  cell.sortable && 'sorting',
                  
                  _vm.currentSort ===
                    `${cell.sortingField ? cell.sortingField : cell.key}2` &&
                    'sorting_desc',
                  _vm.currentSort ===
                    `${cell.sortingField ? cell.sortingField : cell.key}1` &&
                    'sorting_asc',
                ],staticStyle:{"cursor":"pointer"},attrs:{"tabindex":"0","rowspan":"1","colspan":"1"},on:{"click":function($event){return _vm.sort(
                    cell.sortingField ? cell.sortingField : cell.key,
                    cell.sortable
                  )}}},[_vm._v(" "+_vm._s(cell.name)+" ")])})],2)]),(_vm.data_items.length > 0)?_c('tbody',{staticClass:"fw-bold text-gray-600"},[_vm._l((_vm.data_items),function(item,i){return _c('tr',{key:i,staticClass:"odd"},[_vm._l((_vm.tableHeader),function(cell,i){return _c('td',{key:i},[_vm._t(`cell-${cell.key}`,function(){return [_vm._v(" "+_vm._s(item[_vm.prop])+" ")]},{"row":item})],2)})],2)})],2):_c('tbody',[_c('tr',[_c('td',{staticStyle:{"color":"#909399","text-align":"center !important"},attrs:{"colspan":_vm.tableHeader.length}},[_vm._v(" "+_vm._s(_vm.$t('constants.no_data_available'))+" ")])])])])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start"}),_c('div',{staticClass:"col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end"},[_c('el-pagination',{attrs:{"background":"","layout":"prev, pager, next","hide-on-single-page":false,"page-count":_vm.pages,"page-size":parseInt(_vm.pagination.rowsPerPage),"total":_vm.pagination.total},on:{"current-change":_vm.setCurrent}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }