<template>
    <div class="modal fade" id="modal_new_airport_pickup" ref="addNewAirportPickup" tabindex="-1" aria-hidden="true">
        <!--begin::Modal dialog-->
        <div class="modal-dialog modal-dialog-centered mw-650px">
            <!--begin::Modal content-->
            <div class="modal-content">
                <!--begin::Modal header-->
                <div class="modal-header" id="modal_new_airport_pickup_header">
                    <!--begin::Modal title-->
                    <h2 class="fw-bolder">
                        {{
                            mode == "add"
                            ? $t("airports_pickup.new")
                            : $t("airports_pickup.update")
                        }}
                    </h2>
                    <!--end::Modal title-->

                    <!--begin::Close-->
                    <div id="modal_add_airport_pickup_close" data-bs-dismiss="modal"
                        class="btn btn-icon btn-sm btn-active-icon-primary" ref="close">
                        <span class="svg-icon svg-icon-1">
                            <inline-svg src="/media/icons/duotune/arrows/arr061.svg" />
                        </span>
                    </div>
                    <!--end::Close-->
                </div>
                <!--end::Modal header-->
                <!--begin::Form-->
                <el-form @submit.prevent="submit()" :model="form" :rules="rules" ref="formRef">
                    <!--begin::Modal body-->

                    <div class="modal-body py-10 px-lg-17">
                        <div class="row mb-3">
                            <div class="form-group col">
                                <label class="fs-6 mb-2">{{
                                    $t("airports_pickup.airport_name")
                                }}</label>
                                <el-form-item prop="airport" class="mb-0">
                                    <el-autocomplete class="inline-input w-100" v-model="form.airport"
                                        :fetch-suggestions="querySearch"
                                        :placeholder="$t('airports_pickup.search_placeholder')" :trigger-on-focus="false"
                                        @select="handleSelect" value-key="name" @blur="handleBlur()"
                                        @input="resetAirportPickupId">

                                        <template slot-scope="{ item }">
                                            <div class="d-flex align-items-center p-3" style="gap: 10px">
                                                <div class="my-autocomplete__icon">
                                                    <span>
                                                        <inline-svg src="/media/icons/duotune/general/gen018.svg" />
                                                    </span>
                                                </div>
                                                <div class="my-autocomplete__value">
                                                    <span class="name">{{ item.name }}</span>
                                                    <span class="city">{{ item.municipality }}</span>
                                                </div>
                                            </div>
                                        </template>
                                    </el-autocomplete>
                                </el-form-item>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col">
                                <label class="fs-6 mb-2">{{
                                    $t("airports_pickup.price")
                                }}</label>
                                <el-form-item prop="price">
                                    <el-input type="number" v-model="form.price"
                                        :placeholder="$t('airports_pickup.price')">
                                    </el-input>
                                </el-form-item>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer flex-center">
                        <div class="d-flex" style="gap: 10px">
                            <button type="button" class="btn btn-primary" @click="onSubmit" :disabled="disabled">
                                {{$t('constants.save')}}
                            </button>
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" ref="close">{{$t('constants.cancel')}}</button>
                        </div>
                    </div>
                </el-form>
            </div>
        </div>
    </div>
</template>

<script>

import axiosIns from "@/axios.js";
export default {
    components: {

    },
    props: {
        item: Object,
        mode: {
            type: String,
            //default: 'add'
        },
    },
    data() {
        return {
            form: {
                airport: "",
                price: "",
            },
            suggestions: [],
            airport_pickup_id: "",
            rules: {
                airport_id: [
                    {
                        validator: (rule, value, callback) => {
                            console.log(value);
                            if (value && Number.isInteger(this.airport_pickup_id)) {
                                callback();
                            } else {
                                callback(false);
                            }
                        },
                        message: "this field is required",
                        trigger: "blur"
                    },
                ],
                price: [
                    {
                        required: true,
                        message: "this field is required",
                        trigger: "blur",
                    },
                ],
            },
            cities: [],
            disabled: false,
        };
    },
    methods: {
        querySearch(query, cb) {
            axiosIns.get("airports?name=" + query).then((response) => {
                cb(response.data.data);
                this.suggestions = response.data;
            });
        },
        handleSelect(item) {
            this.airport_pickup_id = item.id;
        },
        resetAirportPickupId() {
            this.airport_pickup_id = "";
        },
        handleBlur() {
            if (this.form.airport !== "" && this.airport_pickup_id == "") {
                // No selection made, automatically select the first suggestion
                if (this.suggestions && this.suggestions.length > 0) {
                    this.form.airport = this.suggestions[0].name;
                    this.airport_pickup_id = this.suggestions[0].id;
                }
            }
        },
        onSubmit() {
            this.$refs["formRef"].validate((valid) => {
                if (valid) {
                    const formData = new FormData();
                    /*for (const key in this.form) {
                        if (this.form[key]) {
                            formData.append(key, this.form[key]);
                        }
                    }*/
                    formData.append('airport_id', this.airport_pickup_id);
                    formData.append('price', this.form.price);

                    //this.$swal.showLoading();
                    this.disabled = true;
                    if (this.mode == "add") {
                        axiosIns
                            .post("airports-pickup", formData)
                            .then((response) => {
                                //this.$swal.close();
                                this.disabled = false;
                                this.$notify({
                                    title: "مرحبا",
                                    type: "success",
                                    message: response.data.message,
                                    showClose: true,
                                    position: "top-left",
                                });

                                this.$refs.close.click();
                                this.$emit("success");
                            })
                            .catch((error) => {
                                this.disabled = false;
                            });
                    } else if (this.mode == "edit") {
                        axiosIns
                            .post(
                                "airports-pickup/" + this.item.id + "?_method=put",
                                formData
                            )
                            .then((response) => {
                                //this.$swal.close();
                                this.disabled = false;
                                this.$notify({
                                    title: "مرحبا",
                                    type: "success",
                                    message: response.data.message,
                                    showClose: true,
                                    position: "top-left",
                                });

                                this.$refs.close.click();
                                this.$emit("success");
                            })
                            .catch((error) => {
                                this.disabled = false;
                            });
                    }
                }
            });
        },
    },
    mounted() {
        const modal = this.$refs.addNewAirportPickup;
        modal.addEventListener("shown.bs.modal", () => {
            if (this.mode == "edit") {
                this.form.airport = this.item.name;
                this.airport_pickup_id = this.item.id;
                this.form.price = this.item.price;
            }
        });
        modal.addEventListener("hidden.bs.modal", () => {
            this.form.airport = "";
            this.airport_pickup_id = "";
            this.form.price = "";
        });
    },
};
</script>

<style lang="scss">
.el-autocomplete-suggestion li {
    line-height: 21px !important;
}
.my-autocomplete{
  &__value{
    display: flex;
    flex-direction: column;
    .city {
        font-size: 12px;
    } 
  }
}
</style>