<template>
  <div class="card card-xxl-stretch mb-5 mb-xl-10">
    <!--begin::Header-->
    <div class="card-header align-items-center border-0 mt-4">
      <h3 class="card-title align-items-start flex-column">
        <span class="fw-bolder mb-2 text-dark">{{$t('tours.title')}}</span>
      </h3>

      <div
        class="card-toolbar"
        data-bs-toggle="tooltip"
        data-bs-placement="top"
        data-bs-trigger="hover"
        title="Click to add a user"
      >
        <router-link :to="{ name: 'add-tour' }" class="btn btn-sm btn-primary">
          <span class="svg-icon svg-icon-3">
            <inline-svg src="/media/icons/duotune/arrows/arr075.svg" />
          </span>
         {{$t('tours.new')}}
        </router-link>
      </div>
    </div>
    <div class="card-body py-3">
      <ToursTable />
    </div>
  </div>
</template>

<script>
import ToursTable from "./ToursTable.vue";

export default {
  name: "TourIndex",
  components: {
    ToursTable,
  },
};
</script>
