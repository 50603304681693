<template>
  <div class="modal fade" id="modal_location" ref="locationRef" tabindex="-1" aria-hidden="true">
    <!--begin::Modal dialog-->
    <div class="modal-dialog modal-dialog-centered mw-650px">
      <!--begin::Modal content-->
      <div class="modal-content">
        <!--begin::Modal header-->
        <div class="modal-header" id="modal_location_header">
          <!--begin::Modal title-->
          <h2 class="fw-bolder">{{ $t('cars.select_location') }}</h2>
          <!--end::Modal title-->

          <!--begin::Close-->
          <div id="modal_add_reservation_close" data-bs-dismiss="modal"
            class="btn btn-icon btn-sm btn-active-icon-primary" ref="close">
            <span class="svg-icon svg-icon-1">
              <inline-svg src="/media/icons/duotune/arrows/arr061.svg" />
            </span>
          </div>
          <!--end::Close-->
        </div>
        <!--end::Modal header-->
        <div class="modal-body py-10 px-lg-17">
          <div class="row" style="margin-bottom: 22px;">
            <el-form>
              <div class="d-flex align-items-end" style="gap: 10px;">
                <div class="form-group text-start" style="flex: 1;">
                  <label class="fs-6 mb-2">{{ $t('cars.coordinates') }}</label>
                  <el-form-item class="mb-0">
                    <el-input v-model="address" disabled></el-input>
                  </el-form-item>
                </div>
                <button type="button" class="btn btn-sm btn-light-primary" @click="getCurrentLocation()">
                  {{ $t("tours.currentLocation") }}
                </button>
              </div>
              <div class="row mt-3">
                <label class="fs-6 mb-2">{{ $t("tours.address") }}</label>
                <p>{{ formatted_address }}</p>
              </div>
            </el-form>
          </div>
          <div class="row">
            <GmapMap :center="coord" :zoom="7" map-type-id="terrain" style="width: 100%; height: 350px" ref="mapRef">
              <GmapMarker ref="myMarker" :position="coord" :draggable="true" @dragend="updateCoordinates" />
            </GmapMap>
          </div>
        </div>
        <!--begin::Modal footer-->
        <div class="modal-footer flex-center">
          <!--begin::Button-->
          <div>
            <el-button type="primary" @click="confirmAddress">
              {{ $t('constants.confirm') }}
            </el-button>
          </div>

          <!--end::Button-->
        </div>
        <!--end::Modal footer-->
      </div>
    </div>
  </div>
</template>
  
<script>
export default {
  name: 'LocationModal',
  props: {
    location_type: String,
    coordinates: Object
  },
  data() {
    return {
      coord: {
        lat: 21.48436249892535,
        lng: 39.1924169425293,
      },
      address: "",
      formatted_address: ""
    };
  },
  watch: {
    coordinates(val) {
      if ((val.lat != undefined || !isNaN(val.lat)) && (val.lng != undefined || !isNaN(val.lng))) {
        this.coord = val;
        this.address = val.lat + ", " + val.lng;
        const geocoder = new google.maps.Geocoder();
        //const { latLng } = location;
        //const geocoder = new google.maps.Geocoder();
        const latlng = { lat: val.lat, lng: val.lng };
        geocoder.geocode({ location: latlng }, (results, status) => {

          if (status === 'OK') {
            if (results[0]) {
              this.formatted_address = results[0].formatted_address;
            } else {
              this.formatted_address = 'No results found';
            }
          } else {
            this.formatted_address = 'Geocoder failed due to: ' + status;
          }
        });
      }

    }
  },
  methods: {
    getCurrentLocation() {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          this.address =
            position.coords.latitude + ", " + position.coords.longitude;
          this.coord.lat = position.coords.latitude;
          this.coord.lng = position.coords.longitude;
          this.updateCoordinates();
        },
        (error) => {
          console.log(error.message);
        }
      );
    },
    confirmAddress() {
      if (this.address != "") {
        const coordinates = {
          "coordinates": this.address,
          "formatted_address": this.formatted_address
        }
        this.$emit("confirmAddress", coordinates);
        this.$refs.close.click();
      } else {
        alert("Please select a location");
      }
    },
    updateCoordinates(location) {
      //console.log(location);
      const geocoder = new google.maps.Geocoder();
      const { latLng } = location;
      //const geocoder = new google.maps.Geocoder();
      const latlng = { lat: latLng.lat(), lng: latLng.lng() };

      geocoder.geocode({ location: latlng }, (results, status) => {

        if (status === 'OK') {
          if (results[0]) {
            this.formatted_address = results[0].formatted_address;
          } else {
            this.formatted_address = 'No results found';
          }
        } else {
          this.formatted_address = 'Geocoder failed due to: ' + status;
        }
      });

      this.address = location.latLng.lat() + ", " + location.latLng.lng();
      this.coord.lat = location.latLng.lat();
      this.coord.lng = location.latLng.lng();
    },
  },

  mounted() {
    //this.coord = this.coordinates;
    const modal = this.$refs.locationRef;
    modal.addEventListener("hidden.bs.modal", () => {
      this.address = "";
      this.formatted_address = "";
    });
  }
};
</script>
