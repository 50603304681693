<template>
    <div>
      <CarForm :submitType="'add'" />
    </div>
  </template>
  
  <script>
  import CarForm from "./form.vue";
  export default {
    name: "AddCar",
    components: {
        CarForm,
    },
  };
  </script>