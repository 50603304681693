<template>
  <div class="card">
    <div class="card-header border-0 pt-6">
      <!--begin::Card title-->
      <div class="card-title">
        <!--begin::Search-->
        <div class="d-flex align-items-center position-relative my-1">
          <span class="svg-icon svg-icon-1 position-absolute" :class="direction == 'rtl' ? 'me-6' : 'ms-6'">
            <svg fill="none" viewBox="0 0 24 24" height="24" width="24" xmlns="http://www.w3.org/2000/svg">
              <rect xmlns="http://www.w3.org/2000/svg" opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2"
                rx="1" transform="rotate(45 17.0365 15.1223)" fill="black"></rect>
              <path xmlns="http://www.w3.org/2000/svg"
                d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                fill="black"></path>
            </svg>
          </span>
          <input type="text" v-model="search" @input="searchItems()"
            class="form-control form-control-solid w-250px table-search-input" :placeholder="$t('cars.search_cars')" :class="direction == 'rtl' ? 'pe-15' : 'ps-15'" />
        </div>
        <!--end::Search-->
      </div>
      <!--begin::Card title-->
      <!--begin::Card toolbar-->
      <!--<div class="card-toolbar">
          
          <div
            v-if="checkedCustomers.length > 0"
            class="d-flex justify-content-end align-items-center"
            data-kt-customer-table-toolbar="selected"
          >
            <div class="fw-bolder me-5">
              <span class="me-2">{{ checkedCustomers.length }}</span
              >Selected
            </div>
            <button
              type="button"
              class="btn btn-danger"
              @click="deleteFewCustomers()"
            >
              Delete Selected
            </button>
          </div>
          <!- -end::Group actions- ->
          <!- -begin::Group actions- ->
          <div
            class="d-flex justify-content-end align-items-center d-none"
            data-kt-customer-table-toolbar="selected"
          >
            <div class="fw-bolder me-5">
              <span
                class="me-2"
                data-kt-customer-table-select="selected_count"
              ></span
              >Selected
            </div>
            <button
              type="button"
              class="btn btn-danger"
              data-kt-customer-table-select="delete_selected"
            >
              Delete Selected
            </button>
          </div>
          <!- -end::Group actions- ->
        </div>-->
      <!--end::Card toolbar-->
    </div>
    <div class="card-body pt-0">
      <Datatable :table-header="tableHeader" :enable-items-per-page-dropdown="true" :url="url" :searchTermProp="search"
        ref="datatable">
        <template v-slot:cell-checkbox="{ row: item }">
          <div class="form-check form-check-sm form-check-custom form-check-solid">
            <input class="form-check-input" type="checkbox" :value="item.id" v-model="checkedCustomers" />
          </div>
        </template>
        <template v-slot:cell-make_id="{ row: item }">
          <span>{{ item.make }}</span>
        </template>
        <template v-slot:cell-model_id="{ row: item }">
          <span>{{ item.model }}</span>
        </template>
        <template v-slot:cell-car_model_generation="{ row: item }">
          <span>{{ item.car_model_generation }}</span>
        </template>
        <template v-slot:cell-year="{ row: item }">
          <span>{{ item.year }}</span>
        </template>
        <template v-slot:cell-transmission_type="{ row: item }">
          <span>{{ item.transmission_type }}</span>
        </template>
        <template v-slot:cell-engine_type="{ row: item }">
          <span>{{ item.engine_type }}</span>
        </template>
        <template v-slot:cell-image="{ row: item }">
          <img :src="item.image ? item.image : $store.state.defaultImage" alt="" class="img-fluid">
        </template>
        <template v-slot:cell-daily_price="{ row: item }">
          {{ formatMoney(item.daily_price, currency) }}
        </template>
        <template v-slot:cell-country="{ row: item }">
          {{ item.country }}
        </template>
        <template v-slot:cell-city="{ row: item }">
          {{ item.city }}
        </template>
        <!--<template v-slot:cell-bags="{ row: item }">
            {{ item.bags }}
          </template>
          <template v-slot:cell-capacity="{ row: item }">
            {{ item.capacity }}
          </template>-->
        <template v-slot:cell-doors="{ row: item }">
          {{ item.doors }}
        </template>
        <template v-slot:cell-enabled="{ row: item }">
          <div class="form-check form-switch">
            <input class="form-check-input" type="checkbox" role="switch" id="change-status" :checked="item.enabled == 1" @change="changeCarStatus(item.id)">
          </div>
        </template>
        <template v-slot:cell-actions="{ row: item }">
          <div class="text-start d-flex align-items-center" style="gap: 10px">
            <router-link :to="{ name: 'edit-car', params: { id: item.id } }"
              class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
              <span class="svg-icon svg-icon-1">
                <inline-svg src="/media/icons/duotune/general/gen055.svg" />
              </span>
            </router-link>
            <!--<a
                  href="#"
                  class="
                    btn btn-icon btn-bg-light btn-active-color-primary btn-sm
                    me-1
                  "
                >
                  <span class="svg-icon svg-icon-3">
                    <inline-svg src="/media/icons/duotune/art/art005.svg" />
                  </span>
                </a>-->
            <a href="#" class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm" data-bs-toggle="modal"
              data-bs-target="#modal_delete_item" @click="deleteCar(item.id)">
              <span class="svg-icon svg-icon-3">
                <inline-svg src="/media/icons/duotune/general/gen027.svg" />
              </span>
            </a>
          </div>
        </template>
      </Datatable>
    </div>
  </div>
</template>
  
<script>
import Datatable from "@/components/kt-datatable/Datatable.vue";
import { MenuComponent } from "@/assets/components";
import axiosIns from "@/axios.js";
import helpers from "@/helpers";
export default {
  name: "orders-table",
  components: {
    Datatable,
  },
  data() {
    return {
      url: "cars",
      MenuComponent: MenuComponent,
      currency: this.$store.state.user.currency,
      checkedCustomers: [],
      tableHeader: [
        {
          key: "checkbox",
        },
        {
          name: this.$t('cars.make'),
          key: "make_id",
          sortable: true,
        },
        {
          name: this.$t('cars.model'),
          key: "model_id",
          sortable: true,
        },
        {
          name: this.$t('cars.car_model_generation'),
          key: "car_model_generation",
          sortable: true,
        },
        {
          name: this.$t('cars.year'),
          key: "year",
          sortable: true,
        },
        {
          name: this.$t('cars.transmission_type'),
          key: "transmission_type",
          sortable: false,
        },
        {
          name: this.$t('cars.engine_type'),
          key: "engine_type",
          sortable: false,
        },
        {
          name: this.$t('cars.image'),
          key: "image",
          sortable: true,
        },
        {
          name: this.$t('cars.daily_price'),
          key: "daily_price",
          sortable: true,
        },
        {
          name: this.$t('cars.country_id'),
          key: "country",
          sortable: true,
        },
        {
          name: this.$t('cars.city_id'),
          key: "city",
          sortable: true,
        },
        {
          name: this.$t('cars.doors'),
          key: "doors",
          sortable: true,
        },
        {
          name: this.$t('constants.isEnable'),
          key: "enabled"
        },
        {
          name: this.$t('constants.actions'),
          key: "actions",
        },
      ],

      initCustomers: [],
      search: "",
    };
  },
  computed: {
    direction(){
      return this.$store.state.direction;
    }
  },
  methods: {
    formatMoney(amount, currency) {
      return helpers.formatMoney(amount, currency);
    },
    deleteCar(id) {
      this.$swal(
        {
          title: this.$t('constants.are_you_sure'),
        text: this.$t('constants.delete_desc'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: this.$t('constants.cancel'),
        confirmButtonText: this.$t('constants.delete_btn_confirmation')
        }).then((result) => {
          if (result.isConfirmed) {

            axiosIns.delete(`cars/${id}`)
              .then((response) => {
                this.$swal({
                  title: 'Deleted!',
                  text: response.data.message,
                  icon: 'success'
                })
                this.$refs.datatable.loadItems();
              })
          }
        })
    },
    orderStatus(status) {
      if (status == "pending") {
        return "bg-warning text-dark";
      } else if (
        status == "canceled" ||
        status == "canceled by user" ||
        status == "rejected"
      ) {
        return "bg-danger";
      } else if (status == "accepted" || status == "completed") {
        return "bg-success";
      } else if (status == "started" || status == "driver arrived") {
        return "bg-info";
      }
    },
    deleteFewCustomers() {
      this.checkedCustomers.forEach((item) => {
        this.deleteCustomer(item);
      });
      this.checkedCustomers.length = 0;
    },

    deleteCustomer(id) {
      for (let i = 0; i < this.customers.length; i++) {
        if (this.customers[i].id === id) {
          this.customers.splice(i, 1);
        }
      }
    },

    searchItems() {
      this.customers.splice(0, this.customers.length, this.initCustomers);
      if (this.search !== "") {
        let results = [];
        for (let j = 0; j < this.customers.length; j++) {
          if (this.searchingFunc(this.customers[j], this.search)) {
            results.push(this.customers[j]);
          }
        }
        this.customers.splice(0, this.customers.length, results);
      }
    },

    searchingFunc(obj, value) {
      for (let key in obj) {
        if (!Number.isInteger(obj[key]) && !(typeof obj[key] === "object")) {
          if (obj[key].indexOf(value) != -1) {
            return true;
          }
        }
      }
      return false;
    },

    changeCarStatus(id) {
      const loadingDialog = this.$swal.fire({
        allowOutsideClick: false,
        didOpen: () => {
          this.$swal.showLoading();
        }
      });

      axiosIns.post(`cars/change-status/${id}`)
        .then((response) => {
          loadingDialog.close();
          this.$notify({
            title: "Success",
            type: "success",
            message: response.data.message,
            showClose: true,
            position: "top-left",
          });

        })
    }
  },
  mounted() {
    this.MenuComponent.reinitialization();
    this.initCustomers.splice(0, this.customers.length, this.customers);
  },
};
</script>
<style>
.table-search-input {
  height: 40px !important;
}
</style>
  